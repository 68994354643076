import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { LangContext } from 'App'
import {
  updateApplicationDataToStore,
  upsertApplicationData
} from 'client/actions'
import { ApplicationDto } from 'client/api/application/api'
import DialogNotice from 'client/components/common/noticeDialog'
import { Transition } from 'client/components/common/transition'
import FormError from 'client/components/formErrorMsg'
import { principalInvestment as investments } from 'config'
import _debounce from 'lodash/debounce'
import _toInteger from 'lodash/toInteger'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import validationSchema from 'schema'
import Dialog1en from './dialog1en'
import Dialog1zh from './dialog1zh'
import { useStyles } from './style'
import moment from 'moment'

export const getFormDataMapping = (state: ApplicationDto, type: string) => {
  let result: any = {}
  const distribution: any =
    type === 'mandate'
      ? state.investmentMandateMandatory
      : state.investmentMandateVolunteer
  for (let fundCode in distribution) {
    const val: any = distribution[fundCode]
    result[fundCode] = val
  }

  return result
}

const FormInvestmentMandate = ({
  props,
  activeStep,
  type,
  goNextStep,
}: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [openDialogValErr, setOpenDialogValErr] = useState(false)
  const lang = useContext(LangContext)
  const classes = useStyles()

  const handleChange = _debounce((text: string) => {
    updateTotal()
  }, 300)

  const updateTotal = () => {
    const values: any = getValues()
    let total = 0
    // console.log('values', values)
    for (let row in values) {
      const val = values[row]
      if (row !== 'totalInput' && val && !isNaN(val)) {
        total += _toInteger(val)
        // console.log('row:', row, 'val:', val, total)
      }
    }

    setTotal(total)
    setValue('totalInput', total)
    trigger('totalInput')
  }

  const hookFormFunc = useForm({
    resolver: yupResolver(validationSchema.INVESTMENT_MANDATE),
    mode: 'onBlur',
  })
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    trigger,
    setValue,
  } = hookFormFunc

  const onSubmit = (formData: any) => {
    console.log('formData', formData)

    const distribution: any = {}
    for (let fundCode in formData) {
      const val = formData[fundCode]
      if (fundCode !== 'totalInput' && val && !isNaN(val)) {
        distribution[fundCode] = _toInteger(val)
      }
    }

    let total = 0
    for (var key in distribution) {
      total += distribution[key]
    }

    if (total === 0) {
      setOpen(true)
    } else {
      submit(distribution)
    }
  }

  const submit = (distribution: any) => {
    if (type === 'mandate') {
      dispatch(
        updateApplicationDataToStore({
          investmentMandateMandatory: distribution,
        } as ApplicationDto),
      )
    } else {
      dispatch(
        updateApplicationDataToStore({
          investmentMandateVolunteer: distribution,
        } as ApplicationDto),
      )
    }

    //save via api
    ;(dispatch(upsertApplicationData(true)) as any)
      .then((result: any) => {})
      .catch((error: any) => {
        console.error(error)
      })

    if (goNextStep) {
      goNextStep()
    }
  }

  const setFormData = () => {
    // console.log('setFormData', state)
    const map = getFormDataMapping(state, type)
    for (let [key, value] of Object.entries(map)) {
      // console.log(`${key}: ${value}`)
      setValue(key, value)
    }
    updateTotal()
  }

  const state: ApplicationDto = useSelector((state: any) => {
    return state.application.data as ApplicationDto
  })

  useEffect(() => {
    setFormData()
  }, [state])

  const handleClose = (accept: boolean) => {
    if (accept) {
      submit({})
    }
    setOpen(accept)
  }
  
  // useEffect(() => {
  //   setOpenDialogValErr(true)
  // }, [])

  return (
    <div>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.h3} variant='h3'>
            {t('Investment Allocation')}
            {type === 'mandate' ? (
              <Typography
                variant='caption'
                className={classes.caption}
                display='inline'
              >
                {' '}
                - {t('Mandatory Contribution')}
              </Typography>
            ) : (
              <Typography
                variant='caption'
                className={classes.caption}
                display='inline'
              >
                {' '}
                - {t('Voluntary Contribution')}
              </Typography>
            )}
          </Typography>

          <FormProvider {...hookFormFunc}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t('Name of Constituent Funds')}
                          </TableCell>
                          <TableCell>{t('Fund Code')}</TableCell>
                          <TableCell>{t('Risk / Return Profile')}</TableCell>
                          <TableCell align='right'>
                            {t('Investment Allocation')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {investments.map((row, idx) => (
                          <TableRow key={idx}>
                            <TableCell component='th' scope='row' align='left'>
                              {row.infoUrl ? (
                                <Link
                                  rel='noreferrer'
                                  href={row.infoUrl}
                                  target='_blank'
                                >
                                  {t(row.name)}
                                </Link>
                              ) : (
                                <>{t(row.name)}</>
                              )}
                            </TableCell>
                            <TableCell align='left'>{row.code}</TableCell>
                            <TableCell align='left'>
                              {(() => {
                                switch (row.risk) {
                                  case 'L':
                                    return (
                                      <div
                                        className={`${classes.risk} ${classes.riskL}`}
                                      ></div>
                                    )
                                  case 'M':
                                    return (
                                      <div
                                        className={`${classes.risk} ${classes.riskM}`}
                                      ></div>
                                    )
                                  case 'MH':
                                    return (
                                      <div
                                        className={`${classes.risk} ${classes.riskMH}`}
                                      ></div>
                                    )
                                  case 'ML':
                                    return (
                                      <div
                                        className={`${classes.risk} ${classes.riskML}`}
                                      ></div>
                                    )
                                  case 'H':
                                    return (
                                      <div
                                        className={`${classes.risk} ${classes.riskH}`}
                                      ></div>
                                    )
                                  default:
                                    return null
                                }
                              })()}
                              &nbsp;{t('risk_' + row.risk)}
                            </TableCell>
                            <TableCell align='right'>
                              <TextField
                                margin='none'
                                name={`${row.code}`}
                                inputRef={register}
                                type='number'
                                onChange={(e: any) =>
                                  handleChange(e.target.value)
                                }
                                inputProps={{
                                  min: 0,
                                  max: 100,
                                  pattern: '[0-9]+',
                                  step: 1,
                                }}
                              />
                              <br />
                              <FormError errors={errors[`${row.code}`]} />
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={4} align='right'>
                            {total} %
                            <input
                              type='hidden'
                              value={total}
                              name='totalInput'
                              ref={register}
                            />
                            <br />
                            <FormError errors={errors.totalInput} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Paper>
              <Button
                disabled={Object.keys(errors).length > 0}
                color='primary'
                variant='contained'
                type='submit'
              >
                {t('Save & Next')}
              </Button>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {t('Invest all contributions in DIS?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {t('summaryInvestNote')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color='primary'>
            {t('Disagree')}
          </Button>
          <Button onClick={() => handleClose(true)} color='primary'>
            {t('Agree')}
          </Button>
        </DialogActions>
      </Dialog>
      
      <DialogNotice
        open={openDialogValErr}
        handleClose={() => {
          setOpenDialogValErr(false)
        }}
        title={t('IMPORTANT NOTICE')}
      >
        {lang === 'en' ? (
          <Dialog1en />
        ) : (
          <Dialog1zh />
        )}
      </DialogNotice>
    </div>
  )
}

export default FormInvestmentMandate
