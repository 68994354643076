const t = {
  'pirs-cc1': 'PIRS – Personal Information Collection Statement',
  'pirs-cc2': 'Appointment of Investment &Retirement Consultant',
  'pirs-cc3': 'Client Confirmations',
  'pirs-cc4': 'FNA',
  'pirs-cc4a':
    'I understand the above but I opt NOT to complete the "Financial Needs Analysis" to determine my attitude towards risk and I understand that the registered intermediary will not provide any regulated advice to me in relation to a particular constituent fund.',
  'pirs-cc4b':
    'I understand the above and will complete the "Financial Needs Analysis" before making my decision.',
  'pirs-cc5': 'Rationale underlying the advice:',
  'pirs-cc5a':
    'Not applicable. During the sales process, I have not received any advice',
  'pirs-cc5b':
    'I understand the advice given to me in the sales process is based on the following rationale',
  'pirs-cc5b1': 'Schemes and fund choices',
  'pirs-cc5b2': 'Fund management fees',
  'pirs-cc5b3': 'Comprehensive services',
  'pirs-cc5b4': 'Others',
  'pirs-cc6':
    'The sales process involves claims that the Scheme/constituent fund(s) is/are preferred over the scheme/fund participated in/invested by me.',
  'pirs-cc6a':
    'Yes, the registered intermediary has explained to me the differences between the Scheme/constituent fund(s) and the scheme/constituent fund(s) participated in/invested by me and the benefits, or potential benefits of conducting the transfer.',
  'pirs-cc6b':
    'No, the sales process does not involve comparison between the Scheme/constituent fund(s) and the scheme/fund participated in/invested by me.',
  'pirs-cc7':
    'I hereby confirm that I fully understand the information provided by and discussed with the registered intermediary and I am able to make that material decision (material decision refers to choose a particular constituent fund; make a transfer that would involve a transfer out of a guaranteed fund; making an early withdrawal of accrued benefits from the MPF System; or making voluntary contributions into a particular registered scheme or a particular constituent fund.).',
  'pirs-cc7a': 'Yes',
  'pirs-cc7b': 'No',
  'pirs-cc8': 'Education Level',
  'pirs-cc8a': 'Primary School or below',
  'pirs-cc8b': 'Secondary School',
  'pirs-cc8c': 'Tertiary or above',
  'pirs-cc9': 'I am visually or otherwise impaired.',
  'pirs-cc9a': 'Yes',
  'pirs-cc9b': 'No',
  'pirs-cc10': 'Sales Process',
  'pirs-cc10a':
    '(A) I would like to be accompanied by a companion to witness the relevant sales process and constituent fund selection process.',
  'pirs-cc10b':
    '(B) I would like to have an additional staff to witness the relevant sales process and constituent fund selection process.',
  'pirs-cc10c':
    '(C) I would like to be accompanied by a companion and an additional staff to witness the relevant sales process and constituent fund selection process.',
  'pirs-cc10d':
    '(D) I would like to make the decision by myself and I understand a post-sale call will be conducted by an authorized person of the principal intermediary.',
  'pirs-cc11': 'Confirmation on Transfer Out of Guaranteed Fund',
  'pirs-cc12': 'Client Agreement',
  'Client Confirmations': 'Client Confirmations',
  'PIRS – PERSONAL INFORMATION COLLECTION STATEMENT':
    'PIRS – PERSONAL INFORMATION COLLECTION STATEMENT',
  'Important Notes': 'Important Notes',
  'pirs-rpq1': 'RPQ – Important Notes',
  'pirs-rpq13': 'Important notice and disclaimer',
  'Important notice and disclaimer': 'Important notice and disclaimer',
  'Total Score': 'Total Score',
  'Investment Style': 'Investment Style',
  'Confirmation on Transfer Out of Guaranteed Fund':
    'Confirmation on Transfer Out of Guaranteed Fund',
  'Client Agreement': 'Client Agreement',
  'pirs-sum1': 'Confirmation from Client(s) of Receiving Product Brochure and Relevant Document(s) (MPF)',
  'pirs-sum2': 'MPF latest version of offering document (including MPF Scheme Brochure, Key Scheme Information Document and any addenda)',
  'pirs-sum3': 'Personal Information Collection Statement [Client Copy]',
  'pirs-sum4': 'Disclosure of Sales Related Information',
  'pirs-rpq-q1': 'Which age group do you belong to?',
  'pirs-rpq-q2': 'What is your knowledge of financial markets and investments?',
  'pirs-rpq-q3': 'What is your Monthly Personal / Household Income?',
  'pirs-rpq-q4': 'What is your Monthly Personal / Household Surplus?',
  'pirs-rpq-q5':
    'Current Personal Net Asset (HK$) (include savings, equities, bonds, funds, derivatives and other investments but excluding the value of self-occupied properties):',
  'pirs-rpq-q6':
    'What percentage of your total net liquid assets will you invest in investment products? (Liquid assets means that the assets may be easily turned into cash within a short period of time)',
  'pirs-rpq-q7':
    'What level of potential loss on your original investment product would be generally acceptable to you?',
  'pirs-rpq-q8':
    'If you want to make investment, which type of investments are you most',
  'pirs-rpq-q9': 'What is your expected investment duration?',
  'pirs-rpq-q10':
    'Which of the following best describes your investment objective?',
  'pirs-rpq-q11':
    'In meeting your long-term objectives, how would you feel if there is a decline in the value of your capital in the first couple of years of your investment(s) ?',
  'pirs-rpq-q12':
    'Which of the following best describes your knowledge on derivatives leverage?',
  'pirs-rpq-a2a':
    'None, I have no knowledge in any financial markets and investment knowledge.',
  'pirs-rpq-a2b':
    'Low, I have some basic knowledge of financial markets such as stocks and bonds and the importance of diversification.',
  'pirs-rpq-a2c':
    'Medium, I have some knowledge of financial markets and will read and/or listen to investment or financial related news.',
  'pirs-rpq-a2d':
    'High, I know the features of some financial products, and will review investment related materials from various sources.',
  'pirs-rpq-a2e':
    'Advanced, I am familiar with most financial products and understand the various factors affecting the risk and performance of these financial products.',
  'pirs-rpq-a8a': 'HKD, RMB or USD Cash Savings',
  'pirs-rpq-a8b': 'Foreign Currency or precious Metal',
  'pirs-rpq-a8c': 'Bond or Guaranteed Fund',
  'pirs-rpq-a8d': 'Stock or Open End Fund',
  'pirs-rpq-a8e': 'Structured Products or Derivatives',
  'pirs-rpq-a9a': '< 2 years',
  'pirs-rpq-a9b': '2 - 5 years',
  'pirs-rpq-a9c': '6 - 10 years',
  'pirs-rpq-a9d': '11 - 15 years',
  'pirs-rpq-a9e': '> 15 years',
  'pirs-rpq-a10a': 'Capital preservation',
  'pirs-rpq-a10b': 'Stable regular income such as dividends',
  'pirs-rpq-a10c': 'eBalance between regular income and capital appreciation',
  'pirs-rpq-a10d': 'Stable capital appreciation',
  'pirs-rpq-a10e': 'Maximize capital appreciation by taking maximum risk',
  'pirs-rpq-a11a':
    'I am not willing to take any risk and will be disappointed if my investment(s) dropped in value',
  'pirs-rpq-a11b':
    'I am willing to accept a lower rate of return as long as fluctuations in the value of my investment(s) are small',
  'pirs-rpq-a11c':
    'I am willing to take a moderate level of risk in return of a chance to get a better rate of return',
  'pirs-rpq-a11d':
    'I generally invest for the long-term, but would be concerned with the big fluctuation and the decline',
  'pirs-rpq-a11e':
    'I invest for the long term and would accept these fluctuations associated with higher risk as part of my desire for higher returns',
  'pirs-rpq-a12a': 'None',
  'pirs-rpq-a12b': '< 1 year',
  'pirs-rpq-a12c': '1 - < 3 years',
  'pirs-rpq-a12d': '3 - < 5 years',
  'pirs-rpq-a12e': '≥ 5 years',
  'pirs-rpq-r1': 'Conservative',
  'pirs-rpq-r2': 'Moderate Conservative',
  'pirs-rpq-r3': 'Moderate',
  'pirs-rpq-r4': 'Moderate Aggressive',
  'pirs-rpq-r5': 'Aggressive',
  'pirs-rpq-r1desc':
    'You understand and accept taking minimized risk for minimal capital appreciation. You tend to prefer investments with a low risk of a decline in value. You are more interested in preserving the value of investment than receiving a return on your capital.',
  'pirs-rpq-r2desc':
    'You understand and accept taking low risk for low capital appreciation. You are prepared to tolerate modest fluctuation and volatility in your investment.',
  'pirs-rpq-r3desc':
    'You understand and accept taking moderate risk for moderate capital appreciation. You like the potential for some growth, but are more concerned with keeping what you have. You are prepared to have slight exposure in dynamic investment to help you keep ahead of inflation as well as enhance long-term potential return.',
  'pirs-rpq-r4desc':
    'You understand and accept taking high risk for high capital appreciation. A moderate aggressive strategy suits your investment appetite. Even though this strategy may come with higher level of fluctuation, it has the potential to provide higher long-term returns regardless the ups and downs in the short run that might discourage you.',
  'pirs-rpq-r5desc':
    "You understand and accept taking maximized risk for maximum capital appreciation. You're willing to bear higher risk for potentially substantial earnings even if periods of strong gains as well as significant falls in value of your investment are likely in future.",
  'summary-dialog1':
    'I confirm that the whole selling process of my applied product(s) was conducted in Hong Kong on or before the application date. I also confirm that no one has approached me to conduct any selling related to my applied product(s) in any jurisdiction outside Hong Kong.',
  'soft copy': 'Soft Copy',
  'hard copy': 'Hard Copy',
  'Risk Profile Questionnaire': 'Risk Profile Questionnaire',
  'witness error':
    'The current version of the system is unable to support application which needs a witness. Please use paper form to apply if necessary.',
  witness: 'Witness',
  'Assets': 'Assets',
  'In Curreny': 'In Curreny',
  'Savings (including cash, deposits)': 'Savings (including cash, deposits)',
  'Life insurance coverage': 'Life insurance coverage',
  'Stock/Securities/Mutual Funds/Bonds': 'Stock/Securities/Mutual Funds/Bonds',
  'Other assets/Company benefits (including ORSO, MPF)':
    'Other assets/Company benefits (including ORSO, MPF)',
  'Total Usable Assets': 'Total Usable Assets',
  Expense: 'Expense',
  'Monthly living expenses': 'Monthly living expenses',
  'Monthly family expenses (such as rental, utilities, education)':
    'Monthly family expenses (such as rental, utilities, education)',
  'Monthly mortgage redemption': 'Monthly mortgage redemption',
  'Monthly dependent cost': 'Monthly dependent cost',
  'Other monthly expenses (such as tax liabilities)':
    'Other monthly expenses (such as tax liabilities)',
  'Total monthly expenses': 'Total monthly expenses',
  'Annual Expenses': 'Annual Expenses',
  Income: 'Income',
  'Monthly salary': 'Monthly salary',
  'Monthly bonus': 'Monthly bonus',
  'Other monthly income (such as rental, dividend, etc)':
    'Other monthly income (such as rental, dividend, etc)',
  'Total monthly income': 'Total monthly income',
  'Annual income': 'Annual income',
  Liabilities: 'Liabilities',
  'Outstanding mortgage': 'Outstanding mortgage',
  'Outstanding debt/loans': 'Outstanding debt/loans',
  'Other liabilities (such as tax liabilities':
    'Other liabilities (such as tax liabilities',
  'Total Protection Needs': 'Total Protection Needs',
  'Existing Wealth Management Plan': 'Existing Wealth Management Plan',
  'Product Provider': 'Product Provider',
  'Plan Type': 'Plan Type',
  'Plan Value': 'Plan Value',
  'Effective Date': 'Effective Date',
  'Contribution Period': 'Contribution Period',
  Contribution: 'Contribution',
  'Product Provider (e.g. Insurance Company, Bank)':
    'Product Provider (e.g. Insurance Company, Bank)',
  'Plan Type / Name (e.g. ILAS, Mutual Funds, education funds, etc.)':
    'Plan Type / Name (e.g. ILAS, Mutual Funds, education funds, etc.)',
  'Plan Value (e.g. HK$2,000,000)': 'Plan Value (e.g. HK$2,000,000)',
  'Contribution Paying Period (e.g. Single Contribution / 20 monthly Years)':
    'Contribution Paying Period (e.g. Single Contribution / 20 monthly Years)',
  'Contribution (e.g. HK$5,000 per month)':
    'Contribution (e.g. HK$5,000 per month)',
  'Financial Goal': 'Financial Goal',
  'pirs-fna-source-of-fund-salary': 'Salary',
  'pirs-fna-source-of-fund-other-investment': 'Income from other investments ',
  'pirs-fna-source-of-fund-income': 'Income',
  'pirs-fna-source-of-fund-accumulative-saving':
    'Accumulative savings and investments',
  'pirs-fna-source-of-fund-saving': 'Savings',
  'pirs-fna-source-of-fund-others': 'Others',
  'pirs-fna-goal-saving': 'Savings',
  'pirs-fna-goal-investment': 'Investment',
  'pirs-fna-goal-education': 'Education',
  'pirs-fna-goal-retirement': 'Retirement',
  'pirs-fna-goal-others': 'Others',
  'pirs-fna-goal-01':
    'Source of Wealth and Funds (you may tick more than one)',
  'pirs-fna-goal-02':
    'What are your financial goal(s)? (You may tick more than one)',
  MONTHLY_20_YAERS: '20 Years (Monthly)',
  SINGLE_PAYMENT: 'Single Payment',
  EDUCATION_FUNDS: 'Education Funds',
  MUTUAL_FUNDS: 'Mutual Funds',
  'Risk Disclosure Statement': 'Risk Disclosure Statement',
  'Declaration of the PIRS Investment Consultant': 'Declaration of the PIRS Investment Consultant',
  'Declaration and Acknowledgment of the Applicant': 'Declaration and Acknowledgment of the Applicant',
  'declaration statement': 'Risk Disclosure Statement ("Statement")',
  'pirs-inv-1': 'Are you an existing PIRS client',
  'pirs-inv-2': 'If yes, please provide the submission date of your latest Financial Needs Analysis form (FNA)',
  'Employed': 'Employed',
  'Self-employed': 'Self-employed',
  'Unemployed': 'Unemployed',
  'Employment Status': 'Employment Status',
  'pirs-inv-3': 'Important Reminder for Recommendations (To be completed by licensed consultant)',
  'pirs-inv-4': 'Assumption - Annual interest rate of bank savings',
  'pirs-inv-5': 'Assumption - Average inflation rate (Yearly)',
  'pirs-inv-6': 'Reasons of recommendations',
  'pirs-inv-7': 'Material Queries and Responses',
  'pirs-inv-8': 'Material queries raised by client',
  'pirs-inv-9': 'Responses by investment consultant',
  'Clients Declaration': 'Client’s Declaration',
  'Investment Consultants Declaration': 'Investment Consultant’s Declaration',
  'Holding Name': 'Holding Name',
  'Code': 'Code',
  'PIRS Risk Level': 'PIRS Risk Level',
  'Amount': 'Amount',
  'Weight': 'Weight',
  'Recommended Actions': 'Recommended Actions',
  'Holdings': 'Holdings',
  'Evaluation': 'Evaluation',
  'Existing Holdings': 'Existing Holdings',
  'Portfolio Weighted Average Risk Level': 'Portfolio Weighted Average Risk Level',
  'buy': 'Buy',
  'hold': 'Hold',
  'sell': 'Sell',
  'Do you want to perform FNA': 'Do you want to perform FNA?',
  'Do you need investment advice': 'Do you need investment advice?',
  'Conservative': 'Conservative',
  'Moderate Conservative': 'Moderate Conservative',
  'Moderate': 'Moderate',
  'Moderate Aggressive': 'Moderate Aggressive',
  'Aggressive': 'Aggressive',
  'pirs-fna-p1': 'Financial Needs Analysis is required for any type of voluntary contribution, mutual fund or advice services.',
  'pirs-fna-deviate-desc': 'If you choose to deviate in any respect from the above Financial Needs Analysis or Risk Profile Questionnaire, you must indicate your reason(s) in writing:',
  'pirs-rpq-p1': 'If you need advice and/or are considering any type of voluntary contribution or mutual fund services, please complete the full set of Financial Needs Analysis Form.',
  'pirs-rpq-q13': 'Personal Account service only',
  'pirs-rpq-deviate-desc': 'If you choose to deviate in any respect from the above Risk Profile Questionnaire , you must indicate your reason(s) in writing:',
  'pirs-inv-10': 'Criteria for selecting products and in what aspects they are considered suitable for the client’s risk category',
  'pirs-inv-11': 'SFC Guidelines:',
  'pirs-inv-12': 'Suitability involves investment consultants matching the risk return profile of each recommended investment product with each client’s personal circumstances. Therefore, investment consultants should use their professional judgment to assess diligently whether the characteristics and risk exposures of each recommended investment product (including transaction costs, effect of gearing and foreign currency risks, where appropriate) are actually suitable for the client and are in the best interests of the clients, taking into account the client’s investment objectives, investment horizon, risk tolerance, and financial circumstances, etc. Investment consultants must not take commission rebates or other benefits to be received by them or their related companies as the primary basis for recommending particular investment products to clients. Where investment consultants only recommend investment products which are issued by their related companies, they should disclose this limited availability of products to each client. Lastly, investment consultants should exercise extra care in advising elderly or unsophisticated clients or those who may not be able to make independent investment decisions on complex investment products and rely on investment consultants for advice, particularly when these clients invest in investment products with long maturity periods and those which will attract hefty penalty charges upon early redemption or withdrawal.',
  'pirs-inv-13': 'Investment consultants should help each client make informed decisions by giving the client proper explanations of why recommended investment products are suitable for the client and the nature and extent of risks the investment products bear. In addition to explaining the good points of the investment products, investment consultants should always present balanced views, drawing the client’s attention to the disadvantages and downside risks as well. Investment consultants should use simple and plain language, and in a language that the client can readily understand. The explanations must be fair and not misleading.',
  'pirs-rpq-1': 'Personal Account service or transfer MPF accrued benefits only:  Need advice and/or are considering to participate in any type of voluntary contribution or mutual fund services',
  'pirs-rpq-2': 'Personal Account service or transfer MPF accrued benefits only:  Do not need advice and/or are not considering to participate in any type of voluntary contribution or mutual fund services',
  'pirs-rpq-3': 'Only participate in any type of voluntary contribution or mutual fund services',
  'pirs-inv-14': 'To be completed by licensed consultant :',
  'client remark': `Client's Remark(s)`,
  'pirs-cc13': 'Item 2 and Item 3 in this page MUST be "selected together ☑" OR "un-selected together ☐"',
  'pirs-cc14': 'Are you an employee or director of a securities and futures commission licensee or registered person/institution? (if applicable)',
  'chk-multiple': 'you may tick more than one',
  'range-18k-below': 'HK$180,000 or below',
  'range-18k': 'HK$180,001 - 360,000',
  'range-36k': 'HK$360,001 - 720,000',
  'range-72k': 'HK$720,001 - 960,000',
  'range-96k': 'HK$960,001 - 1,200,000',
  'range-120k': 'HK$1,200,001 or above',
  'range-10k-below': 'HK$100,000 or below',
  'range-10k': 'HK$100,001 - 500,000',
  'range-50k': 'HK$500,001 - 1,000,000',
  'range-100k': 'HK$1,000,001 - 3,000,000',
  'range-300k': 'HK$3,000,001 - 5,000,000',
  'range-500k': 'HK$5,000,001 - 8,000,000',
  'range-800k': 'HK$8,000,001 - 15,000,000',
  'range-15mil': 'HK$15,000,001 or above',
  'Total Liquid Assets': 'Total Liquid Assets',
  'liquid assets remark': 'A liquid asset is an asset that may easily be converted into cash in a short amount of time.',
}

export default t
