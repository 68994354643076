import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LangContext } from 'App'
import {
  updateAccountConsolidation,
  updateCustomerInformation,
  updateRegulatedActivities,
  upsertPirsData,
} from 'client/actions'
import {
  ClientInformationDto,
  ClientInformationDtoTitleEnum,
  // DependentDto,
  PirsRegulatedActivitiesDto,
  PirsRegulatedActivitiesDtoIsAppointmentOfInvestmentAndRetirementConsultantAcceptedEnum,
  PirsRegulatedActivitiesDtoIsPersonalInformationCollectionStatementAcceptedEnum,
  ClientInformationDtoIsSecuritiesDirectorEnum,
} from 'client/api/application/api'
import DialogAgree from 'client/components/common/agreeDialog'
import FormError from 'client/components/formErrorMsg'
import InputDate from 'client/components/inputDate'
import InputDynamicRadioGroup from 'client/components/inputDynamicRadioGroup'
import InputDynamicSelect from 'client/components/inputDynamicSelect'
import InputIdentity from 'client/components/inputIdentity'
import InputNob from 'client/components/inputNob'
import MrStepper, {
  MR_APPLICATION_FORM_STEPS,
  // StepperContext,
} from 'client/components/steppers/mr'
// import Typography from '@material-ui/core/Typography'
// import { isPostOfficeBox } from 'client/validations'
import { hkidCheckDigit } from 'client/validations/validateHkid'
import {
  pirsEducationLvl,
  maritalStatus,
  nationalities,
  occupationsV2,
} from 'config'
import i18n, { defaultNS } from 'i18n'
import pirsEn from 'locales/en/pirs'
import pirsZh from 'locales/zh-Hant/pirs'
import _get from 'lodash/get'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import Loading from 'routes/loading'
import { nullifyEvt } from 'utils/common'
import { validateIsAdult } from 'utils/reqMoment'
import { useStyles } from './style'
import { find as _find } from 'lodash'
import NationalityProofNotice from 'client/components/NationalityProofNotice'

i18n.addResourceBundle('en', defaultNS, pirsEn)
i18n.addResourceBundle('zh-Hant', defaultNS, pirsZh)

const Dialog1zh = React.lazy(() => import('./dialog1zh'))
const Dialog1en = React.lazy(() => import('./dialog1en'))
const Dialog2zh = React.lazy(() => import('./dialog2zh'))
const Dialog2en = React.lazy(() => import('./dialog2en'))

interface Props {
  data?: any
  activeStep: any
  goNextStep?: any
  applicationData?: any
  mode?: string
  applicationId?: string | undefined
}

const EMPTY = {} //_DATA.clientInformation

const MrPersonalInfo = (props: Props) => {
  const lang = useContext(LangContext)
  const [t] = useTranslation()
  const hookFormFunc = useForm()
  const { 
    handleSubmit, register, errors, getValues, 
    // watch, setValue 
  } = hookFormFunc
  // allow PO box for mailing address
  /*
  const validatePostOfficeBox = (value = '', isMailAddress = false) => {
    if (!value) return true
    const isPostAddress = isPostOfficeBox(value)
    // return (
    //   !isPostAddress ||
    //   (isMailAddress
    //     ? 'P.O. Box should not be mailing address'
    //     : 'P.O. Box should not be residential address')
    // )
    return isPostAddress
      ? t('P.O. Box should not be residential address')
      : true
  }
  */

  const state: ClientInformationDto = props.applicationData
    ? props.applicationData.clientInformation
    : EMPTY

  const stateRegulatedActivities: PirsRegulatedActivitiesDto =
    props.applicationData ? props.applicationData.regulatedActivities : EMPTY
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  // const [activeStep, setActiveStep] = useState(props.activeStep)
  const activeStep = props.activeStep
  // const [isLoaded, setIsLoaded] = useState(false)
  // const [isRefusePromo, setIsRefusePromo] = useState(
  //   _get(props, 'applicationData.regulatedActivities.isRefusePromo', false),
  // )
  const isRefusePromo = _get(props, 'applicationData.regulatedActivities.isRefusePromo', false)

  const [openDialog1, setOpenDialog1] = useState(false)
  const [openDialog2, setOpenDialog2] = useState(false)
  const occupationPH = { label: 'Please select', value: '' }
  const [curOccupationT2, setCurOccupationT2] = useState([occupationPH])
  const [hasOccupationT2, setHasOccupationT2] = useState(false)
  // const [selectedOccupationT2, setSelectedOccupationT2] = useState(
  //   state.occupationV2Tier2,
  // )

  const [confirmChk1, setConfirmChk1] = useState(
    _get(
      stateRegulatedActivities,
      'isPersonalInformationCollectionStatementAccepted',
    ) ===
      PirsRegulatedActivitiesDtoIsPersonalInformationCollectionStatementAcceptedEnum.Y,
  )
  const [confirmChk2, setConfirmChk2] = useState(
    _get(
      stateRegulatedActivities,
      'isAppointmentOfInvestmentAndRetirementConsultantAccepted',
    ) ===
      PirsRegulatedActivitiesDtoIsAppointmentOfInvestmentAndRetirementConsultantAcceptedEnum.Y,
  )

  const onSubmit = (formData: any) => {
    console.log('formData', formData)

    if (
      state &&
      state.isS800 &&
      state.accountType &&
      formData.accountType !== state.accountType
    ) {
      dispatch(updateAccountConsolidation([]))
    }

    // dispatch(addApplicationError('TEST ERROR'))

    dispatch(
      updateCustomerInformation({
        isS800: formData.s800Customer,
        accountType: formData.accountType,
        memberNumber: formData.memberNumber,
        contractNumber: formData.contractNumber,
        title: formData.title,
        firstName: formData.firstname,
        lastName: formData.lastname,
        chineseFirstName: formData.chiFirstname,
        chineseLastName: formData.chiLastname,
        identificationNumber: formData.identityNumber,
        identificationType: formData.identityType,
        nationality: formData.nationality,
        gender: formData.gender,
        email: formData.email1,
        dateOfBirth: formData.dateOfBirth,
        mobileNumber: formData.mobile,
        daytimeContactNumber: formData.dayTimeContact,
        smsServiceLanguage: formData.subscribeSmsLang,
        subscribeEcommunicationService:
          formData.subscribeECom === false ? 'N' : 'Y',
        subscribeSmsService: formData.subscribeSms === false ? 'N' : 'Y',
        mobileCountryCode: formData.mobileCountry,
        daytimeContactCountryCode: formData.dayTimeContactCountry,
        natureOfBusiness: parseInt(formData.nob),
        otherNatureOfBusiness: formData.otherNob || '',
        // occupation: formData.occupation,
        occupationV2Tier1: formData.occupationV2Tier1,
        occupationV2Tier2: formData.occupationV2Tier2,
        jobTitle: formData.jobTitle,
        placeOfBirth: formData.placeOfBirth,
        // MR - new fields
        contact3CountryCode: formData.contact3CountryCode,
        contact4CountryCode: formData.contact4CountryCode,
        contact3Number: formData.contact3Number,
        contact4Number: formData.contact4Number,
        employerName: formData.employerName,
        businessAddress: formData.businessAddress,
        maritalState: formData.maritalState,
        educationLevel: formData.educationLevel,
        isSecuritiesDirector: formData.isSecuritiesDirector,
        // dependents: [
        //   {
        //     name: formData.dependentsName1,
        //     age: formData.dependentsAge1,
        //     relationship: formData.dependentsRelationship1,
        //   } as DependentDto,
        //   {
        //     name: formData.dependentsName2,
        //     age: formData.dependentsAge2,
        //     relationship: formData.dependentsRelationship2,
        //   } as DependentDto,
        //   {
        //     name: formData.dependentsName3,
        //     age: formData.dependentsAge3,
        //     relationship: formData.dependentsRelationship3,
        //   } as DependentDto,
        // ],
      } as ClientInformationDto),
    )
    dispatch(
      updateRegulatedActivities({
        isPersonalInformationCollectionStatementAccepted:
          formData.isPersonalInformationCollectionStatementAccepted,
        isAppointmentOfInvestmentAndRetirementConsultantAccepted:
          formData.isAppointmentOfInvestmentAndRetirementConsultantAccepted,
        isRefusePromo: formData.isRefusePromo ? formData.isRefusePromo : 'N',
      } as any),
    )

    const nextTo = MR_APPLICATION_FORM_STEPS[activeStep + 1].to

    Promise.resolve(dispatch(upsertPirsData(true)))
      .then((result: any) => {
        console.log('result', result)

        if (result.data && result.data._id) {
          history.push(`/edit-mr/${result.data._id}/${nextTo}`)
        } else {
          history.push(`/edit-mr/temp/${nextTo}`)
        }
      })
      .catch((err) => console.error(err))

    // if (props.applicationData && props.applicationData._id) {
    //   history.push(`/edit-mr/${props.applicationData._id}/${nextTo}`)
    // }else{
    //   history.push(`/edit-mr/temp/${nextTo}`)
    // }
  }

  // const stepper = useContext(StepperContext)

  const defaultDob = `${new Date().getFullYear()}-01-01`
  const validateMobileNumber = (value = '') => {
    const mobileCountry: any = getValues().mobileCountry
    return validatePhoneNumber(mobileCountry, value)
  }

  const validateHkid = (value = '') => {
    console.log(getValues())
    const identityType: any = getValues().identityType
    if (identityType !== 'hkid') {
      return true
    }
    const result = hkidCheckDigit(value)
    return result ? true : t('Invalid HKID')
  }

  const validateDayContact = (value = '') => {
    const dayTimeContactCountry: any = getValues().dayTimeContactCountry
    return validatePhoneNumber(dayTimeContactCountry, value)
  }

  const validatePhoneNumber = (countryCode: string, value: string) => {
    if (!value) return true
    if (value.length > 15)
      return t('Please input a valid number. Max length is 15')
    const re =
      countryCode === '852' ? new RegExp(/^\d{8}$/) : new RegExp(/^\d+$/)

    return re.test(value) ? true : t('Please input a valid number')
  }

  /**
   * update tier 2 input by selected tier 1
   * @param selected value of selected tier 1 occupation
   */
  const updateOccupationTier2 = (selected: string) => {
    const tier1 = _find(occupationsV2, (row) => row.value === selected) || {
      label: '',
      value: '',
    }

    if (tier1.child) {
      setCurOccupationT2(tier1.child)
      setHasOccupationT2(true)
    } else {
      setCurOccupationT2([tier1])
      // setValue('occupationV2Tier2', tier1.value,{ shouldValidate: true, shouldDirty: true })
      // setSelectedOccupationT2(tier1.value)
      setHasOccupationT2(false)
    }
  }

  /**
   * For occupation's chain select input
   * @param e event from onChange
   */
  const handleChangeOccupation = (e: any) => {
    updateOccupationTier2(e.target.value)
  }

  useEffect(() => {
    if (state.occupationV2Tier1) {
      updateOccupationTier2(state.occupationV2Tier1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const MSG_PLEASE_FILL_IN_THIS_FIELD = t('Please fill in this field')
  // const MSG_PLEASE_FILL_IN_YOUR_MEMEBER_NUMBER = t(
  //   'Please fill in your member number',
  // )
  // const MSG_PLEASE_FILL_IN_YOUR_CONTRACT_NUMBER = t(
  //   'Please fill in your contract number',
  // )
  const MSG_PLEASE_FILL_IN_YOUR_SURNAME = t('Please fill in your surname')
  const MSG_PLEASE_ENTER_VALID_EMAIL = t('Please enter a vaild email')

  if (!state) return <div>Loading...</div>

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <MrStepper
            activeStep={activeStep}
            applicationId={
              props.applicationData ? props.applicationData._id : null
            }
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.h3} variant='h3'>
          {t('Appointment & PICS')}
        </Typography>
      </Grid>
      <FormProvider {...hookFormFunc}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete='off'
          onKeyPress={(event) => {
            if (event.which === 13 /* Enter */) {
              event.preventDefault()
            }
          }}
        >
          <Paper>
            <Grid
              container
              alignContent='stretch'
              alignItems='center'
              justifyContent='flex-start'
              direction='row'
              spacing={2}
            >
              <Grid item xs={12}>
                <FormControl
                  component='fieldset'
                  className={classes.formControlDense}
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={confirmChk2}
                          name='isAppointmentOfInvestmentAndRetirementConsultantAccepted'
                          inputRef={register({
                            required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                          })}
                          icon={<CheckBoxOutlineBlankIcon color='primary' />}
                          checkedIcon={<CheckBoxOutlinedIcon />}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setOpenDialog2(true)
                            } else {
                              setConfirmChk2(false)
                            }
                          }}
                          value={
                            PirsRegulatedActivitiesDtoIsAppointmentOfInvestmentAndRetirementConsultantAcceptedEnum.Y
                          }
                        />
                      }
                      label={`1. ${t('pirs-cc2')}`}
                    />
                  </FormGroup>
                </FormControl>
                <FormError
                  errors={
                    errors.isAppointmentOfInvestmentAndRetirementConsultantAccepted
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component='fieldset'
                  className={classes.formControlDense}
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={confirmChk1}
                          name='isPersonalInformationCollectionStatementAccepted'
                          inputRef={register({
                            required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                          })}
                          icon={<CheckBoxOutlineBlankIcon color='primary' />}
                          checkedIcon={<CheckBoxOutlinedIcon />}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setOpenDialog1(true)
                            } else {
                              setConfirmChk1(false)
                            }
                          }}
                          value={
                            PirsRegulatedActivitiesDtoIsPersonalInformationCollectionStatementAcceptedEnum.Y
                          }
                        />
                      }
                      label={`2. ${t('pirs-cc1')}`}
                    />
                  </FormGroup>
                </FormControl>
                <FormError
                  errors={
                    errors.isPersonalInformationCollectionStatementAccepted
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputDynamicRadioGroup
                  legend={`3. ${t('pirs-cc14')}`}
                  inputName='isSecuritiesDirector'
                  value={state.isSecuritiesDirector}
                  items={[
                    {
                      label: t('Yes'),
                      value: ClientInformationDtoIsSecuritiesDirectorEnum.Y,
                    },
                    {
                      label: t('No'),
                      value: ClientInformationDtoIsSecuritiesDirectorEnum.N,
                    },
                  ]}
                  notRequired
                />
              </Grid>
              <Grid item xs={2}>
                <InputDynamicSelect
                  required
                  label={t('Title')}
                  inputName='title'
                  defaultValue={state.title || ''}
                  style={{ minWidth: 'auto' }}
                  items={[
                    { label: 'Mr', value: ClientInformationDtoTitleEnum.Mr },
                    {
                      label: 'Mrs',
                      value: ClientInformationDtoTitleEnum.Mrs,
                    },
                    { label: 'Ms', value: ClientInformationDtoTitleEnum.Ms },
                    {
                      label: 'Miss',
                      value: ClientInformationDtoTitleEnum.Miss,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  defaultValue={state.lastName}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Surname (English)')}
                  name='lastname'
                  inputRef={register({
                    required: MSG_PLEASE_FILL_IN_YOUR_SURNAME,
                    maxLength: 100,
                  })}
                  onKeyUp={(e: any) => {
                    e.target.value = e.target.value.toUpperCase()
                  }}
                />
                <FormError errors={errors.lastname} />
              </Grid>
              {/* <Grid item xs={6}>
                <TextField
                  defaultValue={state.middleName}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Middle Name(s)')}
                  name='middleName'
                  inputRef={register({
                    maxLength: 100,
                  })}
                />
                <FormError errors={errors.middleName} />
              </Grid> */}
              <Grid item xs={5}>
                <TextField
                  defaultValue={state.firstName}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Given Name (English)')}
                  name='firstname'
                  inputRef={register({
                    maxLength: 100,
                  })}
                  onKeyUp={(e: any) => {
                    e.target.value = e.target.value.toUpperCase()
                  }}
                />
                <FormError errors={errors.firstname} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={state.chineseLastName}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Surname (Chinese)')}
                  name='chiLastname'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={state.chineseFirstName}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Given Name (Chinese)')}
                  name='chiFirstname'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={4}>
                <InputDynamicRadioGroup
                  legend={t('Gender')}
                  inputName='gender'
                  value={state.gender}
                  items={[
                    { label: 'Male', value: 'M' },
                    { label: 'Female', value: 'F' },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  freeSolo
                  defaultValue={state.nationality}
                  options={nationalities.map((option) =>
                    t(`nationality:::${option}`),
                  )}
                  // options={tmp1}
                  style={{ margin: '0', padding: '0' }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        name='nationality'
                        {...params}
                        label={t('Nationality')}
                        variant='outlined'
                        fullWidth
                        style={{ padding: '0' }}
                        inputRef={register({
                          required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                        })}
                      />
                    )
                  }}
                />
                <FormError errors={errors.nationality} />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  freeSolo
                  defaultValue={state.placeOfBirth}
                  options={nationalities.map((option) =>
                    t(`nationality:::${option}`),
                  )}
                  // options={tmp1}
                  style={{ margin: '0', padding: '0' }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        name='placeOfBirth'
                        {...params}
                        label={t('Place of Birth (Optional)')}
                        variant='outlined'
                        fullWidth
                        style={{ padding: '0' }}
                        inputRef={register}
                      />
                    )
                  }}
                />
                <FormError errors={errors.placeOfBirth} />
              </Grid>
              <Grid item xs={4} style={{ paddingTop: '0', marginTop: '-8px' }} />
              <Grid item xs={8} style={{ paddingTop: '0', marginTop: '-8px' }}>
                <NationalityProofNotice />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={6}>
                <InputDate
                  name='dateOfBirth'
                  label={t('Date of birth')}
                  validationRule={{
                    validate: {
                      isAdult: (value: any) => {
                        return validateIsAdult(value)
                          ? true
                          : t('Client age must be >= 18')
                      },
                    },
                    required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                  }}
                  date={state.dateOfBirth || defaultDob}
                />

                {/* <FormError errors={errors.identityNumber} /> */}
              </Grid>
              <Grid item xs={6}>
                <InputIdentity
                  inputIdName='identityNumber'
                  inputIdTypeName='identityType'
                  idNumber={state.identificationNumber}
                  idType={state.identificationType || 'hkid'}
                  validateHkid={validateHkid}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Country Code')}
                  name='mobileCountry'
                  type='number'
                  inputRef={register({
                    required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                  })}
                  defaultValue={parseInt(state.mobileCountryCode) | 852}
                />
                <FormError errors={errors.mobileCountry} />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  defaultValue={state.mobileNumber}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Mobile no.')}
                  name='mobile'
                  type='number'
                  inputRef={register({
                    required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                    validate: (value: any) => {
                      return validateMobileNumber(value)
                    },
                  })}
                />
                <FormError errors={errors.mobile} />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Country Code')}
                  name='dayTimeContactCountry'
                  type='number'
                  inputRef={register}
                  defaultValue={parseInt(state.daytimeContactCountryCode) | 852}
                />
                <FormError errors={errors.dayTimeContactCountry} />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  defaultValue={state.daytimeContactNumber}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Home')}
                  name='dayTimeContact'
                  type='number'
                  inputRef={register({
                    validate: (value: any) => {
                      return validateDayContact(value)
                    },
                  })}
                />
                <FormError errors={errors.dayTimeContact} />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Country Code')}
                  name='contact3CountryCode'
                  type='number'
                  inputRef={register}
                  defaultValue={parseInt(state.contact3CountryCode) | 852}
                />
                <FormError errors={errors.dayTimeContactCountry} />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  defaultValue={state.contact3Number}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Office')}
                  name='contact3Number'
                  type='number'
                  inputRef={register({
                    validate: (value: any) => {
                      return validateDayContact(value)
                    },
                  })}
                />
                <FormError errors={errors.dayTimeContact} />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Country Code')}
                  name='contact4CountryCode'
                  type='number'
                  inputRef={register}
                  defaultValue={parseInt(state.contact4CountryCode) | 852}
                />
                <FormError errors={errors.dayTimeContactCountry} />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  defaultValue={state.contact4Number}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Fax')}
                  name='contact4Number'
                  type='number'
                  inputRef={register({
                    validate: (value: any) => {
                      return validateDayContact(value)
                    },
                  })}
                />
                <FormError errors={errors.dayTimeContact} />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={6}>
                <TextField
                  defaultValue={state.email}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Email Address')}
                  name='email1'
                  inputRef={register({
                    required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^$/,
                      message: MSG_PLEASE_ENTER_VALID_EMAIL,
                    },
                  })}
                />
                <FormError errors={errors.email1} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={state.email}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Confirm Email Address')}
                  name='email2'
                  onPaste={nullifyEvt}
                  inputRef={register({
                    required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^$/,
                      message: MSG_PLEASE_ENTER_VALID_EMAIL,
                    },
                    validate: (value: any) => {
                      return (
                        value === getValues()['email1'] ||
                        'Please input the same email'
                      )
                    },
                  })}
                />
                <FormError errors={errors.email2} />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={6}>
                <InputNob
                  nob={state.natureOfBusiness}
                  otherNob={state.otherNatureOfBusiness}
                ></InputNob>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={state.jobTitle}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Job Title')}
                  name='jobTitle'
                  inputRef={register({
                    required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                  })}
                />
                <FormError errors={errors.jobTitle} />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={4}>
                <InputDynamicSelect
                  label={t('Marital Status')}
                  inputName='maritalState'
                  defaultValue={state.maritalState || ''}
                  style={{ minWidth: 'auto' }}
                  items={maritalStatus}
                />
              </Grid>
              <Grid item xs={4}>
                <InputDynamicSelect
                  required
                  label={t('Education Level')}
                  inputName='educationLevel'
                  defaultValue={state.educationLevel || ''}
                  style={{ minWidth: 'auto' }}
                  items={pirsEducationLvl}
                />
              </Grid>
              <Grid item xs={4}>
                {/* <InputDynamicSelect
                  required
                  defaultValue={state.occupation || ''}
                  label={t('Occupation')}
                  inputName='occupation'
                  style={{ minWidth: 'auto' }}
                  items={occupation}
                /> */}
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={6}>
                <InputDynamicSelect
                  label={t('Occupation')}
                  inputName='occupationV2Tier1'
                  handleOnChange={handleChangeOccupation}
                  defaultValue={state.occupationV2Tier1}
                  items={occupationsV2}
                />
              </Grid>
              {hasOccupationT2 && (
                <Grid item xs={6}>
                  <InputDynamicSelect
                    label={t('Occupation - Group')}
                    inputName='occupationV2Tier2'
                    // defaultValue={selectedOccupationT2}
                    defaultValue={state.occupationV2Tier2}
                    // handleOnChange={handleChangeOccupationT2}
                    items={curOccupationT2}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={6}>
                <TextField
                  defaultValue={state.employerName}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Name of Employer')}
                  name='employerName'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={state.businessAddress}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Business Address')}
                  name='businessAddress'
                  inputRef={register}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h5'>{t('Dependents')}</Typography>
              </Grid>
            </Grid> */}
            {/* <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={5}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[0]
                      ? state.dependents[0].name
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('PersonName')}
                  name='dependentsName1'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[0]
                      ? state.dependents[0].age
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Age')}
                  type='number'
                  name='dependentsAge1'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[0]
                      ? state.dependents[0].relationship
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Relationship')}
                  name='dependentsRelationship1'
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={5}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[1]
                      ? state.dependents[1].name
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('PersonName')}
                  name='dependentsName2'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[1]
                      ? state.dependents[1].age
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Age')}
                  type='number'
                  name='dependentsAge2'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[1]
                      ? state.dependents[1].relationship
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Relationship')}
                  name='dependentsRelationship2'
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' spacing={2}>
              <Grid item xs={5}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[2]
                      ? state.dependents[2].name
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('PersonName')}
                  name='dependentsName3'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[2]
                      ? state.dependents[2].age
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Age')}
                  type='number'
                  name='dependentsAge3'
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  defaultValue={
                    state.dependents && state.dependents[2]
                      ? state.dependents[2].relationship
                      : ''
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Relationship')}
                  name='dependentsRelationship3'
                  inputRef={register}
                />
              </Grid>
            </Grid> */}
          </Paper>
          {/* <InputSignature register={register} errors={errors}></InputSignature> */}
          <Button variant='contained' color='primary' type='submit'>
            {t('Next')}
          </Button>
          {/* {Footer} */}
        </form>
      </FormProvider>

      <DialogAgree
        open={openDialog1}
        handleAgree={() => {
          setConfirmChk1(true)
          setOpenDialog1(false)
        }}
        handleDisagree={() => {
          setConfirmChk1(false)
          setOpenDialog1(false)
        }}
        title={t('PIRS – PERSONAL INFORMATION COLLECTION STATEMENT')}
      >
        <Suspense fallback={<Loading />}>
          {lang === 'en' ? (
            <Dialog1en register={register} chkVal={isRefusePromo} />
          ) : (
            <Dialog1zh register={register} chkVal={isRefusePromo} />
          )}
        </Suspense>
      </DialogAgree>

      <DialogAgree
        open={openDialog2}
        handleAgree={() => {
          setConfirmChk2(true)
          setOpenDialog2(false)
        }}
        handleDisagree={() => {
          setConfirmChk2(false)
          setOpenDialog2(false)
        }}
        title={t('Important Notes')}
      >
        <Suspense fallback={<Loading />}>
          {lang === 'en' ? <Dialog2en /> : <Dialog2zh />}
        </Suspense>
      </DialogAgree>
    </Grid>
  )
}

export default MrPersonalInfo
