import React from 'react'
import Button from '@material-ui/core/Button'
import i18n from '../../i18n'

export const SHOW_LOADING = 'SHOW_LOADING'
export const showLoading = (message: string) => ({
  type: SHOW_LOADING,
  data: message,
})

export const HIDE_LOADING = 'HIDE_LOADING'
export const hideLoading = () => ({
  type: HIDE_LOADING,
})

export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION'
export const enqueueNotification = (
  isBackground = false,
  key?: string,
  message?: string,
  options: any = {},
) => ({
  type: ENQUEUE_NOTIFICATION,
  key,
  message,
  options,
  isBackground,
})
export const enqueueNotificationError = (
  isBackground = false,
  key?: string,
  message?: string,
) => {
  const translatedMessage = i18n.t(message as any)
  return (dispatch: any, getState: () => any) => {
    dispatch(
      enqueueNotification(isBackground, key + '.error', translatedMessage, {
        variant: 'error',
        persist: true,
        action: (key: string) => (
          <Button
            style={{ color: '#FFF' }}
            onClick={() => dispatch(dequeueNotification(key, true))}
          >
            Close
          </Button>
        ),
      }),
    )
  }
}

export const DEQUEUE_NOTIFICATION = 'DEQUEUE_NOTIFICATION'
export const dequeueNotification = (key: string, isBackground = false) => ({
  type: DEQUEUE_NOTIFICATION,
  key,
  isBackground,
})
