import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

import { appInsightConnStr } from 'config'

export const reactPlugin = new ReactPlugin()
export const appInsights = new ApplicationInsights({
  config: {
    // instrumentationKey: appInsightKey,
    connectionString: appInsightConnStr,
    loggingLevelConsole: 0, // 2 for logging errors and warnins to console
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
})
