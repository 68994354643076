import _ from 'lodash'
import {
  TOGGLE_STEP_ERROR,
  TOGGLE_STEP,
  INIT_APPLICATION_STEP,
} from 'client/actions/applicationStep'
import { AXA_APPLICATION_FORM_STEPS } from 'client/components/steppers/axa'
import { FTLIFE_APPLICATION_FORM_STEPS } from 'client/components/steppers/ftLife'
import { TAIPING_APPLICATION_FORM_STEPS } from 'client/components/steppers/taiping'
import { BROKER_DEFAULT } from 'client/components/steppers/brokerDef'
import { ApplicationDtoDistributionChannelCodeEnum } from 'client/api/application/api'

const ApplicationStep = (steps: any = [], action: any) => {
  switch (action.type) {
    case INIT_APPLICATION_STEP:
      console.log(
        `Init application stepper, distributionChannelCode=${action.distributionChannelCode}`,
      )

      switch (action.distributionChannelCode) {
        case null:
        case undefined:
        case '':
          steps = AXA_APPLICATION_FORM_STEPS
          break
        case ApplicationDtoDistributionChannelCodeEnum.AXA:
          steps = AXA_APPLICATION_FORM_STEPS
          break
        case ApplicationDtoDistributionChannelCodeEnum.FTLIFE:
          steps = FTLIFE_APPLICATION_FORM_STEPS
          break
        case ApplicationDtoDistributionChannelCodeEnum.TAIPING:
          steps = TAIPING_APPLICATION_FORM_STEPS
          break
        default:
          steps = BROKER_DEFAULT
      }

      return steps
    case TOGGLE_STEP:
      const n = [...steps]
      let anyChange = false

      steps.forEach((val: any, idx: number) => {
        if (val.id === action.stepId || val.groupId === action.stepId) {
          anyChange = n[idx].isActive !== action.isActive
          n[idx].isActive = action.isActive
        }
        if (n[idx].steps) {
          n[idx].steps.forEach((val2: any, idx2: number) => {
            if (val2.id === action.stepId || val2.groupId === action.stepId) {
              anyChange = n[idx].steps[idx2].isActive !== action.isActive
              n[idx].steps[idx2].isActive = action.isActive
            }
          })
        }
      })

      if (anyChange) {
        return n
      }

      return steps
    case TOGGLE_STEP_ERROR:
      const TOGGLE_STEP_ERROR_INDEX = steps.findIndex(
        (val: any, idx: number) => {
          return val.id === action.stepId
        },
      )
      if (TOGGLE_STEP_ERROR_INDEX > -1) {
        const n = [...steps]
        n[TOGGLE_STEP_ERROR_INDEX].error = action.error
        return n
      } else {
        return steps
      }
    default:
      return steps
  }
}

export default ApplicationStep
