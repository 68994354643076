import React from 'react'
import { Link } from '@material-ui/core'

export default function Dialog1en() {
  return (
    <div>
      <p>
      Members who are U.S. Persons (i.e. U.S. citizens or U.S. permanent residents) should read the member notices in relation to the U.S. Executive Order 13959 in the link below:
      </p>
      <ol>
        <li>Notice For S800 and Smart Plan re: U.S. Persons Investments in funds investing in TraHK [<Link href='https://www.principal.com.hk/sites/default/files/general-files/EO13959%20Notice_Eng.pdf' target='_blank'>LINK</Link>]</li>
        <li>Notice to MPF and ORSO Schemes Participants regarding the Executive Order 13959 [<Link href='https://www.principal.com.hk/sites/default/files/general-files/EO13959_Notice_eng_10_Feb_2021.pdf' target='_blank'>LINK</Link>]</li>
      </ol>
    </div>
  )
}
