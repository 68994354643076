import React, { useState, useRef, useEffect } from 'react'
import Slide from '@material-ui/core/Slide'
import LoadingOverlay from 'react-loading-overlay'
import styled from 'styled-components'
import queryString from 'querystring'
import {
  AdobeSignAgreementsApi,
  SubmissionsApi,
} from 'client/api/application/api'
import { BASE_PATH } from 'config'
import { useHistory } from 'react-router'
import {
  showLoading,
  hideLoading,
} from 'client/actions/applicationNotification'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Transition } from 'client/components/common/transition'

const StyledLoadingOverlay = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;

  & > div._loading_overlay_overlay {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    background: #bbbbbb;
  }
`

const Submission = ({ location }: any) => {
  const [t, i18n] = useTranslation()
  const history = useHistory()
  //const parsed = queryString.parse(location.search.substr(1))
  //const { adobeSignAgreementId, receiver } = parsed
  const adobeSignAgreementId = sessionStorage.adobeSignAgreementId
  const [eSignUrl, setESignUrl] = useState()
  const [loadingMessage, setLoadingMessage] = useState(
    'Connecting to Adobe Sign...',
  )
  const userProfile = useSelector((state: any) => {
    return state ? state.userProfile : null
  })
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    async function init() {
      if (!submitting) {
        setSubmitting(true)
        try {
          if (adobeSignAgreementId) {
            const api = new AdobeSignAgreementsApi(undefined, BASE_PATH)

            const result = await api.adobeSignAgreementControllerGetNextESignUrl(
              adobeSignAgreementId as any,
            )
            const url = result.data
            if (!url) {
              submitApplication()
            } else {
              if (url.startsWith('http')) {
                window.location.href = url
              } else {
                alert(`Error - ${url}`)
                setLoadingMessage(t('Please refresh the brwoser to try again'))
              }
            }
          } else {
            history.push('/')
          }
        } catch (ex) {
          console.error(ex)
          alert('Unknown Error. Please try again')
          setLoadingMessage(t('Please refresh the brwoser to try again'))
        }
      }
    }

    if (userProfile) {
      init()
    }
  }, [userProfile])

  const submitApplication = async () => {
    setLoadingMessage('Submitting Application...')
    try {
      const applicationData = JSON.parse(sessionStorage.applicationData)
      const adobeSignAgreementId = sessionStorage.adobeSignAgreementId
      const api = new SubmissionsApi(undefined, BASE_PATH)
      const option = {
        responseType: 'application/json',
        query: { adobeSignAgreementId },
      }

      let q = null
      if (userProfile && userProfile.x_ictype) {
        if (userProfile.x_ictype === 'Y') {
          q = api.submissionsIdPatch(applicationData['_id'], option)
        } else {
          q = api.submissionsPost(applicationData, option)
        }
        if (q) {
          const response = await q
          console.log('submitApplication', response)
          sessionStorage.removeItem('adobeSignAgreementId')
          sessionStorage.removeItem('applicationData')
          const succeededMsg =
            `${t('Application Submitted')}, ${t('Reference Number')}` +
            `=${response.data.referenceId}`
          alert(succeededMsg)
          history.push('/home')
        }
      } else {
        alert('Missing user profile. Please login again')
        setLoadingMessage(t('Please refresh the brwoser to try again'))
      }
    } catch (ex) {
      console.error(ex)
      alert('Unknown Error. Please try again')
      setLoadingMessage(t('Please refresh the brwoser to try again'))
    } finally {
    }
  }

  return (
    <StyledLoadingOverlay active={true} spinner text={t(loadingMessage)}>
      <div>Adobe Sign! eSignUrl={eSignUrl}</div>
    </StyledLoadingOverlay>
  )
}

export default Submission
