import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControlLabel,
  Grid,
  Tab,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
  TextareaAutosize,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { AxiosError, AxiosResponse } from 'axios'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import {
  confirmSubmissions,
  exportPendingPpoCasesToCsv,
  exportPendingPpoCasesToXlsx,
  exportProcessedPpoCasesToCsv,
  exportProcessedPpoCasesToXlsx,
  fetchPpoCases,
  rejectSubmissions,
} from 'client/actions'
import {
  enqueueNotificationError,
  hideLoading,
  showLoading,
} from 'client/actions/applicationNotification'
import {
  ArchivesApi,
  PpoAdminSummaryResponseDto,
  PpoAdminSummaryResponseDtoStatusEnum,
} from 'client/api/application/api'
import { PPO_BASE_PATH } from 'config'
import update from 'immutability-helper'
import moment from 'moment'
import 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PpoAdminTableHeadRow } from './ppoAdminTableHeadRow'
import { PpoAdminTableRow } from './ppoAdminTableRow'
import { useStyles } from './style'
import { Transition } from 'client/components/common/transition'

interface PpoListItem {
  data: PpoAdminSummaryResponseDto
  isSelected: boolean
}

enum Action {
  Confirm,
  Reject,
}

export const PpoAdminContext = React.createContext({} as any)

const PpoAdmin = () => {
  console.log('render - PpoAdmin')
  const dispatch = useDispatch()
  const classes = useStyles()
  const [items, setItems] = useState<PpoListItem[]>([])
  //const [selectedIds, setSelectedIds] = useState()
  const [isAllChecked, setIsAllChecked] = useState(false)
  //const [checkedMap, setCheckedMap] = useState<any>({})
  const [tabIndex, setTabIndex] = React.useState(0)
  const [reason, setReason] = React.useState('')
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = React.useState(false)
  const [password, setPassword] = useState('')
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState<Action>()
  // const oneWeekBefore = new Date()
  // oneWeekBefore.setDate(oneWeekBefore.getDate() - 7)
  const [queryStartDate, setQueryStartDate] = React.useState<Date | null>(
    moment().add(-31, 'days').toDate(),
  )
  const [queryEndDate, setQueryEndDate] = React.useState<Date | null>(
    new Date(),
  )

  const [isInit, setIsInit] = useState(false)
  const isApiAuth = useSelector((state: any) => state.application.isApiAuth)
  const userProfile = useSelector((state: any) => {
    return state.userProfile
  })

  const handleQueryStartDateChange = (date: Date | null) => {
    setQueryStartDate(date)
  }
  const handleQueryEndDateChange = (date: Date | null) => {
    setQueryEndDate(date)
  }

  const handleDownloadDialogOpen = () => {
    setPassword('')
    setIsDownloadDialogOpen(true)
  }

  const handleDownloadDialogClose = (isDownload: boolean) => {
    setIsDownloadDialogOpen(false)
    // if (isDownload && password) {
    //   if (downlaodFormRef && downlaodFormRef.current) {
    //     // @ts-ignore: Object is possibly 'null'.
    //     downlaodFormRef.current.submit()
    //   }
    // }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  const getSelectedPendingIds = () => {
    let ids = items
      .filter((item: PpoListItem) => {
        return !item.data.ppoStatus && item.isSelected
      })
      .map((item: PpoListItem) => item.data.id)
    ids = ids ? ids : []

    return ids
  }
  const getSelectedProcessedIds = () => {
    let ids = items
      .filter((item: PpoListItem) => {
        return item.data.ppoStatus && item.isSelected
      })
      .map((item: PpoListItem) => item.data.id)
    ids = ids ? ids : []

    return ids
  }

  const fetchData = () => {
    setIsAllChecked(false)
    if (!queryStartDate || !queryEndDate) {
      alert('Please specify the date range')
    } else {
      // use ISO date string
      const start: string = moment(queryStartDate)
        .tz('Asia/Hong_Kong')
        .format('YYYY-MM-DD')
      const end: string = moment(queryEndDate)
        .tz('Asia/Hong_Kong')
        .format('YYYY-MM-DD')
      ;(dispatch(fetchPpoCases(start, end)) as any).then(
        (result: any) => {
          setItems(
            result.map((item: PpoAdminSummaryResponseDto) => {
              return {
                data: item,
                isSelected: false,
              } as PpoListItem
            }),
          )
        },
        (error: any) => {
          
          dispatch(
            enqueueNotificationError(
              true,
              `${new Date().getTime()}_submissions.error`,
              error.message ? error.message : error,
            ),
          )
        },
      )
    }
  }

  useEffect(() => {
    setIsAllChecked(false)
  }, [tabIndex])

  useEffect(() => {
    if (isApiAuth && !isInit) {
      fetchData()
      setIsInit(true)
    }
  }, [isApiAuth, isInit])

  const handleSelect = (id: any, checked: any) => {
    setItems((items) => {
      const index = items.findIndex(
        (value: PpoListItem) => value.data.id === id,
      )

      const updatedItems = update(items, {
        [index]: {
          $set: {
            ...items[index],
            isSelected: checked,
          },
        },
      })
      console.log('old', items)
      console.log('new', updatedItems)

      return updatedItems
    })
  }

  const handleClose = (accept: boolean, reason: string) => {
    if (accept) {
      let ids = getSelectedPendingIds()
      if (ids.length === 0) {
        alert('No record is selected')
      } else {
        if (action === Action.Confirm) {
          confirmSelected(ids)
        } else if (action === Action.Reject) {
          rejectSelected(ids, reason)
        }
      }
    }
    setOpen(false)
  }

  React.useEffect(() => {
    const updatedItems = items.map((item: PpoListItem) => {
      return {
        ...item,
        isSelected:
          item.data.status === PpoAdminSummaryResponseDtoStatusEnum.REVIEWED ||
          item.data.status === PpoAdminSummaryResponseDtoStatusEnum.PROCESSED
            ? isAllChecked
            : false,
      }
    })
    setItems(updatedItems)
  }, [isAllChecked])

  const toggleCheckAll = () => {
    setIsAllChecked(!isAllChecked)
  }

  const exportSelectedToSpreadsheet = (type: 'CSV' | 'XLSX', ids: string[]) => {
    let fetchCall
    if (tabIndex === 0) {
      fetchCall = (ids: string[]) =>
        type === 'CSV'
          ? exportPendingPpoCasesToCsv(ids)
          : exportPendingPpoCasesToXlsx(ids)
    } else if (tabIndex === 1) {
      fetchCall = (ids: string[]) =>
        type === 'CSV'
          ? exportProcessedPpoCasesToCsv(ids)
          : exportProcessedPpoCasesToXlsx(ids)
    }
    if (fetchCall) {
      ;(dispatch(fetchCall(ids)) as any).then(
        (result: any) => {
          const url = window.URL.createObjectURL(
            new Blob([result], {
              type:
                type === 'CSV'
                  ? 'application/csv'
                  : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `export_${new Date().getTime()}.${type === 'CSV' ? 'csv' : 'xlsx'}`,
          )
          //document.body.appendChild(link)
          link.target = '_blank'

          link.click()
        },
        (error: any) => {
          
          dispatch(
            enqueueNotificationError(
              true,
              `${new Date().getTime()}_export_submissions.error`,
              error.message ? error.message : error,
            ),
          )
        },
      )
    }
  }

  const rejectSelected = (ids: string[], reason: string) => {
    ;(dispatch(rejectSubmissions(ids, reason)) as any).then(
      (result: any) => {
        if (result) {
          alert(`The ${ids.length} selected applications have been rejected`)
        } else {
          alert('Reject is failed. Please try again')
        }
        fetchData()
      },
      (error: any) => {
        dispatch(
          enqueueNotificationError(
            true,
            `${new Date().getTime()}_reject_submissions.error`,
            error.message ? error.message : error,
          ),
        )
      },
    )
  }

  function getAxiosErrorMessage(err: AxiosError) {
    let msg = 'UNKNOWN ERROR'
  
    if (err) {
      if (err.message) {
        msg = err.message
      }
      if (
        err.isAxiosError &&
        err.response &&
        err.response.data
      ) {
        msg = `${JSON.stringify(err.response.data)}`
        if(JSON.parse(msg)['message']){
          msg = JSON.parse(msg)['message']
        }
        
        
      }
    }
  
   return msg
  }
  const confirmSelected = (ids: string[]) => {
    Promise.resolve(dispatch(confirmSubmissions(ids))).then(
      (result: any) => {
        if (result) {
          alert(`The ${ids.length} selected applications have been confirmed`)
        } else {
          alert('Confirmation is failed. Please try again')
        }
        fetchData()
      },
      (error: any) => {
        dispatch(
          enqueueNotificationError(
            true,
            `${new Date().getTime()}_confirm_submissions.error`,
            getAxiosErrorMessage(error),
          ),
        )
      },
    )
  }

  const rows: PpoListItem[] = items.filter((item: PpoListItem) =>
    tabIndex === 0 ? !item.data.ppoStatus : item.data.ppoStatus,
  )
  const pendingCount = items.filter((item: PpoListItem) => !item.data.ppoStatus)
    .length
  const processedCount = items.filter(
    (item: PpoListItem) => item.data.ppoStatus,
  ).length

  const [t] = useTranslation()

  return (
    <div
      style={{
        position: 'absolute',
        left: '20px',
        right: '20px',
        padding: '15px 0px',
      }}
    >
      <PpoAdminContext.Provider value={{ fetchData }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              align='center'
              color='primary'
              variant='h2'
              component='h2'
            >
              List of Submitted Applications for Admin
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Dialog
              open={isDownloadDialogOpen}
              onClose={handleDownloadDialogClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>
                {'Download Pdf Files'}
              </DialogTitle>
              <DialogContent>
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label='Zip Password'
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleDownloadDialogClose(false)
                  }}
                  color='primary'
                >
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    if (password) {
                      dispatch(showLoading('Obtaining OTP'))
                      const api = new ArchivesApi(undefined, PPO_BASE_PATH)
                      try {
                        const result = await api.archivesOtpPost({
                          ids: rows
                            .filter((r) => r.isSelected)
                            .map((r) => r.data.id),
                          password,
                        })
                        window.open(
                          `${PPO_BASE_PATH}/archives?otp=${result.data.otp}`,
                          '_blank',
                        )
                        handleDownloadDialogClose(true)
                      } catch (ex) {
                        if (ex.response && ex.response.data) {
                          alert(ex.response.data.message)
                        } else {
                          alert(
                            'Problem occurred while getting the otp. Please try again',
                          )
                          console.error(ex)
                        }
                      }
                      dispatch(hideLoading())
                    } else {
                      alert('Please enter a password')
                    }
                  }}
                  color='primary'
                  autoFocus
                >
                  Download
                </Button>
              </DialogActions>
            </Dialog>
            <Button
              disabled={tabIndex !== 0}
              color='primary'
              variant='contained'
              value='Download Pdf File'
              onClick={(e: any) => {
                let ids = getSelectedPendingIds()
                if (ids.length === 0) {
                  alert('No record is selected')
                } else {
                  handleDownloadDialogOpen()
                }
              }}
            >
              Download Selected Files
            </Button>
            <Button
              color='primary'
              onClick={(e: any) => {
                let ids =
                  tabIndex === 0
                    ? getSelectedPendingIds()
                    : getSelectedProcessedIds()
                if (ids.length === 0) {
                  e.preventDefault()
                  alert('No record is selected')
                } else {
                  exportSelectedToSpreadsheet('CSV', ids)
                }
              }}
              variant='contained'
            >
              Export Selected to CSV
            </Button>
            <Button
              color='primary'
              onClick={(e: any) => {
                let ids =
                  tabIndex === 0
                    ? getSelectedPendingIds()
                    : getSelectedProcessedIds()
                if (ids.length === 0) {
                  e.preventDefault()
                  alert('No record is selected')
                } else {
                  exportSelectedToSpreadsheet('XLSX', ids)
                }
              }}
              variant='contained'
            >
              Export Selected to XLSX
            </Button>
            <Button
              disabled={tabIndex !== 0}
              color='primary'
              onClick={(e: any) => {
                setAction(Action.Confirm)
                setReason('')
                setOpen(true)
              }}
              variant='contained'
            >
              Confirm Selected
            </Button>
            <Button
              disabled={tabIndex !== 0}
              color='primary'
              onClick={(e: any) => {
                setAction(Action.Reject)
                setReason('')
                setOpen(true)
              }}
              variant='contained'
            >
              Reject Selected
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  margin='normal'
                  label='Start'
                  format='MM/dd/yyyy'
                  value={queryStartDate}
                  onChange={handleQueryStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  margin='normal'
                  label='End'
                  format='MM/dd/yyyy'
                  value={queryEndDate}
                  onChange={handleQueryEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <Button
                  color='primary'
                  onClick={(e: any) => {
                    fetchData()
                  }}
                  variant='contained'
                >
                  Search
                </Button>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Paper square className={classes.tabs}>
              <Tabs
                centered
                value={tabIndex}
                indicatorColor='primary'
                textColor='primary'
                onChange={handleTabChange}
              >
                <Tab label={`Pending Cases(${pendingCount})`} />
                <Tab label={`Log(${processedCount})`} />
              </Tabs>
            </Paper>
            <Paper square>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <PpoAdminTableHeadRow
                      isAllChecked={isAllChecked}
                      handleCheckAllClicked={toggleCheckAll}
                      tabIndex={tabIndex}
                      x_channel_code={
                        userProfile ? userProfile.x_channel_code : ''
                      }
                    />
                  </TableHead>
                  <TableBody>
                    {rows
                      .sort((a: PpoListItem, b: PpoListItem) => {
                        if (!a.data.submittedTime && b.data.submittedTime)
                          return 1
                        if (!b.data.submittedTime && a.data.submittedTime)
                          return -1
                        if (!b.data.submittedTime && !a.data.submittedTime)
                          return -1
                        return (
                          a.data.submittedTime.getTime() -
                          b.data.submittedTime.getTime()
                        )
                      })
                      .map((row: PpoListItem, index: any) => {
                        const { data, isSelected } = row
                        const { referenceId } = data
                        return (
                          <PpoAdminTableRow
                            tabIndex={tabIndex}
                            value={data}
                            key={referenceId}
                            referenceId={referenceId}
                            selected={isSelected}
                            handleSelect={handleSelect}
                            x_channel_code={
                              userProfile ? userProfile.x_channel_code : ''
                            }
                          />
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </PpoAdminContext.Provider>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {action === Action.Confirm
            ? t('Are you sure to confirm?')
            : t('Are you sure to reject?')}
        </DialogTitle>
        <DialogContent>
          {action === Action.Reject && (
            <TextareaAutosize
              className={classes.textarea}
              aria-label='minimum height'
              rowsMin={6}
              maxLength={100}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value)
              }}
              placeholder='Please input the reason(max length 100)'
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false, '')} color='primary'>
            {t('Disagree')}
          </Button>
          <Button onClick={() => handleClose(true, reason)} color='primary'>
            {t('Agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PpoAdmin
