import StepConnector from '@material-ui/core/StepConnector'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 17px)',
    right: 'calc(50% + 17px)',
  },
  active: {
    '& $line': {
      borderColor: '#0091da',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0091da',
    },
  },
  line: {
    borderColor: '#C7C7C7',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '25px 0',
    },
    stepper: {
      padding: '25px 0',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
)
