
import _ from 'lodash'
import { SUBMIT_APPLICATION } from 'client/actions/termAndCondition'

const TermAndCondition = (
  state: any = { SUBMIT_APPLICATION: false },
  action: any,
) => {
  switch (action.type) {
    case SUBMIT_APPLICATION:
      return {
        ...state,
        SUBMIT_APPLICATION: action.isAccepted,
      }
    default:
      return state
  }
}

export default TermAndCondition
