// prettier-ignore
export default {
  CLICK_ME: 'Click Me to see detail',
  CLIENT_INFO_TITLE: 'Client Detail',
  'Please indicate your selected Principal MPF Scheme': 'Please indicate your selected Principal MPF Scheme',
  Brand: 'Brand',
  'Fund Choice': 'Fund Choice',
  Consolidation: 'Consolidation',
  Professional: 'Professional Experience',
  Integrated: 'Integrated Service',
  'Other Scheme': 'Other Scheme Features',
  BRAND: 'Brand',
  FUND: 'Fund Choice',
  ACCOUNTCONSOLIDATION: 'Consolidation',
  EXPERIENCE: 'Professional Experience',
  INTEGRATIONSERVICE: 'Integrated Service',
  OTHERS: 'Other Scheme Features',
  'Is your education level above primary?': 'Is your education level above primary?',
  'Are you able to read Chinese or English?': 'Are you able to read Chinese or English?',
  'Are you free from any disability that will affect your ability to make investment decisions independently?': 'Are you free from any disability that will affect your ability to make investment decisions independently?',
  Yes: 'Yes',
  No: 'No',
  'My companion': 'My companion',
  'AXA Representative': 'AXA Representative',
  'Witness Name': 'Witness Name',
  'Do you want to have a witness to the sales process?': 'Do you want to have a witness to the sales process?',
  'ID/Passport Copy': 'ID/Passport Copy',
  'Customer Section': 'Member Information',
  Address: 'Address',
  'AXA Regulated Activites': 'MPF REGULATED ACTIVITIES FORM',
  'FT Life Regulated Activites': 'MPF Client Confirmation Form',
  'Investment Mandate': 'Investment Mandate',
  'Account(s) to be Consolidated': 'Account(s) to be Consolidated',
  'Upload HKID/Passport': 'ID/Passport Copy',
  'Other Document(s)': 'Other Document(s)',
  Summary: 'Summary',
  'Important information to customer': 'Declaration/ Important Note for Intermediary',
  'I accept': 'I accept',
  'Mandatory Investment': 'Mandatory Contribution',
  'Volunteer Investment': 'Voluntary Contribution',
  'Transfer options': 'Transfer options',
  'Transfer All': 'Transfer All',
  'Transfer Partial': 'Transfer Part(s)',
  'PA': 'Personal Account',
  'CA_1': 'Contribution Account(Personal account holder or employee ceasing employment; Self-employed Person)',
  'CA_2': 'Contribution Account(Transfer accrued benefit from contribution account during employment; Employee Choice Arrangement',
  'I Accept': 'I Accept',
  TRANSFER_PARTIAL_OPTIONS_M: 'Employee mandatory contributions in current employment (Transfer to Personal Account ONLY)',
  TRANSFER_PARTIAL_OPTIONS_V: 'Employee voluntary contributions in current employment (Transfer to Personal Account ONLY)',
  TRANSFER_PARTIAL_OPTIONS_AM: 'Mandatory contributions that have been transferred into the contribution account and are attributable to former employment(s)',
  TRANSFER_PARTIAL_OPTIONS_AV: 'Voluntary contributions that have been transferred into the contribution account and are attributable to former employment(s) ',
  'Save & Preview': 'Save & Preview',
  'For A123456(7) Please input A1234567': 'For A123456(7), please input A1234567',
  'e.g. 123456': 'e.g. 123456',
  'e.g. AB123456': 'e.g. AB123456',
  'Are you a current S800 Customer?': 'Are you a current Principal MPF S800 Member and would like to apply MPF consolidation to this S800 account?',
  Clear: 'Clear',
  'Investment Allocation': 'Investment Allocation',
  'Mandatory Contribution': 'Mandatory Contribution ',
  'Voluntary Contribution': 'Voluntary Contribution',
  'Name of original scheme': 'Name of Original Scheme',
  'Name of original trustee': 'Name of Original Trustee',
  'Scheme Member\'s Account Number': 'Scheme Member’s Account Number ',
  'Employer\'s identification number': 'Employer’s Identification Number',
  'Name of former employer': 'Name of Former Employer',
  'Please fill in this field': 'Please fill in this field',
  'Account Number': 'Account Number',
  'Branch Code': 'Branch Code',
  'Bank Code': 'Bank Code',
  'Name of Bank Account Holder': 'Name of Bank Account',
  'Name of Bank': 'Name of Bank',
  'By depositing directly in a bank account': 'By depositing directly in a bank account under the name of scheme member only',
  'By cheque': 'By cheque',
  'Withdraw Voluntary Contributions by': 'Withdraw Voluntary Contributions',
  'Arrangement of my voluntary contributions': 'Arrangement of Voluntary Contributions',
  'Transfer': 'Transfer',
  'Withdraw': 'Withdraw',
  'Type of MPF Account': 'Type of MPF Account',
  'Account Type': 'Account Type',
  'Personal account': 'Personal Account',
  'Contribution account': 'Contribution Account',
  'Member Number': 'Member Number',
  'Please fill in your member number': 'Please fill in member number',
  'Please fill in your contract number': 'Please fill in your contract number',
  'Please fill in your surname': 'Please fill in your surname',
  'Surname': 'Surname',
  'Contract Number': 'Contract Number',
  'Given Name': 'Given Name',
  'Middle Name(s)': 'Middle Name(s)',
  'Gender': 'Gender',
  'Male': 'Male',
  'Female': 'Female',
  'Nature of Business': 'Nature of Business',
  'Nationality': 'Nationality',
  'Date of birth': 'Date of birth',
  'Job Title': 'Job Title',
  'Country Code': 'Country Code',
  'Mobile no.': 'Mobile no.',
  'Daytime contact no': 'Daytime contact no.',
  'Email Address': 'Email Address',
  'Confirm Email Address': 'Register for e-Notification Service.',
  'Subscribe SMS service': 'Subscribe for Quarterly Account Balance SMS service.',
  'Subscribe e-Communication service': 'Subscribe e-Notification service',
  'Residential address (P.O. Box not allowed)': 'Residential address (P.O. Box not allowed)',
  'Area': 'Area',
  'District': 'District',
  'Street': 'Street',
  'No.': 'No.',
  'Estate': 'Estate',
  'Building': 'Building',
  'Block': 'Block',
  'Floor': 'Floor',
  'Room': 'Room',
  'Zip Code': 'Zip Code',
  'City': 'City',
  'Province': 'Province',
  'Country': 'Country',
  'Mailing Address (if different from Residential Address)': 'Mailing Address (if different from Residential Address)',
  'Personal Account Application - Customer Profile': 'Information of Member',
  'MPF Regulated Activities Form': 'MPF Regulated Activities Form',
  'Reason for choosing the Scheme': 'Reason(s) for choosing the Schemes ',
  'Save & Next': 'Save & Next',
  'The Scheme Participant objects to the propsed use of his/her personal data in direct marketing.': 'The Scheme Participant objects to the proposed use of his/her personal data in direct marketing.',
  CRS: 'CRS',
  'Jurisdiction of Residence and Taxpayer Identification Number or its Functional Equivalent (“TIN”)': 'Jurisdiction of Residence and Taxpayer Identification Number or its Functional Equivalent (“TIN”)',
  '(i) I hereby declare that, to the best of my knowledge and belief': '(i) I hereby declare that, to the best of my knowledge and belief',
  'formcrs-1': '(Please put a 「✓」 in the following box as appropriate.)',
  'My Tax Residence is Hong Kong ONLY, with no tax residence in any other jurisdictions or countries (and my HKID number is my TIN) (You may skip (ii).)': 'My Tax Residence is Hong Kong ONLY, with no tax residence in any other jurisdictions or countries (and my HKID number is my TIN) (You may skip (ii).)',
  'If the box above does not apply, please proceed to (ii) which must be filled in.': 'If the box above does not apply, please proceed to (ii) which must be filled in.',
  '(iii) If you are not a tax resident in the Jurisdiction of Current Residence Address or Mailing Address. Please select one of the following reasons as applicable.': '(iii) If you are not a tax resident in the Jurisdiction of Current Residence Address or Mailing Address. Please select one of the following reasons as applicable.',
  '(ii) Please indicate': '(ii) Please indicate',
  '(a) the jurisdiction of residence (including Hong Kong) where the account holder is a resident for tax purposes; and': '(a) the jurisdiction of residence (including Hong Kong) where the account holder is a resident for tax purposes; and',
  '(b) the account holder’s TIN for each jurisdiction indicated. Indicate all (not restricted to five) jurisdictions of residence.': '(b) the account holder’s TIN for each jurisdiction indicated. Indicate all (not restricted to five) jurisdictions of residence.',
  'If the account holder is a tax resident of Hong Kong, the TIN is the Hong Kong Identity Card Number.': 'If the account holder is a tax resident of Hong Kong, the TIN is the Hong Kong Identity Card Number.',
  'If a TIN is unavailable, provide the appropriate reason A, B or C': 'If a TIN is unavailable, provide the appropriate reason A, B or C',
  'Reason': 'Reason',
  'The jurisdiction where the account holder is a resident for tax purposes does not issue TINs to its residents.': 'The jurisdiction where the account holder is a resident for tax purposes does not issue TINs to its residents.',
  'The account holder is unable to obtain a TIN. Explain why the account holder is unable to obtain a TIN if you have selected this reason.': 'The account holder is unable to obtain a TIN. Explain why the account holder is unable to obtain a TIN if you have selected this reason.',
  'TIN is not required. Select this reason only if the authorities of the jurisdiction of residence do not require the TIN to be disclosed.': 'TIN is not required. Select this reason only if the authorities of the jurisdiction of residence do not require the TIN to be disclosed.',
  Add: 'Add',
  'Loading...': 'Loading...',
  'Jurisdiction of Residence*': 'Jurisdiction of Residence*',
  'TIN*': 'TIN',
  'Enter Reason A, B or C if no TIN is available': 'Enter Reason A, B or C if no TIN is available',
  'Explain why the account holder is unable to obtain a TIN if you have selected Reason B': 'Explain why the account holder is unable to obtain a TIN if you have selected Reason B',
  'Hello': 'Hello',
  'Current Residence Address or Mailing Address': 'Current Residence Address or Mailing Address',
  'I am a student at an educational institution in the jurisdiction where I currently reside and hold the appropriate visa; or': 'I am a student at an educational institution in the jurisdiction where I currently reside and hold the appropriate visa; or',
  'I am a teacher, a trainee, or intern at an educational institution or a participant in an educational or cultural exchange visitor program in the jurisdiction where I currently reside, and hold the appropriate visa; or': 'I am a teacher, a trainee, or intern at an educational institution or a participant in an educational or cultural exchange visitor program in the jurisdiction where I currently reside, and hold the appropriate visa; or',
  'I am a foreign individual assigned to a diplomatic post or a position in a consulate or embassy in the jurisdiction where I currently reside; or': 'I am a foreign individual assigned to a diplomatic post or a position in a consulate or embassy in the jurisdiction where I currently reside; or',
  'I am an employee working on a vessel, truck or train travelling between the jurisdiction where I currently reside and other jurisdictions.': 'I am an employee working on a vessel, truck or train travelling between the jurisdiction where I currently reside and other jurisdictions.',
  'The aforementioned reasonable explanation is NOT applicable to me and I therefore provide the following reasonable explanation to support my jurisdiction of tax residence': 'The aforementioned reasonable explanation is NOT applicable to me and I therefore provide the following reasonable explanation to support my jurisdiction of tax residence',
  'Non-Hong Kong residents': 'Non-Hong Kong residents',
  'Cancel': 'Cancel',
  'Submit': 'Submit',
  'OK': 'OK',
  'Subscription': 'Subscribe Service(s)',
  'Logout': 'Logout',
  'Place of Birth (Optional)': 'Place of Birth (Optional)',
  'HKID': 'HKID',
  'Passport': 'Passport',
  'Title': 'Title',
  'Mr': 'Mr',
  'Mrs': 'Mrs',
  'Ms': 'Ms',
  'Miss': 'Miss',
  'summaryInvestNote': 'If this part is not completed or the investment option is inconsistent with the scheme series selected or total Investment Allocation does not equal to 100%, the Trustee will invest all contributions in DIS.',
  'Surname (English)': 'Surname (English)',
  'Given Name (English)': 'Given Name (English)',
  'Surname (Chinese)': 'Surname (Chinese)',
  'Given Name (Chinese)': 'Given Name (Chinese)',
  'Date of Birth': 'Date of Birth',
  'Mailing Address': 'Mailing Address',
  'Agree': 'Agree',
  'Disagree': 'Disagree',
  'Chinese': 'Chinese',
  'English': 'English',
  'Hong Kong': 'H.K.',
  'Kowloon': 'KLN',
  'New Territories': 'N.T.',
  'Others': 'Others',
  'P.O. Box should not be residential address': 'P.O. Box should not be residential address',
  'Y': 'Yes',
  'N': 'No',
  'transferOption_A': 'All',
  'transferOption_P': 'Partial',
  'Any Voluntary Contributions in the Account?': 'Any Voluntary Contribution in the Account?',
  'Transfer from self employed account?': 'Transfer from self employed account?',
  'Self employed Details': 'Self employed Details',
  'selfEmployedDetails_O': 'Ongoing',
  'selfEmployedDetails_T': 'Terminate',
  'withdrawMethod_B': 'By Bank',
  'withdrawMethod_C': 'By Cheque',
  'Termination Date': 'Termination Date',
  'Payment until': 'Payment until',
  'AIA Company (Trustee) Limited': 'AIA Company (Trustee) Limited',
  'Bank of Communications Trustee Limited': 'Bank of Communications Trustee Limited',
  'Bank Consortium Trust Company Limited': 'Bank Consortium Trust Company Limited',
  'Bank of East Asia (Trustee) Limited': 'Bank of East Asia (Trustee) Limited',
  'BOCI-Prudential Trustee Limited': 'BOCI-Prudential Trustee Limited',
  'China Life Trustees Limited': 'China Life Trustees Limited',
  'HSBC Institutional Trust Services (Asia) Ltd': 'HSBC Institutional Trust Services (Asia) Ltd',
  'HSBC Provident Fund Trustee (Hong Kong) Limited': 'HSBC Provident Fund Trustee (Hong Kong) Limited',
  'Manulife Provident Funds Trust Company': 'Manulife Provident Funds Trust Company',
  'YF Life Trustees Limited': 'YF Life Trustees Limited',
  'Sun Life Pension Trust Limited': 'Sun Life Pension Trust Limited',
  'Principal Trust Company (Asia) Limited': 'Principal Trust Company (Asia) Limited',
  'Sun Life Trustee Company Limited': 'Sun Life Trustee Company Limited',
  'BCT(MPF) Industry Choice': 'BCT(MPF) Industry Choice',
  'BCT(MPF) Pro Choice': 'BCT(MPF) Pro Choice',
  'Allianz Global Investors MPF Plan': 'Allianz Global Investors MPF Plan',
  'BCT Strategic MPF Scheme': 'BCT Strategic MPF Scheme',
  'AMTD MPF Scheme': 'AMTD MPF Scheme',
  'BCOM Joyful Retirement MPF Scheme': 'BCOM Joyful Retirement MPF Scheme',
  'AIA MPF Prime Value Choice': 'AIA MPF Prime Value Choice',
  'BEA (MPF) Industry Scheme': 'BEA (MPF) Industry Scheme',
  'BEA (MPF) Master Trust Scheme': 'BEA (MPF) Master Trust Scheme',
  'BEA (MPF) Value Scheme': 'BEA (MPF) Value Scheme',
  'BOC-Prudential Easy-Choice Mandatory Provident Fund Scheme': 'BOC-Prudential Easy-Choice Mandatory Provident Fund Scheme',
  'My Choice Mandatory Provident Fund Scheme': 'My Choice Mandatory Provident Fund Scheme',
  'China Life MPF Master Trust Scheme': 'China Life MPF Master Trust Scheme',
  'Fidelity Retirement Master Trust': 'Fidelity Retirement Master Trust',
  'Haitong MPF Retirement Fund': 'Haitong MPF Retirement Fund',
  'Hang Seng Mandatory Provident Fund Supertrust Plus': 'Hang Seng Mandatory Provident Fund Supertrust Plus',
  'HSBC Mandatory Provident Fund-Supertrust Plus': 'HSBC Mandatory Provident Fund-Supertrust Plus',
  'Manulife Global Select (MPF) Scheme': 'Manulife Global Select (MPF) Scheme',
  'Mass Mandatory Provident Fund Scheme': 'Mass Mandatory Provident Fund Scheme',
  'Sun Life MPF Basic Scheme': 'Sun Life MPF Basic Scheme',
  'Sun Life MPF Comprehensive Scheme': 'Sun Life MPF Comprehensive Scheme',
  'Principal MPF Scheme Series 600': 'Principal MPF Scheme Series 600',
  'Principal MPF Scheme Series 800': 'Principal MPF Scheme Series 800',
  'Principal MPF-Simple Plan': 'Principal MPF-Simple Plan',
  'Principal MPF-Smart Plan': 'Principal MPF-Smart Plan',
  'Sun Life Rainbow MPF Scheme': 'Sun Life Rainbow MPF Scheme',
  'Standard Chartered Trustee (Hong Kong) Limited': 'Standard Chartered Trustee (Hong Kong) Limited',
  'Default Investment Strategy': 'Default Investment Strategy',
  'Principal Age 65 Plus Fund': 'Principal Age 65 Plus Fund',
  'Principal Core Accumulation Fund': 'Principal Core Accumulation Fund',
  'Principal Capital Guaranteed Fund': 'Principal Capital Guaranteed Fund',
  'Principal Long Term Guaranteed Fund': 'Principal Long Term Guaranteed Fund',
  'Principal MPF Conservative Fund': 'Principal MPF Conservative Fund',
  'Principal HK Dollar Savings Fund': 'Principal HK Dollar Savings Fund',
  'Principal Hong Kong Bond Fund': 'Principal Hong Kong Bond Fund',
  'Principal Asian Bond Fund': 'Principal Asian Bond Fund',
  'Principal International Bond Fund': 'Principal International Bond Fund',
  'Principal Stable Yield Fund': 'Principal Stable Yield Fund',
  'Principal Long Term Accumulation Fund': 'Principal Long Term Accumulation Fund',
  'Principal Global Growth Fund': 'Principal Global Growth Fund',
  'Principal Aggressive Strategy Fund': 'Principal Aggressive Strategy Fund',
  'Principal US Equity Fund': 'Principal US Equity Fund',
  'Principal Asian Equity Fund': 'Principal Asian Equity Fund',
  'Principal China Equity Fund': 'Principal China Equity Fund',
  'Principal Hang Seng Index Tracking Fund': 'Principal Hang Seng Index Tracking Fund',
  'Principal Hong Kong Equity Fund': 'Principal Hong Kong Equity Fund',
  'Principal International Equity Fund': 'Principal International Equity Fund',
  'risk_L': 'L',
  'risk_M': 'M',
  'risk_ML': 'ML',
  'risk_MH': 'MH',
  'risk_H': 'H',
  'risk_': '',
  'Catering': 'Catering',
  'Construction': 'Construction',
  'Manufacturing': 'Manufacturing',
  'Financing / Banking Services': 'Financing / Banking Services',
  'Community / Social / Personal Services': 'Community / Social / Personal Services',
  'Wholesale /Retail / Import & Export': 'Wholesale /Retail / Import & Export',
  'Transport': 'Transport',
  'Cleaning': 'Cleaning',
  'Security Guard': 'Security Guard',
  'Hairdressing & Beauty': 'Hairdressing & Beauty',
  'Estate / Builidng': 'Estate / Builidng',
  'City / State / Country': 'City / State / Country',
  'logintc_1': 'Please acknowledge and consent to this Declaration/ Important note before using this system.',
  'logintc_2': 'Please ensure that you are a subsidiary intermediary attached to AXA China Region Insurance Company Limited.',
  'logintc_3': 'Please ensure that you are authorized to setup the MPF scheme - Principal MPF Scheme Series 800 (“Scheme”).',
  'logintc_4': 'You shall comply with the Personal Data (Privacy) Ordinance, the Mandatory Provident Fund Schemes Ordinance and all other applicable ordinances and rules, guidelines and code of conduct (as amended from time to time) issued by the relevant authorities when using client’s information.',
  'logintc_5': 'This system may carry additional risks including any loss or damage caused by data, mobile equipment setting or its hardware, any interception, interruption or corruption of instruction / transmission or unauthorized use of password due to auto-password-saving feature of certain mobile equipment. Principal Trust Company (Asia) Limited and its affiliates (collectively referred to as “Principal”) shall not be held responsible for any of such risks, loss or damage.',
  'logintc_6': 'For data security reasons, we strongly recommend that you do NOT use a public computer or wireless network available to the public to access this system.  Before accessing this system, please ensure that you have installed an up-to-date anti-virus software and anti-spyware software on your computer or mobile equipment (where appropriate) to protect it from viruses and malicious programs\' attack.',
  'logintc_7': 'The system includes temporary save/ storage function, draft application will only be kept for 45 days while submitted application can only be kept for 45 days. Please ensure to obtain client’s consent before inputting, revising, deleting and submitting the data.',
  'logintc_8': 'The system would not guarantee the account setup or MPF account transfer can be successfully executed.  Such action may be rejected due to incorrect information provided, repeated applications or other possible reasons. Principal shall have the right to make the final decision.',
  'logintc_9': 'After submission, client will receive an encrypted copy of the application(s) submitted by email for record keeping. If the system do not have records of client’s email address, a hard copy of the submitted application(s) will be delivered to client’s correspondence address by post. Please ensure the correspondence address and email address (if any) of client are accurately provided.',
  'Name of Constituent Funds': 'Name of Constituent Funds',
  'Fund Code': 'Fund Code',
  'Risk / Return Profile': 'Risk / Return Profile',
  'Trustee': 'Trustee',
  'Original Plan': 'Original Plan',
  'Transfer type': 'Transfer type',
  'Account No.': 'Account No.',
  'Action': 'Action',
  'Member Information': 'Member Information',
  'Investment Total': 'Investment Total',
  'Transfered Account': 'Transfered Account',
  'Personal Information Collection Statement': 'Personal Information Collection Statement',
  'tc-personal-axa-1': 'AXA China Region Insurance Company Limited (referred to hereinafter as “AXA CRI”) recognises its responsibilities in relation to the collection, holding, processing, use and/or transfer of personal data under the Personal Data (Privacy) Ordinance (Cap. 486) (“PDPO”). Personal data will be collected only for lawful and relevant purposes and all practicable steps will be taken to ensure that personal data held by AXA CRI is accurate. AXA CRI will take all practicable steps to ensure security of the personal data and to avoid unauthorised or accidental access, erasure or other use.',
  'tc-personal-axa-2': 'Please note that if you do not provide us with your personal data, we may not be able to provide the information, products or services you need or process your request.',
  'tc-personal-axa-3': 'Purpose: From time to time it is necessary for AXA CRI to collect your personal data which may be used, stored, processed, transferred, disclosed or shared by us to such persons as Principal Trust Company (Asia) Limited (the “Trustee”) or any service provider may consider necessary for purposes (“Purposes”), including: 1. offering, providing and marketing to you the products/services of the Trustee, and administering, maintaining, managing and operating such products/services; 2. Processing and evaluating any applications or requests made by you for mandatory provident fund (“MPF”) related products/services offered by the Trustee and our affiliates; 3. providing subsequent services to you pertaining to MPF related products and services; 4. evaluating your financial needs; 5. designing MPF-related products/services for customers; 6. matching any data held which relates to you from time to time for any of the purposes listed herein; 7. making disclosure as required by any applicable law, rules, regulations, codes of practice or guidelines or to assist in law enforcement purposes, investigations by police or other government or regulatory authorities in Hong Kong or elsewhere; 8. conducting identity checks ; 9. complying with the laws of any applicable jurisdiction; and 10. other purposes directly relating to any of the above.',
  'tc-personal-axa-4': 'Transfer of personal data: Personal data will be kept confidential but, subject to the provisions of any applicable law, may be provided to: 1. any of our affiliates, any person associated with AXA CRI, the Trustee, your employer, industry association or federation in Hong Kong or elsewhere and in this regard you consent to the transfer of your data outside of Hong Kong; 2. any person to conduct investigation as necessary in respect of any MPF related products/services provided by AXA CRI and/or our affiliates and the Trustee; 3. any agent, contractor or third party who provides administrative, technology or other services to AXA CRI and/or our affiliates and the Trustee in Hong Kong or elsewhere and who has a duty of confidentiality to the same; 4. any actual or proposed assignee, transferee, participant or sub-participant of our rights or business; and 5. any government department or other appropriate governmental or regulatory authority in Hong Kong or elsewhere.',
  'tc-personal-axa-5': 'Transfer of your personal data will only be made for one or more of the Purposes specified above.',
  'tc-personal-axa-6': 'Access and correction of personal data: Under the PDPO, you have the right to ascertain whether AXA CRI holds your personal data, to obtain a copy of the data, and to correct any data that is inaccurate. You may also request AXA CRI to inform you of the type of personal data held by it. Requests for access and correction or for information regarding policies and practices and kinds of data held by AXA CRI should be addressed in writing to: Data Privacy Officer, AXA China Region Insurance Company Limited, Suite 2001, 20/F, Tower Two, Times Square,1 Matheson Street, Causeway Bay, Hong Kong. A reasonable fee may be charged to offset AXA CRI’s administrative and actual costs incurred in complying with your data access requests.  。',
  'tc-personal-1': 'The provision of information and other personal data by you is on a voluntary basis.  However, failure to provide us with the information and other personal data as requested may result in your application/instruction not being able to be processed.  The information and other personal data provided by you will only be accessed and handled by Principal Trust Company (Asia) Limited (“the Trustee and Administrator”) and its affiliate(s), the sponsor, the promoter of the Scheme and the relevant persons stated herein below.',
  'tc-personal-2': 'The information and other personal data collected from you from time to time will be used for the purposes of: (1) processing your application for participation under the Scheme; (2) administering and managing your contributions and accrued benefits under the Scheme; (3) carrying out your instructions or responding to any enquiries given or purporting to be given by you or on your behalf; (4) direct marketing of mandatory provident fund (“MPF”), retirement schemes, MPF and retirement schemes related services and products of Principal Trust Company (Asia) Limited and its member company(ies); (5) providing MPF and retirement schemes related services; (6) maintaining statistical data and providing a database for product and market research; (7) compliance with applicable laws, regulations, guidelines or guidance given or issued by any legal, regulatory, governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or associations of financial services providers within or outside the Hong Kong Special Administrative Region (“Hong Kong”), including but not limited to the Foreign Account Tax Compliance Act and the Common Reporting Standard; and (8) any other purposes relating or incidental to the above.',
  'tc-personal-3': 'Furthermore, for the purpose of automatic exchange of financial account information, such information and information regarding the account holder and any reportable account(s) may be reported by the Trustee and its member company(ies) to the Inland Revenue Department of the Government of Hong Kong (“IRD”) and exchanged with the tax authorities of another jurisdiction or jurisdictions in which the account holder may be resident for tax purposes, pursuant to the legal provisions for exchange of financial account information provided under the Inland Revenue Ordinance (Cap.112 of the Laws of Hong Kong).  You shall advise us of any change in circumstances which affects the tax residency status of the account holder, and to provide us with a suitably updated self-certification form within 30 days of such change in circumstances.',
  'tc-personal-4': 'You may visit the IRD website that sets out information relating to the implementation of automatic exchange of financial account information in Hong Kong: http://www.ird.gov.hk/eng/tax/dta_aeoi.htm.',
  'tc-personal-5': 'Your personal data (name, contact details and account records) may be used for direct marketing of MPF products, retirement schemes, MPF and retirement schemes related services and products of the Trustee and its member company(ies) only with your consent.  If you do not wish your personal data being used for direct marketing as described herein, you should indicate your objection by ticking ( “✓”) the box placed at the end of this Personal Information Collection Statement.',
  'tc-personal-6': 'Your personal data may be transferred/disclosed to the following parties (whether within or outside Hong Kong) for any of the purposes stated above: (1) any agent, contractor, third party service provider, or any company(ies) within the same group of companies to which the Trustee belongs which provides administrative, telecommunications, computer, data processing or storage, marketing, professional or other services to the Trustee in connection with their business operations; (2) any person to whom the Trustee is under an obligation to make disclosure under the requirements of any laws and regulations binding on the Trustee or any of its member company(ies) or under and for the purposes of any guidelines issued by regulatory, tax or other authorities with which the Trustee or its member companies are expected to comply; and (3) any actual or proposed assignee of the Trustee or participant or sub-participant or transferee of the rights of the Trustee in respect of you.',
  'tc-personal-7': 'Under the Personal Data (Privacy) Ordinance (Cap.486 of the Laws of Hong Kong), you have a right to request access to and correction of any of your personal information held by the Companies and to request not to use your personal data for direct marketing purpose as stated above. The aforesaid requests can be made in writing to: Data Protection Officer, Principal Trust Company (Asia) Limited, 30/F, Millennium City 6, 392 Kwun Tong Road, Kwun Tong, Kowloon, Hong Kong.  If you have any questions or wish to know more about our privacy policy, please send your enquiry to the above address or contact us at (852) 2251 9322 (for employers) or (852) 2827 1233 (for members).',
  'Personal Information Collection Statement_1': 'Personal Information Collection Statement',
  'No record was found': 'No record was found',
  'Last Modified at': 'Last Modified at',
  'No submission was found': 'No submission was found',
  'Invest all contributions in DIS?': 'Invest all contributions in DIS?',
  'Transfer Account': 'Transfer Account',
  Terminate: 'Terminate',
  Ongoing: 'Ongoing',
  'Connecting to Adobe Sign...': 'Connecting to Adobe Sign...',
  'Submitting Application...': 'Submitting Application...',
  'Application Submitted': 'Application Submitted',
  'Reference Number': 'Reference Number',
  'Next': 'Next',
  'Preview': 'Preview',
  'Click this for Remote Signing': 'Click this for Remote Signing',
  'List of Submitted Applications': 'List of Submitted Applications',
  'Submitted Applications': 'Submitted Applications',
  'Create New Application': 'Create New Application',
  'Edit Draft': 'Edit Draft',
  'Consent to Use of Adobe': 'Consent to Use of Adobe',
  'adobetemrs-1': 'I agree to use Adobe and agree to be bound by the Adobe General Terms of Use and the Consumer Disclosure Regarding Conducting Business Electronically, Receiving Electronic Notices and Disclosures, and Signing Documents Electronically, which can be found at <1>https://www.adobe.com/legal/terms.echo.html</1>',
  'adobetemrs-2': ' and <1>https://www.adobe.com/special/misc/consumerdisclosure.html</1> respectively, and may be updated and revised from time to time by Adobe without notice. The updated and revised terms will be available at the above-referenced website.',
  'M': 'Male',
  'F': 'Female',
  'zh-Hant': 'Chinese',
  'en': 'English',
  'area_HK': 'Hong Kong',
  'area_KLN': 'Kowloon',
  'area_NT': 'New Territories',
  'area_Others': 'Others',
  'identType_hkid': 'HK ID',
  'identType_passport': 'Passport',
  'The max length is': 'The max length is',
  'formCrs-2': 'If Part ( i ) is filled in, there is no need to fill in Part ( ii ).',
  'formCrs-3': 'Please fill in either Part ( i ) or Part ( ii ).',
  'area_': '',
  'modalWitness-1': 'The current version of the system is unable to support application which needs a witness.  Please use paper form to apply if necessary.',
  'Please select a new scheme.': 'Please select a scheme.',
  'Please refresh the brwoser to try again': 'Please refresh the brwoser to try again',
  'modalac-q1a1': 'Employee mandatory contributions in <0>current employment</0>',
  'modalac-q1a2': 'Employee voluntary contributions in <0>current employment</0>',
  'modalac-q1a3': 'Mandatory contributions that have been transferred into the contribution account and are attributable to <0>former employment(s)</0>',
  'modalac-q1a4': 'Voluntary contributions that have been transferred into the contribution account and are attributable to <0>former employment(s)</0>',
  'formAC-1': 'Please add an account first.',
  'Remove': 'Remove',
  'Add a new document': 'Add a new document',
  'Please select a document type': 'Please select a document type',
  'Document type': 'Document type',
  'Threshold': 'Threshold',
  'Please adjust the threshold value for a clear image': 'Please adjust the threshold value for a clear image',
  'MPF Registration No.': 'MPF Registration Number',
  'Agent Contact Number': 'Intermediary Contact No.',
  'Please fill in': 'Please fill in ',
  'Click to see the sample': 'Click to see the sample',
  'MPF Intermediary Name': 'Intermediary Name',
  'as shown on HKID Card / passport': 'as shown on HKID Card / passport',
  'Client age must be >= 18': 'Client age must be >= 18',
  'Invalid HKID': 'Invalid HKID',
  'Please input a valid number': 'Please input a valid number',
  'Please input a valid number. Max length is 15': 'Please input a valid number. Max length is 15',
  'Please enter a vaild email': 'Please enter a vaild email',
  'Please select at least one reason': 'Please select at least one reason',
  'Please input an integer between 0 to 100': 'Please input an integer between 0 to 100',
  'The total must be 0 % or 100%': 'The total must be 0 % or 100%',
  'Please upload the ID / Passport copy': 'Please upload the ID / Passport copy',
  'List of Applications (click to open)': 'List of Applications (click to open)',
  'CLOSE': 'CLOSE',
  'EDIT APPLICATION': 'EDIT APPLICATION',
  'E-signatures Tracking': 'E-signatures Tracking',
  'Waiting for signarure': 'Email has been sent out, waiting for signarure',
  'DISCLOSURE OF NECESSARY INFORMATION': 'DISCLOSURE OF NECESSARY INFORMATION',
  'The application is signed and being processed': 'The application is signed and being processed',
  'Principal Personal Information Collection Statement': 'Principal - Personal Information Collection Statement',
  'I Confirm': 'I Confirm',
  'The application is cancelled': 'The application is cancelled',
  'The agreeemnt should be signed in Hong Kong': 'The agreeemnt should be signed in Hong Kong',
  'Loading data from Adobe Sign': 'Loading data from Adobe Sign',
  'Declaration': 'Declaration',
  'Please confirm this application is signed in Hong Kong.': 'Please confirm this application is signed in Hong Kong.。',
  'PIRS Process (New Case)': 'PIRS Process (New Case)',
  'Edit PIRS Process': 'Edit WIP PRIS Process',
  'Marital Status': 'Marital Status',
  'Education Level': 'Education Level',
  'Occupation': 'Occupation',
  'Single': 'Single',
  'Married': 'Married',
  'Widowed': 'Widowed',
  'Divorced': 'Divorced',
  'Client Confirmations': 'Client Confirmations',
  'Personal Information': 'Personal Information',
  'Name of Employer': 'Name of Employer',
  'Business Address': 'Business Address',
  'Primary': 'Primary School or below',
  'Secondary': 'Secondary School',
  'Non-degree Tertiary': 'Post-Secondary',
  'Degree or above': 'University or above',
  'Finance / Insurance': 'Finance / Insurance',
  'Engineers': 'Engineers',
  'Professionals': 'Professionals',
  'Customer Services': 'Customer Services',
  'Information Technology': 'Information Technology',
  'Housewife': 'Housewife',
  'Retired': 'Retired',
  'Unemployment': 'Unemployment',
  'Disclosure of Sales Related Information': 'Disclosure of Sales Related Information',
  'mr-disclosure-1': '(Pursuant to Section 34ZL(1)(e) of the Mandatory Provident Fund Schemes Ordinance)',
  'mr-disclosure-2': 'Client Name',
  'mr-disclosure-3': 'The details of sales related information are as follows:',
  'mr-disclosure-4': '1. I, ',
  'mr-disclosure-5': '(MPF Intermediary Reg. No. ',
  'mr-disclosure-6': '), am acting as the subsidiary intermediary of the principal intermediary, Principal Investment & Retirement Services Limited (“PIRS”), in distributing Principal MPF Scheme Series 800 ("Scheme").',
  'mr-disclosure-7': '2. PIRS, with its principal place of business at 29/F, Sun Hung Kai Centre, 30 Harbour Road, Wanchai, Hong Kong and its branch office at 30/F, Millennium City 6, 392 Kwun Tong Road, Kowloon, Hong Kong, is the promoter of the Scheme. The trustee and administrator of the Scheme are Principal Trust Company (Asia) Limited. The investment manager of the Scheme is Principal Asset Management Company (Asia) Limited. The custodian of the Scheme is Citibank N.A..',
  'mr-disclosure-8': '3. Neither PIRS nor I will charge the client any direct fees in respect of the services provided in point 5 below.',
  'mr-disclosure-9': '4. In addition to receiving monthly salary, I may receive year-end bonus upon successfully selling of the Scheme if I can achieve certain annual sales target. The choice of constituent funds(s) made by the client under the Scheme will not affect my monthly salary income/year-end bonus.',
  'mr-disclosure-10': '5. I am conducting/will conduct the following regulated activity (ies):Invite you to make one or more of the following materials decision/advise you on:',
  'mr-disclosure-10-1': '(a) making a transfer that would involve a transfer out of a guaranteed fund.',
  'mr-disclosure-10-2': '(b) making an early withdrawal of accrued benefits from the MPF System.',
  'mr-disclosure-10-3': '(c) whether, or when, to apply to join or become a member of the Scheme.',
  'mr-disclosure-10-4': '(d) whether, or when, to apply to participate in the Scheme as an employer.',
  'mr-disclosure-10-5': '(e) whether, or when, to pay contributions (including voluntary contributions) to the Scheme, or to invest in a particular constituent fund of the Scheme.',
  'mr-disclosure-10-6': '(f) the amount of contributions (including voluntary contributions) to be paid to the Scheme, or the amount to be invested in a particular constituent fund of the Scheme.',
  'mr-disclosure-10-7': '(g) whether, or when, to transfer accrued benefits from a registered scheme to the Scheme; or from a particular constituent fund of a registered scheme to a particular constituent fund of the Scheme.',
  'mr-disclosure-10-8': '(h) the amount of accrued benefits to be transferred from a registered scheme to the Scheme, or from a particular constituent fund of a registered scheme to a particular constituent fund of the Scheme.',
  'mr-disclosure-10-9': '(i) whether, or when, to transfer benefits or the amount of benefits to be transferred from an occupational retirement scheme to the Scheme.',
  'mr-disclosure-10-10': '(j) whether, or when, to make a claim or the amount of a claim for the payment of accrued benefits from a registered scheme.',
  'Home': 'Home',
  'Office': 'Office',
  'Fax': 'Fax',
  'PersonName': 'Name',
  'Age': 'Age',
  'Relationship': 'Relationship',
  'PIRS Process Number': 'PIRS Process Number',
  'Refresh Data': 'Refresh Data',
  'RPQ': 'RPQ',
  'Confirmation of Receiving Doc': 'Confirmation of Receiving Doc',
  'FNA': 'Financial Needs Analysis',
  'FNA - Important Notes': 'Financial Needs Analysis - Important Notes',
  'Invalid Date Format': 'Invalid Date Format',
  'Financial Needs Analysis': 'Financial Needs Analysis',
  'Dependents': 'Dependents',
  'Forms Preview': 'Forms Preview',
  'IMPORTANT NOTICE': 'IMPORTANT NOTICE',
  'Appointment & PICS': 'Appointment & PICS',
  'Client Agreement & Risk Disclosure Statement': 'Client Agreement & Risk Disclosure Statement',
  'Investment Advice Record': 'Investment Advice Record',
  'Sign on the spot': 'Sign on the spot',
  'Non-Face to face e-signing': 'Non-Face to face e-signing',
  'Actions': 'Actions',
  'FTLife': 'CTFLife',
  'Must Complete': 'Must Complete',
  'Missing "Account Number(s)" in the "Account(s) to be Consolidated" Screen, please go back to update the record.': 'Missing "Account Number(s)" in the "Account(s) to be Consolidated" Screen, please go back to update the record.',
  'Subscription remarks': 'If you have previously subscribed our SMS and/or e-Notification service, you do not need subscribe again',
  'Please select an account type': 'Please select an account type.',
  'Manulife RetireChoice (MPF) Scheme': 'Manulife RetireChoice (MPF) Scheme',
  '5 points to avoid delay/rejection of account setup through iPA': '5 points to avoid delay/rejection of account setup through iPA',
  'screen-guide-1': 'Ensure the photo and the text are clear when uploading the HKID.',
  'screen-guide-2': 'Certification of HKID card by the financial consultant himself is not accepted.',
  'screen-guide-3': 'Clearly state the Nature of Business and Job Title.',
  'screen-guide-4': 'Incomplete residential address.',
  'screen-guide-5': 'The name of client and that for e-signature is not matched.',
  'Communication Language': 'Communication Language',
  'Family Status': 'Family Status',
  'Annual Income with desc': 'Annual Income (HK$) (Includes bonus, double pay, etc)',
  'email-tooltip': 'E-Notification Service covers all notices and documents in electronic form issued by Principal Trust Company (Asia) Limited from time to time which include member benefit statement, notices and fund fact sheets. To register for this service, you must provide your email address. The E-Notification Service shall apply to all your MPF accounts under the MPF scheme you have given consent and will extend to the new MPF account(s) under the same scheme. After registration, we will send the E-Notification to your email address for retrieving the notices and documents on our website. The retention period of member benefit statements and fund fact sheets on the website is for a minimum of 24 months while the notices is for a minimum of 12 months after the issuance of the E-Notification. If we become aware that you could not receive the E-Notification, hard copies of the notices and documents will be sent to your latest correspondence address at no extra cost. Your E-Notification Service remains valid until it is cancelled by following reasons: (i) you cancel the E-Notification Service, (ii) all your MPF accounts have been terminated, (iii) the E-Notification cannot be successfully given to you and no valid email address can be updated from you within a period of 60 days, and (iv) we have been notified the death or mental incapacitation of you. You may revoke your consent to the subscription for E-Notification Service at any time by contacting Principal.  If you revoke such consent, you will be unsubscribed from E-Notification Service automatically.  A confirmation notice will be sent to you after completion.',
  'sms-tooltip': 'By subscribing Quarterly Account Balance SMS service, you will receive regular updates of your personal pension account balance via SMS on your registered cellular phone number. You may revoke your consent to the subscription for Quarterly Account Balance SMS service at any time by contacting Principal.',
  'Occupation - Group': 'Occupation - Group',
  'Please select': 'Please select',
  'pirs-rpq-q1-1': 'What is your age group?',
  'pirs-rpq-q2-1': 'What percentage of your total net liquid assets will you invest in investment products?',
  'pirs-rpq-q3-1': 'Have you had any experience in investing in the financial instruments below?',
  'pirs-rpq-q4-1': 'How many years of investment experience do you have with the financial instruments selected above?',
  'pirs-rpq-q5-1': 'What is your investment objective?',
  'pirs-rpq-q6-1': 'What is your expected investment horizon?',
  'pirs-rpq-q7-1': 'Over a short time horizon (i.e. 1 year), what would be a comfortable average price fluctuation level to you?',
  'pirs-rpq-q8-1': 'To meet your long-term objectives, how would you feel if there is a decline in the value of your capital of your investment(s) over a period of time?',
  'Investment Grade Bonds / Bond Funds': 'Investment Grade Bonds / Bond Funds',
  'Foreign Currency / Precious Metal': 'Foreign Currency / Precious Metal',
  'Balanced Funds / Mixed Asset Funds': 'Balanced Funds / Mixed Asset Funds',
  'Stock / ETF / Equity Funds': 'Stock / ETF / Equity Funds',
  'High Yield Bond Funds / Structured Products / Derivatives': 'High Yield Bond Funds / Structured Products / Derivatives',
  'None of the above': 'None of the above',
  'Less than 1 year': 'Less than 1 year',
  '1 to less than 3 years': '1 to less than 3 years',
  '3 to less than 5 years': '3 to less than 5 years',
  '5 years or above': '5 years or above',
  'No experience': 'No experience',
  'Capital preservation': 'Capital preservation',
  'Regular stable income': 'Regular stable income',
  'Balance between regular income and capital appreciation': 'Balance between regular income and capital appreciation',
  'Substantial capital appreciation': 'Substantial capital appreciation',
  'Maximize capital appreciation by taking maximum risk': 'Maximize capital appreciation by taking maximum risk',
  '1 to 2 years': '1 to 2 years',
  '2 to 3 years': '2 to 3 years',
  '3 to 5 years': '3 to 5 years',
  'More than 5 years': 'More than 5 years',
  'Around (+/-) 5%': 'Around (+/-) 5%',
  'Around (+/-) 10%': 'Around (+/-) 10%',
  'Around (+/-) 15%': 'Around (+/-) 15%',
  'Around (+/-) 20%': 'Around (+/-) 20%',
  'Around or more than (+/-) 25%': 'Around or more than (+/-) 25%',
  'pirs-rpq-a8a1': 'I am not willing to take any risk and will be disappointed if there is a drop in value of my investment(s)',
  'pirs-rpq-a8b1': 'I am willing to accept a lower rate of return as long as fluctuations in the value of my investment(s) are small',
  'pirs-rpq-a8c1': 'I am willing to take a moderate level of risk in return for a better rate of return',
  'pirs-rpq-a8d1': 'I generally invest for the long-term, but would be concerned with the huge fluctuation and drops',
  'pirs-rpq-a8e1': 'I generally invest for the long term and would accept these fluctuations associated with higher risks for higher returns',
  'Agriculture and Fisheries / Manufacturing': 'Agriculture and Fisheries / Manufacturing',
  'Blacksmith, Toolmaker, Plumber, Welder, Stonecutter & Carver': 'Blacksmith, Toolmaker, Plumber, Welder, Stonecutter & Carver',
  'Buyer, merchandiser': 'Buyer, merchandiser',
  'Carpenter, Wood Worker, Wood Preparation Worker, Logger, Forestry Worker': 'Carpenter, Wood Worker, Wood Preparation Worker, Logger, Forestry Worker',
  'Crop & Farm Worker, Livestock Farmer, Other Agricultural Worker, Fisherman': 'Crop & Farm Worker, Livestock Farmer, Other Agricultural Worker, Fisherman',
  'Factory/Production supervisor/worker, Manual/Industrial Worker': 'Factory/Production supervisor/worker, Manual/Industrial Worker',
  'Jewellery & Precious Metal Worker,  Metal Processor': 'Jewellery & Precious Metal Worker,  Metal Processor',
  'Machinery Fitter, Assembler': 'Machinery Fitter, Assembler',
  'Printer & Related, Paper pulp & Paper Maker': 'Printer & Related, Paper pulp & Paper Maker',
  'Spinner, Weaver, Knitter, Dyer, Tanner, Fellmonger, Felt Dresser': 'Spinner, Weaver, Knitter, Dyer, Tanner, Fellmonger, Felt Dresser',
  'Auctioneer': 'Auctioneer',
  'Banking / Financial Services / Business Professionals': 'Banking / Financial Services / Business Professionals',
  'Accountant, auditor': 'Accountant, auditor',
  'Banker': 'Banker',
  'Business Executive, businessman, merchant': 'Business Executive, businessman, merchant',
  'Chief Executive Officer': 'Chief Executive Officer',
  'Chief Officers: operations, legal, finance, technology, risk, internal audit, compliance, etc.': 'Chief Officers: operations, legal, finance, technology, risk, internal audit, compliance, etc.',
  'Company Director / Chairman': 'Company Director / Chairman',
  'Economist': 'Economist',
  'Financial analyst, business analyst': 'Financial analyst, business analyst',
  'Human resources personnel, recruiters': 'Human resources personnel, recruiters',
  'Insurance agent/consultant, financial planner, real estate agent': 'Insurance agent/consultant, financial planner, real estate agent',
  'Market Research Field Worker': 'Market Research Field Worker',
  'Statistician, Actuaries': 'Statistician, Actuaries',
  'Construction/Engineer': 'Construction/Engineer',
  'Diplomatic Personnel': 'Diplomatic Personnel',
  'Disciplined Services': 'Disciplined Services',
  'Domestic / Property Service': 'Domestic / Property Service',
  'Government Officials': 'Government Officials',
  'Information Technology Professionals': 'Information Technology Professionals',
  'Legal Professionals': 'Legal Professionals',
  'Judge, Magistrate, Judicial Officer': 'Judge, Magistrate, Judicial Officer',
  'Solicitor, lawyer, legal service personnel, company secretary': 'Solicitor, lawyer, legal service personnel, company secretary',
  'Life Science and Health Professionals': 'Life Science and Health Professionals',
  'Middle Management / Administrative / Clerical': 'Middle Management / Administrative / Clerical',
  'Money Changer': 'Money Changer',
  'Other Professionals': 'Other Professionals',
  'Athlete, Sportsman': 'Athlete, Sportsman',
  'Author, Critic, Journalist': 'Author, Critic, Journalist',
  'Biologist, Zoologist & related': 'Biologist, Zoologist & related',
  'Commercial Artist & Designer': 'Commercial Artist & Designer',
  'Film producer/technician and personnel': 'Film producer/technician and personnel',
  'Geologist, Miner & Quarryman': 'Geologist, Miner & Quarryman',
  'Glass/Potter & related maker': 'Glass/Potter & related maker',
  'Medicine sales representative': 'Medicine sales representative',
  'Other Production Worker': 'Other Production Worker',
  'Performers': 'Performers',
  'Petroleum Refining Worker': 'Petroleum Refining Worker',
  'Promoter, Demonstrator': 'Promoter, Demonstrator',
  'Rubber & Oil palm Processor, Rubber & Plastic Product Maker': 'Rubber & Oil palm Processor, Rubber & Plastic Product Maker',
  'Sculptor, Painter, Artist': 'Sculptor, Painter, Artist',
  'Shoe Maker & Leather Goods Maker': 'Shoe Maker & Leather Goods Maker',
  'Still & Movie Cameraman': 'Still & Movie Cameraman',
  'Street/News Vendor, Hawker': 'Street/News Vendor, Hawker',
  'Tobacco Preparer & Cigar Maker': 'Tobacco Preparer & Cigar Maker',
  'Upholsterer': 'Upholsterer',
  'Personal Care Services': 'Personal Care Services',
  'Retail / Catering / Cleaning / Food Service Worker': 'Retail / Catering / Cleaning / Food Service Worker',
  'Retiree': 'Retiree',
  'Social Worker and Religious Services': 'Social Worker and Religious Services',
  'Student': 'Student',
  'Teacher / Education Professional': 'Teacher / Education Professional',
  'Transportation / Logistics / Hospitality / Tourism': 'Transportation / Logistics / Hospitality / Tourism',
  'Unemployed': 'Unemployed',
  'Positive number only': 'Positive number only',
  'MPF Client Confirmation': 'MPF Client Confirmation',
  'Important Note': 'Important Note',

  'Please drag & drop files here, or click to select files': 'Please drag & drop files here, or click to select files',
  'Please click': 'Please click',
  'Important note about nationality proof': 'Important note about nationality proof',
  'IPA – nationality proof': 'IPA – nationality proof`',
  'nationality-proof-notice-body': 'If the nationality reported does not match with the information shown on the copy of identity proof, client must also provide copy of nationality proof bearing photograph. Please send the relevant document to <1>{{email}}</1> with subject “{{subject}}”.'
}
