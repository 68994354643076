import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import { Typography } from '@material-ui/core'

// typings
import { FieldError } from 'react-hook-form/dist/types'

interface FormErrorProps {
  errors: FieldError | undefined
}

const useStyles = makeStyles(() => ({
  verticalAlign: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}))

const ErrorMsg: React.FunctionComponent<FormErrorProps> = ({ errors }) => {
  const classes = useStyles()
  if (!errors || !errors.message) {
    return null
  }
  return (
    <Typography
      color='error'
      className={classes.verticalAlign}
      id={`error-${errors && errors.ref ? errors.ref.name : 'generic'}`}
    >
      <ErrorIcon />
      &nbsp;
      {errors.message}
    </Typography>
  )
}

export default ErrorMsg
