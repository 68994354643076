import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
// import Select from 'react-select'
import _map from 'lodash/map'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import FormError from 'client/components/formErrorMsg'
import { useStyles } from './style'
import { useTranslation } from 'react-i18next'

interface Props {
  disabled?: boolean
  required?: boolean
  defaultValue: any
  label: string
  inputName: string
  items: Items[]
  handleOnChange?: any
  style?: any
}

interface Items {
  label: string
  value: string
}

export default function InputDynamicSelect(props: Props) {
  const classes = useStyles()

  const [selectedValue, setSelectedValue] = useState(props.defaultValue)
  const { register, errors, setValue } = useFormContext()
  const [t] = useTranslation()
  const style = props.style ? props.style : {}

  function _handleChange(event: any) {
    const val = event.target.value
    setSelectedValue(val)
    setValue(props.inputName, val, { shouldValidate: true, shouldDirty: true })
    if (props.handleOnChange) {
      props.handleOnChange(event)
    }
  }

  const MSG_PLEASE_FILL_IN_THIS_FIELD = t('Please fill in this field')

  return (
    <div className={classes.root}>
      <FormControl
        variant='outlined'
        className={classes.formControl}
        fullWidth
        style={style}
      >
        <InputLabel className={classes.labelRoot}>{props.label}</InputLabel>
        <Select
          disabled={props.disabled}
          defaultValue={props.defaultValue || ''}
          onChange={_handleChange}
        >
          {_map(props.items, (val: any, idx: number) => {
            return (
              <MenuItem key={idx} value={val.value}>
                {t(val.label)}
              </MenuItem>
            )
          })}
        </Select>
        <input
          disabled={props.disabled}
          name={props.inputName}
          type='hidden'
          value={selectedValue || ''}
          ref={
            props.required
              ? register({
                  required: MSG_PLEASE_FILL_IN_THIS_FIELD,
                })
              : register
          }
        />
        <FormError errors={errors[props.inputName]} />
      </FormControl>
    </div>
  )
}
