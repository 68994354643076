import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core'
import {
  updateCustomerInformation,
  upsertApplicationData,
} from 'client/actions'
import { ClientInformationDto } from 'client/api/application/api'
import FormError from 'client/components/formErrorMsg'
import InputDynamicCheckbox from 'client/components/inputDynamicCheckbox'
import InputDynamicSelect from 'client/components/inputDynamicSelect'
import { isPostOfficeBox } from 'client/validations'
import { hkArea } from 'config'
import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useStyles } from './style'

interface Props {
  data?: any
  activeStep?: any
  goNextStep?: any
  applicationData: any
  mode: string
}

const AddressForm = (props: Props) => {
  const [t] = useTranslation()
  const hookFormFunc = useForm()
  const { handleSubmit, register, errors, getValues } = hookFormFunc

  // allow PO box for mailing address
  const validatePostOfficeBox = (value = '', isMailAddress = false) => {
    if (!value) return true
    const isPostAddress = isPostOfficeBox(value)
    // return (
    //   !isPostAddress ||
    //   (isMailAddress
    //     ? 'P.O. Box should not be mailing address'
    //     : 'P.O. Box should not be residential address')
    // )
    return isPostAddress
      ? t('P.O. Box should not be residential address')
      : true
  }

  const state: ClientInformationDto = useSelector((state: any) => {
    return state.application.data.clientInformation as ClientInformationDto
  })

  useEffect(() => {
    console.log('state.isMailAddressDifferent', state.isMailAddressDifferent)
    setIsMailDiff(state.isMailAddressDifferent === 'Y')
  }, [state])

  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const [isMailDiff, setIsMailDiff] = useState(false)

  const onSubmit = (formData: any) => {
    console.log('formData', formData)

    const newData = {
      residentialAddressArea: formData.residentialAddressArea,
      residentialAddressDistinct: formData.residentialAddressDistinct,
      residentialAddressStreet: formData.residentialAddressStreet,
      residentialAddressStreetNumber: formData.residentialAddressStreetNumber,
      residentialAddressEstate: formData.residentialAddressEstate,
      residentialAddressBuilding: formData.residentialAddressBuilding,
      residentialAddressBlock: formData.residentialAddressBlock,
      residentialAddressFloor: formData.residentialAddressFloor,
      residentialAddressRoom: formData.residentialAddressRoom,
      residentialAddressZipCode: formData.residentialAddressZipCode,
      residentialAddressCity: formData.residentialAddressCity,
      residentialAddressState: formData.residentialAddressState,
      residentialAddressCountry: formData.residentialAddressCountry,
      isMailAddressDifferent:
        formData.isMailAddressDifferent === 'Y' ? 'Y' : 'N',
      mailAddressArea: isMailDiff ? formData.mailAddressArea : '',
      mailAddressDistinct: isMailDiff ? formData.mailAddressDistinct : '',
      mailAddressStreet: isMailDiff ? formData.mailAddressStreet : '',
      mailAddressStreetNumber: isMailDiff
        ? formData.mailAddressStreetNumber
        : '',
      mailAddressEstate: isMailDiff ? formData.mailAddressEstate : '',
      mailAddressBuilding: isMailDiff ? formData.mailAddressBuilding : '',
      mailAddressBlock: isMailDiff ? formData.mailAddressBlock : '',
      mailAddressFloor: isMailDiff ? formData.mailAddressFloor : '',
      mailAddressRoom: isMailDiff ? formData.mailAddressRoom : '',
      mailAddressZipCode: isMailDiff ? formData.mailAddressZipCode : '',
      mailAddressCity: isMailDiff ? formData.mailAddressCity : '',
      mailAddressState: isMailDiff ? formData.mailAddressState : '',
      mailAddressCountry: isMailDiff ? formData.mailAddressCountry : '',
    } as ClientInformationDto

    dispatch(updateCustomerInformation(newData))

    Promise.resolve(dispatch(upsertApplicationData(true)))
      .then((result: any) => {})
      .catch((err) => console.error(err))

    if (props.goNextStep) {
      props.goNextStep()
    }
  }

  return state ? (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.h3} variant='h3'>
          {t('Residential address (P.O. Box not allowed)')}
        </Typography>
      </Grid>
      <FormProvider {...hookFormFunc}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={6}>
                    <InputDynamicSelect
                      label={t('Area')}
                      inputName='residentialAddressArea'
                      defaultValue={state.residentialAddressArea}
                      items={hkArea}
                      required={state.isS800 !== 'Y'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      defaultValue={state.residentialAddressDistinct}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('District')}
                      name='residentialAddressDistinct'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    {errors.residentialAddressDistinct && (
                      <Typography color='error'>
                        {errors.residentialAddressDistinct &&
                          errors.residentialAddressDistinct.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      defaultValue={state.residentialAddressStreet}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Street')}
                      name='residentialAddressStreet'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    {errors.residentialAddressStreet && (
                      <Typography color='error'>
                        {errors.residentialAddressStreet &&
                          errors.residentialAddressStreet.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      defaultValue={state.residentialAddressStreetNumber}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('No.')}
                      name='residentialAddressStreetNumber'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    {errors.residentialAddressStreetNumber && (
                      <Typography color='error'>
                        {errors.residentialAddressStreetNumber &&
                          errors.residentialAddressStreetNumber.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      defaultValue={state.residentialAddressEstate}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Estate')}
                      name='residentialAddressEstate'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    {errors.residentialAddressEstate && (
                      <Typography color='error'>
                        {errors.residentialAddressEstate &&
                          errors.residentialAddressEstate.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      defaultValue={state.residentialAddressBuilding}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Building')}
                      name='residentialAddressBuilding'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    {errors.residentialAddressBuilding && (
                      <Typography color='error'>
                        {errors.residentialAddressBuilding &&
                          errors.residentialAddressBuilding.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      defaultValue={state.residentialAddressBlock}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Block')}
                      name='residentialAddressBlock'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    {errors.residentialAddressBlock && (
                      <Typography color='error'>
                        {errors.residentialAddressBlock &&
                          errors.residentialAddressBlock.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      defaultValue={state.residentialAddressFloor}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Floor')}
                      name='residentialAddressFloor'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    {errors.residentialAddressFloor && (
                      <Typography color='error'>
                        {errors.residentialAddressFloor &&
                          errors.residentialAddressFloor.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      defaultValue={state.residentialAddressRoom}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Room')}
                      name='residentialAddressRoom'
                      inputRef={register({
                        validate: (value: any) => {
                          return validatePostOfficeBox(value)
                        },
                      })}
                    />
                    <FormError errors={errors.residentialAddressRoom} />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      defaultValue={state.residentialAddressZipCode}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Zip Code')}
                      name='residentialAddressZipCode'
                      inputRef={register}
                    />
                    <FormError errors={errors.residentialAddressZipCode} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      defaultValue={state.residentialAddressCity}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('City')}
                      name='residentialAddressCity'
                      inputRef={register}
                    />
                    <FormError errors={errors.residentialAddressCity} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      defaultValue={state.residentialAddressState}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Province')}
                      name='residentialAddressState'
                      inputRef={register}
                    />
                    <FormError errors={errors.residentialAddressState} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      defaultValue={state.residentialAddressCountry}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Country')}
                      name='residentialAddressCountry'
                      inputRef={register}
                    />
                    <FormError errors={errors.residentialAddressCountry} />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Checkbox
              name='ifMailingAdd'
              inputProps={{
                'aria-label': 'disabled checked checkbox',
              }}
            /> */}
                    <InputDynamicCheckbox
                      inputName='isMailAddressDifferent'
                      label={t(
                        'Mailing Address (if different from Residential Address)',
                      )}
                      handleOnChange={(selected) => {
                        setIsMailDiff(selected)
                      }}
                      defaultChecked={state.isMailAddressDifferent === 'Y'}
                      value='Y'
                    />
                  </Grid>
                </Grid>
                {isMailDiff && (
                  <Grid container justifyContent='center' spacing={2}>
                    <Grid item xs={6}>
                      <InputDynamicSelect
                        disabled={!isMailDiff}
                        label={t('Area')}
                        inputName='mailAddressArea'
                        defaultValue={state.mailAddressArea}
                        items={hkArea}
                        required={state.isS800 !== 'Y'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        defaultValue={state.mailAddressDistinct}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('District')}
                        name='mailAddressDistinct'
                        inputRef={register}
                      />
                      {errors.mailAddressDistinct && (
                        <Typography color='error'>
                          {errors.mailAddressDistinct &&
                            errors.mailAddressDistinct.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        defaultValue={state.mailAddressStreet}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Street')}
                        name='mailAddressStreet'
                        inputRef={register}
                      />
                      {errors.mailAddressStreet && (
                        <Typography color='error'>
                          {errors.mailAddressStreet &&
                            errors.mailAddressStreet.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        defaultValue={state.mailAddressStreetNumber}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('No.')}
                        name='mailAddressStreetNumber'
                        inputRef={register}
                      />
                      {errors.mailAddressStreetNumber && (
                        <Typography color='error'>
                          {errors.mailAddressStreetNumber &&
                            errors.mailAddressStreetNumber.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        defaultValue={state.mailAddressEstate}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Estate')}
                        name='mailAddressEstate'
                        inputRef={register}
                      />
                      {errors.mailAddressEstate && (
                        <Typography color='error'>
                          {errors.mailAddressEstate &&
                            errors.mailAddressEstate.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        defaultValue={state.mailAddressBuilding}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Building')}
                        name='mailAddressBuilding'
                        inputRef={register}
                      />
                      {errors.mailAddressBuilding && (
                        <Typography color='error'>
                          {errors.mailAddressBuilding &&
                            errors.mailAddressBuilding.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        defaultValue={state.mailAddressBlock}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Block')}
                        name='mailAddressBlock'
                        inputRef={register}
                      />
                      {errors.mailAddressBlock && (
                        <Typography color='error'>
                          {errors.mailAddressBlock &&
                            errors.mailAddressBlock.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        defaultValue={state.mailAddressFloor}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Floor')}
                        name='mailAddressFloor'
                        inputRef={register}
                      />
                      {errors.mailAddressFloor && (
                        <Typography color='error'>
                          {errors.mailAddressFloor &&
                            errors.mailAddressFloor.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        defaultValue={state.mailAddressRoom}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Room')}
                        name='mailAddressRoom'
                        inputRef={register}
                      />
                      {errors.mailAddressRoom && (
                        <Typography color='error'>
                          {errors.mailAddressRoom &&
                            errors.mailAddressRoom.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        defaultValue={state.mailAddressZipCode}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Zip Code')}
                        name='mailAddressZipCode'
                        inputRef={register}
                      />
                      <FormError errors={errors.mailAddressZipCode} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        defaultValue={state.mailAddressCity}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('City')}
                        name='mailAddressCity'
                        inputRef={register}
                      />
                      <FormError errors={errors.mailAddressCity} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        defaultValue={state.mailAddressState}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Province')}
                        name='mailAddressState'
                        inputRef={register}
                      />
                      <FormError errors={errors.mailAddressState} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        defaultValue={state.mailAddressCountry}
                        disabled={!isMailDiff}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={t('Country')}
                        name='mailAddressCountry'
                        inputRef={register}
                      />
                      <FormError errors={errors.mailAddressCountry} />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>

          <Button variant='contained' color='primary' type='submit'>
            {t('Save & Next')}
          </Button>
        </form>
      </FormProvider>
    </Grid>
  ) : null
}

export default AddressForm
