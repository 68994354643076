import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import _debounce from 'lodash/debounce'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'

interface Props {
  title: string
  children?: React.ReactNode
}

const Agreement = ({ title, children }: Props) => {
  const classes = useStyles()
  // const hookFormFunc = useForm()
  // const { handleSubmit, register, errors, getValues } = hookFormFunc
  // const {
  //   register,
  //   handleSubmit,
  //   // formState: { dirty }
  // } = useFormContext()
  const [t] = useTranslation()

  const [isScrollBtm, setISScrollBtm] = useState(false)

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 2)
    if (bottom) {
      setISScrollBtm(true)
    }
  }

  return (
    <Grid container spacing={2}>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <Grid item xs={12}>
        <Typography variant='h3' style={{ margin: '20px 0 10px' }}>{title}</Typography>
      </Grid>
      <Grid item xs={12} onScroll={_debounce(handleScroll, 100)} className={classes.scrollCont}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Grid item xs={3}>
            <Button
              disabled={!isScrollBtm}
              variant='contained' color='primary' type='submit' fullWidth>
              {t('I Accept')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* </form> */}
    </Grid>
  )
}

export default Agreement
