export const validInvestmentDistribution = (value = '') => {
  return _validInvestmentDistribution(value) || 'Please input 0-100'
}

export const _validInvestmentDistribution = (value = '') => {
  if (!value) return true
  const re = /^[1-9][0-9]?$|^100$|^0$/g
  const result = re.test(value)
  //console.log('validInvestmentDistribution', value, result)
  return result
}

