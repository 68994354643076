import {
  CrsInformationDto,
  ClientInformationDto,
  ClientInformationDtoGenderEnum,
  ClientInformationDtoSmsServiceLanguageEnum,
  ApplicationDto,
  ClientInformationDtoAccountTypeEnum,
  AxaRegulatedActivitiesDto,
  AxaRegulatedActivitiesDtoPlanSelectionReasonsEnum,
  AxaRegulatedActivitiesDtoWitnessTypeEnum,
  AxaRegulatedActivitiesDtoIsAbovePrimaryEducationEnum,
  AxaRegulatedActivitiesDtoIsAbleToReadChineseOrEnglishEnum,
  AxaRegulatedActivitiesDtoIsNotDisabledEnum,
  ConsolidatedAccountDtoHasVoluntaryEnum,
  ConsolidatedAccountDtoTransferTypeEnum,
  ConsolidatedAccountDtoVoluntaryArrangeEnum,
  ConsolidatedAccountDtoWithdrawMethodEnum,
  ConsolidatedAccountDto,
  ConsolidatedAccountDtoSelfEmployedDetailsEnum,
  ConsolidatedAccountDtoIsSelfEmployedAccountEnum,
  ConsolidatedAccountDtoTransferOptionEnum,
  ConsolidatedAccountDtoTransferPartialOptionsEnum,
  CrsInformationDtoIsHkOnlyTaxResidentEnum,
  CrsInformationDtoReasonOfDifferentAddressEnum,
  CrsJurisdictionOfResidenceDto,
  CrsJurisdictionOfResidenceDtoReasonForNoTinEnum,
  ApplicationDtoLocaleEnum,
  ClientInformationDtoTitleEnum,
  ApplicationDtoDistributionChannelCodeEnum,
  FTLifeRegulatedActivitiesDto,
  ClientInformationDtoOccupationEnum,
  ClientInformationDtoEducationLevelEnum,
  ClientInformationDtoMaritalStateEnum,
  DependentDto,
  PirsRpqDto,
  PirsFnaDto,
  PirsSummaryDto,
  PirsInvestmentAdviceDto,
} from '../api/application/api'
import {
  REQUEST_APPLICATION_DATA,
  RECEIVE_APPLICATION_DATA,
  UPDATE_CLIENT_INFORMATION,
  LOAD_DUMMY_DATA,
  RESET_APPLICATION_DATA,
  UPDATE_APPLICATION_DATA_TO_STORE,
  ADD_APPLICATION_ERROR,
  UPDATE_AXA_REGULATED_ACTIVITIES,
  UPDATE_ACCOUNT_CONSOLIDATION,
  UPDATE_APPLICATION_SIGNATURES,
  UPDATE_CRS_INFORMATION,
  REQUEST_DRAFTS,
  RECEIVE_DRAFTS,
  REQUEST_SUBMISSIONS,
  RECEIVE_SUBMISSIONS,
  UPDATE_REGULATED_ACTIVITIES,
  AUTHENTICATE,
  UPDATE_PIRS_RPQ_INFORMATION,
  UPDATE_PIRS_SUMMARY,
  UPDATE_FNA,
  UPDATE_PIRS_INVESTMENT_ADVICE,
} from '../actions'
import { SESSION_STORAGE_LOCALE } from 'config'

const EMPTY_DATA: ApplicationDto = {
  locale: ApplicationDtoLocaleEnum.ZhHant,
  identityDocumentId: '',
  clientInformation: {} as ClientInformationDto,
  regulatedActivities: {} as any,
  axaRegulatedActivities: {} as AxaRegulatedActivitiesDto,
  investmentMandateMandatory: {},
  investmentMandateVolunteer: {},
  consolidatedAccounts: [],
  uploadDocuments: [],
  appendices: [],
  crsInformation: {
    jurisdictions: new Array<CrsJurisdictionOfResidenceDto>(),
  } as CrsInformationDto,
  distributionChannelCode: ApplicationDtoDistributionChannelCodeEnum.AXA,
  isRemoteSigning: false,
  remoteSigningStatus: {} as any,
  pirsRpq: {} as any,
  pirsFna: {} as any,
  pirsSummary: {} as any,
  pirsInvestmentAdvice: {} as PirsInvestmentAdviceDto,
  adobeSignAgreementId: '',
  channelExtraInfo: {} as any,
}

export interface ApplicationStore {
  isFetching: boolean
  isSubmitting: boolean
  isApiAuth: boolean
  errors: []
  data: ApplicationDto
}

export const INITIAL_STATE: ApplicationStore = {
  isFetching: false,
  isSubmitting: false,
  isApiAuth: false,
  errors: [],
  data: EMPTY_DATA,
}

export const DUMMY_DATA: ApplicationDto = {
  locale: ApplicationDtoLocaleEnum.ZhHant, // TODO: currently it is never updated after init
  identityDocumentId: '',
  regulatedActivities: {
    planSelectionReasons: [
      AxaRegulatedActivitiesDtoPlanSelectionReasonsEnum.BRAND,
      AxaRegulatedActivitiesDtoPlanSelectionReasonsEnum.EXPERIENCE,
    ],
    isAbovePrimaryEducation:
      AxaRegulatedActivitiesDtoIsAbovePrimaryEducationEnum.Y,
    isAbleToReadChineseOrEnglish:
      AxaRegulatedActivitiesDtoIsAbleToReadChineseOrEnglishEnum.Y,
    isNotDisabled: AxaRegulatedActivitiesDtoIsNotDisabledEnum.Y,
    witnessType: AxaRegulatedActivitiesDtoWitnessTypeEnum.MYCOMPANION,
    witnessName: 'Chan Tai Man',
  },
  axaRegulatedActivities: {
    planSelectionReasons: [
      AxaRegulatedActivitiesDtoPlanSelectionReasonsEnum.BRAND,
      AxaRegulatedActivitiesDtoPlanSelectionReasonsEnum.EXPERIENCE,
    ],
    isAbovePrimaryEducation:
      AxaRegulatedActivitiesDtoIsAbovePrimaryEducationEnum.Y,
    isAbleToReadChineseOrEnglish:
      AxaRegulatedActivitiesDtoIsAbleToReadChineseOrEnglishEnum.Y,
    isNotDisabled: AxaRegulatedActivitiesDtoIsNotDisabledEnum.Y,
    witnessType: AxaRegulatedActivitiesDtoWitnessTypeEnum.MYCOMPANION,
    witnessName: 'Chan Tai Man',
  },
  consolidatedAccounts: [
    {
      accountNo: 'A123456',
      employerID: 'E123456',
      employerName: 'Principal',
      hasVoluntary: ConsolidatedAccountDtoHasVoluntaryEnum.Y,
      trustee: '19700',
      scheme: '19700-4',
      bankAccountHolder: 'Chan Tai Man',
      bankCode: '004',
      branchCode: '001',
      bankName: 'HSBC',
      bankAccountNumber: '123456',
      transferType: ConsolidatedAccountDtoTransferTypeEnum.PA,
      voluntaryArrange: ConsolidatedAccountDtoVoluntaryArrangeEnum.T,
      withdrawMethod: ConsolidatedAccountDtoWithdrawMethodEnum.B,
      selfEmployedDetails: ConsolidatedAccountDtoSelfEmployedDetailsEnum.T,
      isSelfEmployedAccount: ConsolidatedAccountDtoIsSelfEmployedAccountEnum.Y,
      payUntil: '2019-10-31',
      terminationDate: '2019-10-20',
      transferOption: ConsolidatedAccountDtoTransferOptionEnum.A,
      transferPartialOptions: [
        ConsolidatedAccountDtoTransferPartialOptionsEnum.M,
      ],
    },
  ],
  investmentMandateMandatory: { DIS: 100 },
  investmentMandateVolunteer: { DIS: 100 },
  uploadDocuments: [],
  appendices: [],
  clientInformation: {
    isS800: 'Y',
    firstName: 'Tai Man',
    lastName: 'Chan',
    title: ClientInformationDtoTitleEnum.Mr,
    accountType: ClientInformationDtoAccountTypeEnum.CA,
    memberNumber: 'A1234',
    contractNumber: 'B5678',
    chineseFirstName: '陳',
    chineseLastName: '大文',
    identificationNumber: 'A1234563',
    identificationType: 'hkid',
    nationality: '香港特別行政區',
    gender: ClientInformationDtoGenderEnum.M,
    email: 'none@example.com',
    dateOfBirth: '1980-11-16',
    mobileNumber: '66666666',
    daytimeContactNumber: '22222222',
    contact3Number: '33333333',
    contact4Number: '44444444',
    smsServiceLanguage: ClientInformationDtoSmsServiceLanguageEnum.En,
    subscribeEcommunicationService: 'Y',
    subscribeSmsService: 'Y',
    mobileCountryCode: '852',
    daytimeContactCountryCode: '852',
    contact3CountryCode: '852',
    contact4CountryCode: '852',
    natureOfBusiness: 88,
    otherNatureOfBusiness: 'NO WORK',
    occupation: ClientInformationDtoOccupationEnum.ENGINEERS,
    jobTitle: 'IT Engineer',
    employerName: 'HSBC',
    businessAddress: 'HK ISLAND',
    maritalState: ClientInformationDtoMaritalStateEnum.SINGLE,
    educationLevel: ClientInformationDtoEducationLevelEnum.DEGREEORABOVE,
    dependents: [
      { name: 'D1', age: 30, relationship: 'R1' } as DependentDto,
      { name: 'D2', age: 40, relationship: 'R2' } as DependentDto,
      { name: 'D3', age: 60, relationship: 'R3' } as DependentDto,
    ],
  } as ClientInformationDto,
  crsInformation: {
    isHkOnlyTaxResident: CrsInformationDtoIsHkOnlyTaxResidentEnum.N,
    jurisdictions: [
      {
        jurisdictionOfResidence: 'USA',
        tin: '123456',
        reasonForNoTin: CrsJurisdictionOfResidenceDtoReasonForNoTinEnum.B,
        explanationForUnableToObtainTin: 'TESTING',
      } as CrsJurisdictionOfResidenceDto,
    ],
    reasonOfDifferentAddress:
      CrsInformationDtoReasonOfDifferentAddressEnum.STUDENT,
  } as CrsInformationDto,
  distributionChannelCode: ApplicationDtoDistributionChannelCodeEnum.AXA,
  isRemoteSigning: false,
  remoteSigningStatus: {} as any,
  pirsRpq: {} as any,
  pirsFna: {} as any,
  pirsSummary: {} as any,
  pirsInvestmentAdvice: {} as PirsInvestmentAdviceDto,
  adobeSignAgreementId: '',
  channelExtraInfo: {
    channel: 'bank',
    code: 'xxx',
  },
}

const ApplicationData = (
  state: ApplicationStore = INITIAL_STATE,
  action: any,
) => {
  //assign locale from sessionStorage
  // state = {
  //   ...state,
  //   data: {
  //     ...state.data,
  //     locale: sessionStorage.getItem(
  //       SESSION_STORAGE_LOCALE,
  //     ) as ApplicationDtoLocaleEnum,
  //   },
  // }

  // alert(1)
  switch (action.type) {
    case AUTHENTICATE:
      return { ...state, isApiAuth: true }
    case REQUEST_SUBMISSIONS:
      return { ...state, isFetching: true }
    case RECEIVE_SUBMISSIONS:
      return { ...state, isFetching: false }
    case REQUEST_DRAFTS:
      return { ...state, isFetching: true }
    case RECEIVE_DRAFTS:
      return { ...state, isFetching: false }
    case REQUEST_APPLICATION_DATA:
      return { ...state, isFetching: true }
    case LOAD_DUMMY_DATA:
      return { ...state, data: DUMMY_DATA }
    case ADD_APPLICATION_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.errorMessage],
      }
    case RESET_APPLICATION_DATA:
      return { ...state, data: { ...EMPTY_DATA, ...action.initData } }
    case RECEIVE_APPLICATION_DATA:
      return {
        ...state,
        data: { ...EMPTY_DATA, ...action.data },
        isFetching: false,
      }
    case UPDATE_CLIENT_INFORMATION:
      return updateClientInformation(state, action.data)
    case UPDATE_PIRS_RPQ_INFORMATION:
      return updatePirsRpq(state, action.data)
    case UPDATE_PIRS_SUMMARY:
      return updatePirsSummary(state, action.data)
    case UPDATE_CRS_INFORMATION:
      return updateCrsInformation(state, action.data)
    case UPDATE_ACCOUNT_CONSOLIDATION:
      return updateAccountConsolidation(state, action.data)
    case UPDATE_APPLICATION_SIGNATURES:
      return updateApplicationSignatures(state, action.data)
    case UPDATE_AXA_REGULATED_ACTIVITIES:
      return updateAxaRegulatedActivities(state, action.data)
    case UPDATE_REGULATED_ACTIVITIES:
      return updateRegulatedActivities(state, action.data)
    case UPDATE_FNA:
      return updateFna(state, action.data)
    case UPDATE_APPLICATION_DATA_TO_STORE:
      return updateApplicationDataToStore(state, action.data)
    case UPDATE_PIRS_INVESTMENT_ADVICE:
      return updatePirsInvestmentAdvice(state, action.data)
    default:
      return state
  }
}

const updateCrsInformation = (
  state: ApplicationStore,
  crsData: CrsInformationDto,
) => {
  return {
    ...state,
    data: {
      ...state.data,
      crsInformation: {
        ...state.data.crsInformation,
        ...crsData,
      },
    },
  } as ApplicationStore
}

const updateClientInformation = (
  state: ApplicationStore,
  clientData: ClientInformationDto,
) => {
  return {
    ...state,
    data: {
      ...state.data,
      clientInformation: {
        ...state.data.clientInformation,
        ...clientData,
      },
    },
    isFetching: false,
  } as ApplicationStore
}

const updatePirsRpq = (state: ApplicationStore, rpqData: PirsRpqDto) => {
  return {
    ...state,
    data: {
      ...state.data,
      pirsRpq: {
        ...state.data.pirsRpq,
        ...rpqData,
      },
    },
  } as ApplicationStore
}

const updatePirsSummary = (
  state: ApplicationStore,
  summaryData: PirsSummaryDto,
) => {
  return {
    ...state,
    data: {
      ...state.data,
      pirsSummary: {
        ...state.data.pirsSummary,
        ...summaryData,
      },
    },
  } as ApplicationStore
}

const updateAccountConsolidation = (
  state: ApplicationStore,
  consolidatedAccountArray: Array<ConsolidatedAccountDto>,
) => {
  return {
    ...state,
    data: {
      ...state.data,
      consolidatedAccounts: [...consolidatedAccountArray],
    },
  } as ApplicationStore
}

const updateApplicationSignatures = (state: ApplicationStore, data: any) => {
  return {
    ...state,
    data: {
      ...state.data,
      agentSignatureBase64: data.agentSignatureBase64,
      clientSignatureBase64: data.clientSignatureBase64,
      witnessSignatureBase64: data.witnessSignatureBase64,
    },
  } as ApplicationStore
}

const updateAxaRegulatedActivities = (
  state: any,
  axaRegulatedActivitiesData: AxaRegulatedActivitiesDto,
) => {
  return {
    ...state,
    data: {
      ...state.data,
      witnessSignatureBase64:
        axaRegulatedActivitiesData.witnessType ===
        AxaRegulatedActivitiesDtoWitnessTypeEnum.NO
          ? ''
          : state.data.witnessSignatureBase64,
      axaRegulatedActivities: {
        ...state.data.axaRegulatedActivities,
        ...axaRegulatedActivitiesData,
      },
    },
  }
}

const updateRegulatedActivities = (
  state: any,
  regulatedActivitiesData:
    | AxaRegulatedActivitiesDto
    | FTLifeRegulatedActivitiesDto,
) => {
  return {
    ...state,
    data: {
      ...state.data,
      regulatedActivities: {
        ...state.data.regulatedActivities,
        ...regulatedActivitiesData,
      },
    },
  }
}

const updateFna = (state: any, fnaData: PirsFnaDto) => {
  return {
    ...state,
    data: {
      ...state.data,
      pirsFna: {
        ...state.data.pirsFna,
        ...fnaData,
      },
    },
  } as ApplicationStore
}

const updateApplicationDataToStore = (
  state: any,
  applicationData: ApplicationDto,
) => {
  state.data = {
    ...state.data,
    ...applicationData,
  }
  return state
  // return {
  //   ...state,
  //   data: {
  //     ...state.data,
  //     ...applicationData,
  //   },
  // }
}

const updatePirsInvestmentAdvice = (state: any, data: PirsInvestmentAdviceDto) => {
  return {
    ...state,
    data: {
      ...state.data,
      pirsInvestmentAdvice: {
        ...state.data.pirsInvestmentAdvice,
        ...data,
      },
    },
  } as ApplicationStore
}

export default ApplicationData
