import {
  Button,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { Transition } from 'client/components/common/transition'
import IconAdd from '@material-ui/icons/Add'
import IconDelete from '@material-ui/icons/Delete'
import IconEdit from '@material-ui/icons/Edit'
import {
  updateAccountConsolidation,
  upsertApplicationData,
} from 'client/actions'
import {
  ClientInformationDto,
  ConsolidatedAccountDto,
} from 'client/api/application/api'
import ConfirmDialog from 'client/components/confirmDialog'
import ModalForAccount from 'client/components/modalAccount'
import { RootStore } from 'client/reducers'
import { schemeMap, trusteeMap } from 'config'
import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import useStyles from './style'

// function getModalStyle() {
//   const top = 50
//   const left = 50

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   }
// }

const FormAccountConsolidation = ({ activeStep, goNextStep }: any) => {
  const classes = useStyles()

  // const [modalStyle] = useState(getModalStyle)
  const [open, setOpen] = useState(false)
  const [t] = useTranslation()
  const [editData, setEditData] = useState(null)
  const [editIdx, setEditIdx] = useState(-1)
  // const [deleteRecord, setDeleteRecord] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [curDelIdx, setCurDelIdx] = useState(-1)
  const [openFinalValDialog, setOpenFinalValDialog] = useState(false)

  // TODO: refactor states for modal to single state
  // const [modalProps, setModalProps] = useState({
  // })

  const handleOpenDeleteDialog = (idx: number) => {
    setCurDelIdx(idx)
    setOpenDeleteDialog(true)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleCloseDeleteDialog = (val: any) => {
    if (val === 'Y') {
      dispatchToForm({ action: 'delete', data: curDelIdx })
    }

    setOpenDeleteDialog(false)
  }

  const dispatch = useDispatch()
  // const history = useHistory()

  const customValidate = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      return true
    }
    // open dialog and block submission
    setOpenFinalValDialog(true)
    return false
  }

  const handleSubmit = () => {
    console.log('formData', formData)

    const isFormValidate = customValidate(formData)
    if (isFormValidate === true) {
      dispatch(updateAccountConsolidation(formData))

      Promise.resolve(dispatch(upsertApplicationData(true)) as any)
        .then((result: any) => {})
        .catch((error: any) => {
          console.error(error)
        })

      if (goNextStep) {
        goNextStep()
      }
    }
  }

  const clientInformation: ClientInformationDto = useSelector(
    (state: RootStore) => {
      return state.application.data.clientInformation
    },
  )

  const consolidatedAccounts: Array<ConsolidatedAccountDto> = useSelector(
    (state: RootStore) => {
      return state.application.data.consolidatedAccounts
    },
  )

  const [formData, dispatchToForm] = useReducer(
    (formData: any, { action, data, editIdx = -1 }: any) => {
      switch (action) {
        case 'init': {
          console.log('init', data)
          if (data && data.transferPartialOptions) {
            //filter out unused boolean from DOM form values e.g.  [false, false, "AM", "AV"]
            data.transferPartialOptions = data.transferPartialOptions.filter(
              (v: any) => v !== false,
            )
          }
          return [...data]
        }
        case 'add': {
          if (data && data.transferPartialOptions) {
            //filter out unused boolean from DOM form values e.g.  [false, false, "AM", "AV"]
            data.transferPartialOptions = data.transferPartialOptions.filter(
              (v: any) => v !== false,
            )
          }
          return [...formData, data]
        }
        case 'delete': {
          return formData.filter((_: any, idx: number) => idx !== data)
        }
        case 'edit': {
          if (data && data.transferPartialOptions) {
            //filter out unused boolean from DOM form values e.g.  [false, false, "AM", "AV"]
            data.transferPartialOptions = data.transferPartialOptions.filter(
              (v: any) => v !== false,
            )
          }
          formData[editIdx] = data
          return formData
        }
        default:
          return formData
      }
    },
    [],
  )

  useEffect(() => {
    console.log('consolidatedAccounts', consolidatedAccounts)
    dispatchToForm({
      action: 'init',
      data: consolidatedAccounts,
    })
  }, [consolidatedAccounts])

  const handleClose = (type = 'cancel', data?: any, editIdx?: number) => {
    // console.log('in parent... ', formData, data)

    // don't do anything for cancel button
    // if (data && data.transferType) {
    // console.log('in handleclose... ', type, data, editIdx)
    if (type === 'add') {
      // console.log('in parent...', data)
      dispatchToForm({ action: 'add', data })
      // if (!formData || formData.length <= 0) {
      //   // const cur = formData
      //   // const accounts = cur.push(data)

      //   // setFormData([data])
      //   setFormData({ type: 'add', value: data })

      //   // console.log('local state from data... ', formData)
      // } else {
      //   if (formData && Array.isArray(formData)) {
      //     console.log('formdata ok --- ', data, formData)
      //     // setFormData([...formData, data])
      //     setFormData({ type: 'add', value: data })
      //   } else {
      //     console.log('formdata broken --- ', data, formData)
      //   }
      // }
    } else if (type === 'edit') {
      dispatchToForm({ action: 'edit', data, editIdx })
      setEditData(null)
      setEditIdx(-1)
    }
    // console.log('local state from data... ', formData)
    setOpen(false)
  }

  return (
    <Grid container spacing={1}>
      <Typography className={classes.h3} variant='h3'>
        {t('Account(s) to be Consolidated')}
      </Typography>
      <Grid item xs={12} md={12}>
        <Paper>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setEditData(null)
              setEditIdx(-1)
              handleOpen()
            }}
          >
            <IconAdd />
            {t('Add')}
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Trustee')}</TableCell>
                <TableCell>{t('Original Plan')}</TableCell>
                <TableCell>{t('Transfer type')}</TableCell>
                <TableCell>{t('Account No.')}</TableCell>
                <TableCell align='right'>{t('Action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.map((row, idx) => (
                <Fragment key={`account_${idx}`}>
                  <TableRow >
                    <TableCell colSpan={3}><Typography variant="h3">Transfer Account {idx+1}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" align="right">
                      {row.companyName}
                    </TableCell>
                    <TableCell align="right">{row.plan}</TableCell>
                    <TableCell rowSpan={2} align="right">
                      <Fab size="small" className={classes.fab} onClick={handleOpen}>
                        <IconEdit></IconEdit>
                      </Fab>
                      <Fab size="small" className={classes.fab}>
                        <IconDelete></IconDelete>
                      </Fab>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{row.tel}</TableCell>
                  </TableRow>
                </Fragment>
              ))} */}
              {Array.isArray(formData) &&
                formData.map((row, idx: number) => (
                  <Fragment key={`account_${idx}`}>
                    <TableRow>
                      <TableCell align='left'>
                        {t(trusteeMap[row.trustee])}
                      </TableCell>
                      <TableCell align='left'>
                        {t(schemeMap[row.scheme])}
                      </TableCell>
                      <TableCell align='left'>{t(row.transferType)}</TableCell>
                      {/* <TableCell align="left">{row.isSelfEmployedAccount}</TableCell> */}
                      <TableCell align='left'>{row.accountNo}</TableCell>
                      <TableCell align='right'>
                        <Fab
                          size='small'
                          className={classes.fab}
                          onClick={() => {
                            // dispatchToForm({ 'action': 'edit', data: idx })
                            setEditData(row)
                            setEditIdx(idx)
                            handleOpen()
                          }}
                        >
                          <IconEdit></IconEdit>
                        </Fab>
                        <Fab
                          size='small'
                          className={classes.fab}
                          onClick={() => handleOpenDeleteDialog(idx)}
                        >
                          <IconDelete></IconDelete>
                        </Fab>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </Paper>

        <ModalForAccount
          open={open}
          onClose={handleClose}
          data={editData}
          editIdx={editIdx}
          isS800={clientInformation.isS800}
          accountType={clientInformation.accountType}
        />

        <ConfirmDialog
          open={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          title={t('Do you want to remove the record?')}
          content={t('This action cannot be undone.')}
        />
      </Grid>

      <Button
        variant='contained'
        type='button'
        color='primary'
        onClick={handleSubmit}
      >
        {t('Save & Next')}
      </Button>

      <Dialog
        open={openFinalValDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {t('formAC-1')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFinalValDialog(false)} color='primary'>
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default FormAccountConsolidation
