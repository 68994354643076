import { TableRow, Checkbox } from '@material-ui/core'
import { ApplicationResponseDtoDistributionChannelCodeEnum } from 'client/api/application/api'
import React from 'react'
import { FtLifeLogPageHeader } from './ftLife/logPageHeader'
import { FtLifeQueryPageHeader } from './ftLife/queryPageHeader'
import { GeneralLogPageHeader } from './general/logPageHeader'
import { GeneralQueryPageHeader } from './general/queryPageHeader'
import { MrLogPageHeader } from './mr/logPageHeader'
import { MrQueryPageHeader } from './mr/queryPageHeader'
import { StyledTableCell } from './style'
export interface PpoAdminTableHeadRowProps {
  tabIndex: number
  isAllChecked: boolean
  handleCheckAllClicked: () => void
  x_channel_code: string
}

export function PpoAdminTableHeadRow(props: PpoAdminTableHeadRowProps) {
  const { x_channel_code, tabIndex } = props

  const QueryHeader = () => {
    switch (x_channel_code) {
      case ApplicationResponseDtoDistributionChannelCodeEnum.FTLIFE:
        return <FtLifeQueryPageHeader {...props} />
      case ApplicationResponseDtoDistributionChannelCodeEnum.MR:
      case ApplicationResponseDtoDistributionChannelCodeEnum.BANK:
        return <MrQueryPageHeader {...props} />
      default:
        return <GeneralQueryPageHeader {...props} />
    }
  }

  const LogHeader = () => {
    switch (x_channel_code) {
      case ApplicationResponseDtoDistributionChannelCodeEnum.FTLIFE:
        return <FtLifeLogPageHeader {...props} />
      case ApplicationResponseDtoDistributionChannelCodeEnum.MR:
        return <MrLogPageHeader {...props} />
      default:
        return <GeneralLogPageHeader {...props} />
    }
  }

  switch (tabIndex) {
    case 0:
      return (
        <TableRow>
          <QueryHeader />
        </TableRow>
      )

    case 1:
    default:
      return (
        <TableRow>
          <LogHeader />
        </TableRow>
      )
  }
}
