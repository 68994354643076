const t = {
  'pirs-cc1': 'PIRS - 個人資料收集聲明',
  'pirs-cc2': '投資及退休顧問委任書',
  'pirs-cc3': '客戶確認書',
  'pirs-cc4': '財務需要分析',
  'pirs-cc4a':
    '本人明白上述，但本人選擇不填寫"財務需要分析"來判斷我對風險的態度及本人明白註冊中介人未能就某一特定成分基金向本人提供任何受規管意見。',
  'pirs-cc4b': '本人明白上述，並在作出決定前填寫"財務需要分析"。',
  'pirs-cc5': '給予客戶建議的理據 : ',
  'pirs-cc5a': '不適用。在銷售過程中，本人並沒有獲得任何建議。',
  'pirs-cc5b': '本人明白在此銷售過程中收到的建議是基於以下理據 : ',
  'pirs-cc5b1': '計劃及其基金選擇',
  'pirs-cc5b2': '基金管理費用',
  'pirs-cc5b3': '綜合服務',
  'pirs-cc5b4': '其他',
  'pirs-cc6': '銷售過程中涉及到申述計劃/成分基金較本人所參加之計劃/基金為佳。',
  'pirs-cc6a':
    '是，註冊中介人已向本人解釋計劃/成分基金和本人所參加之計劃/成分基金之間的差異，及作出轉移所帶來的利益或潛在利益。',
  'pirs-cc6b':
    '否，銷售過程中並不涉及到計劃/成分基金及本人所參加之計劃/基金作出比較。',
  'pirs-cc7':
    '本人確認本人完全明白註冊中介人所提供及與其所討論的資料，並能作出該關鍵決定 (關鍵決定指選擇某一特定成分基金；作出涉及轉出保證基金之轉移；於強積金制度內提早提取累算權益；或作出自願性供款於某一特定註冊計劃或某一特定成分基金。)。',
  'pirs-cc7a': '是',
  'pirs-cc7b': '否',
  'pirs-cc8': '教育程度',
  'pirs-cc8a': '小學或以下',
  'pirs-cc8b': '中學',
  'pirs-cc8c': '大專或以上',
  'pirs-cc9': '本人視力或其他方面屬有障礙人士。',
  'pirs-cc9a': '是',
  'pirs-cc9b': '否',
  'pirs-cc10': '銷售過程',
  'pirs-cc10a':
    '(A) 本人希望由一位朋友陪同並見證有關銷售及選擇成分基金的過程。',
  'pirs-cc10b': '(B) 本人希望安排多一名員工見證有關銷售及選擇成分基金的過程。',
  'pirs-cc10c':
    '(C) 本人希望由一位朋友及一名員工陪同並見證有關銷售及選擇成分基金的過程',
  'pirs-cc10d':
    '(D) 本人希望行使自己的決定及明白將會有被主事中介人授權的人士為本人進行售後電話確認。',
  'pirs-cc11': '保證基金轉出之確認',
  'pirs-cc12': '客戶協議書',
  'Client Confirmations': '客戶確認書',
  'PIRS – PERSONAL INFORMATION COLLECTION STATEMENT': 'PIRS – 個人資料收集聲明',
  'Important Notes': '重要事項',
  'pirs-rpq1': '風險承擔能力調查問卷 – 重要事項',
  'pirs-rpq13': '重要事項及免責聲明',
  'Important notice and disclaimer': '重要事項及免責聲明',
  'Total Score': '總分數',
  'Investment Style': '投資風格',
  'Confirmation on Transfer Out of Guaranteed Fund':
    '保證基金轉出之確認',
  'Client Agreement': '客戶協議書',
  'pirs-sum1': '確認客人收訖產品說明文件及有關文件 (強積金)',
  'pirs-sum2': '強積金計劃最新版本的銷售文件(包括強積金計劃說明書，主要計劃資料文件及補編)',
  'pirs-sum3': '個人資料收集聲明[客戶存根]',
  'pirs-sum4': '披露銷售相關資料',
  'pirs-rpq-q1': '您的年齡介乎於以下哪個組別?',
  'pirs-rpq-q2': '您對金融市場投資的認識有多少?',
  'pirs-rpq-q3': '您的每月個人或家庭收入?',
  'pirs-rpq-q4': '您的每月個人或家庭盈餘?',
  'pirs-rpq-q5':
    '時個人資產淨值（港元）(包括存款、股票、債券、基金、衍生工具及其他投資，並不包括自住物業之價值)',
  'pirs-rpq-q6':
    '您會用淨流動資產總值的多少百分比來購買投資產品?(流動資產指可於短期內容易轉換為現金的資產)',
  'pirs-rpq-q7': '您可接受投資本金出現什麼程度的潛在虧損?',
  'pirs-rpq-q8': '若您打算投資，您認為下列那一類別的投資工具最合適您?',
  'pirs-rpq-q9': '您預期投資年期是多久?',
  'pirs-rpq-q10': '以下那一項最能描述您的投資目標?',
  'pirs-rpq-q11': '作為一項長線投資，您對於本金在首數年時錄得損失有何感受?',
  'pirs-rpq-q12': '以下那一項最能描述您對衍生金融商品認知?',
  'pirs-rpq-a2a': '並無認識，我對金融市場及投資一無所知。',
  'pirs-rpq-a2b':
    '低水平：我對金融市場只有基本知識，例如股票和債券及分散投資的重要性。',
  'pirs-rpq-a2c':
    '中水平：我對金融市場有一些知識，並會閱讀及/或聆聽有關投資或金融的新聞。',
  'pirs-rpq-a2d':
    '高水平：我對一些金融產品的特性有認識，並會從不同來源檢閱投資相關的資料。',
  'pirs-rpq-a2e':
    '精通：我熟悉大部份金融產品，並明白影響這些金融產品的風險和表現的各項因素。',
  'pirs-rpq-a8a': '港元、人民幣或美元存款',
  'pirs-rpq-a8b': '外幣存款或貴金屬',
  'pirs-rpq-a8c': '債券或保本基金',
  'pirs-rpq-a8d': '股票或開放式基金',
  'pirs-rpq-a8e': '結構性產品或衍生工具',
  'pirs-rpq-a9a': '< 2 年',
  'pirs-rpq-a9b': '2 - 5 年',
  'pirs-rpq-a9c': '6 - 10 年',
  'pirs-rpq-a9d': '11 - 15 年',
  'pirs-rpq-a9e': '> 15 年',
  'pirs-rpq-a10a': '保本',
  'pirs-rpq-a10b': '穩定的定期收入',
  'pirs-rpq-a10c': '在定期收入及資本增值中取得平衡 ',
  'pirs-rpq-a10d': '穩定的資本增值',
  'pirs-rpq-a10e': '承擔最高風險以使資本增值最大化',
  'pirs-rpq-a11a': '我不願承受任何風險，並會感到失望',
  'pirs-rpq-a11b': '為了減少投資波幅，我願降低對投資的預期回報',
  'pirs-rpq-a11c': '為了有機會換取到比較好的投資回報率，我願承受中程度的風險',
  'pirs-rpq-a11d': '這是一項長線投資，只有較大的波動或損失才會令我擔心',
  'pirs-rpq-a11e':
    '這是一項長線投資，我願意承受較大的風險及波幅，以換取較大預期回報',
  'pirs-rpq-a12a': '無',
  'pirs-rpq-a12b': '< 1 年',
  'pirs-rpq-a12c': '1 - < 3 年',
  'pirs-rpq-a12d': '3 - < 5 年',
  'pirs-rpq-a12e': '≥ 5 年',
  'pirs-rpq-r1': '保守型',
  'pirs-rpq-r2': '適度保守型',
  'pirs-rpq-r3': '均衡型',
  'pirs-rpq-r4': '適度進取型',
  'pirs-rpq-r5': '進取型',
  'pirs-rpq-r1desc':
    '你明白及願意接受極小的損失，以換取極小的潛在投資回報。您偏向投資價值下降的風險較低的投資，您對保存投資價值的興趣大於獲取您的資本回報。',
  'pirs-rpq-r2desc':
    '你明白及願意接受低程度的損失，以換取低的潛在投資回報。您準備忍受一些投資上的波動及波幅。',
  'pirs-rpq-r3desc':
    '你明白及願意接受中程度的損失，以換取中程度的潛在投資回報。您希望取得潛在的增長，亦希望維持現有的資產水準。您準備將部分資產分佈於進取類別的投資以讓您的回報高於通脹及獲取長線的潛在回報。',
  'pirs-rpq-r4desc':
    '你明白及願意接受高程度的損失，以換取高程度的潛在投資回報。一個平穩中偏進取的投資策略最適合您的投資取向。雖然這個投資組合可能帶來較大的波幅，但亦會為您帶來較大的長線潛在增值回報，而短暫的大起大跌不會打擊您的信心。',
  'pirs-rpq-r5desc':
    '你明白及願意接受重大程度的損失，以換取重大的潛在投資回報。您明白投資價值將來在某些時期內或會有強勁的收益，或大幅下跌的虧損。',
  'summary-dialog1':
    '本人確認我申請之產品之整個銷售過程均在申請日 期當日或之前在香港進行。本人亦確認我沒有在香港以外的地區被任何人接觸以進行任何有關我申請之產品的 銷售。',
  'soft copy': '電子版本',
  'hard copy': '印刷版本',
  'Risk Profile Questionnaire': '風險承擔能力調查問卷',
  'witness error': '本系統暫不支援需見證人的申請。如有需要，請使用紙本申請。',
  witness: '見證人',

  'Assets': '資產',
  'In Curreny': '可用貨幣',
  'Savings (including cash, deposits)': '儲蓄（包括現金、存款)',
  'Life insurance coverage': '人壽保險保障額',
  'Stock/Securities/Mutual Funds/Bonds': '股票/證券/共同基金/債券',
  'Other assets/Company benefits (including ORSO, MPF)':
    '其他資產/公司褔利(包括職業退休計劃、強積金)',
  'Total Usable Assets': '總可用資產',
  Expense: '開支',
  'Monthly living expenses': '每月生活開支',
  'Monthly family expenses (such as rental, utilities, education)':
    '每月家庭開支(例如租金、水電及煤氣、教育)',
  'Monthly mortgage redemption': '按揭還款',
  'Monthly dependent cost': '每月受養人開支',
  'Other monthly expenses (such as tax liabilities)': '其他開支(例如稅務)',
  'Total monthly expenses': '每月總開支',
  'Annual Expenses': '每年總開支',
  Income: '收入',
  'Monthly salary': '月薪',
  'Monthly bonus': '獎金',
  'Other monthly income (such as rental, dividend, etc)':
    '其他收入(例如租金、股息等)',
  'Total monthly income': '每月總收入 ',
  'Annual income': '每年總收入',
  Liabilities: '負債',
  'Outstanding mortgage': '未償還按揭貸款',
  'Outstanding debt/loans': '未償還債務/貸款',
  'Other liabilities (such as tax liabilities': '其他負債(例如稅務)',
  'Total Protection Needs': '總保障需要 ',
  'Existing Wealth Management Plan': '現有理財計劃',
  'Product Provider': '計劃提供商',
  'Plan Type': '計劃類別/名稱',
  'Plan Value': '計劃現值',
  'Effective Date': '生效日期',
  'Contribution Period': '供款年期',
  Contribution: '供款額',
  'Product Provider (e.g. Insurance Company, Bank)':
    '計劃提供商 (如保險公司、銀行等)',
  'Plan Type / Name (e.g. ILAS, Mutual Funds, education funds, etc.)':
    '計劃類別/名稱(如投資相連壽險、互惠基金、教育基金等)',
  'Plan Value (e.g. HK$2,000,000)': '計劃現值 (e.g. HK$2,000,000)',
  'Contribution Paying Period (e.g. Single Contribution / 20 monthly Years)':
    '供款年期 (如整付投資 /20 年)',
  'Contribution (e.g. HK$5,000 per month)': '供款額 (如每月HK$5,000)',
  'Financial Goal': '財務目標分析',
  'pirs-fna-source-of-fund-salary': '工資',
  'pirs-fna-source-of-fund-other-investment': '其他投資的收益',
  'pirs-fna-source-of-fund-income': '收入',
  'pirs-fna-source-of-fund-accumulative-saving': '累積儲蓄及投資',
  'pirs-fna-source-of-fund-saving': '儲蓄',
  'pirs-fna-source-of-fund-others': '其他',
  'pirs-fna-goal-saving': '儲蓄',
  'pirs-fna-goal-investment': '投資',
  'pirs-fna-goal-education': '教育',
  'pirs-fna-goal-retirement': '退休',
  'pirs-fna-goal-others': '其他',
  'pirs-fna-goal-01':
    '財富及資金來源 (可選多於一項)',
  'pirs-fna-goal-02': ' 您的財務目標為何? (可選多於一項)',
  MONTHLY_20_YAERS: '20年 (每月)',
  SINGLE_PAYMENT: '一次性付款',
  EDUCATION_FUNDS: '教育基金',
  MUTUAL_FUNDS: '共同基金',
  'Risk Disclosure Statement': '風險披露聲明',
  'Declaration of the PIRS Investment Consultant': 'PIRS投資顧問的聲明',
  'Declaration and Acknowledgment of the Applicant': '申請人的聲明',
  'declaration statement': '風險披露聲明 (「聲明」)',
  'pirs-inv-1': '你是 PIRS 現有客戶',
  'pirs-inv-2': '若你是現有客戶，請提供最近的財務需要分析表格的提交日期。',
  'Employed': '受僱',
  'Self-employed': '自僱',
  'Unemployed': '待業',
  'Employment Status': '就業狀況',
  'pirs-inv-3': '建議相關的重要提示 (由持牌顧問填寫)',
  'pirs-inv-4': '假設的存款年利率',
  'pirs-inv-5': '假設的每年平均通脹率',
  'pirs-inv-6': '建議理由',
  'pirs-inv-7': '重要疑問及回應紀錄',
  'pirs-inv-8': '客戶提出的重要疑問',
  'pirs-inv-9': '投資顧問的回應',
  'Clients Declaration': '客戶聲明',
  'Investment Consultants Declaration': '投資顧問聲明',
  'Holding Name': '持貨名稱',
  'Code': '編號',
  'PIRS Risk Level': '信安投資及退休金服務有限公司風險評級',
  'Amount': '款額',
  'Weight': '比重',
  'Recommended Actions': '建議行動',
  'Holdings': '持貨',
  'Evaluation': '評估',
  'Existing Holdings': '現有持貨',
  'Portfolio Weighted Average Risk Level': '組合平均風險評級',
  'buy': '買入',
  'hold': '持有',
  'sell': '沽出',
  'Do you want to perform FNA': 'Do you want to perform FNA?',
  'Do you need investment advice': '你是否需要投資建議?',
  'Conservative': '保守型',
  'Moderate Conservative': '適度保守型',
  'Moderate': '均衡型',
  'Moderate Aggressive': '適度進取型',
  'Aggressive': '進取型',
  'pirs-fna-p1': '任何類型的自願性供款、互惠基金或建議服務需填寫《財務需求分析表》。',
  'pirs-fna-deviate-desc': '如您的選擇在任何方面偏離上述「財務需要分析」或「風險承擔能力調查問卷」，您必須書面詳述有關原因：',
  'pirs-rpq-p1': '如果您需要建議及／或考慮參與任何類型的自願性供款和互惠基金服務，請填寫全套的《財務需求分析表》。',
  'pirs-rpq-q13': '只開設個人帳戶',
  'pirs-rpq-deviate-desc': '如您的選擇在任何方面偏離上述「風險承擔能力調查問卷」，您必須書面詳述有關原因：',
  'pirs-inv-10': '挑選產品的準則及適合客戶所屬風險類別的理由',
  'pirs-inv-11': '證監會指引:',
  'pirs-inv-12': '投資顧問必須將其推介的每項投資產品的風險回報狀況與每名客戶的個人情況進行配對，從而提供合理適當的意見。因此，投資顧問應運用其專業判斷能力，並顧及客戶的投資目標、投資期、風險承受能力及財政狀況等，勤勉盡責地評估所推介的每項投資產品的特點及所涉及的風險(包括交易成本、槓桿效應及外匯風險(如適用者))是否確實適合客戶，以及符合客戶的最佳利益。投資顧問不得就他們將會收取的佣金回扣或其他關連公司將收取的其他利益作為向客戶推介某一投資產品的主要依據。若投資顧問只限於推介其關連公司發行的投資產品，便應向每名客戶披露此限制。最後，投資顧問在向長者、或對投資於投資產品缺乏認識的客戶、或那些未能就複雜的投資產品作出獨立投資決定並需要倚賴投資顧問的意見時，必須格外審慎，尤其是當此等客戶是投資於禁售期限長，並於提早贖回時會被徵收巨額罰款的投資產品。',
  'pirs-inv-13': '投資顧問應就所推介的投資產品為何適合客戶，以及該等投資產品的性質及風險程度，給予客戶適當的解釋以協助客戶作出有根據的決定。除了說明投資產品的好處外，投資顧問亦應向客戶提出持平的意見，使客戶同時注意到該投資產品的缺點及下跌的風險。投資顧問在向客戶解釋投資產品時，應採用簡單、淺白、而客戶又可以容易明白的語言，所作出的解釋必須不偏不倚及絕無誤導成份。',
  'pirs-rpq-1': '只開設個人帳戶或轉移累算權益:  需要建議及／或考慮參與任何類型的自願性供款或互惠基金服務',
  'pirs-rpq-2': '只開設個人帳戶或轉移累算權益:  不需要建議及／或不考慮參與任何類型的自願性供款或互惠基金服務',
  'pirs-rpq-3': '只參與任何類型的自願性供款或互惠基金服務',
  'pirs-inv-14': '由持牌顧問填寫 :',
  'client remark': `客戶備注`,
  'pirs-cc13': '本頁第二及第三項必須"一併選擇 ☑"或 "一併不選擇 ☐"',
  'pirs-cc14': '您是證監會持牌⼈或註冊⼈⼠/機構的僱員或董事嗎？(如適用)',
  'chk-multiple': '可選多於一項',
  'range-18k-below': '180,000 港元或以下',
  'range-18k': '180,001 - 360,000 港元',
  'range-36k': '360,001 - 720,000 港元',
  'range-72k': '720,001 - 960,000 港元',
  'range-96k': '960,001 - 1,200,000 港元',
  'range-120k': '1,200,001 港元以上',
  'range-10k-below': '100,000 港元或以下',
  'range-10k': '100,001 - 500,000 港元',
  'range-50k': '500,001 - 1,000,000 港元',
  'range-100k': '1,000,001 - 3,000,000 港元',
  'range-300k': '3,000,001 - 5,000,000 港元',
  'range-500k': '5,000,001 - 8,000,000 港元',
  'range-800k': '8,000,001 - 15,000,000 港元',
  'range-15mil': '15,000,001 港元以上',
  'Total Liquid Assets': '流動資產總額',
  'liquid assets remark': '流動資產指可於短時間內容易轉化成現金的資產。',
}

export default t
