import moment from 'moment'

export const validateIsAdult = (
  value: Date | string,
  dateFormat: string = 'YYYY-MM-DD',
) => {
  console.log('validateIsAdult, value=', value)
  let years
  let dob
  if (value instanceof Date) {
    dob = value
    years = moment(new Date()).diff(value, 'years', true)
  } else {
    dob = moment(value, dateFormat)
    years = moment(new Date()).diff(dob, 'years', true)
  }

  console.log('validateIsAdult', dob, years)
  return years >= 18
}
