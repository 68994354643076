import React, { useState, useEffect } from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import SaveIcon from '@material-ui/icons/Save'
import { Link } from 'react-router-dom'
import {
  makeStyles,
  useTheme
} from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  upsertApplicationData,
  resetApplicationData,
  loadDummyData,
} from 'client/actions'
import { testChangeChannel } from 'client/actions/userProfile'

export const DRAW_WIDTH = 240

const AdapterLink: any = React.forwardRef((props: any, ref: any) => (
  <Link innerRef={ref} {...props} />
))

const DrawerItem = (props: any) => {
  return (
    <ListItem button component={AdapterLink} to={props.to}>
      <ListItemIcon>
        <InboxIcon />
      </ListItemIcon>
      <ListItemText primary={props.label} />
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAW_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAW_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
}))

const AppDrawer = ({ handleDrawerClose, open }: any) => {
  const classes = useStyles()
  const theme = useTheme()
  const [language, setLanguage] = useState('zh-Hant')
  const [canSave, setCanSave] = useState(false)
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const state: any = useSelector((state: any) => state.application.data)

  useEffect(() => {
    setCanSave(true)
  }, [state])

  const toggleLanguage = () => {
    const lang = language === 'en' ? 'zh-Hant' : 'en'
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }

  const save = () => {
    setCanSave(false)
    dispatch(upsertApplicationData())
  }

  const resetStore = () => {
    dispatch(resetApplicationData())
  }
  const loadDummyToStore = () => {
    dispatch(loadDummyData())
  }
  const toggleChannel = () => {
    dispatch(testChangeChannel())
  }

  return (
    <Drawer
      className={classes.drawer}
      variant='persistent'
      anchor='right'
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1' }} className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              toggleLanguage()
            }}
            size='medium'
          >
            {language === 'en' ? '繁' : 'En'}
          </Button>
        </div>
      </div>
      <Divider />
      <List>
        <DrawerItem to='/' label='Home'></DrawerItem>
        <DrawerItem to='/style-guide' label='Style Guide'></DrawerItem>
        <DrawerItem
          to='/swagger-client'
          label='Swagger ClientTest'
        ></DrawerItem>
        <DrawerItem to='/adobe-sign' label='Adobe Sign'></DrawerItem>
        <ListItem disabled={!canSave} button onClick={save}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText
            primary={canSave ? 'SAVE(State Changed)' : 'SAVE(No Changed)'}
          />
        </ListItem>
        <ListItem button onClick={resetStore}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText primary='RESET STORE' />
        </ListItem>
        <ListItem button onClick={loadDummyToStore}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText primary='LOAD DUMMY DATA TOSTORE' />
        </ListItem>
        <ListItem button onClick={toggleChannel}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText primary='Toggle Channel' />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default AppDrawer
