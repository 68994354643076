import _assign from 'lodash/assign'

export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION'
export const toggleTermAndCondition = (isAccepted: boolean) => {
  return (dispatch: any) => {
    dispatch({
      type: SUBMIT_APPLICATION,
      isAccepted,
    })
  }
}
