import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import {
  updateCustomerInformation,
  upsertApplicationData,
} from 'client/actions'
import { ClientInformationDto } from 'client/api/application/api'
import Agreement from 'client/components/agreement'
import InputDynamicCheckbox from 'client/components/inputDynamicCheckbox'
import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

interface Props {
  goNextStep?: (id?: string) => void
}

const TandC = (props: Props) => {
  const state: ClientInformationDto = useSelector((state: any) => {
    return state.application.data.clientInformation as ClientInformationDto
  })

  const [t] = useTranslation()

  const hookFormFunc = useForm()
  const { handleSubmit } = hookFormFunc
  const dispatch = useDispatch()
  // const [termsAcceptCollect, setTermsAcceptCollect] = useState(false)

  const onSubmit = (formData: any) => {
    // return false
    const newData = {
      termsAcceptCollect: formData.termsAcceptCollect || 'N',
    }
    dispatch(updateCustomerInformation(newData as any))
    Promise.resolve(dispatch(upsertApplicationData(false)))
      .then((result: any) => {
        if (props.goNextStep) {
          if (result && result.data && result.data.id) {
            props.goNextStep(result.data.id)
          } else {
            props.goNextStep()
          }
        }
      })
      .catch((err) => console.error(err))
    // if (props.goNextStep) {
    //   props.goNextStep()
    // }
  }

  // react.useEffect(() => {
  //   console.log('lala... ', isAccept1)
  // }, [isAccept1])

  return (
    <Grid container spacing={2}>
      <FormProvider {...hookFormFunc}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Agreement
            title={t('Principal Personal Information Collection Statement')}
          >
            <p>{t('tc-personal-1')}</p>
            <p>{t('tc-personal-2')}</p>
            <p>{t('tc-personal-3')}</p>
            <p>{t('tc-personal-4')}</p>
            <p>{t('tc-personal-5')}</p>
            <p>{t('tc-personal-6')}</p>
            <p>{t('tc-personal-7')}</p>
            <InputDynamicCheckbox
              inputName='termsAcceptCollect'
              label={t(
                'The Scheme Participant objects to the propsed use of his/her personal data in direct marketing.',
              )}
              handleOnChange={(selected) => {
                console.log('selected!!!', selected)
                // setTermsAcceptCollect(selected)
              }}
              defaultChecked={state.termsAcceptCollect === 'Y'}
              value='Y'
            />
          </Agreement>
        </form>
      </FormProvider>
    </Grid>
  )
}

export default TandC
