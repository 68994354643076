import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Grid,
  CircularProgressProps,
  Box,
  CircularProgress,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'

import { useTranslation } from 'react-i18next'
import {
  AdobeSignAgreementsApi,
  RemoteSigningStatusDto,
  RemoteSigningStatusDtoRejectReasonEnum,
  RemoteSigningStatusDtoStatusEnum,
  SubmissionsApi,
} from 'client/api/application/api'
import { BASE_PATH, PPO_BASE_PATH } from 'config'
import { string } from 'yup'
import { makeStyles } from '@material-ui/styles'
import { use } from 'i18next'
import {
  showLoading,
  hideLoading,
} from 'client/actions/applicationNotification'
import { useDispatch } from 'react-redux'
import { PpoAdminContext } from '.'

enum ADOBE_MEMEBER_SIGING_STATUS {
  WAITING_FOR_MY_SIGNATURE = 'WAITING_FOR_MY_SIGNATURE',
  NOT_YET_VISIBLE = 'NOT_YET_VISIBLE',
  WAITING_FOR_OTHERS = 'WAITING_FOR_OTHERS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

const api = new SubmissionsApi(undefined, PPO_BASE_PATH)

const useStyles = makeStyles((theme) => ({}))

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; size: number },
) {
  const { size, value } = props
  return (
    <Box
      width={size}
      height={size}
      style={{ position: 'relative', display: 'inline-flex' }}
      position='relative'
      display='inline-flex'
    >
      <CircularProgress variant='determinate' {...props} />
      <Box
        width={size}
        height={size}
        top={0}
        left={0}
        bottom={0}
        right={0}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='h1'
          component='div'
          color='textSecondary'
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  )
}
interface AppendixUploadDialogProps {
  isOpen: boolean
  uploadProgress: number
}

export const AppendixUploadDialog = (props: AppendixUploadDialogProps) => {
  const { isOpen, uploadProgress } = props
  const [t] = useTranslation()

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t('Upload Appendix')}</DialogTitle>
      <DialogContent>
        <Grid container direction='column'>
          {uploadProgress >= 0 && (
            <CircularProgressWithLabel value={uploadProgress} size={200} />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Grid justifyContent='flex-end' container>
          <Button onClick={() => handleClose()} color='primary'>
            {t('CLOSE')}
          </Button>
        </Grid> */}
      </DialogActions>
    </Dialog>
  )
}

interface AppendixUploaderProps {
  uploadContext: { applicationId: string }
  handleClose: () => void
}

export const AppendixUploader = (props: AppendixUploaderProps) => {
  const { uploadContext, handleClose } = props
  const [t] = useTranslation()
  const inputFileRef = useRef(null)
  const [uploadProgress, setUploadProgress] = useState(-1)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const onFilesSelected = async (e: any) => {
    setIsDialogOpen(true)
    try {
      const files = e.target.files
      const data = new FormData()
      for (const file of files) {
        if (!file.name.toUpperCase().endsWith('.PDF')) {
          alert('Only pdf file is accepted')
          return
        }
        data.append('files[]', file)
      }
      setUploadProgress(0)
      await api.adminUploadAppendicesPost(
        uploadContext.applicationId,
        {
          data,
          onUploadProgress: (e: any) => {
            setUploadProgress(Math.round((100 * e.loaded) / e.total))
          },
        },
      )
    } catch (ex) {
      let message = ex
      if (ex.response) {
        message = ex.response.data
      }
      alert(message)
    } finally {
      setUploadProgress(-1)
      setIsDialogOpen(false)
      handleClose()
    }
  }

  useEffect(() => {
    if (uploadContext && uploadContext.applicationId) {
      console.log('uploadContext', uploadContext)
      console.log('inputFileRef', inputFileRef)
      if (inputFileRef && inputFileRef.current) {
        // @ts-ignore: Object is possibly 'null'.
        inputFileRef.current.click()
      }
    }
  }, [uploadContext])

  return (
    <>
      <input
        type='file'
        multiple={true}
        onChange={onFilesSelected}
        ref={inputFileRef}
        style={{ display: 'none' }}
      />
      <AppendixUploadDialog
        isOpen={isDialogOpen}
        uploadProgress={uploadProgress}
      />
    </>
  )
}

export default AppendixUploader
