// prettier-ignore
export default {
  CLICK_ME: '請按顯示詳情',
  CLIENT_INFO_TITLE: '成員資料',
  'Please indicate your selected Principal MPF Scheme': '請註明您選取的信安強積金計劃',
  Brand: '品牌',
  'Fund Choice': '基金選擇',
  Consolidation: '戶口整合',
  Professional: '專業 / 經驗',
  Integrated: '綜合服務',
  'Other Scheme': '其他計劃特點',
  BRAND: '品牌',
  FUND: '基金選擇',
  ACCOUNTCONSOLIDATION: '戶口整合',
  EXPERIENCE: '專業 / 經驗',
  INTEGRATIONSERVICE: '綜合服務',
  OTHERS: '其他計劃特點',
  'Is your education level above primary?': '您已達小學以上的教育程度?',
  'Are you able to read Chinese or English?': '您能閱讀中文或英文?',
  'Are you free from any disability that will affect your ability to make investment decisions independently?': '您沒有任何殘疾會影響您的獨立投資決定?',
  Yes: '是',
  No: '否',
  'My companion': '我的同伴',
  'AXA Representative': 'AXA 安盛代表',
  'Witness Name': '見證人姓名',
  'Do you want to have a witness to the sales process?': '在銷售過程中您需要有見證人?',
  'ID/Passport Copy': '身份證 / 護照副本',
  'Customer Section': '成員資料',
  Address: '地址',
  'AXA Regulated Activites': '強積金受規管活動表格',
  'FT Life Regulated Activites': '強積金客戶確認書',
  'Investment Mandate': '投資授權書',
  'Account(s) to be Consolidated': '需整合的個人帳戶',
  'Upload HKID/Passport': '身份證 / 護照副本',
  'Other Document(s)': '其他文件',
  Summary: '摘要',
  'Important information to customer': '致中介人的聲明及重要事項',
  'I accept': '我接受',
  'Mandatory Investment': '強制性供款',
  'Volunteer Investment': '自願性供款',
  'Transfer options': '轉移選擇',
  'Transfer All': '轉移全部',
  'Transfer Partial': '轉移部分',
  PA: '個人帳戶',
  CA_1: '供款帳戶(已離職僱員; 自僱人士; 非僱員自選安排)',
  CA_2: '供款帳戶(現職僱員; 僱員自選安排)',
  'I Accept': '我接受',
  TRANSFER_PARTIAL_OPTIONS_M: '現職期間的僱員強制性供款(只限轉移至個人帳戶)',
  TRANSFER_PARTIAL_OPTIONS_V: '現職期間的僱員自願性供款(只限轉移至個人帳戶)',
  TRANSFER_PARTIAL_OPTIONS_AM: '以往工作所累積並已轉移至供款帳戶的強制性供款',
  TRANSFER_PARTIAL_OPTIONS_AV: '以往工作所累積並已轉移至供款帳戶的自願性供款',
  'Save & Preview': '儲存及預覽',
  'For A123456(7) Please input A1234567': '如 A123456(7) 請輸入為A1234567',
  'For Member Number: e.g. 123456': '如 123456',
  'For Contract Number e.g. AB123456': '如 AB123456',
  'Are you a current S800 Customer?': '您是否現有信安強積金計劃 800系列的成員並打算申請強積金整合至該800系列帳戶?',
  Clear: '清除',
  'Investment Allocation': '投資分配',
  'Mandatory Contribution': '強制性供款',
  'Voluntary Contribution': '自願性供款',
  'Name of original scheme': '原計劃名稱',
  'Name of original trustee': '原受託人名稱',
  "Scheme Member's Account Number": '計劃成員帳戶號碼',
  "Employer's identification number": '僱主識別號碼',
  'Name of former employer': '前僱主名稱',
  'Please fill in this field': '請輸入本欄位',
  'Account Number': '帳戶號碼',
  'Branch Code': '分行編號',
  'Bank Code': '銀行編號',
  'Name of Bank Account Holder': '銀行帳戶姓名',
  'Name of Bank': '銀行名稱',
  'By depositing directly in a bank account': '直接存入只以計劃成員名義開立的銀行帳戶',
  'By cheque': '支票付款',
  'Withdraw Voluntary Contributions by': '提取自願性供款權益安排',
  'Arrangement of my voluntary contributions': '自願性供款的安排',
  Transfer: '一併轉移',
  Withdraw: '提取權益',
  'Type of MPF Account': '強積金帳戶類別',
  'Account Type': '帳戶類別',
  'Personal account': '個人帳戶',
  'Contribution account': '供款帳戶',
  'Member Number': '成員帳戶號碼',
  'Please fill in your member number': '請輸入成員帳戶號碼',
  'Please fill in your contract number': '請輸入合約編號',
  'Please fill in your surname': '請輸入英文姓氏',
  Surname: '姓氏',
  'Contract Number': '合約編號',
  'Given Name': '名字',
  'Middle Name(s)': '中間名',
  Gender: '性別',
  Male: '男性',
  Female: '女性',
  'Nature of Business': '業務性質',
  Nationality: '國籍',
  'Date of birth': '出生日期',
  'Job Title': '職銜',
  'Country Code': '電話區號',
  'Mobile no.': '手提號碼',
  'Daytime contact no': '日間聯絡號碼',
  'Email Address': '電子郵箱',
  'Confirm Email Address': '再輸入電子郵箱以作確認。',
  'Subscribe SMS service': '登記每季帳戶結餘短訊服務。',
  'Subscribe e-Communication service': '登記電子通訊服務。',
  'Residential address (P.O. Box not allowed)': '住宅地址 (不接納郵箱地址)',
  Area: '區域',
  District: '地區',
  Street: '街道',
  'No.': '街號',
  Estate: '屋苑',
  Building: '大廈',
  Block: '座',
  Floor: '樓',
  Room: '室',
  'Zip Code': '郵遞區號碼',
  City: '城市',
  Province: '省',
  Country: '國家',
  'Mailing Address (if different from Residential Address)': '通訊地址 (如與住宅地址不同)',
  'Personal Account Application - Customer Profile': '成員資料',
  'MPF Regulated Activities Form': '強積金受規管活動表格',
  'Reason for choosing the Scheme': '選取計劃的原因',
  'Save & Next': '儲存及繼續',
  'The Scheme Participant objects to the propsed use of his/her personal data in direct marketing.': '計劃參與者反對他/她的個人資料被使用於擬作出的直接促銷。',
  CRS: '共同匯報標準',
  'Jurisdiction of Residence and Taxpayer Identification Number or its Functional Equivalent (“TIN”)':
    '居留司法管轄區及稅務編號或具有等同功能的識辨編號（簡稱「稅務編號」）',
  '(i) I hereby declare that, to the best of my knowledge and belief':
    '(i) 以本人所知及所信，在此聲明',
  'formcrs-1': '(如適用，請在下面的方格上填上「✓」。)',
  'My Tax Residence is Hong Kong ONLY, with no tax residence in any other jurisdictions or countries (and my HKID number is my TIN) (You may skip (ii).)':
    '本人之稅務居住地為只有香港，及沒有處於任何其他司法管轄區或國家的稅務居住地(而我的香港身份證號碼是我的稅務編號) (可略過(ii)部份。)',
  'If the box above does not apply, please proceed to (ii) which must be filled in.':
    '如果上面的方格不適用，請填寫（ii）部份。',
  '(iii) If you are not a tax resident in the Jurisdiction of Current Residence Address or Mailing Address. Please select one of the following reasons as applicable.':
    '(iii) 如果您不是現時住址或通訊地址的稅務居民，請根據情況選擇以下一個原因。',
  '(ii) Please indicate': '(ii) 請列明',
  '(a) the jurisdiction of residence (including Hong Kong) where the account holder is a resident for tax purposes; and':
    '(a) 帳戶持有人的居留司法管轄區，亦即帳戶持有人的稅務管轄區(香港包括在內); 及',
  '(b) the account holder’s TIN for each jurisdiction indicated. Indicate all (not restricted to five) jurisdictions of residence.':
    '(b) 該居留司法管轄區發給帳戶持有人的稅務編號。列出所有(不限於5個)居留司法管轄區。',
  'If the account holder is a tax resident of Hong Kong, the TIN is the Hong Kong Identity Card Number.':
    '如帳戶持有人是香港稅務居民，稅務編號是其香港身份證號碼。',
  'If a TIN is unavailable, provide the appropriate reason A, B or C':
    '如沒有提供稅務編號，必須填寫合適的理由',
  Reason: '理由',
  'The jurisdiction where the account holder is a resident for tax purposes does not issue TINs to its residents.':
    '帳戶持有人的居留司法稅務管轄區並沒有向其居民發出稅務編號。',
  'The account holder is unable to obtain a TIN. Explain why the account holder is unable to obtain a TIN if you have selected this reason.':
    '帳戶持有人不能取得稅務編號。如選取這一理由，解釋帳戶持有人不能取得稅務編號的原因。',
  'TIN is not required. Select this reason only if the authorities of the jurisdiction of residence do not require the TIN to be disclosed.':
    '帳戶持有人毋須提供稅務編號。居留司法管轄區的主管機關不需要帳戶持有人披露稅務編號。',
  Add: '增加',
  'Loading...': '載入中...',
  'Jurisdiction of Residence*': '居留司法管轄區*',
  'TIN*': '稅務編號',
  'Enter Reason A, B or C if no TIN is available':
    '如沒有提供稅務編號， 填寫理由A、B 或C',
  'Explain why the account holder is unable to obtain a TIN if you have selected Reason B':
    '如選擇理由B， 解釋帳戶持有人不能取得稅務編號的原因',
  Hello: '您好',
  'Current Residence Address or Mailing Address': '現時住址或通訊地址',
  'I am a student at an educational institution in the jurisdiction where I currently reside and hold the appropriate visa; or':
    '本人是一名學生，就讀於目前居留司法管轄區內的教育機構，並持有相關的有效簽證; 或',
  'I am a teacher, a trainee, or intern at an educational institution or a participant in an educational or cultural exchange visitor program in the jurisdiction where I currently reside, and hold the appropriate visa; or':
    '本人是一名在目前居留司法管轄區的教師、培訓人員、實習生、或教育或文化交流訪問計劃的參加者，並持有相關的有效簽證; 或',
  'I am a foreign individual assigned to a diplomatic post or a position in a consulate or embassy in the jurisdiction where I currently reside; or':
    '本人是一名外國人，並被派駐目前居留司法管轄區之外交崗位或在目前居留司法管轄區的領事館或大使館擔任職位; 或',
  'I am an employee working on a vessel, truck or train travelling between the jurisdiction where I currently reside and other jurisdictions.':
    '本人是一名來往目前居留司法管轄區及其他居留司法管轄區之間行駛途中的船隻、卡車或火車上的僱員。',
  'The aforementioned reasonable explanation is NOT applicable to me and I therefore provide the following reasonable explanation to support my jurisdiction of tax residence':
    '上述合理解釋皆不適用，因此本人提供下列合理解釋以支持本人的稅務居住地區',
  'Non-Hong Kong residents': '非香港稅務居民',
  Cancel: '取消',
  Submit: '提交',
  OK: '確定',
  Subscription: '申請服務',
  'Logout': '登出',
  'Place of Birth (Optional)': '出生地點(可不填寫)',
  'HKID': '香港身份證',
  'Passport': '護照',
  'Title': '稱謂',
  'Mr': '先生',
  'Mrs': '太太',
  'Ms': '女士',
  'Miss': '小姐',
  'summaryInvestNote': '如無適當指示或所選擇之基金與所選擇之系列不符或投資分配總數不等於 100%，受託人會將所有供款投資於預設投資策略。',
  'Surname (English)': '姓氏 (英文)',
  'Given Name (English)': '名字 (英文)',
  'Surname (Chinese)': '姓氏 (中文)',
  'Given Name (Chinese)': '名字 (中文)',
  'Date of Birth': '出生日期',
  'Mailing Address': '通訊地址',
  'Agree': '同意',
  'Disagree': '不同意',
  'Chinese': '中文',
  'English': '英文',
  'Hong Kong': '香港',
  'Kowloon': '九龍',
  'New Territories': '新界',
  'Others': '其他',
  'P.O. Box should not be residential address': '不接納郵箱地址作為住宅地址',
  'Y': '是',
  'N': '否',
  transferOption_A: '轉移全部',
  transferOption_P: '轉移部分',
  'Any Voluntary Contributions in the Account?': '該帳戶是否有自願性供款?',
  'Transfer from self employed account?': '轉出帳戶為自僱人士帳戶',
  'Self employed Details': '現時自僱人士身分詳情',
  'selfEmployedDetails_O': '維持自僱',
  'selfEmployedDetails_T': '終止自僱',
  'withdrawMethod_B': '存入成員的銀行帳戶',
  'withdrawMethod_C': '支票付款',
  'Termination Date': '終止自僱日期',
  'Payment until': '維持自僱 - 原計劃最後供款日期',
  'AIA Company (Trustee) Limited': '友邦（信託）有限公司',
  'Bank of Communications Trustee Limited': '交通銀行信託有限公司',
  'Bank Consortium Trust Company Limited': '銀聯信託有限公司',
  'Bank of East Asia (Trustee) Limited': '東亞銀行（信託）有限公司',
  'BOCI-Prudential Trustee Limited': '中銀國際英國保誠信託有限公司',
  'China Life Trustees Limited': '中國人壽信託有限公司',
  'HSBC Institutional Trust Services (Asia) Ltd': '滙豐機構信託服務（亞洲）有限公司',
  'HSBC Provident Fund Trustee (Hong Kong) Limited': 'HSBC Provident Fund Trustee (Hong Kong) Limited',
  'Manulife Provident Funds Trust Company': '宏利公積金信託有限公司',
  'YF Life Trustees Limited': '萬通信託有限公司',
  'Sun Life Pension Trust Limited': '永明退休金信託有限公司',
  'Principal Trust Company (Asia) Limited': '信安信託（亞洲）有限公司',
  'Sun Life Trustee Company Limited': '永明信託有限公司',
  'BCT(MPF) Industry Choice': 'BCT（強積金）行業計劃',
  'BCT(MPF) Pro Choice': 'BCT 積金之選',
  'Allianz Global Investors MPF Plan': '安聯強積金計劃',
  'BCT Strategic MPF Scheme': 'BCT 強積金策略計劃',
  'AMTD MPF Scheme': 'AMTD 強積金計劃',
  'BCOM Joyful Retirement MPF Scheme': '交通銀行愉盈退休強積金計劃',
  'AIA MPF Prime Value Choice': '友邦強積金優選計劃',
  'BEA (MPF) Industry Scheme': '東亞（強積金）行業計劃',
  'BEA (MPF) Master Trust Scheme': '東亞（強積金）集成信託計劃',
  'BEA (MPF) Value Scheme': '東亞（強積金）享惠計劃',
  'BOC-Prudential Easy-Choice Mandatory Provident Fund Scheme': '中銀保誠簡易強積金計劃',
  'My Choice Mandatory Provident Fund Scheme': '我的強積金計劃',
  'China Life MPF Master Trust Scheme': '中國人壽強積金集成信託計劃',
  'Fidelity Retirement Master Trust': '富達退休集成信託',
  'Haitong MPF Retirement Fund': '海通MPF退休金',
  'Hang Seng Mandatory Provident Fund Supertrust Plus': '恒生強積金智選計劃',
  'HSBC Mandatory Provident Fund-Supertrust Plus': '滙豐強積金智選計劃',
  'Manulife Global Select (MPF) Scheme': '宏利環球精選（強積金）計劃',
  'Mass Mandatory Provident Fund Scheme': '萬全強制性公積金計劃',
  'Sun Life MPF Basic Scheme': '永明強積金基本計劃',
  'Sun Life MPF Comprehensive Scheme': '永明強積金綜合計劃',
  'Principal MPF Scheme Series 600': '信安強積金計劃600系列',
  'Principal MPF Scheme Series 800': '信安強積金計劃800系列​',
  'Principal MPF-Simple Plan': '信安強積金–易富之選',
  'Principal MPF-Smart Plan': '信安強積金–明智之選',
  'Sun Life Rainbow MPF Scheme': '永明彩虹強積金計劃',
  'Standard Chartered Trustee (Hong Kong) Limited': '渣打信託(香港)有限公司',
  'SHKP MPF Employer Sponsored Scheme': '新地強積金僱主營辦計劃',
  'Default Investment Strategy': '預設投資策略',
  'Principal Age 65 Plus Fund': '信安65歲後基金',
  'Principal Core Accumulation Fund': '信安核心累積基金',
  'Principal Capital Guaranteed Fund': '信安資本保證基金',
  'Principal Long Term Guaranteed Fund': '信安長線保證基金',
  'Principal MPF Conservative Fund': '信安強積金保守基金',
  'Principal HK Dollar Savings Fund': '信安港元儲蓄基金',
  'Principal Hong Kong Bond Fund': '信安香港債券基金',
  'Principal Asian Bond Fund': '信安亞洲債券基金',
  'Principal International Bond Fund': '信安國際債券基金',
  'Principal Stable Yield Fund': '信安平穩回報基金',
  'Principal Long Term Accumulation Fund': '信安長線增值基金',
  'Principal Global Growth Fund': '信安環球增長基金',
  'Principal Aggressive Strategy Fund': '信安進取策略基金',
  'Principal US Equity Fund': '信安美國股票基金',
  'Principal Asian Equity Fund': '信安亞洲股票基金',
  'Principal China Equity Fund': '信安中國股票基金',
  'Principal Hang Seng Index Tracking Fund': '信安恒指基金',
  'Principal Hong Kong Equity Fund': '信安香港股票基金',
  'Principal International Equity Fund': '信安國際股票基金',
  'risk_L': '低',
  'risk_M': '中等',
  'risk_ML': '低至中等',
  'risk_MH': '中等至高',
  'risk_H': '高',
  'risk_': '',
  'Catering': '飲食業',
  'Construction': '建造業',
  'Manufacturing': '製造業',
  'Financing / Banking Services': '銀行及金融業',
  'Community / Social / Personal Services': '社會服務業',
  'Wholesale /Retail / Import & Export': '批發/零售/出入口',
  'Transport': '運輸業',
  'Cleaning': '清潔業',
  'Security Guard': '保安業',
  'Hairdressing & Beauty': '髮型及美容',
  'Estate / Builidng': '屋苑 / 大廈',
  'City / State / Country': '城市 / 省 / 國家',
  'logintc_1': '在使用本系統前，請先細閱及同意以下的聲明及重要事項。',
  'logintc_2': '請確保閣下已成為安盛金融有限公司的強積金中介人 (附屬中介人)。',
  'logintc_3': '請確保閣下已獲授權開立強積金計劃- 信安強積金計劃800系列(“ 該計劃”)。',
  'logintc_4': '在使用客戶的資料時必須遵守個人資料私隱條例、強制性公積金計劃條例及由相關機構不時發出的條例、規例、指引及守則。',
  'logintc_5': '使用本系統可帶來附加風險，包括但不限因資訊、流動設備的設置或硬件造成的任何損壞或損失，或因訊息傳送時的阻截、中斷或損壞而導致風險或由於流動設備的自動密碼儲存功能而導致未獲授權的密碼使用。信安信託（亞洲）有限公司及其成員公司(以下統稱為”信安”)均不會就上述風險、損壞或損失，承擔任何責任。',
  'logintc_6': '我們強烈建議閣下不應使用公共電腦或供公眾使用的無線網絡進入本系統，並於使用本系統前確保閣下的電腦或流動設備已安裝最新的防毒軟件及防間諜軟件(如適用)，以保護閣下的電腦或流動設備免受病毒或惡意程式的侵襲。',
  'logintc_7': '本系統包含臨時儲存功能，申請文件草稿只可保留不多於45天及已遞交的申請文件只可保存不多於45天。請確保資料輸入、修改、刪除及遞交前必須得到客戶確認。',
  'logintc_8': '本系統並不保證開立帳戶或強積金的資金轉移可有效執行，如資料有誤、重覆申請或其他可能因素可使申請不獲接納。信安將保留最終決定權。',
  'logintc_9': '在遞交申請後，系統將自動跟據客戶已輸入的電郵地址傳送加密副本予客戶備存。如此系統沒有客戶的電郵地址紀錄，已遞交的表格副本將稍後郵寄至客戶的通訊地址。請確保客戶的通訊地址及電郵地址(如有) 真實及正確無誤。',
  'Name of Constituent Funds': '成份基金名稱',
  'Fund Code': '基金代號',
  'Risk / Return Profile': '風險/回報程度',
  'Trustee': '受託人名稱',
  'Original Plan': '原計劃名稱',
  'Transfer type': '帳戶類別',
  'Account No.': '帳戶號碼',
  'Action': '修正',
  'Member Information': '成員資料',
  'Investment Total': '合共',
  'Transfered Account': '需整合的個人帳戶',
  'Personal Information Collection Statement': '個人資料收集的聲明',
  'tc-personal-axa-1': '安盛金融有限公司（下稱“安盛”）明白其就《個人資料（私隱）條例》（香港法例第486章）（“條例”）收集、持有、處理、使用和／或轉移個人資料所負有的責任。受託人僅將為合法和相關的目的收集個人資料，並將採取一切切實可行的步驟，確保安盛所持個人資料的準確性。安盛將採取一切切實可行的步驟，確保個人資料的安全性，及避免發生未經授權或者因意外而擅自取得、刪除或另行使用個人資料的情況。',
  'tc-personal-axa-2': '敬請注意，如果閣下不向受託人提供閣下的個人資料，我們可能無法提供閣下所需的資料、產品或服務，或無法處理閣下的要求。',
  'tc-personal-axa-3': '目的：安盛不時有必要收集閣下的個人資料，並可能因下列各項目的（“有關目的”）而供信安信託(亞洲)有限公司(“受託人”)使用、存儲、處理、轉移、披露或共享該等個人資料：1.向閣下推介、提供和營銷受託人之產品／服務，以及提供、維持、管理和操作該等產品／服務；2.處理和評估閣下就受託人及安盛關聯方所提供之有關强制性公積金(“强積金”) 的產品／服務提出的任何申請或要求；3.向閣下提供有關强積金產品及服務的後續服務；4.評估閣下的財務需求；5.為客戶設計有關强積金的產品／服務；6.不時就本條款所列的任何目的核對所持有的與閣下有關的任何資料；7.作出任何適用法律、規則、規例、實務守則或指引所要求的披露或協助在香港或香港以外其他地方的警方或其他政府或監管機構執法及進行調查；8.進行身份核查；9.遵守任何適用的司法管轄區的法律；及10.與上述任何目的直接有關的其他目的。',
  'tc-personal-axa-4': '個人資料的轉移：個人資料將予以保密，但在遵守任何適用法律條文的前提下，可提供給：1.位於香港或香港以外其他地方的任何安盛關聯方、受託人的任何相關聯人士、閣下之僱主、行業協會或聯會，以及就此方面而言，閣下同意將閣下的資料轉移至香港境外；2.與就安盛關聯方和／或受託人提供的任何有關强積金的產品／服務在有需要時進行調查的任何人士；3.在香港或香港以外其他地方向安盛關聯方和／或受託人提供行政，技術或其他服務並對個人資料負有保密義務的任何代理、承包商或第三方；4.受託人權利或業務的任何實際或建議的承讓人、受讓方、參與者或次參與者；及5.在香港或香港以外其他地方的任何政府部門或其他適當的政府或監管機關。',
  'tc-personal-axa-5': '閣下的個人資料將僅為上文中規定的一個或多個有關目的而被轉移。',
  'tc-personal-axa-6': '個人資料的查閱和更正：根據條例，閣下有權查明安盛是否持有閣下的個人資料，獲取該資料的副本，以及更正任何不準確的資料。閣下還可以要求安盛告知閣下安盛所持個人資料的種類。查閱和更正的要求，或有關獲取政策、常規及受託人所持的資料種類的資料，均應以書面形式發送至：香港銅鑼灣勿地臣街1號時代廣場2座20樓2001室，安盛金融有限公司，個人資料保護主任。安盛可能會向閣下收取合理的費用，以抵銷安盛為執行閣下的資料查閱要求而引致的行政和實際費用。',
  'tc-personal-1': '閣下提供的資料及其他個人資料純屬自願性質。然而，如未能提供所需資料及其他個人資料，可能導致閣下的申請/指示不獲處理。閣下提供的資料及其他個人資料僅供信安信託(亞洲)有限公司(「受託人及管理人」) 及其相關聯公司 、 有關計劃的保薦人、推銷人及以下所指的相關人士/機構使用及處理。',
  'tc-personal-2': '向閣下所收集的資料及其他個人資料將會用作下列用途：(1) 處理閣下就該計劃的申請； (2) 處理及管理閣下於該計劃的供款及累算權益；(3) 執行閣下的指示或答覆閣下或閣下代表的查詢；(4) 直接促銷信安信託(亞洲)有限公司及其成員公司的強制性公積金(「強積金」)產品、退休計劃、強積金及退休計劃的相關服務及產品；(5) 提供強積金及退休計劃的相關服務；(6) 維持統計數據及用作產品及市場研究資料庫；(7) 遵守有關不論於香港特別行政區境内或境外的任何法律、監管、政府、税務、執法或其他機關,或金融服務供應商的自律監管或行業組織或協會作出或發出的任何適用的法律、規則、指引或指導, 包括但不限於《外國帳戶稅務合規法案》及共同匯報標準；及 (8) 用作與任何上述有關的用途。',
  'tc-personal-3': '除上述以外，受託人及其成員公司可根據<税務條例>（香港法例第112章)有關交換財務帳戶資料的法律條文，及作自動交換財務帳戶資料用途，把該等資料和關於帳戶持有人及任何須申報帳戶的資料向香港特別行政區政府税務局(「税務局」)申報，從而把資料轉交到帳戶持有人的税務管轄區的税務當局。如情況有所改變，以致影響帳戶持有人的税務居民身分，閣下會通知本公司，並會在情況發生改變後30日内，向本公司提交一份已適當更新的自我證明表格。',
  'tc-personal-4': '閣下可參閱税務局網站 http://www.ird.gov.hk/chi/tax/dta_aeoi.htm 以了解香港實施自動交換財務帳戶資料的詳情。',
  'tc-personal-5': '只有在閣下的同意下，閣下的個人資料（姓名，聯絡資料和戶口記錄）或會用於直接促銷本公司及其成員公司的強制性公積金的產品，退休計劃，強積金相關的服務和產品。如閣下不欲閣下的個人資料被用於如本文所述之直接促銷，您應在此個人資料收集說明書末部的方格內加上 (「✓」) 號，以表示不同意。 ',
  'tc-personal-6': '閣下的個人資料可能轉移/披露予以下的人士 (不論在香港特別行政區內外) 作為上述所載的任何用途：(1) 在業務上向受託人提供行政、電訊、電腦、數據處理或儲存、市場推廣、專業或其他任何服務的代理、承包商、第三方服務供應商或本公司所屬公司集團旗下的任何公司；(2) 就受託人或其成員公司所需遵守的法律及規則要求，或按監管機構、税務或其他主管機構要求受託人或其成員公司需遵守的指引，受託人因而有責任要向其披露的任何人士；及 (3) 允許任何受託人的實際或建議承讓人或受託人所持客戶權益的分享者、再分享者、受讓人擁有有關客戶資料的權利。',
  'tc-personal-7': '根據個人資料(私隱)條例（香港法例第486章），閣下有權要求查閱及更正該公司所持有閣下的個人資料及要求閣下的個人資料不被用作上述的直接促銷用途。上述要求可以書面形式通知保障資料主任，信安信託(亞洲)有限公司，香港九龍觀塘觀塘道392號創紀之城6期30樓，閣下如有任何疑問或欲進一步了解本公司的私隱政策，請致函到上述地址或致電（852）2251 9322 (供僱主查詢)或(852) 2827 1233 (供成員查詢)與本公司聯絡。',
  'Upload Again': '重新上載',
  'Personal Information Collection Statement_1': '個人資料收集說明書',
  'No record was found': '找不到相關紀錄',
  'Last Modified at': '最後修改日期',
  'No submission was found': '找不到已提交紀錄',
  'Invest all contributions in DIS?': '將所有供款投資於預設投資策略?',
  'Transfer Account': '轉移戶口',
  Terminate: '終止',
  Ongoing: '繼續',
  'Connecting to Adobe Sign...': '正在連接到Adobe Sign...',
  'Submitting Application...': '正在提交申請...',
  'Application Submitted': '申請已成功提交',
  'Reference Number': '申請編號',
  'Next': '繼續',
  'Preview': '預覽',
  'Click this for Remote Signing': '按此進行遠端簽名',
  'List of Submitted Applications': '已提交申請',
  'Submitted Applications': '已提交申請',
  'Create New Application': '建立新申請',
  'Edit Draft': '更改未提交申請',
  'Consent to Use of Adobe': '同意使用Adobe',
  'adobetemrs-1': '本人同意使用Adobe並同意遵守Adobe一般使用條款和關於以電子方式進行業務、接收電子通知和披露，以及以電子方式簽署文件的消費者披露條款，並受其約束。上述條款已分別上載於網址 <1>https://www.adobe.com/legal/terms.echo.html</1>',
  'adobetemrs-2': ' 及 <1>https://www.adobe.com/special/misc/consumerdisclosure.html</1> ，Adobe亦可能會不時更新和修訂，而不作另行通知。更新和修訂的條款將在上述網址提供。',
  'M': '男性',
  'F': '女性',
  'zh-Hant': '中文',
  'en': '英文',
  'area_HK': '香港',
  'area_KLN': '九龍',
  'area_NT': '新界',
  'area_Others': '其他',
  'identType_hkid': '身份證',
  'identType_passport': '護照',
  'The max length is': '最大長度是',
  'formCrs-2': '如已選擇 ( i ) 部份，則 ( ii ) 部份不需填寫。',
  'formCrs-3': '請填寫( i ) 或( ii )部份。',
  'area_': '',
  'modalWitness-1': '本系統暫不支援需見證人的申請。如有需要，請使用紙本申請。',
  'Please select a new scheme.': '請選擇原計劃。',
  'Please refresh the brwoser to try again': '請重新載入網頁瀏覽器',
  'modalac-q1a1': '<0>現職期間</0>的僱員強制性供款',
  'modalac-q1a2': '<0>現職期間</0>的僱員自願性供款',
  'modalac-q1a3': '<0>以往工作</0>所累積並已轉移至供款帳戶的強制性供款',
  'modalac-q1a4': '<0>以往工作</0>所累積並已轉移至供款帳戶的自願性供款',
  'formAC-1': '請填寫最少一個帳戶',
  'Do you want to remove the record?': '你確定要移除此記錄?',
  'This action cannot be undone.': '此記錄一經刪除即無法復原',
  'Remove': '移除',
  'Add a new document': '新增新文件',
  'Please select a document type': '請選擇文件種類',
  'Document type': '文件種類',
  'Threshold': '閾值',
  'Please adjust the threshold value for a clear image': '請調整閾值直至影像清晰',
  'MPF Registration No.': '強積金註冊編號',
  'Agent Contact Number': '中介人聯絡電話',
  'Please fill in': '請填上',
  'Click to see the sample': '按此瀏覽清晰樣本',
  'MPF Intermediary Name': '中介人姓名',
  'as shown on HKID Card / passport': '與香港身分證上/護照的姓名相同',
  'Client age must be >= 18': '申請人必須年滿18歲',
  'Invalid HKID': '身份證號碼錯誤',
  'Please input a valid number': '請輸入正確的電話號碼',
  'Please input a valid number. Max length is 15': '請輸入正確的電話號碼(長度最大15)',
  'Please enter a vaild email': '請輸入正確的電郵地址',
  'Please select at least one reason': '請輸入至少一個選項',
  'Please input an integer between 0 to 100': '請輸入整數(0-100)',
  'The total must be 0 % or 100%': '請調整投資分配總數至0或100',
  'Please upload the ID / Passport copy': '請上載身份證 / 護照副本',
  'List of Applications (click to open)': '申請列表',
  'CLOSE': '關閉',
  'EDIT APPLICATION': '修改申請',
  'E-signatures Tracking': '簽署追踪',
  'Waiting for signarure': '電郵已發送，正等待簽署',
  'DISCLOSURE OF NECESSARY INFORMATION': '向客戶披露所需資料',
  'The application is signed and being processed': '已簽署，正等候系統處理。',
  'Principal Personal Information Collection Statement': '信安 - 個人資料收集說明書',
  'I Confirm': '我確定',
  'The application is cancelled': '申請已被取消。',
  'The agreeemnt should be signed in Hong Kong': '只接受香港境內簽署文件',
  'Loading data from Adobe Sign': '正從Adobe Sign載入資料',
  'Declaration': '聲明',
  'Please confirm this application is signed in Hong Kong.': '請確定是次申請是在本港簽署。',
  'PIRS Process (New Case)': 'PIRS步驟(新申請)',
  'Edit PIRS Process': '更改未提交PIRS步驟',
  'Marital Status': '婚姻狀況',
  'Education Level': '教育程度',
  'Occupation': '職業',
  'Single': '未婚',
  'Married': '已婚',
  'Widowed': '鰥寡',
  'Divorced': '離婚',
  'Client Confirmations': '客戶確認書',
  'Personal Information': '個人資料',
  'Name of Employer': '僱主名稱',
  'Business Address': '辦事處地址',
  'Primary': '小學或以下',
  'Secondary': '中學',
  'Non-degree Tertiary': '大專',
  'Degree or above': '大學或以上',
  'Finance / Insurance': '金融／保險',
  'Engineers': '工程師',
  'Professionals': '專業人士',
  'Customer Services': '客戶服務',
  'Information Technology': '資訊科技',
  'Housewife': '家庭主婦',
  'Retired': '退休',
  'Unemployment': '失業',
  'Disclosure of Sales Related Information': '披露銷售相關資料',
  'mr-disclosure-1': '(根據強制性公積金條例 34ZL(1)(e)部分)',
  'mr-disclosure-2': '客戶姓名',
  'mr-disclosure-3': '銷售相關資料的詳情如下:',
  'mr-disclosure-4': '1. 本人，',
  'mr-disclosure-5': '(強積金中介人註冊號碼',
  'mr-disclosure-6': '），為主事中介人，信安投資及退休金服務有限公司(“PIRS”)的附屬中介人，為其銷售信安強積金計劃800系列("計劃")。',
  'mr-disclosure-7': '2. PIRS，主要營業地點為香港灣仔港灣道30 號新鴻基中心29 樓; 而分行辦事處為香港九龍觀塘觀塘道392 號創紀之城6 期30 樓，是計劃的推銷商；計劃的受託人及管理人是信安信託（亞洲）有限公司；計劃的投資經理是信安資金管理（亞洲）有限公司；計劃的保管人是花旗銀行。',
  'mr-disclosure-8': '3. PIRS或本人不會就以下第5點所提供的服務向客戶收取任何直接費用。',
  'mr-disclosure-9': '4. 除收取每月薪酬外，本人在成功銷售計劃後，如本人可以達到每年指定的銷售目標或可收取年終獎金。客戶對計劃中成分基金的選擇並不會影響本人的每月薪酬/年終獎金。',
  'mr-disclosure-10': '5. 本人正在進行/將會進行的受規管活動:邀請您作出以下其中一項或多項關鍵決定/向閣下提供以下的意見:',
  'mr-disclosure-10-1': '(a) 因轉移而涉及轉出保證基金。',
  'mr-disclosure-10-2': '(b) 從強積金制度提早提取累算權益。',
  'mr-disclosure-10-3': '(c) 是否申請加入計劃或成為計劃的成員或何時作出該申請。',
  'mr-disclosure-10-4': '(d) 是否申請以僱主身分參與計劃或何時作出該申請。',
  'mr-disclosure-10-5': '(e) 是否向計劃供款(包括自願性供款)或投資於計劃的某成分基金，或何時支付該供款或作出該投資。',
  'mr-disclosure-10-6': '(f) 將會向計劃供款(包括自願性供款)的款額，或將會投資於計劃的某成分基金的款額。',
  'mr-disclosure-10-7': '(g) 是否將累算權益從某註冊計劃轉移至計劃，或從某註冊計劃的一個成分基金轉移至計劃的另一個成分基金，或何時如此轉移該累算權益。',
  'mr-disclosure-10-8': '(h) 將會從某註冊計劃轉移至計劃的累算權益的款額，或將會從某註冊計劃的一個成分基金轉移至計劃的另一個成分基金的累算權益的款額。',
  'mr-disclosure-10-9': '(i) 是否將某職業退休計劃的權益轉移至計劃;或將會從某職業退休計劃轉移至計劃的權益的款額或何時作出轉移該權益。',
  'mr-disclosure-10-10': '(j) 是否提出要求從某註冊計劃支付累算權益的申索;或何時提出該申索或申索的款額。',
  'Home': '家居',
  'Office': '辦公室',
  'Fax': '傳真',
  'PersonName': '姓名',
  'Age': '年齡',
  'Relationship': '關係',
  'PIRS Process Number': 'PIRS 步驟申請編號',
  'Refresh Data': '資料更新',
  'RPQ': '風險承擔能力調查問卷',
  'Confirmation of Receiving Doc': '確認收訖文件',
  'FNA': '財務需要分析',
  'FNA - Important Notes': '財務需要分析 - 重要事項',
  'Invalid Date Format': '請輸入正確日期',
  'Financial Needs Analysis': '財務需要分析',
  'Dependents': '受養人資料',
  'Forms Preview': '表格預覽',
  'IMPORTANT NOTICE': '重要通知',
  'Appointment & PICS': '委任顧問及個人資料收集聲明',
  'Client Agreement & Risk Disclosure Statement': '客戶協議書及風險披露聲明',
  'Investment Advice Record': '投資意見記錄',
  'Sign on the spot': '即場簽署',
  'Non-Face to face e-signing': '非會面簽署',
  'Actions': '行動',
  'FTLife': '周大福人壽',
  'Must Complete': '必須填寫',
  'Missing "Account Number(s)" in the "Account(s) to be Consolidated" Screen, please go back to update the record.': '請到"需整合的個人帳戶"頁面中輸入有關"帳戶號碼"。',
  'Subscription remarks': '如閣下早前已申請我們的短訊及/或電子通訊服務，則毋須再次申請',
  'Please select an account type': '請選擇帳戶類別。',
  'Manulife RetireChoice (MPF) Scheme': '宏利退休精選（強積金）計劃',
  '5 points to avoid delay/rejection of account setup through iPA': '避免帳戶開立延誤/被拒的五大須知',
  'screen-guide-1': '上載身份證時，請確保圖片文字清晰可讀',
  'screen-guide-2': '不接受理財顧問本人核實自己身份證開立戶口',
  'screen-guide-3': '業務性質/職銜不清晰',
  'screen-guide-4': '住宅地址不完整',
  'screen-guide-5': '開戶姓名與簽署名稱不符',
  'Communication Language': '通訊語言',
  'Family Status': '家庭狀況',
  'Annual Income with desc': '每年收入(港幣) (實際收入包括奬金、雙糧等)',
  'email-tooltip': '電子通訊服務包括信安信託(亞洲)有限公司不時以電子形式發出之所有通告及文件，包括成員權益報表、強積金通告及基金便覽。如要登記這項服務，閣下必須提供您的電子郵箱。電子通訊服務適用於閣下已同意享用此服務的強積金計劃下之所有有效的強積金帳戶，並會延伸至同一計劃下的新強積金帳戶。登記完成後，本公司會發送「電郵提示通知」到閣下的電子郵箱，提醒閣下到本公司網站檢視通告及文件。在發出「電郵提示通知」後，成員權益報表及基金便覽在網站的保留期為至少 24 個月，而強積金通告的保留期則為至少 12 個月。如我們發現閣下未能收到「電郵提示通知」，我們會將文件列印並郵寄到閣下最新的通訊地址，並且不會收取任何額外費用。基於以下原因，電子通訊服務會被取消 : (i)閣下取消電子通訊服務、(ii)閣下的強積金戶口已被終止、(iii) 未能成功發送 「電郵提示通知」到閣下的電子郵箱，而閣下並未能於60天內向我們更新有效的電子郵箱，及(iv)我們收到閣下的身故/喪失精神行為能力通知。閣下可以隨時聯繫信安要求撤銷同意使用電子通訊服務。如果閣下同意撤銷使用該服務，閣下將自動取消訂閱電子通訊服務。完成更新後，我們將發出確認通知予閣下。',
  'sms-tooltip': '登記每季帳戶結餘短訊服務，閣下將透過登記的手機號碼定期收到退休金帳戶的結餘手機短訊。閣下可以隨時聯繫信安要求撤銷同意使用每季帳戶結餘短訊服務。',
  'Occupation - Group': '職業 - 類別',
  'Please select': '請選擇',
  'pirs-rpq-q1-1': '您屬於哪個年齡組別？',
  'pirs-rpq-q2-1': '以淨流動資產總值計算，您會動⽤多少個百分比來購買投資產品？',
  'pirs-rpq-q3-1': '您是否擁有投資於以下⾦融⼯具的經驗?',
  'pirs-rpq-q4-1': '承上題，您對這些⾦融⼯具擁有多少年投資經驗？',
  'pirs-rpq-q5-1': '您有何投資⽬的？',
  'pirs-rpq-q6-1': '您預計的投資年期是多久？',
  'pirs-rpq-q7-1': '您覺得於短期内(⼀年)讓你舒適的平均價格波動⽔平是甚麼？',
  'pirs-rpq-q8-1': '為滿⾜您的長期⽬標，若投資本⾦價值在⼀段時期下跌，您會有何感受?',
  'Investment Grade Bonds / Bond Funds': '投資級債券 / 債券基⾦',
  'Foreign Currency / Precious Metal': '外幣存款 / 貴⾦屬',
  'Balanced Funds / Mixed Asset Funds': '平衡型基⾦ / 混合資產基⾦',
  'Stock / ETF / Equity Funds': '股票 / 交易所交易基⾦ / 股票基⾦',
  'High Yield Bond Funds / Structured Products / Derivatives': '⾼收益債券基⾦ / 結構性產品 / 衍⽣⼯具',
  'None of the above': '以上皆不適⽤',
  'Less than 1 year': '不⾜1 年',
  '1 to less than 3 years': '1 年⾄不⾜3 年',
  '3 to less than 5 years': '3 年⾄不⾜5 年',
  '5 years or above': '5 年或以上',
  'No experience': '無',
  'Capital preservation': '保本',
  'Regular stable income': '穩定的定期收入',
  'Balance between regular income and capital appreciation': '在定期收入及資本增值中取得平衡',
  'Substantial capital appreciation': '明顯的資本增值',
  'Maximize capital appreciation by taking maximum risk': '承擔最⾼風險，以取得最⾼的資本增值',
  '1 to 2 years': '1 - 2 年',
  '2 to 3 years': '2 - 3 年',
  '3 to 5 years': '3 - 5 年',
  'More than 5 years': '5 年以上',
  'Around (+/-) 5%': '約 (+/-) 5%',
  'Around (+/-) 10%': '約 (+/-) 10%',
  'Around (+/-) 15%': '約 (+/-) 15%',
  'Around (+/-) 20%': '約 (+/-) 20%',
  'Around or more than (+/-) 25%': '約或多於 (+/-) 25%',
  'pirs-rpq-a8a1': '我無意承擔任何風險，如果投資價值下跌，我將會感到失望',
  'pirs-rpq-a8b1': '只要投資價值的波幅偏低，我願意接受較低的回報率',
  'pirs-rpq-a8c1': '我願意承擔中度風險。以取得較佳的回報率',
  'pirs-rpq-a8d1': '雖然以長期投資為主，但我仍然關注較⼤的波幅或損失',
  'pirs-rpq-a8e1': '我以長期投資為主，亦願意承擔較⾼波幅和風險，以取得較⾼回報',
  'Agriculture and Fisheries / Manufacturing': '漁農 / 製造 / 工業 / 批發 / 出入口人員',
  'Blacksmith, Toolmaker, Plumber, Welder, Stonecutter & Carver': '鐵匠、工具匠、水電工、焊工、石匠和雕刻人員',
  'Buyer, merchandiser': '採購員',
  'Carpenter, Wood Worker, Wood Preparation Worker, Logger, Forestry Worker': '木匠、木工、木材準備工人、伐木工、林業工人',
  'Crop & Farm Worker, Livestock Farmer, Other Agricultural Worker, Fisherman': '農作物和農場工人、畜牧農民、其他農業工人、漁夫',
  'Factory/Production supervisor/worker, Manual/Industrial Worker': '工廠/生產主管/工人、技工/工業工人',
  'Jewellery & Precious Metal Worker,  Metal Processor': '珠寶和貴金屬工人、金屬加工人員',
  'Machinery Fitter, Assembler': '機械鉗工、裝配員',
  'Printer & Related, Paper pulp & Paper Maker': '印刷及相關、紙漿和造紙人員',
  'Spinner, Weaver, Knitter, Dyer, Tanner, Fellmonger, Felt Dresser': '紡紗工、織工、針織工、染工、 製革工、皮革匠、毛氈製造',
  'Auctioneer': '拍賣',
  'Banking / Financial Services / Business Professionals': '銀行 / 金融服務 / 商業專業人士',
  'Accountant, auditor': '會計師、審計師',
  'Banker': '銀行家',
  'Business Executive, businessman, merchant': '企業主管，商務人員，商人',
  'Chief Executive Officer': '行政總裁',
  'Chief Officers: operations, legal, finance, technology, risk, internal audit, compliance, etc.': '主管人員：營運、法律、財務、科技、風險、內部審計、合規等',
  'Company Director / Chairman': '公司董事/主席',
  'Economist': '經濟學家',
  'Financial analyst, business analyst': '金融分析員、業務分析員',
  'Human resources personnel, recruiters': '人力資源人員、招聘人員',
  'Insurance agent/consultant, financial planner, real estate agent': '保險代理人/顧問、理財規劃師、房地產經紀',
  'Market Research Field Worker': '市場研究外勤人員',
  'Statistician, Actuaries': '統計學家、精算師',
  'Construction/Engineer': '建築 / 工程師',
  'Diplomatic Personnel': '外交人員',
  'Disciplined Services': '紀律部隊',
  'Domestic / Property Service': '家居 / 物業服務人員',
  'Government Officials': '政府官員',
  'Information Technology Professionals': '資訊科技專業人員',
  'Legal Professionals': '法律專業人員',
  'Judge, Magistrate, Judicial Officer': '法官、裁判官、司法官',
  'Solicitor, lawyer, legal service personnel, company secretary': '事務律師、律師、法律服務人員、公司秘書',
  'Life Science and Health Professionals': '醫療及保健專業人員',
  'Middle Management / Administrative / Clerical': '中層管理 / 行政 / 文員',
  'Money Changer': '貨幣兌換商',
  'Other Professionals': '其他專業人員',
  'Athlete, Sportsman': '體育、運動員',
  'Author, Critic, Journalist': '作家、評論家、記者',
  'Biologist, Zoologist & related': '生物學家、動物學家及相關人員',
  'Commercial Artist & Designer': '商業藝術家及設計師',
  'Film producer/technician and personnel': '電影製片人/技術人員及員工',
  'Geologist, Miner & Quarryman': '地質工作人員、礦工和採石工',
  'Glass/Potter & related maker': '玻璃/陶藝及相關製造人員',
  'Medicine sales representative': '醫藥營業人員',
  'Other Production Worker': '其他生產工人',
  'Performers': '表演者',
  'Petroleum Refining Worker': '煉油工人',
  'Promoter, Demonstrator': '宣傳員、示範員',
  'Rubber & Oil palm Processor, Rubber & Plastic Product Maker': '橡膠和油棕處理人員、 橡塑和塑料製品製造人員',
  'Sculptor, Painter, Artist': '雕塑家、畫家、藝術家',
  'Shoe Maker & Leather Goods Maker': '鞋匠和皮革製品製造人員',
  'Still & Movie Cameraman': '靜態及電影攝影師',
  'Street/News Vendor, Hawker': '街道/報紙攤檔、小販',
  'Tobacco Preparer & Cigar Maker': '煙草製造和雪茄製造人員',
  'Upholsterer': '裝潢師',
  'Personal Care Services': '個人護理服務',
  'Retail / Catering / Cleaning / Food Service Worker': '零售/ 餐飲 / 清潔 / 食品服務人員',
  'Retiree': '退休人士',
  'Social Worker and Religious Services': '社會工作和宗教服務',
  'Student': '學生',
  'Teacher / Education Professional': '教師 / 專業教育人員',
  'Transportation / Logistics / Hospitality / Tourism': '運輸 / 物流 / 接待 / 旅遊工作人員',
  'Unemployed': '待業',
  'Positive number only': '請輸入正確數字',
  'MPF Client Confirmation': '強積金客戶確認書',
  'Important Note': '重要事項',

  'Please drag & drop files here, or click to select files': '請將文件拖放到此處，或點擊以選取檔案',
  'Please click': '請點擊',
  'Important note about nationality proof': '有關國籍證明的重要提示',
  'IPA – nationality proof': 'IPA-國籍證明',
  'nationality-proof-notice-body': '如所申報之國籍與提交的身份證明文件上之資料不相符，客戶必須另外附上附有相片的國籍證明副本。請將相關文件電郵至 <1>{{email}}</1>並註明「{{subject}}」。'
}
