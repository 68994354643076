import { number, string, object, lazy } from 'yup'
import mapValues from 'lodash/mapValues'
import { _validInvestmentDistribution } from 'utils/validateInvestmentDistribution'
import i18n from '../i18n'

let investmentMandateSchema: any = lazy((obj: any) =>
  object(
    mapValues(obj, (value, key) => {
      //console.log('investmentMandateSchema', key, value)
      if (key === 'totalInput') {
        return number().test(
          'is-0-or-100',
          i18n.t('The total must be 0 % or 100%'),
          (value) => value === 0 || value === 100,
        )
      } else {
        const result = string().test(
          'is-0-100',
          i18n.t('Please input an integer between 0 to 100'),
          (value) => _validInvestmentDistribution(value),
        )

        return result
      }
    }),
  ),
)

let uploadDocumentSchema: any = lazy((obj: any) =>
  object(
    mapValues(obj, (value, key) => {
      const msg = i18n.t('Please upload the ID / Passport copy')
      return string().required(msg)
    }),
  ),
)

export default {
  // CUSTOMER_INFORMATION_STEP: object().shape({}),
  // IDENTITY_UPLOAD: object().shape({
  //   isS800Customer: string(),
  //   identityDocumentId: string().required(i18n.t('Please upload the ID / Passport copy')),
  //   // identityDocumentId: string().when('isS800Customer', {
  //   //   is: 'N',
  //   //   then: string().required(),
  //   // }),
  // }),
  INVESTMENT_MANDATE: investmentMandateSchema,
  // INVESTMENT_MANDATE_VOLUNTEER: investmentMandateSchema,
  UPLOAD_DOCUMENT: uploadDocumentSchema,
}
