import React from "react";
import { Container, Typography } from '@material-ui/core'

function Error404({ location }) {
  return (
    <Container maxWidth="md">
      <Typography>404 Not Found: {location.pathname}</Typography>
    </Container>
  )
}

export default Error404
