// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * PA Consolidation API
 * PA Consolidation API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


 import * as globalImportUrl from 'url';
 import { Configuration } from './configuration';
 import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
 // Some imports not used depending on template conditions
 // @ts-ignore
 import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';
 
 /**
  * 
  * @export
  * @interface AgentInformationDto
  */
 export interface AgentInformationDto {
     /**
      * The unit code of the agent
      * @type {string}
      * @memberof AgentInformationDto
      */
     unitCode: string;
     /**
      * The source code of the agent
      * @type {string}
      * @memberof AgentInformationDto
      */
     sourceCode: string;
     /**
      * The agent code of the agent
      * @type {string}
      * @memberof AgentInformationDto
      */
     agentCode: string;
     /**
      * The name of the agent
      * @type {string}
      * @memberof AgentInformationDto
      */
     agentName: string;
     /**
      * The email of the agent
      * @type {string}
      * @memberof AgentInformationDto
      */
     agentEmail: string;
     /**
      * The mpfa license mumber of the agent
      * @type {string}
      * @memberof AgentInformationDto
      */
     mpfaLicenseNumber: string;
     /**
      * The contact mumber of the agent
      * @type {string}
      * @memberof AgentInformationDto
      */
     contactNumber: string;
     /**
      * Auto fill from ID token
      * @type {string}
      * @memberof AgentInformationDto
      */
     sfcLicenseNumber: string;
 }
 /**
  * 
  * @export
  * @interface AppendixtDto
  */
 export interface AppendixtDto {
     /**
      * The file name
      * @type {string}
      * @memberof AppendixtDto
      */
     fileName: string;
     /**
      * The image id
      * @type {string}
      * @memberof AppendixtDto
      */
     uploadId: string;
 }
 /**
  * 
  * @export
  * @interface ApplicationDto
  */
 export interface ApplicationDto {
     /**
      * The distribution channel code
      * @type {string}
      * @memberof ApplicationDto
      */
     distributionChannelCode: ApplicationDtoDistributionChannelCodeEnum;
     /**
      * locale
      * @type {string}
      * @memberof ApplicationDto
      */
     locale: ApplicationDtoLocaleEnum;
     /**
      * 
      * @type {CrsInformationDto}
      * @memberof ApplicationDto
      */
     crsInformation: CrsInformationDto;
     /**
      * 
      * @type {ClientInformationDto}
      * @memberof ApplicationDto
      */
     clientInformation: ClientInformationDto;
     /**
      * 
      * @type {AxaRegulatedActivitiesDto}
      * @memberof ApplicationDto
      */
     axaRegulatedActivities: AxaRegulatedActivitiesDto;
     /**
      * 
      * @type {AxaRegulatedActivitiesDto | FTLifeRegulatedActivitiesDto | PirsRegulatedActivitiesDto}
      * @memberof ApplicationDto
      */
     regulatedActivities: AxaRegulatedActivitiesDto | FTLifeRegulatedActivitiesDto | PirsRegulatedActivitiesDto;
     /**
      * The uuid of the document image file
      * @type {string}
      * @memberof ApplicationDto
      */
     identityDocumentId: string;
     /**
      * The investment distribution of the mandatory
      * @type {object}
      * @memberof ApplicationDto
      */
     investmentMandateMandatory: object;
     /**
      * The investment distribution of the volunteer
      * @type {object}
      * @memberof ApplicationDto
      */
     investmentMandateVolunteer: object;
     /**
      * The documents uploaded
      * @type {Array<UploadDocumentDto>}
      * @memberof ApplicationDto
      */
     uploadDocuments: Array<UploadDocumentDto>;
     /**
      * The appendices uploaded
      * @type {Array<AppendixtDto>}
      * @memberof ApplicationDto
      */
     appendices: Array<AppendixtDto>;
     /**
      * Extra info from summary page
      * @type {ChannelExtraInfoDto}
      * @memberof ApplicationDto
      */
     channelExtraInfo: ChannelExtraInfoDto;
     /**
      * The list of consolidated accounts
      * @type {Array<ConsolidatedAccountDto>}
      * @memberof ApplicationDto
      */
     consolidatedAccounts: Array<ConsolidatedAccountDto>;
     /**
      * Is remote signing created for this application?
      * @type {boolean}
      * @memberof ApplicationDto
      */
     isRemoteSigning: boolean;
     /**
      * The status of the remote signing
      * @type {RemoteSigningStatusDto}
      * @memberof ApplicationDto
      */
     remoteSigningStatus: RemoteSigningStatusDto;
     /**
      * The adobeSignAgreementId of the application
      * @type {string}
      * @memberof ApplicationDto
      */
     adobeSignAgreementId: string;
     /**
      * RPQ of PIRS
      * @type {PirsRpqDto}
      * @memberof ApplicationDto
      */
     pirsRpq: PirsRpqDto;
     /**
      * FNA of PIRS
      * @type {PirsFnaDto}
      * @memberof ApplicationDto
      */
     pirsFna: PirsFnaDto;
     /**
      * Summary of PIRS
      * @type {PirsSummaryDto}
      * @memberof ApplicationDto
      */
     pirsSummary: PirsSummaryDto;
     /**
      * Investment advice of PIRS
      * @type {PirsInvestmentAdviceDto}
      * @memberof ApplicationDto
      */
     pirsInvestmentAdvice: PirsInvestmentAdviceDto;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum ApplicationDtoDistributionChannelCodeEnum {
     AXA = 'AXA',
     FTLIFE = 'FTLIFE',
     CONVOY = 'CONVOY',
     IPP = 'IPP',
     MIDLAND = 'MIDLAND',
     COREPACIFIC = 'CORE PACIFIC',
     NOBLEAPEX = 'NOBLE APEX',
     IPG = 'IPG',
     AMG = 'AMG',
     CIS = 'CIS',
     KGI = 'KGI',
     AMPLE = 'AMPLE',
     COMPASS = 'COMPASS',
     HKAIC = 'HKAIC',
     GRANDNORM = 'GRANDNORM',
     GCWRM = 'GCWRM',
     DC = 'DC',
     CHANNEL8 = 'CHANNEL 8',
     DYNASTY = 'DYNASTY',
     FRONTIER = 'FRONTIER',
     CENTALINE = 'CENTALINE',
     KINGKEY = 'KINGKEY',
     AALTD = 'AALTD',
     HTINTL = 'HTINTL',
     RMINSUR = 'RMINSUR',
     STORFIELD = 'STORFIELD',
     TRANSPAC = 'TRANSPAC',
     WISEINSUR = 'WISEINSUR',
     ABLTD = 'ABLTD',
     IFAST = 'IFAST',
     MR = 'MR',
     CXA = 'CXA',
     TWHK = 'TWHK',
     OHWM = 'OHWM',
     IPPFA = 'IPPFA',
     TF = 'TF',
     BANK = 'BANK',
     KARL = 'KARL',
     CASH = 'CASH',
     CENTURYINV = 'CENTURYINV',
     EVERON = 'EVERON',
     SFIINS = 'SFIINS',
     MARCO = 'MARCO',
     NOVA = 'NOVA',
     TAIPING = 'TAIPING',
     ONEPLATFORM = 'ONEPLATFORM',
     RM = 'RM',
     SESG = 'SESG'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ApplicationDtoLocaleEnum {
     ZhHant = 'zh-Hant',
     En = 'en'
 }
 
 /**
  * 
  * @export
  * @interface ApplicationResponseDto
  */
 export interface ApplicationResponseDto {
     /**
      * The distribution channel code
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     distributionChannelCode: ApplicationResponseDtoDistributionChannelCodeEnum;
     /**
      * locale
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     locale: ApplicationResponseDtoLocaleEnum;
     /**
      * 
      * @type {CrsInformationDto}
      * @memberof ApplicationResponseDto
      */
     crsInformation: CrsInformationDto;
     /**
      * 
      * @type {ClientInformationDto}
      * @memberof ApplicationResponseDto
      */
     clientInformation: ClientInformationDto;
     /**
      * 
      * @type {AxaRegulatedActivitiesDto}
      * @memberof ApplicationResponseDto
      */
     axaRegulatedActivities: AxaRegulatedActivitiesDto;
     /**
      * 
      * @type {AxaRegulatedActivitiesDto | FTLifeRegulatedActivitiesDto | PirsRegulatedActivitiesDto}
      * @memberof ApplicationResponseDto
      */
     regulatedActivities: AxaRegulatedActivitiesDto | FTLifeRegulatedActivitiesDto | PirsRegulatedActivitiesDto;
     /**
      * The uuid of the document image file
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     identityDocumentId: string;
     /**
      * The investment distribution of the mandatory
      * @type {object}
      * @memberof ApplicationResponseDto
      */
     investmentMandateMandatory: object;
     /**
      * The investment distribution of the volunteer
      * @type {object}
      * @memberof ApplicationResponseDto
      */
     investmentMandateVolunteer: object;
     /**
      * The documents uploaded
      * @type {Array<UploadDocumentDto>}
      * @memberof ApplicationResponseDto
      */
     uploadDocuments: Array<UploadDocumentDto>;
     /**
      * The appendices uploaded
      * @type {Array<AppendixtDto>}
      * @memberof ApplicationResponseDto
      */
     appendices: Array<AppendixtDto>;
     /**
      * Extra info from summary page
      * @type {ChannelExtraInfoDto}
      * @memberof ApplicationResponseDto
      */
     channelExtraInfo: ChannelExtraInfoDto;
     /**
      * The list of consolidated accounts
      * @type {Array<ConsolidatedAccountDto>}
      * @memberof ApplicationResponseDto
      */
     consolidatedAccounts: Array<ConsolidatedAccountDto>;
     /**
      * Is remote signing created for this application?
      * @type {boolean}
      * @memberof ApplicationResponseDto
      */
     isRemoteSigning: boolean;
     /**
      * The status of the remote signing
      * @type {RemoteSigningStatusDto}
      * @memberof ApplicationResponseDto
      */
     remoteSigningStatus: RemoteSigningStatusDto;
     /**
      * The adobeSignAgreementId of the application
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     adobeSignAgreementId: string;
     /**
      * RPQ of PIRS
      * @type {PirsRpqDto}
      * @memberof ApplicationResponseDto
      */
     pirsRpq: PirsRpqDto;
     /**
      * FNA of PIRS
      * @type {PirsFnaDto}
      * @memberof ApplicationResponseDto
      */
     pirsFna: PirsFnaDto;
     /**
      * Summary of PIRS
      * @type {PirsSummaryDto}
      * @memberof ApplicationResponseDto
      */
     pirsSummary: PirsSummaryDto;
     /**
      * Investment advice of PIRS
      * @type {PirsInvestmentAdviceDto}
      * @memberof ApplicationResponseDto
      */
     pirsInvestmentAdvice: PirsInvestmentAdviceDto;
     /**
      * The application id
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     id: string;
     /**
      * The reference id of the application. Default is an object id, once submitted a reference id with format \"PYYYYMMDD####\" e.g. \"P201911170001\" will be assigned
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     referenceId: string;
     /**
      * The creation time of the application
      * @type {Date}
      * @memberof ApplicationResponseDto
      */
     createdTime: Date;
     /**
      * The last modification time of the application
      * @type {Date}
      * @memberof ApplicationResponseDto
      */
     lastModifiedTime: Date;
     /**
      * The submission time of the application
      * @type {Date}
      * @memberof ApplicationResponseDto
      */
     submittedTime: Date;
     /**
      * The status of the application
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     status: string;
     /**
      * Is it a pris application?
      * @type {boolean}
      * @memberof ApplicationResponseDto
      */
     isPirs: boolean;
     /**
      * The status of the PPO cases
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     ppoStatus: ApplicationResponseDtoPpoStatusEnum;
     /**
      * The remark of the PPO cases
      * @type {string}
      * @memberof ApplicationResponseDto
      */
     ppoRemark: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum ApplicationResponseDtoDistributionChannelCodeEnum {
     AXA = 'AXA',
     FTLIFE = 'FTLIFE',
     CONVOY = 'CONVOY',
     IPP = 'IPP',
     MIDLAND = 'MIDLAND',
     COREPACIFIC = 'CORE PACIFIC',
     NOBLEAPEX = 'NOBLE APEX',
     IPG = 'IPG',
     AMG = 'AMG',
     CIS = 'CIS',
     KGI = 'KGI',
     AMPLE = 'AMPLE',
     COMPASS = 'COMPASS',
     HKAIC = 'HKAIC',
     GRANDNORM = 'GRANDNORM',
     GCWRM = 'GCWRM',
     DC = 'DC',
     CHANNEL8 = 'CHANNEL 8',
     DYNASTY = 'DYNASTY',
     FRONTIER = 'FRONTIER',
     CENTALINE = 'CENTALINE',
     KINGKEY = 'KINGKEY',
     AALTD = 'AALTD',
     HTINTL = 'HTINTL',
     RMINSUR = 'RMINSUR',
     STORFIELD = 'STORFIELD',
     TRANSPAC = 'TRANSPAC',
     WISEINSUR = 'WISEINSUR',
     ABLTD = 'ABLTD',
     IFAST = 'IFAST',
     MR = 'MR',
     CXA = 'CXA',
     TWHK = 'TWHK',
     OHWM = 'OHWM',
     IPPFA = 'IPPFA',
     TF = 'TF',
     BANK = 'BANK',
     KARL = 'KARL',
     CASH = 'CASH',
     CENTURYINV = 'CENTURYINV',
     EVERON = 'EVERON',
     SFIINS = 'SFIINS',
     MARCO = 'MARCO',
     NOVA = 'NOVA',
     TAIPING = 'TAIPING',
     ONEPLATFORM = 'ONEPLATFORM',
     RM = 'RM',
     SESG = 'SESG'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ApplicationResponseDtoLocaleEnum {
     ZhHant = 'zh-Hant',
     En = 'en'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ApplicationResponseDtoPpoStatusEnum {
     CONFIRMED = 'CONFIRMED',
     REJECTED = 'REJECTED'
 }
 
 /**
  * 
  * @export
  * @interface ApprovalDto
  */
 export interface ApprovalDto {
     /**
      * The ids of the application for approval
      * @type {Array<string>}
      * @memberof ApprovalDto
      */
     ids: Array<string>;
     /**
      * The remark for the approval
      * @type {string}
      * @memberof ApprovalDto
      */
     remark: string;
 }
 /**
  * 
  * @export
  * @interface ArchiveRequestDto
  */
 export interface ArchiveRequestDto {
     /**
      * The list of the applications\' id
      * @type {Array<string>}
      * @memberof ArchiveRequestDto
      */
     ids: Array<string>;
     /**
      * The password for archive
      * @type {string}
      * @memberof ArchiveRequestDto
      */
     password: string;
 }
 /**
  * 
  * @export
  * @interface AxaRegulatedActivitiesDto
  */
 export interface AxaRegulatedActivitiesDto {
     /**
      * The reasons of the plan selection
      * @type {Array<string>}
      * @memberof AxaRegulatedActivitiesDto
      */
     planSelectionReasons: Array<AxaRegulatedActivitiesDtoPlanSelectionReasonsEnum>;
     /**
      * Is above primary education?
      * @type {string}
      * @memberof AxaRegulatedActivitiesDto
      */
     isAbovePrimaryEducation: AxaRegulatedActivitiesDtoIsAbovePrimaryEducationEnum;
     /**
      * Is able to read chinese or english?
      * @type {string}
      * @memberof AxaRegulatedActivitiesDto
      */
     isAbleToReadChineseOrEnglish: AxaRegulatedActivitiesDtoIsAbleToReadChineseOrEnglishEnum;
     /**
      * Is not disabled?
      * @type {string}
      * @memberof AxaRegulatedActivitiesDto
      */
     isNotDisabled: AxaRegulatedActivitiesDtoIsNotDisabledEnum;
     /**
      * The type of witness
      * @type {string}
      * @memberof AxaRegulatedActivitiesDto
      */
     witnessType: AxaRegulatedActivitiesDtoWitnessTypeEnum;
     /**
      * Witness Name
      * @type {string}
      * @memberof AxaRegulatedActivitiesDto
      */
     witnessName: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum AxaRegulatedActivitiesDtoPlanSelectionReasonsEnum {
     BRAND = 'BRAND',
     FUND = 'FUND',
     ACCOUNTCONSOLIDATION = 'ACCOUNTCONSOLIDATION',
     EXPERIENCE = 'EXPERIENCE',
     INTEGRATIONSERVICE = 'INTEGRATIONSERVICE',
     OTHERS = 'OTHERS'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum AxaRegulatedActivitiesDtoIsAbovePrimaryEducationEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum AxaRegulatedActivitiesDtoIsAbleToReadChineseOrEnglishEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum AxaRegulatedActivitiesDtoIsNotDisabledEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum AxaRegulatedActivitiesDtoWitnessTypeEnum {
     MYCOMPANION = 'MY_COMPANION',
     AXAREPRESENTATIVE = 'AXA_REPRESENTATIVE',
     NO = 'NO'
 }
 
 /**
  * 
  * @export
  * @interface ChannelExtraInfoDto
  */
 export interface ChannelExtraInfoDto {
     /**
      * Channel field for bank channel
      * @type {string}
      * @memberof ChannelExtraInfoDto
      */
     channel?: string;
     /**
      * Br Code field for bank channel
      * @type {string}
      * @memberof ChannelExtraInfoDto
      */
     brCode?: string;
     /**
      * RM field for bank channel
      * @type {string}
      * @memberof ChannelExtraInfoDto
      */
     rm?: string;
     /**
      * Code field for bank channel
      * @type {string}
      * @memberof ChannelExtraInfoDto
      */
     code?: string;
 }
 /**
  * 
  * @export
  * @interface ClientInformationDto
  */
 export interface ClientInformationDto {
     /**
      * Is S800 Customer
      * @type {string}
      * @memberof ClientInformationDto
      */
     isS800: ClientInformationDtoIsS800Enum;
     /**
      * Account Type
      * @type {string}
      * @memberof ClientInformationDto
      */
     accountType: ClientInformationDtoAccountTypeEnum;
     /**
      * Member Number
      * @type {string}
      * @memberof ClientInformationDto
      */
     memberNumber: string;
     /**
      * Contract Number
      * @type {string}
      * @memberof ClientInformationDto
      */
     contractNumber: string;
     /**
      * Title
      * @type {string}
      * @memberof ClientInformationDto
      */
     title: ClientInformationDtoTitleEnum;
     /**
      * First Name
      * @type {string}
      * @memberof ClientInformationDto
      */
     firstName: string;
     /**
      * Middle Name
      * @type {string}
      * @memberof ClientInformationDto
      */
     middleName: string;
     /**
      * Last Name
      * @type {string}
      * @memberof ClientInformationDto
      */
     lastName: string;
     /**
      * Chinese Last Name
      * @type {string}
      * @memberof ClientInformationDto
      */
     chineseFirstName: string;
     /**
      * Chinese First Name
      * @type {string}
      * @memberof ClientInformationDto
      */
     chineseLastName: string;
     /**
      * Identification Number
      * @type {string}
      * @memberof ClientInformationDto
      */
     identificationNumber: string;
     /**
      * Identification Type
      * @type {string}
      * @memberof ClientInformationDto
      */
     identificationType: string;
     /**
      * Nationality
      * @type {string}
      * @memberof ClientInformationDto
      */
     nationality: string;
     /**
      * Gender
      * @type {string}
      * @memberof ClientInformationDto
      */
     gender: ClientInformationDtoGenderEnum;
     /**
      * Email
      * @type {string}
      * @memberof ClientInformationDto
      */
     email: string;
     /**
      * Subscribe Ecommunication Service using the email address
      * @type {string}
      * @memberof ClientInformationDto
      */
     subscribeEcommunicationService: ClientInformationDtoSubscribeEcommunicationServiceEnum;
     /**
      * Subscribe Ecommunication Service using the SMS
      * @type {string}
      * @memberof ClientInformationDto
      */
     subscribeSmsService: ClientInformationDtoSubscribeSmsServiceEnum;
     /**
      * Date Of Birth(ISO 8601)
      * @type {string}
      * @memberof ClientInformationDto
      */
     dateOfBirth: string;
     /**
      * Mobile Country Code
      * @type {string}
      * @memberof ClientInformationDto
      */
     mobileCountryCode: string;
     /**
      * Mobile Number
      * @type {string}
      * @memberof ClientInformationDto
      */
     mobileNumber: string;
     /**
      * DaytimeContact Country Code
      * @type {string}
      * @memberof ClientInformationDto
      */
     daytimeContactCountryCode: string;
     /**
      * Daytime Contact Number
      * @type {string}
      * @memberof ClientInformationDto
      */
     daytimeContactNumber: string;
     /**
      * SMS Service Language(ISO 639-1)
      * @type {string}
      * @memberof ClientInformationDto
      */
     smsServiceLanguage: ClientInformationDtoSmsServiceLanguageEnum;
     /**
      * Nature Of Business
      * @type {number}
      * @memberof ClientInformationDto
      */
     natureOfBusiness: number;
     /**
      * Other Nature Of Business
      * @type {string}
      * @memberof ClientInformationDto
      */
     otherNatureOfBusiness: string;
     /**
      * Job title
      * @type {string}
      * @memberof ClientInformationDto
      */
     jobTitle: string;
     /**
      * Residential Address Area
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressArea: ClientInformationDtoResidentialAddressAreaEnum;
     /**
      * Residential Address Distinct
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressDistinct: string;
     /**
      * Residential Address Street
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressStreet: string;
     /**
      * Residential Address Street Number
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressStreetNumber: string;
     /**
      * Residential Address Estate
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressEstate: string;
     /**
      * Residential Address Building
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressBuilding: string;
     /**
      * Residential Address Block
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressBlock: string;
     /**
      * Residential Address Floor
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressFloor: string;
     /**
      * Residential Address Room
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressRoom: string;
     /**
      * Residential Address ZipCode
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressZipCode: string;
     /**
      * Residential Address City
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressCity: string;
     /**
      * Residential Address State
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressState: string;
     /**
      * Residential Address Country
      * @type {string}
      * @memberof ClientInformationDto
      */
     residentialAddressCountry: string;
     /**
      * Is mail address different from the residential address
      * @type {string}
      * @memberof ClientInformationDto
      */
     isMailAddressDifferent: ClientInformationDtoIsMailAddressDifferentEnum;
     /**
      * Mailing Address Area
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressArea: ClientInformationDtoMailAddressAreaEnum;
     /**
      * Mailing Address Distinct
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressDistinct: string;
     /**
      * Mailing Address Street
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressStreet: string;
     /**
      * Mailing Address Street Number
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressStreetNumber: string;
     /**
      * Mailing Address Estate
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressEstate: string;
     /**
      * Mailing Address Building
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressBuilding: string;
     /**
      * Mailing Address Block
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressBlock: string;
     /**
      * Mailing Address Floor
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressFloor: string;
     /**
      * Mailing Address Room
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressRoom: string;
     /**
      * Mailing Address ZipCode
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressZipCode: string;
     /**
      * Mailing Address City
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressCity: string;
     /**
      * Mailing Address State
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressState: string;
     /**
      * Mailing Address Country
      * @type {string}
      * @memberof ClientInformationDto
      */
     mailAddressCountry: string;
     /**
      * The place of birth - City
      * @type {string}
      * @memberof ClientInformationDto
      */
     placeOfBirthCity: string;
     /**
      * The place of birth - State
      * @type {string}
      * @memberof ClientInformationDto
      */
     placeOfBirthState: string;
     /**
      * The place of birth - Country
      * @type {string}
      * @memberof ClientInformationDto
      */
     placeOfBirthCountry: string;
     /**
      * The place of birth
      * @type {string}
      * @memberof ClientInformationDto
      */
     placeOfBirth: string;
     /**
      * User accept the terms regarding personal information collection
      * @type {string}
      * @memberof ClientInformationDto
      */
     termsAcceptCollect: ClientInformationDtoTermsAcceptCollectEnum;
     /**
      * The 3rd contact country code
      * @type {string}
      * @memberof ClientInformationDto
      */
     contact3CountryCode: string;
     /**
      * The 3rd contact number
      * @type {string}
      * @memberof ClientInformationDto
      */
     contact3Number: string;
     /**
      * The 4th contact country code
      * @type {string}
      * @memberof ClientInformationDto
      */
     contact4CountryCode: string;
     /**
      * The 4th contact number
      * @type {string}
      * @memberof ClientInformationDto
      */
     contact4Number: string;
     /**
      * The list of dependents
      * @type {Array<DependentDto>}
      * @memberof ClientInformationDto
      */
     dependents: Array<DependentDto>;
     /**
      * The education level for PIRS form
      * @type {string}
      * @memberof ClientInformationDto
      */
     educationLevel: ClientInformationDtoEducationLevelEnum;
     /**
      * Marital status
      * @type {string}
      * @memberof ClientInformationDto
      */
     maritalState: ClientInformationDtoMaritalStateEnum;
     /**
      * Occupation
      * @type {string}
      * @memberof ClientInformationDto
      */
     occupation: ClientInformationDtoOccupationEnum;
     /**
      * Name of employer
      * @type {string}
      * @memberof ClientInformationDto
      */
     employerName: string;
     /**
      * Address of business
      * @type {string}
      * @memberof ClientInformationDto
      */
     businessAddress: string;
     /**
      * Annual Income
      * @type {string}
      * @memberof ClientInformationDto
      */
     annualIncome: string;
     /**
      * Family status
      * @type {string}
      * @memberof ClientInformationDto
      */
     familyStatus: ClientInformationDtoFamilyStatusEnum;
     /**
      * The education level for normal form
      * @type {string}
      * @memberof ClientInformationDto
      */
     educationLevelAlpha: ClientInformationDtoEducationLevelAlphaEnum;
     /**
      * Are you an employee or director of a securities and futures commission licensee or registered person/institution?
      * @type {string}
      * @memberof ClientInformationDto
      */
     isSecuritiesDirector: ClientInformationDtoIsSecuritiesDirectorEnum;
     /**
      * Occupation, after 2022 Jan
      * @type {string}
      * @memberof ClientInformationDto
      */
     occupationV2Tier1: string;
     /**
      * Occupation, after 2022 Jan
      * @type {string}
      * @memberof ClientInformationDto
      */
     occupationV2Tier2: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoIsS800Enum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoAccountTypeEnum {
     CA = 'CA',
     PA = 'PA'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoTitleEnum {
     Mr = 'Mr',
     Mrs = 'Mrs',
     Ms = 'Ms',
     Miss = 'Miss'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoGenderEnum {
     M = 'M',
     F = 'F'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoSubscribeEcommunicationServiceEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoSubscribeSmsServiceEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoSmsServiceLanguageEnum {
     En = 'en',
     ZhHant = 'zh-Hant'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoResidentialAddressAreaEnum {
     HK = 'HK',
     KLN = 'KLN',
     NT = 'NT',
     Others = 'Others'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoIsMailAddressDifferentEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoMailAddressAreaEnum {
     HK = 'HK',
     KLN = 'KLN',
     NT = 'NT',
     Others = 'Others'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoTermsAcceptCollectEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoEducationLevelEnum {
     PRIMARY = 'PRIMARY',
     SECONDARY = 'SECONDARY',
     NONDEGREETERITARY = 'NON_DEGREE_TERITARY',
     DEGREEORABOVE = 'DEGREE_OR_ABOVE'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoMaritalStateEnum {
     SINGLE = 'SINGLE',
     MARRIED = 'MARRIED',
     WIDOWED = 'WIDOWED',
     DIVORCED = 'DIVORCED'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoOccupationEnum {
     FINANCE = 'FINANCE',
     ENGINEERS = 'ENGINEERS',
     PROFESSIONALS = 'PROFESSIONALS',
     CUSTOMERSERVICES = 'CUSTOMERSERVICES',
     INFORMATIONTECH = 'INFORMATIONTECH',
     HOUSEWIFE = 'HOUSEWIFE',
     RETIRED = 'RETIRED',
     UNEMPLOYMENT = 'UNEMPLOYMENT',
     OTHERS = 'OTHERS'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoFamilyStatusEnum {
     SINGLE = 'SINGLE',
     MARRIED = 'MARRIED'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoEducationLevelAlphaEnum {
     PRIMARY = 'PRIMARY',
     SECONDARY = 'SECONDARY',
     NONDEGREETERITARY = 'NON_DEGREE_TERITARY',
     DEGREEORABOVE = 'DEGREE_OR_ABOVE'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ClientInformationDtoIsSecuritiesDirectorEnum {
     Y = 'Y',
     N = 'N'
 }
 
 /**
  * 
  * @export
  * @interface ConsolidatedAccountDto
  */
 export interface ConsolidatedAccountDto {
     /**
      * The account number
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     accountNo: string;
     /**
      * The employer id
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     employerID: string;
     /**
      * The employer name
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     employerName: string;
     /**
      * has voluntary?
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     hasVoluntary: ConsolidatedAccountDtoHasVoluntaryEnum;
     /**
      * The scheme id e.g. 19700-4 for S800
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     scheme: string;
     /**
      * The trustee id e.g. 19700 for Principal
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     trustee: string;
     /**
      * The bank account holder
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     bankAccountHolder: string;
     /**
      * The bank code e.g. 004 for HSBC
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     bankCode: string;
     /**
      * The bank branch code
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     branchCode: string;
     /**
      * The bank name
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     bankName: string;
     /**
      * The transfer type
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     transferType: ConsolidatedAccountDtoTransferTypeEnum;
     /**
      * The voluntary arrangement
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     voluntaryArrange: ConsolidatedAccountDtoVoluntaryArrangeEnum;
     /**
      * The WITHDRAW_METHOD
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     withdrawMethod: ConsolidatedAccountDtoWithdrawMethodEnum;
     /**
      * The bank account number
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     bankAccountNumber: string;
     /**
      * Transfer from self employed account?
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     isSelfEmployedAccount: ConsolidatedAccountDtoIsSelfEmployedAccountEnum;
     /**
      * Self employed Details
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     selfEmployedDetails: ConsolidatedAccountDtoSelfEmployedDetailsEnum;
     /**
      * Payment until
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     payUntil: string;
     /**
      * Termination Date
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     terminationDate: string;
     /**
      * Transfer option
      * @type {string}
      * @memberof ConsolidatedAccountDto
      */
     transferOption: ConsolidatedAccountDtoTransferOptionEnum;
     /**
      * The option of partial transfer
      * @type {Array<string>}
      * @memberof ConsolidatedAccountDto
      */
     transferPartialOptions: Array<ConsolidatedAccountDtoTransferPartialOptionsEnum>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoHasVoluntaryEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoTransferTypeEnum {
     PA = 'PA',
     CA1 = 'CA_1',
     CA2 = 'CA_2'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoVoluntaryArrangeEnum {
     T = 'T',
     W = 'W'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoWithdrawMethodEnum {
     B = 'B',
     C = 'C'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoIsSelfEmployedAccountEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoSelfEmployedDetailsEnum {
     T = 'T',
     O = 'O'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoTransferOptionEnum {
     A = 'A',
     P = 'P'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum ConsolidatedAccountDtoTransferPartialOptionsEnum {
     M = 'M',
     V = 'V',
     AM = 'AM',
     AV = 'AV'
 }
 
 /**
  * 
  * @export
  * @interface CreateAgreementDto
  */
 export interface CreateAgreementDto {
     /**
      * 
      * @type {ApplicationDto}
      * @memberof CreateAgreementDto
      */
     applicationData: ApplicationDto;
     /**
      * The user inputted MpfaLicenseNumber
      * @type {string}
      * @memberof CreateAgreementDto
      */
     mpfaLicenseNumber: string;
     /**
      * The contact number of the agent
      * @type {string}
      * @memberof CreateAgreementDto
      */
     contactNumber: string;
     /**
      * The name of the agent
      * @type {string}
      * @memberof CreateAgreementDto
      */
     agentName: string;
     /**
      * Auto fill from ID token
      * @type {string}
      * @memberof CreateAgreementDto
      */
     sfcLicenseNumber: string;
 }
 /**
  * 
  * @export
  * @interface CrsInformationDto
  */
 export interface CrsInformationDto {
     /**
      * Hong Kong ONLY, with no tax residence in any other jurisdictions or countries
      * @type {string}
      * @memberof CrsInformationDto
      */
     isHkOnlyTaxResident: CrsInformationDtoIsHkOnlyTaxResidentEnum;
     /**
      * The list of jurisdiction of residence
      * @type {Array<CrsJurisdictionOfResidenceDto>}
      * @memberof CrsInformationDto
      */
     jurisdictions: Array<CrsJurisdictionOfResidenceDto>;
     /**
      * Current Residence Address or Mailing Address in CRS form
      * @type {string}
      * @memberof CrsInformationDto
      */
     residenceAddress: string;
     /**
      * If you are not a tax resident in the Jurisdiction of Current Residence Address or Mailing Address, please select one of the following reasons as applicable
      * @type {string}
      * @memberof CrsInformationDto
      */
     reasonOfDifferentAddress: CrsInformationDtoReasonOfDifferentAddressEnum;
     /**
      * An explanation to support the jurisdiction of tax residence
      * @type {string}
      * @memberof CrsInformationDto
      */
     reasonOfDifferentAddressOther: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum CrsInformationDtoIsHkOnlyTaxResidentEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum CrsInformationDtoReasonOfDifferentAddressEnum {
     STUDENT = 'STUDENT',
     EDUCATION = 'EDUCATION',
     FOREIGNER = 'FOREIGNER',
     TRANSPORTATION = 'TRANSPORTATION',
     OTHERS = 'OTHERS'
 }
 
 /**
  * 
  * @export
  * @interface CrsJurisdictionOfResidenceDto
  */
 export interface CrsJurisdictionOfResidenceDto {
     /**
      * Jurisdiction of Residence
      * @type {string}
      * @memberof CrsJurisdictionOfResidenceDto
      */
     jurisdictionOfResidence: string;
     /**
      * Tin
      * @type {string}
      * @memberof CrsJurisdictionOfResidenceDto
      */
     tin: string;
     /**
      * Hong Kong ONLY, with no tax residence in any other jurisdictions or countries
      * @type {string}
      * @memberof CrsJurisdictionOfResidenceDto
      */
     reasonForNoTin: CrsJurisdictionOfResidenceDtoReasonForNoTinEnum;
     /**
      * Explain why the account holder is unable to obtain a TIN if you have selected Reason B
      * @type {string}
      * @memberof CrsJurisdictionOfResidenceDto
      */
     explanationForUnableToObtainTin: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum CrsJurisdictionOfResidenceDtoReasonForNoTinEnum {
     A = 'A',
     B = 'B',
     C = 'C'
 }
 
 /**
  * 
  * @export
  * @interface DependentDto
  */
 export interface DependentDto {
     /**
      * The name of the dependent
      * @type {string}
      * @memberof DependentDto
      */
     name: string;
     /**
      * The age of the dependent
      * @type {number}
      * @memberof DependentDto
      */
     age: number;
     /**
      * The relationship of the dependent
      * @type {string}
      * @memberof DependentDto
      */
     relationship: string;
 }
 /**
  * 
  * @export
  * @interface FTLifeRegulatedActivitiesDto
  */
 export interface FTLifeRegulatedActivitiesDto {
     /**
      * Is declaration accepted?
      * @type {string}
      * @memberof FTLifeRegulatedActivitiesDto
      */
     isDeclarationAccepted: FTLifeRegulatedActivitiesDtoIsDeclarationAcceptedEnum;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum FTLifeRegulatedActivitiesDtoIsDeclarationAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 
 /**
  * 
  * @export
  * @interface McQuestionDto
  */
 export interface McQuestionDto {
     /**
      * The question id
      * @type {string}
      * @memberof McQuestionDto
      */
     id: string;
     /**
      * The answer
      * @type {string}
      * @memberof McQuestionDto
      */
     answer: string;
 }
 /**
  * 
  * @export
  * @interface OtpResponseDto
  */
 export interface OtpResponseDto {
     /**
      * An one time password
      * @type {string}
      * @memberof OtpResponseDto
      */
     otp: string;
     /**
      * Expiration in seconds
      * @type {number}
      * @memberof OtpResponseDto
      */
     expirationInSeconds: number;
 }
 /**
  * 
  * @export
  * @interface PirsFnaDto
  */
 export interface PirsFnaDto {
     /**
      * Do you want to perform FNA?
      * @type {string}
      * @memberof PirsFnaDto
      */
     willDoFNA: PirsFnaDtoWillDoFNAEnum;
     /**
      * Do you need investment advice?
      * @type {string}
      * @memberof PirsFnaDto
      */
     willDoInvestAdvice: PirsFnaDtoWillDoInvestAdviceEnum;
     /**
      * Is RPQ important note accepted?
      * @type {string}
      * @memberof PirsFnaDto
      */
     isFnaImportantNoteAccepted: PirsFnaDtoIsFnaImportantNoteAcceptedEnum;
     /**
      * The source of funds
      * @type {Array<string>}
      * @memberof PirsFnaDto
      */
     sourceOfFund: Array<PirsFnaDtoSourceOfFundEnum>;
     /**
      * Other source of fund
      * @type {string}
      * @memberof PirsFnaDto
      */
     sourceOfFundOther: string;
     /**
      * Reason of deviate in any respect from FNA or RPQ
      * @type {string}
      * @memberof PirsFnaDto
      */
     deviateReason: string;
     /**
      * Total Liquid Assets
      * @type {string}
      * @memberof PirsFnaDto
      */
     totalLiquidAsset: string;
     /**
      * Annual Income
      * @type {string}
      * @memberof PirsFnaDto
      */
     annualIncome: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum PirsFnaDtoWillDoFNAEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsFnaDtoWillDoInvestAdviceEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsFnaDtoIsFnaImportantNoteAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsFnaDtoSourceOfFundEnum {
     SALARY = 'SALARY',
     OTHERINVESTMENT = 'OTHER_INVESTMENT',
     INCOME = 'INCOME',
     ACCUMULATIVESAVING = 'ACCUMULATIVE_SAVING',
     SAVING = 'SAVING',
     OTHERS = 'OTHERS'
 }
 
 /**
  * 
  * @export
  * @interface PirsInvestmentAdviceDto
  */
 export interface PirsInvestmentAdviceDto {
     /**
      * Existing pirs client
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     isPirs: PirsInvestmentAdviceDtoIsPirsEnum;
     /**
      * FNA submission date
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     fnaSubmissionDate: string;
     /**
      * Status of employment
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     employStatus: PirsInvestmentAdviceDtoEmployStatusEnum;
     /**
      * Is user agree to the important reminder for recommendations
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     isRecommendationAgreed: PirsInvestmentAdviceDtoIsRecommendationAgreedEnum;
     /**
      * Assumption - Annual interest rate of bank savings
      * @type {number}
      * @memberof PirsInvestmentAdviceDto
      */
     annualInterest: number;
     /**
      * Assumption - Average inflation rate (Yearly)
      * @type {number}
      * @memberof PirsInvestmentAdviceDto
      */
     yearlyInflation: number;
     /**
      * Existing holdings
      * @type {Array<PirsInvestmentExistHoldingDto>}
      * @memberof PirsInvestmentAdviceDto
      */
     existHoldings: Array<PirsInvestmentExistHoldingDto>;
     /**
      * Holdings Recommendation
      * @type {Array<PirsInvestmentRecommendHoldingDto>}
      * @memberof PirsInvestmentAdviceDto
      */
     recommendHoldings: Array<PirsInvestmentRecommendHoldingDto>;
     /**
      * Holdings after evaluation
      * @type {Array<PirsInvestmentHoldingEvaluationDto>}
      * @memberof PirsInvestmentAdviceDto
      */
     holdingsEvaluation: Array<PirsInvestmentHoldingEvaluationDto>;
     /**
      * Portfolio weighted average risk level
      * @type {number}
      * @memberof PirsInvestmentAdviceDto
      */
     riskLevel: number;
     /**
      * Reasons of recommendations
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     recommendReason: string;
     /**
      * Material queries raised by client
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     materialQuery: string;
     /**
      * Responses by investment consultant
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     materialResponse: string;
     /**
      * Client declaration is accepted
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     isClientDeclareAccepted: PirsInvestmentAdviceDtoIsClientDeclareAcceptedEnum;
     /**
      * Investment consultant declaration accepted
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     isAgentDeclareAccepted: PirsInvestmentAdviceDtoIsAgentDeclareAcceptedEnum;
     /**
      * Personal information collection accepted
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     isClientRecordAccepted: PirsInvestmentAdviceDtoIsClientRecordAcceptedEnum;
     /**
      * Client\'s remarks in the modal box of client\'s declaration
      * @type {string}
      * @memberof PirsInvestmentAdviceDto
      */
     clientRemark: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum PirsInvestmentAdviceDtoIsPirsEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsInvestmentAdviceDtoEmployStatusEnum {
     Employed = 'Employed',
     SelfEmployed = 'Self-employed',
     Unemployed = 'Unemployed',
     Others = 'Others'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsInvestmentAdviceDtoIsRecommendationAgreedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsInvestmentAdviceDtoIsClientDeclareAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsInvestmentAdviceDtoIsAgentDeclareAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsInvestmentAdviceDtoIsClientRecordAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 
 /**
  * 
  * @export
  * @interface PirsInvestmentExistHoldingDto
  */
 export interface PirsInvestmentExistHoldingDto {
     /**
      * Holding Name
      * @type {string}
      * @memberof PirsInvestmentExistHoldingDto
      */
     holdingName: string;
     /**
      * Code
      * @type {string}
      * @memberof PirsInvestmentExistHoldingDto
      */
     code: string;
     /**
      * Amount
      * @type {number}
      * @memberof PirsInvestmentExistHoldingDto
      */
     amount: number;
     /**
      * Weight in percentage
      * @type {number}
      * @memberof PirsInvestmentExistHoldingDto
      */
     weight: number;
 }
 /**
  * 
  * @export
  * @interface PirsInvestmentHoldingEvaluationDto
  */
 export interface PirsInvestmentHoldingEvaluationDto {
     /**
      * Holding Name
      * @type {string}
      * @memberof PirsInvestmentHoldingEvaluationDto
      */
     holdingName: string;
     /**
      * Code
      * @type {string}
      * @memberof PirsInvestmentHoldingEvaluationDto
      */
     code: string;
     /**
      * Risk level
      * @type {number}
      * @memberof PirsInvestmentHoldingEvaluationDto
      */
     riskLvl: number;
     /**
      * Amount
      * @type {number}
      * @memberof PirsInvestmentHoldingEvaluationDto
      */
     amount: number;
     /**
      * Weight in percentage
      * @type {number}
      * @memberof PirsInvestmentHoldingEvaluationDto
      */
     weight: number;
 }
 /**
  * 
  * @export
  * @interface PirsInvestmentRecommendHoldingDto
  */
 export interface PirsInvestmentRecommendHoldingDto {
     /**
      * Holding Name
      * @type {string}
      * @memberof PirsInvestmentRecommendHoldingDto
      */
     holdingName: string;
     /**
      * Code
      * @type {string}
      * @memberof PirsInvestmentRecommendHoldingDto
      */
     code: string;
     /**
      * Recommended Actions
      * @type {string}
      * @memberof PirsInvestmentRecommendHoldingDto
      */
     action: PirsInvestmentRecommendHoldingDtoActionEnum;
     /**
      * Amount
      * @type {number}
      * @memberof PirsInvestmentRecommendHoldingDto
      */
     amount: number;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum PirsInvestmentRecommendHoldingDtoActionEnum {
     Buy = 'buy',
     Sell = 'sell',
     Hold = 'hold'
 }
 
 /**
  * 
  * @export
  * @interface PirsRegulatedActivitiesDto
  */
 export interface PirsRegulatedActivitiesDto {
     /**
      * Is personal information collection statement accepted?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isPersonalInformationCollectionStatementAccepted: PirsRegulatedActivitiesDtoIsPersonalInformationCollectionStatementAcceptedEnum;
     /**
      * Is appointment of investment & retirement consultant accepted?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isAppointmentOfInvestmentAndRetirementConsultantAccepted: PirsRegulatedActivitiesDtoIsAppointmentOfInvestmentAndRetirementConsultantAcceptedEnum;
     /**
      * Is client confirmation accepted?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isClientConfirmationAccepted: PirsRegulatedActivitiesDtoIsClientConfirmationAcceptedEnum;
     /**
      * Is FNA chosen to complete?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isFNA: PirsRegulatedActivitiesDtoIsFNAEnum;
     /**
      * Is advice received during the sales process?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isAdviceReceived: PirsRegulatedActivitiesDtoIsAdviceReceivedEnum;
     /**
      * The advices received during the sales process
      * @type {Array<string>}
      * @memberof PirsRegulatedActivitiesDto
      */
     advices: Array<PirsRegulatedActivitiesDtoAdvicesEnum>;
     /**
      * Is the client received comparison between the schemes and funds involved in the sales process?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isSchemeAndFundCompared: PirsRegulatedActivitiesDtoIsSchemeAndFundComparedEnum;
     /**
      * Is the client able to make that material decision?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isDecisionDeclarationAccepted: PirsRegulatedActivitiesDtoIsDecisionDeclarationAcceptedEnum;
     /**
      * Education level
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     educationLevel: PirsRegulatedActivitiesDtoEducationLevelEnum;
     /**
      * Is the client visually or otherwise impaired?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isDisabled: PirsRegulatedActivitiesDtoIsDisabledEnum;
     /**
      * The participant(s) in the sales process
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     salesProcessParticipant: PirsRegulatedActivitiesDtoSalesProcessParticipantEnum;
     /**
      * Is Confirmation on Transfer Out of Guaranteed Fund accepted?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isTransferOfGuaranteedFundAccepted: PirsRegulatedActivitiesDtoIsTransferOfGuaranteedFundAcceptedEnum;
     /**
      * Is client agreement accepted?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isClientAgreementAccepted: PirsRegulatedActivitiesDtoIsClientAgreementAcceptedEnum;
     /**
      * Refuse to use personal data in direct marketing serivces
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isRefusePromo: PirsRegulatedActivitiesDtoIsRefusePromoEnum;
     /**
      * Rationale for understanding the advice in detail
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     othersDesc: string;
     /**
      * Is applicant accepted risk disclosure statement?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isRiskDisclosureStatApplicantAccepted: PirsRegulatedActivitiesDtoIsRiskDisclosureStatApplicantAcceptedEnum;
     /**
      * Is agent accepted risk disclosure statement?
      * @type {string}
      * @memberof PirsRegulatedActivitiesDto
      */
     isRiskDisclosureStatAgentAccepted: PirsRegulatedActivitiesDtoIsRiskDisclosureStatAgentAcceptedEnum;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsPersonalInformationCollectionStatementAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsAppointmentOfInvestmentAndRetirementConsultantAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsClientConfirmationAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsFNAEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsAdviceReceivedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoAdvicesEnum {
     SCHEMEANDFUND = 'SCHEME_AND_FUND',
     FUNDMANAGEMENTFEES = 'FUND_MANAGEMENT_FEES',
     COMPREHENSIVESERVICE = 'COMPREHENSIVE_SERVICE',
     OTHERS = 'OTHERS'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsSchemeAndFundComparedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsDecisionDeclarationAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoEducationLevelEnum {
     PRIMARYSCHOOLORBELOW = 'PRIMARY_SCHOOL_OR_BELOW',
     SECONDARYSCHOOL = 'SECONDARY_SCHOOL',
     TERTIARYORABOVE = 'TERTIARY_OR_ABOVE'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsDisabledEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoSalesProcessParticipantEnum {
     FRIEND = 'WITH_FRIEND',
     STAFF = 'WITH_STAFF',
     FRIENDANDSTAFF = 'WITH_FRIEND_AND_STAFF',
     MYSELF = 'WITH_MYSELF'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsTransferOfGuaranteedFundAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsClientAgreementAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsRefusePromoEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsRiskDisclosureStatApplicantAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRegulatedActivitiesDtoIsRiskDisclosureStatAgentAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 
 /**
  * 
  * @export
  * @interface PirsRpqDto
  */
 export interface PirsRpqDto {
     /**
      * Is RPQ important note accepted?
      * @type {string}
      * @memberof PirsRpqDto
      */
     isRpqImportantNoteAccepted: PirsRpqDtoIsRpqImportantNoteAcceptedEnum;
     /**
      * Is RPQ disclaimer note accepted?
      * @type {string}
      * @memberof PirsRpqDto
      */
     isRpqDisclaimerAccepted: PirsRpqDtoIsRpqDisclaimerAcceptedEnum;
     /**
      * Is Personal Account service only? (deprecated)
      * @type {string}
      * @memberof PirsRpqDto
      */
     isPersonalAccount: PirsRpqDtoIsPersonalAccountEnum;
     /**
      * Age group
      * @type {string}
      * @memberof PirsRpqDto
      */
     ageGroup: PirsRpqDtoAgeGroupEnum;
     /**
      * The list of questions
      * @type {Array<McQuestionDto>}
      * @memberof PirsRpqDto
      */
     questions: Array<McQuestionDto>;
     /**
      * Reason of deviate in any respect from above RPQ
      * @type {string}
      * @memberof PirsRpqDto
      */
     deviateReason: string;
     /**
      * Radio buttons for options of personal account service
      * @type {string}
      * @memberof PirsRpqDto
      */
     paService: PirsRpqDtoPaServiceEnum;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRpqDtoIsRpqImportantNoteAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRpqDtoIsRpqDisclaimerAcceptedEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRpqDtoIsPersonalAccountEnum {
     Y = 'Y',
     N = 'N'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRpqDtoAgeGroupEnum {
     ABOVE65 = 'AGE_ABOVE_65',
     _5665 = 'AGE_56_65',
     _4155 = 'AGE_41_55',
     _3040 = 'AGE_30_40',
     BELOW30 = 'AGE_BELOW_30'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PirsRpqDtoPaServiceEnum {
     PAWITHADVICE = 'PA_WITH_ADVICE',
     PAWITHOUTADVICE = 'PA_WITHOUT_ADVICE',
     VOLUNTARY = 'VOLUNTARY'
 }
 
 /**
  * 
  * @export
  * @interface PirsSummaryDto
  */
 export interface PirsSummaryDto {
     /**
      * Soft copy or hard copy
      * @type {string}
      * @memberof PirsSummaryDto
      */
     brochureType: string;
     /**
      * Name of other documents
      * @type {string}
      * @memberof PirsSummaryDto
      */
     others: string;
 }
 /**
  * 
  * @export
  * @interface PpoAdminSummaryResponseDto
  */
 export interface PpoAdminSummaryResponseDto {
     /**
      * The application id
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     id: string;
     /**
      * The creation time of the application
      * @type {Date}
      * @memberof PpoAdminSummaryResponseDto
      */
     createdTime: Date;
     /**
      * The submission time of the application
      * @type {Date}
      * @memberof PpoAdminSummaryResponseDto
      */
     submittedTime: Date;
     /**
      * The last modified time of the application
      * @type {Date}
      * @memberof PpoAdminSummaryResponseDto
      */
     lastModifiedTime: Date;
     /**
      * The ppo process(confirm/reject) time
      * @type {Date}
      * @memberof PpoAdminSummaryResponseDto
      */
     ppoProcessTime: Date;
     /**
      * The reference id of a submitted application
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     referenceId: string;
     /**
      * The client name
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     clientName: string;
     /**
      * 
      * @type {SubmissionSummaryDto}
      * @memberof PpoAdminSummaryResponseDto
      */
     submissionSummary: SubmissionSummaryDto;
     /**
      * 
      * @type {ClientInformationDto}
      * @memberof PpoAdminSummaryResponseDto
      */
     clientInformation: ClientInformationDto;
     /**
      * 
      * @type {AgentInformationDto}
      * @memberof PpoAdminSummaryResponseDto
      */
     agentInformation: AgentInformationDto;
     /**
      * The status of the application
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     status: PpoAdminSummaryResponseDtoStatusEnum;
     /**
      * The status of the PPO cases
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     ppoStatus: PpoAdminSummaryResponseDtoPpoStatusEnum;
     /**
      * The remark of the PPO cases
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     ppoRemark: string;
     /**
      * Number of PC Form
      * @type {number}
      * @memberof PpoAdminSummaryResponseDto
      */
     numOfPcForm: number;
     /**
      * Number of PM Form
      * @type {number}
      * @memberof PpoAdminSummaryResponseDto
      */
     numOfPmForm: number;
     /**
      * Number of PP Form
      * @type {number}
      * @memberof PpoAdminSummaryResponseDto
      */
     numOfPpForm: number;
     /**
      * (Room) A (Floor) 12 (Block) G (Building) XX House
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     address1: string;
     /**
      * (Estate) Tai Ko Shing (Street) 123 XX Street (District) Aberdeen
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     address2: string;
     /**
      * The investment Mandate(Mandatory)
      * @type {object}
      * @memberof PpoAdminSummaryResponseDto
      */
     investmentMandateMandatory: object;
     /**
      * The investment Mandate(Volunteer)
      * @type {object}
      * @memberof PpoAdminSummaryResponseDto
      */
     investmentMandateVolunteer: object;
     /**
      * The total score of the rpq
      * @type {number}
      * @memberof PpoAdminSummaryResponseDto
      */
     rpqScore: number;
     /**
      * The investment style according to the rpq score
      * @type {string}
      * @memberof PpoAdminSummaryResponseDto
      */
     rpqType: PpoAdminSummaryResponseDtoRpqTypeEnum;
     /**
      * The appendices uploaded
      * @type {Array<AppendixtDto>}
      * @memberof PpoAdminSummaryResponseDto
      */
     appendices: Array<AppendixtDto>;
     /**
      * Is remote signing created for this application?
      * @type {boolean}
      * @memberof PpoAdminSummaryResponseDto
      */
     isRemoteSigning: boolean;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum PpoAdminSummaryResponseDtoStatusEnum {
     DRAFT = 'DRAFT',
     SUBMITTED = 'SUBMITTED',
     PROCESSED = 'PROCESSED',
     REVIEWED = 'REVIEWED'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PpoAdminSummaryResponseDtoPpoStatusEnum {
     CONFIRMED = 'CONFIRMED',
     REJECTED = 'REJECTED'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum PpoAdminSummaryResponseDtoRpqTypeEnum {
     CONSERVATIVE = 'CONSERVATIVE',
     MODERATEONSERVATIVE = 'MODERATE_ONSERVATIVE',
     MODERATE = 'MODERATE',
     MODERATEAGGRESSIVE = 'MODERATE_AGGRESSIVE',
     AGGRESSIVE = 'AGGRESSIVE'
 }
 
 /**
  * 
  * @export
  * @interface RemoteSigningStatusDto
  */
 export interface RemoteSigningStatusDto {
     /**
      * The status of the remote signing
      * @type {string}
      * @memberof RemoteSigningStatusDto
      */
     status: RemoteSigningStatusDtoStatusEnum;
     /**
      * The reject reason
      * @type {string}
      * @memberof RemoteSigningStatusDto
      */
     rejectReason: RemoteSigningStatusDtoRejectReasonEnum;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RemoteSigningStatusDtoStatusEnum {
     REJECTED = 'REJECTED',
     PROCESSED = 'PROCESSED'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RemoteSigningStatusDtoRejectReasonEnum {
     NOTSIGNEDINHK = 'NOT_SIGNED_IN_HK'
 }
 
 /**
  * 
  * @export
  * @interface SubmissionResponseDto
  */
 export interface SubmissionResponseDto {
     /**
      * The reference id of a submitted application
      * @type {string}
      * @memberof SubmissionResponseDto
      */
     referenceId: string;
 }
 /**
  * 
  * @export
  * @interface SubmissionSummaryDto
  */
 export interface SubmissionSummaryDto {
     /**
      * Incoming Source of Application. Suggest to use \"eSubmission\", and add other name if there is new incoming source in the future
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     incomingSource: string;
     /**
      * The batch process date of the application
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     batchISODateStr: string;
     /**
      * The time which PPO receive the application
      * @type {Date}
      * @memberof SubmissionSummaryDto
      */
     ppoReceivedDate: Date;
     /**
      * MPF Plan Name
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     mpfPlanName: string;
     /**
      * Scheme Type. If the form received with AM-PA, then mark PA, otherwise, please leave this field blank. Remarks : we\'re given to understand that the application is only applicable to PA account at this stage, and Principal may need to add other scheme type if adding other type of account, e.g. SEP  in the future
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     schemeType: string;
     /**
      * Client Name
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     clientName: string;
     /**
      * Date of receiving client instruction marked on the RA form (mm/dd/yyyy)
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     clientInstructionReceivedDate: string;
     /**
      * If the response is \"No\" to any of the questions in part 3 - step 1 in RA form, then mark \"Y\", otherwise mark \"N\"
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     isVulnerableClient: string;
     /**
      * The submitted forms
      * @type {Array<string>}
      * @memberof SubmissionSummaryDto
      */
     forms: Array<SubmissionSummaryDtoFormsEnum>;
     /**
      * Is the agreement signed by client via SMS?
      * @type {string}
      * @memberof SubmissionSummaryDto
      */
     isClientSignedBySms: SubmissionSummaryDtoIsClientSignedBySmsEnum;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum SubmissionSummaryDtoFormsEnum {
     NBAPPFORMPaInputForm = 'NBAPPFORM_PaInputForm',
     AxaRegulatedActivityForm = 'AxaRegulatedActivityForm',
     MISCSCertifyTrueCopyByAagentForm = 'MISCS_CertifyTrueCopyByAagentForm',
     MBRUPDATEDisclosureForm = 'MBRUPDATE_DisclosureForm',
     ECommunicationForm = 'ECommunicationForm',
     MISCSIdentifyDocumentSignForm = 'MISCS_IdentifyDocumentSignForm',
     LegalPaAgreementForm = 'Legal_PaAgreementForm',
     PcForm = 'PcForm',
     FTLifeRegulatedActivityForm = 'FTLifeRegulatedActivityForm',
     FTLifeDisclosureForm = 'FTLife_DisclosureForm',
     AxaRemoteSigningDisclaimerForm = 'AxaRemoteSigningDisclaimerForm',
     MRClientAgreement = 'MRClientAgreement',
     MRConfirmationfromClient = 'MRConfirmationfromClient',
     MRPIRSAppointmentofInvestmentConsultant = 'MRPIRSAppointmentofInvestmentConsultant',
     MRPIRSClientConfirmation = 'MRPIRSClientConfirmation',
     MRPIRSDisclosureofSalesRelatedInformation = 'MRPIRSDisclosureofSalesRelatedInformation',
     MRPIRSFinancialNeedsAnaylsisForm = 'MRPIRSFinancialNeedsAnaylsisForm',
     MRPIRSRiskDisclosureStatement = 'MRPIRSRiskDisclosureStatement',
     MRPIRSRPQ = 'MRPIRSRPQ',
     MRPIRSInvestmentAdviceRecord = 'MRPIRSInvestmentAdviceRecord',
     MRPIRSInvestmentAdviceRecordSignPage = 'MRPIRSInvestmentAdviceRecordSignPage'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum SubmissionSummaryDtoIsClientSignedBySmsEnum {
     Y = 'Y',
     N = 'N'
 }
 
 /**
  * 
  * @export
  * @interface UploadDocumentDto
  */
 export interface UploadDocumentDto {
     /**
      * document type
      * @type {string}
      * @memberof UploadDocumentDto
      */
     type: UploadDocumentDtoTypeEnum;
     /**
      * The image id
      * @type {string}
      * @memberof UploadDocumentDto
      */
     imageId: string;
     /**
      * This is a free text input for the doucment type
      * @type {string}
      * @memberof UploadDocumentDto
      */
     otherValue: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum UploadDocumentDtoTypeEnum {
     IDENTITYDOCUMENT = 'IDENTITY_DOCUMENT',
     OTHERS = 'OTHERS'
 }
 
 
 /**
  * AdobeSignAgreementsApi - axios parameter creator
  * @export
  */
 export const AdobeSignAgreementsApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Get the eSign url of the provided agreement id
          * @param {string} adobeSignAgreementId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementControllerGetNextESignUrl(adobeSignAgreementId: string, options: any = {}): RequestArgs {
             // verify required parameter 'adobeSignAgreementId' is not null or undefined
             if (adobeSignAgreementId === null || adobeSignAgreementId === undefined) {
                 throw new RequiredError('adobeSignAgreementId','Required parameter adobeSignAgreementId was null or undefined when calling adobeSignAgreementControllerGetNextESignUrl.');
             }
             const localVarPath = `/adobeSignAgreements/{adobeSignAgreementId}/signingUrl`
                 .replace(`{${"adobeSignAgreementId"}}`, encodeURIComponent(String(adobeSignAgreementId)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get the status of the provided agreement id
          * @param {string} adobeSignAgreementId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementControllerGetStatus(adobeSignAgreementId: string, options: any = {}): RequestArgs {
             // verify required parameter 'adobeSignAgreementId' is not null or undefined
             if (adobeSignAgreementId === null || adobeSignAgreementId === undefined) {
                 throw new RequiredError('adobeSignAgreementId','Required parameter adobeSignAgreementId was null or undefined when calling adobeSignAgreementControllerGetStatus.');
             }
             const localVarPath = `/adobeSignAgreements/{adobeSignAgreementId}`
                 .replace(`{${"adobeSignAgreementId"}}`, encodeURIComponent(String(adobeSignAgreementId)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Generate an adobe sign agreement by providing application data
          * @param {CreateAgreementDto} createAgreementDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementsPost(createAgreementDto: CreateAgreementDto, options: any = {}): RequestArgs {
             // verify required parameter 'createAgreementDto' is not null or undefined
             if (createAgreementDto === null || createAgreementDto === undefined) {
                 throw new RequiredError('createAgreementDto','Required parameter createAgreementDto was null or undefined when calling adobeSignAgreementsPost.');
             }
             const localVarPath = `/adobeSignAgreements`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"CreateAgreementDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createAgreementDto !== undefined ? createAgreementDto : {}) : (createAgreementDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * AdobeSignAgreementsApi - functional programming interface
  * @export
  */
 export const AdobeSignAgreementsApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Get the eSign url of the provided agreement id
          * @param {string} adobeSignAgreementId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementControllerGetNextESignUrl(adobeSignAgreementId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
             const localVarAxiosArgs = AdobeSignAgreementsApiAxiosParamCreator(configuration).adobeSignAgreementControllerGetNextESignUrl(adobeSignAgreementId, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get the status of the provided agreement id
          * @param {string} adobeSignAgreementId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementControllerGetStatus(adobeSignAgreementId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
             const localVarAxiosArgs = AdobeSignAgreementsApiAxiosParamCreator(configuration).adobeSignAgreementControllerGetStatus(adobeSignAgreementId, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Generate an adobe sign agreement by providing application data
          * @param {CreateAgreementDto} createAgreementDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementsPost(createAgreementDto: CreateAgreementDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
             const localVarAxiosArgs = AdobeSignAgreementsApiAxiosParamCreator(configuration).adobeSignAgreementsPost(createAgreementDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * AdobeSignAgreementsApi - factory interface
  * @export
  */
 export const AdobeSignAgreementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Get the eSign url of the provided agreement id
          * @param {string} adobeSignAgreementId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementControllerGetNextESignUrl(adobeSignAgreementId: string, options?: any) {
             return AdobeSignAgreementsApiFp(configuration).adobeSignAgreementControllerGetNextESignUrl(adobeSignAgreementId, options)(axios, basePath);
         },
         /**
          * 
          * @summary Get the status of the provided agreement id
          * @param {string} adobeSignAgreementId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementControllerGetStatus(adobeSignAgreementId: string, options?: any) {
             return AdobeSignAgreementsApiFp(configuration).adobeSignAgreementControllerGetStatus(adobeSignAgreementId, options)(axios, basePath);
         },
         /**
          * 
          * @summary Generate an adobe sign agreement by providing application data
          * @param {CreateAgreementDto} createAgreementDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adobeSignAgreementsPost(createAgreementDto: CreateAgreementDto, options?: any) {
             return AdobeSignAgreementsApiFp(configuration).adobeSignAgreementsPost(createAgreementDto, options)(axios, basePath);
         },
     };
 };
 
 /**
  * AdobeSignAgreementsApi - object-oriented interface
  * @export
  * @class AdobeSignAgreementsApi
  * @extends {BaseAPI}
  */
 export class AdobeSignAgreementsApi extends BaseAPI {
     /**
      * 
      * @summary Get the eSign url of the provided agreement id
      * @param {string} adobeSignAgreementId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof AdobeSignAgreementsApi
      */
     public adobeSignAgreementControllerGetNextESignUrl(adobeSignAgreementId: string, options?: any) {
         return AdobeSignAgreementsApiFp(this.configuration).adobeSignAgreementControllerGetNextESignUrl(adobeSignAgreementId, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Get the status of the provided agreement id
      * @param {string} adobeSignAgreementId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof AdobeSignAgreementsApi
      */
     public adobeSignAgreementControllerGetStatus(adobeSignAgreementId: string, options?: any) {
         return AdobeSignAgreementsApiFp(this.configuration).adobeSignAgreementControllerGetStatus(adobeSignAgreementId, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Generate an adobe sign agreement by providing application data
      * @param {CreateAgreementDto} createAgreementDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof AdobeSignAgreementsApi
      */
     public adobeSignAgreementsPost(createAgreementDto: CreateAgreementDto, options?: any) {
         return AdobeSignAgreementsApiFp(this.configuration).adobeSignAgreementsPost(createAgreementDto, options)(this.axios, this.basePath);
     }
 
 }
 
 
 /**
  * ArchivesApi - axios parameter creator
  * @export
  */
 export const ArchivesApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary POST - Generate an OTP for downloading archive
          * @param {ArchiveRequestDto} archiveRequestDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         archivesOtpPost(archiveRequestDto: ArchiveRequestDto, options: any = {}): RequestArgs {
             // verify required parameter 'archiveRequestDto' is not null or undefined
             if (archiveRequestDto === null || archiveRequestDto === undefined) {
                 throw new RequiredError('archiveRequestDto','Required parameter archiveRequestDto was null or undefined when calling archivesOtpPost.');
             }
             const localVarPath = `/archives/otp`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"ArchiveRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(archiveRequestDto !== undefined ? archiveRequestDto : {}) : (archiveRequestDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get - Retrieve an archive
          * @param {string} otp 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         archivesPost(otp: string, options: any = {}): RequestArgs {
             // verify required parameter 'otp' is not null or undefined
             if (otp === null || otp === undefined) {
                 throw new RequiredError('otp','Required parameter otp was null or undefined when calling archivesPost.');
             }
             const localVarPath = `/archives`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (otp !== undefined) {
                 localVarQueryParameter['otp'] = otp;
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * ArchivesApi - functional programming interface
  * @export
  */
 export const ArchivesApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary POST - Generate an OTP for downloading archive
          * @param {ArchiveRequestDto} archiveRequestDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         archivesOtpPost(archiveRequestDto: ArchiveRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpResponseDto> {
             const localVarAxiosArgs = ArchivesApiAxiosParamCreator(configuration).archivesOtpPost(archiveRequestDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get - Retrieve an archive
          * @param {string} otp 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         archivesPost(otp: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
             const localVarAxiosArgs = ArchivesApiAxiosParamCreator(configuration).archivesPost(otp, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * ArchivesApi - factory interface
  * @export
  */
 export const ArchivesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary POST - Generate an OTP for downloading archive
          * @param {ArchiveRequestDto} archiveRequestDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         archivesOtpPost(archiveRequestDto: ArchiveRequestDto, options?: any) {
             return ArchivesApiFp(configuration).archivesOtpPost(archiveRequestDto, options)(axios, basePath);
         },
         /**
          * 
          * @summary Get - Retrieve an archive
          * @param {string} otp 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         archivesPost(otp: string, options?: any) {
             return ArchivesApiFp(configuration).archivesPost(otp, options)(axios, basePath);
         },
     };
 };
 
 /**
  * ArchivesApi - object-oriented interface
  * @export
  * @class ArchivesApi
  * @extends {BaseAPI}
  */
 export class ArchivesApi extends BaseAPI {
     /**
      * 
      * @summary POST - Generate an OTP for downloading archive
      * @param {ArchiveRequestDto} archiveRequestDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof ArchivesApi
      */
     public archivesOtpPost(archiveRequestDto: ArchiveRequestDto, options?: any) {
         return ArchivesApiFp(this.configuration).archivesOtpPost(archiveRequestDto, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Get - Retrieve an archive
      * @param {string} otp 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof ArchivesApi
      */
     public archivesPost(otp: string, options?: any) {
         return ArchivesApiFp(this.configuration).archivesPost(otp, options)(this.axios, this.basePath);
     }
 
 }
 
 
 /**
  * DraftsApi - axios parameter creator
  * @export
  */
 export const DraftsApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve draft applications
          * @param {'summary' | 'detail'} view 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsGet(view: 'summary' | 'detail', options: any = {}): RequestArgs {
             // verify required parameter 'view' is not null or undefined
             if (view === null || view === undefined) {
                 throw new RequiredError('view','Required parameter view was null or undefined when calling draftsGet.');
             }
             const localVarPath = `/drafts`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (view !== undefined) {
                 localVarQueryParameter['view'] = view;
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Retrieve a draft
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsIdGet(id: string, options: any = {}): RequestArgs {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling draftsIdGet.');
             }
             const localVarPath = `/drafts/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update a draft application by replacing the whole content
          * @param {string} id 
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsIdPut(id: string, applicationDto: ApplicationDto, options: any = {}): RequestArgs {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling draftsIdPut.');
             }
             // verify required parameter 'applicationDto' is not null or undefined
             if (applicationDto === null || applicationDto === undefined) {
                 throw new RequiredError('applicationDto','Required parameter applicationDto was null or undefined when calling draftsIdPut.');
             }
             const localVarPath = `/drafts/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"ApplicationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(applicationDto !== undefined ? applicationDto : {}) : (applicationDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create a draft application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsPost(applicationDto: ApplicationDto, options: any = {}): RequestArgs {
             // verify required parameter 'applicationDto' is not null or undefined
             if (applicationDto === null || applicationDto === undefined) {
                 throw new RequiredError('applicationDto','Required parameter applicationDto was null or undefined when calling draftsPost.');
             }
             const localVarPath = `/drafts`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"ApplicationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(applicationDto !== undefined ? applicationDto : {}) : (applicationDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * DraftsApi - functional programming interface
  * @export
  */
 export const DraftsApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve draft applications
          * @param {'summary' | 'detail'} view 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsGet(view: 'summary' | 'detail', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationResponseDto>> {
             const localVarAxiosArgs = DraftsApiAxiosParamCreator(configuration).draftsGet(view, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Retrieve a draft
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsIdGet(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseDto> {
             const localVarAxiosArgs = DraftsApiAxiosParamCreator(configuration).draftsIdGet(id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update a draft application by replacing the whole content
          * @param {string} id 
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsIdPut(id: string, applicationDto: ApplicationDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
             const localVarAxiosArgs = DraftsApiAxiosParamCreator(configuration).draftsIdPut(id, applicationDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create a draft application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsPost(applicationDto: ApplicationDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseDto> {
             const localVarAxiosArgs = DraftsApiAxiosParamCreator(configuration).draftsPost(applicationDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * DraftsApi - factory interface
  * @export
  */
 export const DraftsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Retrieve draft applications
          * @param {'summary' | 'detail'} view 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsGet(view: 'summary' | 'detail', options?: any) {
             return DraftsApiFp(configuration).draftsGet(view, options)(axios, basePath);
         },
         /**
          * 
          * @summary Retrieve a draft
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsIdGet(id: string, options?: any) {
             return DraftsApiFp(configuration).draftsIdGet(id, options)(axios, basePath);
         },
         /**
          * 
          * @summary Update a draft application by replacing the whole content
          * @param {string} id 
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsIdPut(id: string, applicationDto: ApplicationDto, options?: any) {
             return DraftsApiFp(configuration).draftsIdPut(id, applicationDto, options)(axios, basePath);
         },
         /**
          * 
          * @summary Create a draft application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         draftsPost(applicationDto: ApplicationDto, options?: any) {
             return DraftsApiFp(configuration).draftsPost(applicationDto, options)(axios, basePath);
         },
     };
 };
 
 /**
  * DraftsApi - object-oriented interface
  * @export
  * @class DraftsApi
  * @extends {BaseAPI}
  */
 export class DraftsApi extends BaseAPI {
     /**
      * 
      * @summary Retrieve draft applications
      * @param {'summary' | 'detail'} view 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DraftsApi
      */
     public draftsGet(view: 'summary' | 'detail', options?: any) {
         return DraftsApiFp(this.configuration).draftsGet(view, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Retrieve a draft
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DraftsApi
      */
     public draftsIdGet(id: string, options?: any) {
         return DraftsApiFp(this.configuration).draftsIdGet(id, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Update a draft application by replacing the whole content
      * @param {string} id 
      * @param {ApplicationDto} applicationDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DraftsApi
      */
     public draftsIdPut(id: string, applicationDto: ApplicationDto, options?: any) {
         return DraftsApiFp(this.configuration).draftsIdPut(id, applicationDto, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Create a draft application
      * @param {ApplicationDto} applicationDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DraftsApi
      */
     public draftsPost(applicationDto: ApplicationDto, options?: any) {
         return DraftsApiFp(this.configuration).draftsPost(applicationDto, options)(this.axios, this.basePath);
     }
 
 }
 
 
 /**
  * PirsesApi - axios parameter creator
  * @export
  */
 export const PirsesApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve pirs applications
          * @param {'summary' | 'detail'} view 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesGet(view: 'summary' | 'detail', options: any = {}): RequestArgs {
             // verify required parameter 'view' is not null or undefined
             if (view === null || view === undefined) {
                 throw new RequiredError('view','Required parameter view was null or undefined when calling pirsesGet.');
             }
             const localVarPath = `/pirses`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (view !== undefined) {
                 localVarQueryParameter['view'] = view;
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Retrieve a draft
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesIdGet(id: string, options: any = {}): RequestArgs {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling pirsesIdGet.');
             }
             const localVarPath = `/pirses/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update a pirs application by replacing the whole content
          * @param {string} id 
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesIdPut(id: string, applicationDto: ApplicationDto, options: any = {}): RequestArgs {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling pirsesIdPut.');
             }
             // verify required parameter 'applicationDto' is not null or undefined
             if (applicationDto === null || applicationDto === undefined) {
                 throw new RequiredError('applicationDto','Required parameter applicationDto was null or undefined when calling pirsesIdPut.');
             }
             const localVarPath = `/pirses/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"ApplicationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(applicationDto !== undefined ? applicationDto : {}) : (applicationDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create a pirs application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesPost(applicationDto: ApplicationDto, options: any = {}): RequestArgs {
             // verify required parameter 'applicationDto' is not null or undefined
             if (applicationDto === null || applicationDto === undefined) {
                 throw new RequiredError('applicationDto','Required parameter applicationDto was null or undefined when calling pirsesPost.');
             }
             const localVarPath = `/pirses`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"ApplicationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(applicationDto !== undefined ? applicationDto : {}) : (applicationDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * PirsesApi - functional programming interface
  * @export
  */
 export const PirsesApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve pirs applications
          * @param {'summary' | 'detail'} view 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesGet(view: 'summary' | 'detail', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationResponseDto>> {
             const localVarAxiosArgs = PirsesApiAxiosParamCreator(configuration).pirsesGet(view, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Retrieve a draft
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesIdGet(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseDto> {
             const localVarAxiosArgs = PirsesApiAxiosParamCreator(configuration).pirsesIdGet(id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update a pirs application by replacing the whole content
          * @param {string} id 
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesIdPut(id: string, applicationDto: ApplicationDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
             const localVarAxiosArgs = PirsesApiAxiosParamCreator(configuration).pirsesIdPut(id, applicationDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create a pirs application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesPost(applicationDto: ApplicationDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseDto> {
             const localVarAxiosArgs = PirsesApiAxiosParamCreator(configuration).pirsesPost(applicationDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * PirsesApi - factory interface
  * @export
  */
 export const PirsesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Retrieve pirs applications
          * @param {'summary' | 'detail'} view 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesGet(view: 'summary' | 'detail', options?: any) {
             return PirsesApiFp(configuration).pirsesGet(view, options)(axios, basePath);
         },
         /**
          * 
          * @summary Retrieve a draft
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesIdGet(id: string, options?: any) {
             return PirsesApiFp(configuration).pirsesIdGet(id, options)(axios, basePath);
         },
         /**
          * 
          * @summary Update a pirs application by replacing the whole content
          * @param {string} id 
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesIdPut(id: string, applicationDto: ApplicationDto, options?: any) {
             return PirsesApiFp(configuration).pirsesIdPut(id, applicationDto, options)(axios, basePath);
         },
         /**
          * 
          * @summary Create a pirs application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         pirsesPost(applicationDto: ApplicationDto, options?: any) {
             return PirsesApiFp(configuration).pirsesPost(applicationDto, options)(axios, basePath);
         },
     };
 };
 
 /**
  * PirsesApi - object-oriented interface
  * @export
  * @class PirsesApi
  * @extends {BaseAPI}
  */
 export class PirsesApi extends BaseAPI {
     /**
      * 
      * @summary Retrieve pirs applications
      * @param {'summary' | 'detail'} view 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof PirsesApi
      */
     public pirsesGet(view: 'summary' | 'detail', options?: any) {
         return PirsesApiFp(this.configuration).pirsesGet(view, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Retrieve a draft
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof PirsesApi
      */
     public pirsesIdGet(id: string, options?: any) {
         return PirsesApiFp(this.configuration).pirsesIdGet(id, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Update a pirs application by replacing the whole content
      * @param {string} id 
      * @param {ApplicationDto} applicationDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof PirsesApi
      */
     public pirsesIdPut(id: string, applicationDto: ApplicationDto, options?: any) {
         return PirsesApiFp(this.configuration).pirsesIdPut(id, applicationDto, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Create a pirs application
      * @param {ApplicationDto} applicationDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof PirsesApi
      */
     public pirsesPost(applicationDto: ApplicationDto, options?: any) {
         return PirsesApiFp(this.configuration).pirsesPost(applicationDto, options)(this.axios, this.basePath);
     }
 
 }
 
 
 /**
  * PpoAdminCasesApi - axios parameter creator
  * @export
  */
 export const PpoAdminCasesApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve submitted applications for ppo admin
          * @param {string} start 
          * @param {string} end 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesGet(start: string, end: string, options: any = {}): RequestArgs {
             // verify required parameter 'start' is not null or undefined
             if (start === null || start === undefined) {
                 throw new RequiredError('start','Required parameter start was null or undefined when calling ppoAdminCasesGet.');
             }
             // verify required parameter 'end' is not null or undefined
             if (end === null || end === undefined) {
                 throw new RequiredError('end','Required parameter end was null or undefined when calling ppoAdminCasesGet.');
             }
             const localVarPath = `/ppoAdminCases`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (start !== undefined) {
                 localVarQueryParameter['start'] = start;
             }
 
             if (end !== undefined) {
                 localVarQueryParameter['end'] = end;
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Approve submitted applications for ppo admin
          * @param {'CONFIRM' | 'REJECT'} ppoApprovalStatus 
          * @param {ApprovalDto} approvalDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesPatch(ppoApprovalStatus: 'CONFIRM' | 'REJECT', approvalDto: ApprovalDto, options: any = {}): RequestArgs {
             // verify required parameter 'ppoApprovalStatus' is not null or undefined
             if (ppoApprovalStatus === null || ppoApprovalStatus === undefined) {
                 throw new RequiredError('ppoApprovalStatus','Required parameter ppoApprovalStatus was null or undefined when calling ppoAdminCasesPatch.');
             }
             // verify required parameter 'approvalDto' is not null or undefined
             if (approvalDto === null || approvalDto === undefined) {
                 throw new RequiredError('approvalDto','Required parameter approvalDto was null or undefined when calling ppoAdminCasesPatch.');
             }
             const localVarPath = `/ppoAdminCases`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (ppoApprovalStatus !== undefined) {
                 localVarQueryParameter['ppoApprovalStatus'] = ppoApprovalStatus;
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"ApprovalDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(approvalDto !== undefined ? approvalDto : {}) : (approvalDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Generate Spreadsheet by providing applicatino Ids
          * @param {'ALL' | 'PENDING' | 'PROCESSED'} ppoViewStatus 
          * @param {'CSV' | 'XLSX'} spreadsheetExportType 
          * @param {Array<string>} requestBody 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesSpreadsheetPost(ppoViewStatus: 'ALL' | 'PENDING' | 'PROCESSED', spreadsheetExportType: 'CSV' | 'XLSX', requestBody: Array<string>, options: any = {}): RequestArgs {
             // verify required parameter 'ppoViewStatus' is not null or undefined
             if (ppoViewStatus === null || ppoViewStatus === undefined) {
                 throw new RequiredError('ppoViewStatus','Required parameter ppoViewStatus was null or undefined when calling ppoAdminCasesSpreadsheetPost.');
             }
             // verify required parameter 'spreadsheetExportType' is not null or undefined
             if (spreadsheetExportType === null || spreadsheetExportType === undefined) {
                 throw new RequiredError('spreadsheetExportType','Required parameter spreadsheetExportType was null or undefined when calling ppoAdminCasesSpreadsheetPost.');
             }
             // verify required parameter 'requestBody' is not null or undefined
             if (requestBody === null || requestBody === undefined) {
                 throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling ppoAdminCasesSpreadsheetPost.');
             }
             const localVarPath = `/ppoAdminCases`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (ppoViewStatus !== undefined) {
                 localVarQueryParameter['ppoViewStatus'] = ppoViewStatus;
             }
 
             if (spreadsheetExportType !== undefined) {
                 localVarQueryParameter['spreadsheetExportType'] = spreadsheetExportType;
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * PpoAdminCasesApi - functional programming interface
  * @export
  */
 export const PpoAdminCasesApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve submitted applications for ppo admin
          * @param {string} start 
          * @param {string} end 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesGet(start: string, end: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PpoAdminSummaryResponseDto>> {
             const localVarAxiosArgs = PpoAdminCasesApiAxiosParamCreator(configuration).ppoAdminCasesGet(start, end, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Approve submitted applications for ppo admin
          * @param {'CONFIRM' | 'REJECT'} ppoApprovalStatus 
          * @param {ApprovalDto} approvalDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesPatch(ppoApprovalStatus: 'CONFIRM' | 'REJECT', approvalDto: ApprovalDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
             const localVarAxiosArgs = PpoAdminCasesApiAxiosParamCreator(configuration).ppoAdminCasesPatch(ppoApprovalStatus, approvalDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Generate Spreadsheet by providing applicatino Ids
          * @param {'ALL' | 'PENDING' | 'PROCESSED'} ppoViewStatus 
          * @param {'CSV' | 'XLSX'} spreadsheetExportType 
          * @param {Array<string>} requestBody 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesSpreadsheetPost(ppoViewStatus: 'ALL' | 'PENDING' | 'PROCESSED', spreadsheetExportType: 'CSV' | 'XLSX', requestBody: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
             const localVarAxiosArgs = PpoAdminCasesApiAxiosParamCreator(configuration).ppoAdminCasesSpreadsheetPost(ppoViewStatus, spreadsheetExportType, requestBody, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * PpoAdminCasesApi - factory interface
  * @export
  */
 export const PpoAdminCasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Retrieve submitted applications for ppo admin
          * @param {string} start 
          * @param {string} end 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesGet(start: string, end: string, options?: any) {
             return PpoAdminCasesApiFp(configuration).ppoAdminCasesGet(start, end, options)(axios, basePath);
         },
         /**
          * 
          * @summary Approve submitted applications for ppo admin
          * @param {'CONFIRM' | 'REJECT'} ppoApprovalStatus 
          * @param {ApprovalDto} approvalDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesPatch(ppoApprovalStatus: 'CONFIRM' | 'REJECT', approvalDto: ApprovalDto, options?: any) {
             return PpoAdminCasesApiFp(configuration).ppoAdminCasesPatch(ppoApprovalStatus, approvalDto, options)(axios, basePath);
         },
         /**
          * 
          * @summary Generate Spreadsheet by providing applicatino Ids
          * @param {'ALL' | 'PENDING' | 'PROCESSED'} ppoViewStatus 
          * @param {'CSV' | 'XLSX'} spreadsheetExportType 
          * @param {Array<string>} requestBody 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         ppoAdminCasesSpreadsheetPost(ppoViewStatus: 'ALL' | 'PENDING' | 'PROCESSED', spreadsheetExportType: 'CSV' | 'XLSX', requestBody: Array<string>, options?: any) {
             return PpoAdminCasesApiFp(configuration).ppoAdminCasesSpreadsheetPost(ppoViewStatus, spreadsheetExportType, requestBody, options)(axios, basePath);
         },
     };
 };
 
 /**
  * PpoAdminCasesApi - object-oriented interface
  * @export
  * @class PpoAdminCasesApi
  * @extends {BaseAPI}
  */
 export class PpoAdminCasesApi extends BaseAPI {
     /**
      * 
      * @summary Retrieve submitted applications for ppo admin
      * @param {string} start 
      * @param {string} end 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof PpoAdminCasesApi
      */
     public ppoAdminCasesGet(start: string, end: string, options?: any) {
         return PpoAdminCasesApiFp(this.configuration).ppoAdminCasesGet(start, end, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Approve submitted applications for ppo admin
      * @param {'CONFIRM' | 'REJECT'} ppoApprovalStatus 
      * @param {ApprovalDto} approvalDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof PpoAdminCasesApi
      */
     public ppoAdminCasesPatch(ppoApprovalStatus: 'CONFIRM' | 'REJECT', approvalDto: ApprovalDto, options?: any) {
         return PpoAdminCasesApiFp(this.configuration).ppoAdminCasesPatch(ppoApprovalStatus, approvalDto, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Generate Spreadsheet by providing applicatino Ids
      * @param {'ALL' | 'PENDING' | 'PROCESSED'} ppoViewStatus 
      * @param {'CSV' | 'XLSX'} spreadsheetExportType 
      * @param {Array<string>} requestBody 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof PpoAdminCasesApi
      */
     public ppoAdminCasesSpreadsheetPost(ppoViewStatus: 'ALL' | 'PENDING' | 'PROCESSED', spreadsheetExportType: 'CSV' | 'XLSX', requestBody: Array<string>, options?: any) {
         return PpoAdminCasesApiFp(this.configuration).ppoAdminCasesSpreadsheetPost(ppoViewStatus, spreadsheetExportType, requestBody, options)(this.axios, this.basePath);
     }
 
 }
 
 
 /**
  * SubmissionsApi - axios parameter creator
  * @export
  */
 export const SubmissionsApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Delete an appendix
          * @param {string} applicationId 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesIdDelete(applicationId: string, id: string, options: any = {}): RequestArgs {
             // verify required parameter 'applicationId' is not null or undefined
             if (applicationId === null || applicationId === undefined) {
                 throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling adminUploadAppendicesIdDelete.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling adminUploadAppendicesIdDelete.');
             }
             const localVarPath = `/submissions/{applicationId}/appendices/{id}`
                 .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Retrieve a upload appendix
          * @param {string} applicationId 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesIdGet(applicationId: string, id: string, options: any = {}): RequestArgs {
             // verify required parameter 'applicationId' is not null or undefined
             if (applicationId === null || applicationId === undefined) {
                 throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling adminUploadAppendicesIdGet.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling adminUploadAppendicesIdGet.');
             }
             const localVarPath = `/submissions/{applicationId}/appendices/{id}`
                 .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Upload an appendix pdf
          * @param {string} applicationId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesPost(applicationId: string, options: any = {}): RequestArgs {
             // verify required parameter 'applicationId' is not null or undefined
             if (applicationId === null || applicationId === undefined) {
                 throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling adminUploadAppendicesPost.');
             }
             const localVarPath = `/submissions/{applicationId}/appendices`
                 .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Retrieve submitted applications
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsGet(options: any = {}): RequestArgs {
             const localVarPath = `/submissions`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Retrieve a submitted application
          * @param {string} referenceId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsIdGet(referenceId: string, options: any = {}): RequestArgs {
             // verify required parameter 'referenceId' is not null or undefined
             if (referenceId === null || referenceId === undefined) {
                 throw new RequiredError('referenceId','Required parameter referenceId was null or undefined when calling submissionsIdGet.');
             }
             const localVarPath = `/submissions/{referenceId}`
                 .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Submit an existing application
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsIdPatch(id: string, options: any = {}): RequestArgs {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling submissionsIdPatch.');
             }
             const localVarPath = `/submissions/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary submit an application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsPost(applicationDto: ApplicationDto, options: any = {}): RequestArgs {
             // verify required parameter 'applicationDto' is not null or undefined
             if (applicationDto === null || applicationDto === undefined) {
                 throw new RequiredError('applicationDto','Required parameter applicationDto was null or undefined when calling submissionsPost.');
             }
             const localVarPath = `/submissions`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
             const needsSerialization = (<any>"ApplicationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(applicationDto !== undefined ? applicationDto : {}) : (applicationDto || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * SubmissionsApi - functional programming interface
  * @export
  */
 export const SubmissionsApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Delete an appendix
          * @param {string} applicationId 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesIdDelete(applicationId: string, id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
             const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).adminUploadAppendicesIdDelete(applicationId, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Retrieve a upload appendix
          * @param {string} applicationId 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesIdGet(applicationId: string, id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
             const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).adminUploadAppendicesIdGet(applicationId, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Upload an appendix pdf
          * @param {string} applicationId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesPost(applicationId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
             const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).adminUploadAppendicesPost(applicationId, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Retrieve submitted applications
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationResponseDto>> {
             const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).submissionsGet(options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Retrieve a submitted application
          * @param {string} referenceId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsIdGet(referenceId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseDto> {
             const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).submissionsIdGet(referenceId, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Submit an existing application
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsIdPatch(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionResponseDto> {
             const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).submissionsIdPatch(id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary submit an application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsPost(applicationDto: ApplicationDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionResponseDto> {
             const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).submissionsPost(applicationDto, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * SubmissionsApi - factory interface
  * @export
  */
 export const SubmissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Delete an appendix
          * @param {string} applicationId 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesIdDelete(applicationId: string, id: string, options?: any) {
             return SubmissionsApiFp(configuration).adminUploadAppendicesIdDelete(applicationId, id, options)(axios, basePath);
         },
         /**
          * 
          * @summary Retrieve a upload appendix
          * @param {string} applicationId 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesIdGet(applicationId: string, id: string, options?: any) {
             return SubmissionsApiFp(configuration).adminUploadAppendicesIdGet(applicationId, id, options)(axios, basePath);
         },
         /**
          * 
          * @summary Upload an appendix pdf
          * @param {string} applicationId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         adminUploadAppendicesPost(applicationId: string, options?: any) {
             return SubmissionsApiFp(configuration).adminUploadAppendicesPost(applicationId, options)(axios, basePath);
         },
         /**
          * 
          * @summary Retrieve submitted applications
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsGet(options?: any) {
             return SubmissionsApiFp(configuration).submissionsGet(options)(axios, basePath);
         },
         /**
          * 
          * @summary Retrieve a submitted application
          * @param {string} referenceId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsIdGet(referenceId: string, options?: any) {
             return SubmissionsApiFp(configuration).submissionsIdGet(referenceId, options)(axios, basePath);
         },
         /**
          * 
          * @summary Submit an existing application
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsIdPatch(id: string, options?: any) {
             return SubmissionsApiFp(configuration).submissionsIdPatch(id, options)(axios, basePath);
         },
         /**
          * 
          * @summary submit an application
          * @param {ApplicationDto} applicationDto 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         submissionsPost(applicationDto: ApplicationDto, options?: any) {
             return SubmissionsApiFp(configuration).submissionsPost(applicationDto, options)(axios, basePath);
         },
     };
 };
 
 /**
  * SubmissionsApi - object-oriented interface
  * @export
  * @class SubmissionsApi
  * @extends {BaseAPI}
  */
 export class SubmissionsApi extends BaseAPI {
     /**
      * 
      * @summary Delete an appendix
      * @param {string} applicationId 
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof SubmissionsApi
      */
     public adminUploadAppendicesIdDelete(applicationId: string, id: string, options?: any) {
         return SubmissionsApiFp(this.configuration).adminUploadAppendicesIdDelete(applicationId, id, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Retrieve a upload appendix
      * @param {string} applicationId 
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof SubmissionsApi
      */
     public adminUploadAppendicesIdGet(applicationId: string, id: string, options?: any) {
         return SubmissionsApiFp(this.configuration).adminUploadAppendicesIdGet(applicationId, id, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Upload an appendix pdf
      * @param {string} applicationId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof SubmissionsApi
      */
     public adminUploadAppendicesPost(applicationId: string, options?: any) {
         return SubmissionsApiFp(this.configuration).adminUploadAppendicesPost(applicationId, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Retrieve submitted applications
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof SubmissionsApi
      */
     public submissionsGet(options?: any) {
         return SubmissionsApiFp(this.configuration).submissionsGet(options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Retrieve a submitted application
      * @param {string} referenceId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof SubmissionsApi
      */
     public submissionsIdGet(referenceId: string, options?: any) {
         return SubmissionsApiFp(this.configuration).submissionsIdGet(referenceId, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Submit an existing application
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof SubmissionsApi
      */
     public submissionsIdPatch(id: string, options?: any) {
         return SubmissionsApiFp(this.configuration).submissionsIdPatch(id, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary submit an application
      * @param {ApplicationDto} applicationDto 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof SubmissionsApi
      */
     public submissionsPost(applicationDto: ApplicationDto, options?: any) {
         return SubmissionsApiFp(this.configuration).submissionsPost(applicationDto, options)(this.axios, this.basePath);
     }
 
 }
 
 
 /**
  * UploadApi - axios parameter creator
  * @export
  */
 export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve a upload image
          * @param {string} id 
          * @param {'small' | 'medium' | 'large'} size 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadsIdGet(id: string, size: 'small' | 'medium' | 'large', options: any = {}): RequestArgs {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling uploadsIdGet.');
             }
             // verify required parameter 'size' is not null or undefined
             if (size === null || size === undefined) {
                 throw new RequiredError('size','Required parameter size was null or undefined when calling uploadsIdGet.');
             }
             const localVarPath = `/upload/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (size !== undefined) {
                 localVarQueryParameter['size'] = size;
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Upload an image
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadsPost(options: any = {}): RequestArgs {
             const localVarPath = `/upload`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             delete localVarUrlObj.search;
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * UploadApi - functional programming interface
  * @export
  */
 export const UploadApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Retrieve a upload image
          * @param {string} id 
          * @param {'small' | 'medium' | 'large'} size 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadsIdGet(id: string, size: 'small' | 'medium' | 'large', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
             const localVarAxiosArgs = UploadApiAxiosParamCreator(configuration).uploadsIdGet(id, size, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Upload an image
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadsPost(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
             const localVarAxiosArgs = UploadApiAxiosParamCreator(configuration).uploadsPost(options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * UploadApi - factory interface
  * @export
  */
 export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Retrieve a upload image
          * @param {string} id 
          * @param {'small' | 'medium' | 'large'} size 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadsIdGet(id: string, size: 'small' | 'medium' | 'large', options?: any) {
             return UploadApiFp(configuration).uploadsIdGet(id, size, options)(axios, basePath);
         },
         /**
          * 
          * @summary Upload an image
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadsPost(options?: any) {
             return UploadApiFp(configuration).uploadsPost(options)(axios, basePath);
         },
     };
 };
 
 /**
  * UploadApi - object-oriented interface
  * @export
  * @class UploadApi
  * @extends {BaseAPI}
  */
 export class UploadApi extends BaseAPI {
     /**
      * 
      * @summary Retrieve a upload image
      * @param {string} id 
      * @param {'small' | 'medium' | 'large'} size 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UploadApi
      */
     public uploadsIdGet(id: string, size: 'small' | 'medium' | 'large', options?: any) {
         return UploadApiFp(this.configuration).uploadsIdGet(id, size, options)(this.axios, this.basePath);
     }
 
     /**
      * 
      * @summary Upload an image
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UploadApi
      */
     public uploadsPost(options?: any) {
         return UploadApiFp(this.configuration).uploadsPost(options)(this.axios, this.basePath);
     }
 
 }
 
 
 