import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  CardHeader,
  Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchDrafts } from 'client/actions'
import {
  ApplicationResponseDto,
  RemoteSigningStatusDtoStatusEnum,
} from 'client/api/application/api'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { ThunkDispatch } from 'redux-thunk'
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemoteTwoTone'
import SigningStatusDialog from './signingStatusDialog'

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    cursor: 'pointer',
  },
}))

export type ReduxDispatch = ThunkDispatch<any, any, any>
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>()
}

const ListDraft: FunctionComponent<any> = () => {
  //const api = new DraftsApi(undefined, BASE_PATH)\
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const classes = useStyles()
  const [items, setItems] = useState<ApplicationResponseDto[] | undefined>()
  const [selectedApplication, setSelectedApplication] = useState<
    ApplicationResponseDto
  >({} as any)
  const [t] = useTranslation()

  useEffect(() => {
    Promise.resolve(dispatch(fetchDrafts(false)) as any).then((result: any) => {
      setItems(result)
    })
    //const fetchData = async () => {
    //  api
    //    .draftsGet({
    //      params: {
    //        view: 'summary',
    //      },
    //      transformResponse: [
    //        function(data: any) {
    //          return JSON.parse(data, jsonDateParser)
    //        },
    //      ],
    //    })
    //    .then((result: AxiosResponse<ApplicationResponseDto[]>) => {
    //      console.log(result)
    //      setItems(result.data)
    //    })
    //}
    //fetchData()
  }, [])

  function handleOpenApplication(application: ApplicationResponseDto) {
    const id = (application as any)['_id']
    if (application.isRemoteSigning && application.adobeSignAgreementId) {
      setSelectedApplication(application)
    } else {
      navigateToEditApplication(id)
    }
  }

  function navigateToEditApplication(id: string) {
    history.push(`edit/${id}`)
  }

  function handleSigningStatusDialogClose() {
    setSelectedApplication({} as any)
  }

  return (
    <Container maxWidth='md'>
      <Grid
        spacing={10}
        container
        alignContent='center'
        justifyContent='flex-end'
        direction='column'
      >
        <Grid item xs={12}></Grid>
        {items && items.length === 0 && (
          <Grid item xs={12}>
            <Typography
              align='center'
              color='primary'
              variant='h2'
              component='h2'
            >
              {t('No record was found')}
            </Typography>
          </Grid>
        )}
        {items && items.length > 0 && (
          <Typography
            color='primary'
            variant='h2'
            component='h2'
            align='center'
          >
            {t('List of Applications (click to open)')}
          </Typography>
        )}

        {items &&
          items
            .sort((a: ApplicationResponseDto, b: ApplicationResponseDto) => {
              if (!a.lastModifiedTime && b.lastModifiedTime) return 1
              if (!b.lastModifiedTime && a.lastModifiedTime) return -1
              if (!b.lastModifiedTime && !a.lastModifiedTime) return -1
              return b.lastModifiedTime.getTime() - a.lastModifiedTime.getTime()
            })
            .map((value: ApplicationResponseDto, index: any) => {
              const id = (value as any)['_id']
              const remoteSigningStatus = value.remoteSigningStatus
              return (
                <Box key={index} mt={3}>
                  <Card
                    className={classes.card}
                    onClick={() => {
                      handleOpenApplication(value)
                    }}
                  >
                    <CardHeader
                      avatar={
                        value.isRemoteSigning && (
                          <SettingsRemoteIcon
                            color='primary'
                            style={
                              remoteSigningStatus &&
                              remoteSigningStatus.status ===
                                RemoteSigningStatusDtoStatusEnum.REJECTED
                                ? { fill: 'red' }
                                : {}
                            }
                          />
                        )
                      }
                      title={
                        <Typography
                          color='primary'
                          variant='h6'
                          component='h6'
                          align='right'
                        >
                          {value.clientInformation &&
                            value.clientInformation.firstName}
                          ,{' '}
                          {value.clientInformation &&
                            value.clientInformation.lastName}
                          {` (${t('Last Modified at')} ${
                            value.lastModifiedTime
                              ? value.lastModifiedTime.toLocaleString('en-HK', {
                                  timeZone: 'Asia/Hong_Kong',
                                })
                              : 'N/A'
                          })`}
                        </Typography>
                      }
                    />
                  </Card>
                </Box>
              )
            })}
      </Grid>
      <SigningStatusDialog
        remoteSigningStatus={selectedApplication.remoteSigningStatus}
        handleEdit={navigateToEditApplication}
        handleClose={handleSigningStatusDialogClose}
        applicationId={(selectedApplication as any)['_id']}
        adobeSignAgreementId={selectedApplication.adobeSignAgreementId}
      />
    </Container>
  )
}

export default ListDraft
