import { Theme, createStyles, TableCell, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React from 'react'
import styled from 'styled-components'
import { PpoAdminTableHeadRowProps } from '../ppoAdminTableHeadRow'

// const StyledTableCell = withStyles((theme: Theme) =>
//   createStyles({
//     head: {
//       fontSize: 'small',
//     },
//     body: {
//       fontSize: 'small',
//     },
//   }),
// )(TableCell)

const StyledTableCell = styled(({ className, ...props }) => (
  <TableCell {...props} classes={{ paper: className }} />
))`
  head {
    font-size: 'small';
  }
  body {
    font-size: 'small';
  }
`

export function FtLifeLogPageHeader(props: PpoAdminTableHeadRowProps) {
  const { isAllChecked, handleCheckAllClicked } = props
  return (
    <>
      <StyledTableCell>
        <Checkbox
          checked={isAllChecked}
          onChange={() => handleCheckAllClicked()}
          value='allChecked'
          color='primary'
        />
        <strong>Ref # (PYYMMDD001)</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Record Date (MM/DD/YYYY)</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Client&nbsp;Name</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Forms&nbsp;Submitted</strong>
      </StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell>
        <strong>Agent&nbsp;Code</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Agent&nbsp;Name</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>
          Date&nbsp;of&nbsp;receiving
          <br />
          client&nbsp;instruction
          <br />
          (MM/DD/YYYY)
        </strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>
          Vulunerable&nbsp;Client
          <br />
          (Y/N)
        </strong>
      </StyledTableCell>
      {/* <StyledTableCell align='center'>
        <strong>NB (Yes/No)</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>FTLife Ref No.</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>Service Provider</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>Cleint Type</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>HKID/Passport</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>P(C)-Non ECA</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>P(M)-NonECA</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>P(P)-ECA</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>Address(1)</strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>Address(2)</strong>
      </StyledTableCell> */}
      <StyledTableCell>
        <strong>
          Confirmed&nbsp;/
          <br />
          &nbsp;Rejected
        </strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Remark</strong>
      </StyledTableCell>

      <StyledTableCell>
        <strong>
          Confirmed&nbsp;/ &nbsp;Rejected Date
          <br />
          (User)
          <br />
          (MM/DD/YYYY)
        </strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>
          Signed&nbsp;via&nbsp;mobile
          <br />
          (Y/N)
        </strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>
          NF2F
          <br />
          (Y/N)
        </strong>
      </StyledTableCell>
    </>
  )
}
