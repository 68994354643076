import { combineReducers } from 'redux'
import ApplicationData, { ApplicationStore } from './applicationData'
import ApplicationStep from './applicationStep'
import TermAndCondition from './termAndCondition'
import ApplicationNotification from './applicationNotification'
import UserProfile from './userProfile'

export interface RootStore {
  steps: []
  application: ApplicationStore
}

// interface AppState {
//   locale: string
// }

const initialAppState = {
  locale: 'zh-Hant',
}

const appReducer = (state = initialAppState, action: any) => {
  switch (action.type) {
    case 'chi': {
      return {
        locale: 'zh-Hant',
      }
    }
    case 'eng': {
      return {
        locale: 'en',
      }
    }
  }
}

const rootReducer = combineReducers<any>({
  userProfile: UserProfile,
  steps: ApplicationStep,
  application: ApplicationData,
  termAndCondition: TermAndCondition,
  notifications: ApplicationNotification,
  // appStates: appReducer,
})

export default rootReducer
