import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function SnackbarNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const application = useSelector((state: any) => state.notifications)

  useEffect(() => {
    //enqueue new notification
    if (application.notificationsAdded) {
      application.notificationsAdded.forEach((n: any) => {
        enqueueSnackbar(n.message, {
          key: n.key,
          persist: true,
          ...n.options,
        })
      })
    }
  }, [application.notificationsAdded])

  useEffect(() => {
    //enqueue new notification
    if (application.notificationsRemoved) {
      application.notificationsRemoved.forEach((key: string) => {
        closeSnackbar(key)
      })
    }
  }, [application.notificationsRemoved])

  return null
}
