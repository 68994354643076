import React from 'react'
import Button from '@material-ui/core/Button'
import { AuthenticationContext, useReactOidc } from '@axa-fr/react-oidc-context'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const btnStyles = {
  color: '#ffffff',
}

export const WithLogoutBtn = withRouter(props => {
  const [t] = useTranslation()
  const { oidcUser, events } = useReactOidc()

  const handleLogout = (logout: any): any => () => {
    console.log('hello.. ', oidcUser, events)
    // props.history.push('/')
    logout()
  }
  
  const handleLogin = (login: any): any => () => {
    props.history.push('/')
    login()
  }

  return (
    <AuthenticationContext.Consumer>
      {(props: any) => {
        return (
          <>
            {props.oidcUser ? (
              <Button style={btnStyles} onClick={handleLogout(props.logout)}>
                {t('Logout')}
              </Button>
            ) : (
              <Button style={btnStyles} onClick={handleLogout(props.login)}>
                {t('Login')}
              </Button>
            )}
          </>
        )
      }}
    </AuthenticationContext.Consumer>
  )
})

// export const LogoutBtn = () => {
//   const [t] = useTranslation()
  
//   return (
//     <AuthenticationContext.Consumer>
//       {(props: any) => {
//         return (
//           <Button style={btnStyles} onClick={props.logout}>
//             {t('Logout')}
//           </Button>
//         )
//       }}
//     </AuthenticationContext.Consumer>
//   )
// }

// export default LoginOutBtn
