import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import LoadingOverlay from 'react-loading-overlay'
import { useTranslation } from 'react-i18next'
import { LOADING_MESSAGE } from 'client/reducers/applicationNotification'

const StyledLoadingOverlay = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: ${props => (props.active ? '9999' : '0')};
  display: ${props => (props.active ? 'block' : 'none')};

  & > div:first-child {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
  }
`

export default function OverlayLoading() {
  const application = useSelector((state: any) => state.application)
  const notifications = useSelector((state: any) => state.notifications)
  const [t] = useTranslation()
  return (
    <>
      <StyledLoadingOverlay
        active={application.isFetching || application.isSubmitting}
        spinner
        text={t(LOADING_MESSAGE)}
      ></StyledLoadingOverlay>
      <StyledLoadingOverlay
        active={notifications.isLoading}
        spinner
        text={t(notifications.loadingMessage || LOADING_MESSAGE)}
      ></StyledLoadingOverlay>
    </>
  )
}
