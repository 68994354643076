import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Section from './style'
import axios, { CancelTokenSource } from 'axios'
import LoadingOverlay from 'react-loading-overlay'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { UPLOAD_SIZE_LIMIT } from 'config'
import { Button } from '@material-ui/core'
import {
  enqueueNotification,
  dequeueNotification,
} from 'client/actions/applicationNotification'
import * as EXIF from 'exif-parser'
import ModalImageEdit from '../modalImageEdit'
import { useTranslation } from 'react-i18next'

export const OPERATION_CANCELED_BY_THE_USER = 'Operation canceled by the user.'

type UploadImageProps = {
  id: string
  fileName: string
  uploadEndpoint: string
  createCancelTokenSource?: (
    componentId: string,
    source: CancelTokenSource,
  ) => void
  onFileSelected?: (files: any) => void
  onImageLoaded?: (componentId: string, files: any) => void
  onUploadCompleted?: (componentId: string, imageId: any) => void
  onUploadFailed?: (componentId: string, err: any) => void
  onUploadStarted?: (componentId: string) => void
  onUploadProgress?: (progressEvent: any) => void
}

const StyledLoadingOverlay = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;

  & > div._loading_overlay_overlay {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    background: #bbbbbb;
  }
`

export default function UploadImage(props: UploadImageProps) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
  // const [progress, setProgress] = useState('0%')
  // const [isUploading, setIsUploading] = useState(false)
  const isUploading = false
  const [isImageEditOpen, setIsImageEditOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const _arrayBufferToBase64 = (ab: ArrayBuffer) => {
    let binary = ''
    const bytes = new Uint8Array(ab)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  const resize = async (file: any): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      // Create an image
      var img = document.createElement('img')
      // Create a file reader
      var reader = new FileReader()
      // Set the image once loaded into file reader
      reader.onload = function (e) {
        // @ts-ignore: Object is possibly 'null'.
        const ab: ArrayBuffer = e.target.result
        const parser = EXIF.create(new Buffer(ab))
        let orientation = 1
        try {
          // @ts-ignore: Object is possibly 'null'.
          orientation = parser.parse().tags.Orientation as number
        } catch (ex) {}

        // @ts-ignore: Object is possibly 'null'.
        img.src = 'data:image/png;base64,' + _arrayBufferToBase64(ab)

        img.onload = () => {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')
          // @ts-ignore: Object is possibly 'null'.
          ctx.drawImage(img, 0, 0)

          const MAX_WIDTH = 2000
          let width = img.width
          let height = img.height

          if (width > MAX_WIDTH) {
            const ratio = MAX_WIDTH / width
            width = MAX_WIDTH
            height = height * ratio
          }

          const ctxHeight = height
          const ctxWidth = width

          let _w = 0
          let _h = 0
          if ([5, 6, 7, 8].includes(orientation)) {
            _w = height
            _h = width
          } else {
            _w = width
            _h = height
          }

          canvas.width = _w
          canvas.height = _h
          ctx = canvas.getContext('2d')
          switch (orientation) {
            case 2:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(-1, 0, 0, 1, ctxWidth, 0)
              break
            case 3:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(-1, 0, 0, -1, ctxWidth, ctxHeight)
              break
            case 4:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(1, 0, 0, -1, 0, ctxHeight)
              break
            case 5:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(0, 1, 1, 0, 0, 0)
              break
            case 6:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(0, 1, -1, 0, ctxHeight, 0)
              break
            case 7:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(0, -1, -1, 0, ctxHeight, ctxWidth)
              break
            case 8:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(0, -1, 1, 0, 0, ctxWidth)
              break
            default:
              // @ts-ignore: Object is possibly 'null'.
              ctx.transform(1, 0, 0, 1, 0, 0)
          }

          // @ts-ignore: Object is possibly 'null'.
          ctx.drawImage(img, 0, 0, ctxWidth, ctxHeight)

          canvas.toBlob(
            (result: any) => {
              resolve(result)
            },
            'image/jpeg',
            0.9,
          )
        } // img.onload
      }
      // Load files into file reader
      reader.readAsArrayBuffer(file)
    })
  }

  const upload = (biLevelImage: Blob, orgImage: Blob) => {
    const data = new FormData()
    data.append('files[]', orgImage, props.fileName)
    data.append('files[]', biLevelImage, props.fileName)

    const notificationId = props.fileName
    dispatch(
      enqueueNotification(true, notificationId, 'Uploading...', {
        variant: 'info',
      }),
    )

    const source = axios.CancelToken.source()
    if (props.createCancelTokenSource) {
      props.createCancelTokenSource(props.id, source)
    }
    axios
      .post(props.uploadEndpoint, data, {
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent)
          if (props.onUploadProgress) {
            console.log(progressEvent)
            props.onUploadProgress(progressEvent)
          }
        },
      })
      .then((res: any) => {
        console.log('uploadimg... upload complete...', res)
        const imageId = res.data
        if (!imageId) {
          throw 'Unknow error'
        }
        if (props.onUploadCompleted) {
          props.onUploadCompleted(props.id, imageId)
        }
      })
      .catch((err) => {
        const errorMsg = err.message || err || 'Unknown Error'
        console.log('uploadimg... upload error...', errorMsg)
        if (errorMsg != OPERATION_CANCELED_BY_THE_USER) {
          dispatch(
            enqueueNotification(true, notificationId + '.error', errorMsg, {
              variant: 'error',
              persist: true,
              action: (key: string) => (
                <Button
                  style={{ color: '#FFF' }}
                  onClick={() => dispatch(dequeueNotification(key, true))}
                >
                  Close
                </Button>
              ),
            }),
          )
        }
        if (props.onUploadFailed) {
          props.onUploadFailed(props.id, errorMsg)
        }
      })
      .finally(() => {
        dispatch(dequeueNotification(notificationId, true))
      })
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      if (props.onFileSelected) {
        props.onFileSelected(acceptedFiles)
      } else {
        if (props.onUploadStarted) {
          props.onUploadStarted(props.id)
        }
        resize(acceptedFiles[0]).then((file: any) => {
          if (file.size > UPLOAD_SIZE_LIMIT) {
            if (props.onUploadFailed) {
              props.onUploadFailed(
                props.id,
                'File too large. Max. size is 10MB',
              )
            }
          } else {
            // if (props.onImageLoaded) {
            //   props.onImageLoaded(props.id, file)
            // }

            console.log(file)
            const image = window.URL.createObjectURL(file)
            setSelectedImage(image)
            setIsImageEditOpen(true)

            //upload(file)
          }
        })
      }
    }
  }, [acceptedFiles])

  const files = acceptedFiles.map((file: any) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    )
  })

  const handleImageEditClose = () => {
    setIsImageEditOpen(false)
  }

  const handleImageEditAdd = (biLevelImage: Blob, orgImage: Blob) => {
    if (props.onImageLoaded) {
      props.onImageLoaded(props.id, biLevelImage)
    }
    setIsImageEditOpen(false)
    upload(biLevelImage, orgImage)
  }

  return (
    <>
      <Section>
        <StyledLoadingOverlay active={isUploading} spinner text='Uploading...'>
          <div {...getRootProps({ className: `dropzone` })}>
            <input
              capture
              {...getInputProps({ multiple: false, accept: 'image/*' })}
            />
            <p>{t('Please drag & drop files here, or click to select files')}</p>
          </div>
          {/* <aside>
        <h4>Progress</h4>
        <ul>{progress}</ul>
      </aside> */}
        </StyledLoadingOverlay>
      </Section>
      <ModalImageEdit
        open={isImageEditOpen}
        onClose={handleImageEditClose}
        onAdd={handleImageEditAdd}
        dataUrl={selectedImage}
      />
    </>
  )
}
