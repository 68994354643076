import { Badge } from '@material-ui/core'
import { StepIconProps } from '@material-ui/core/StepIcon'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

const useQontoStepIconStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    color: '#0091da',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  completed: {
    color: '#0091da !important',
    border: '4px solid #0091da !important'
  },
  active: {
    background: '#0091da !important',
    border: '4px solid #0091da !important',
    color: '#FFF !important'
  },
  error: {
    background: '#F00 !important',
    border: '4px solid #F00 !important',
    color: '#FFF !important'
  },
  numberCircle: {
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    padding: '0px',
    background: '#fff',
    border: '4px solid #C7C7C7',
    color: '#C7C7C7',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily:
      '"FS Elliot Web Bold", "FS Elliot Web Regular", Arial, sans-serif'
  },
  margin: {
    margin: theme.spacing(2)
  }
})
)

const QontoStepIcon = (props: StepIconProps) => {
  // const [horizontal, setHorizontal] = React.useState<'left' | 'right'>('right')
  // const [vertical, setVertical] = React.useState<'top' | 'bottom'>('top')
  const classes = useQontoStepIconStyles()
  const { active, completed, error } = props
  // console.log(active, completed)
  return (
    <Badge color='error' overlap='circle' badgeContent='!' invisible={!error}>
      <div className={clsx(classes.root, {})}>
        {
          <div
            className={clsx(classes.numberCircle, {
              [classes.completed]: completed,
              [classes.active]: active
            })}
          >
            {props.icon}
          </div>
        }
      </div>
    </Badge>
  )
}

export default QontoStepIcon
