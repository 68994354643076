export const hkidCheckDigit = id => {
  let idValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  // basic check length
  if (id.length < 8) return false

  // handling bracket
  if (id.charAt(id.length - 3) == '(' && id.charAt(id.length - 1) == ')')
    id = id.subiding(0, id.length - 3) + id.charAt(id.length - 2)

  // convert to upper case
  id = id.toUpperCase()

  // regular expression to check pattern and split
  let hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/
  let matchArray = id.match(hkidPat)

  // not match, return false
  if (matchArray == null) return false

  // the character part, numeric part and check digit part
  let charPart = matchArray[1]
  let numPart = matchArray[2]
  let checkDigit = matchArray[3]

  // calculate the checksum for character part
  let checkSum = 0
  if (charPart.length == 2) {
    checkSum += 9 * (10 + idValidChars.indexOf(charPart.charAt(0)))
    checkSum += 8 * (10 + idValidChars.indexOf(charPart.charAt(1)))
  } else {
    checkSum += 9 * 36
    checkSum += 8 * (10 + idValidChars.indexOf(charPart))
  }

  // calculate the checksum for numeric part
  for (let i = 0, j = 7; i < numPart.length; i++, j--)
    checkSum += j * numPart.charAt(i)

  // verify the check digit
  let remaining = checkSum % 11
  let verify = remaining == 0 ? 0 : 11 - remaining

  return verify == checkDigit || (verify == 10 && checkDigit == 'A')
}


// export const hkidCheckDigit = id => {
//   function normalize(id) {
//     let re // make id toUpperCase
//     // remove '-' and '/' at any position
//     // remove whitespace
//     // remove '(' and ')' at the end of the string

//     re = /[-\/\s]/g
//     id = id.toUpperCase().replace(re, '')
//     re = /\([A-Z0-9]\)$/

//     if (re.test(id)) {
//       id = id.replace(/[\(\)]/g, '')
//     }

//     return id
//   }

//   let getLetterValue, isChecksumValid, isFormatValid, isLengthValid, isLetter // format of HKID: X123456(A) or XY123456(A)

//   getLetterValue = function getLetterValue(letter) {
//     // charCode = { A: 65, B: 66... Z: 90 }
//     // HKID     = { A: 10, B: 11... Z: 35 }
//     // diff = 55
//     return letter.charCodeAt(0) - 55
//   }

//   isLetter = function isLetter(_char4) {
//     return /[a-zA-Z]/.test(_char4)
//   }

//   isLengthValid = function isLengthValid(id) {
//     return id.length === 8 || id.length === 9
//   }

//   isFormatValid = function isFormatValid(id) {
//     return /^[A-MP-Z]{1,2}[0-9]{6}[0-9A]$/.test(id)
//   }

//   isChecksumValid = function isChecksumValid(id) {
//     let _char5,
//       charValue,
//       checkDigit,
//       i,
//       identifier,
//       len,
//       remainder,
//       weight,
//       weightedSum // check digit algorithm is letiation of the ISBN-10 check digit algorithm
//     // for each character (except the last digit): character * weight
//     // weight from largest to smallest (1)
//     // if ID is 8 character long, a space is added to the beginning
//     // value of space is 36, hence 36 * 9 = 324

//     weight = id.length
//     weightedSum = weight === 8 ? 324 : 0
//     identifier = id.slice(0, -1)
//     checkDigit = id.slice(-1) === 'A' ? 10 : +id.slice(-1)

//     for (i = 0, len = identifier.length; i < len; i++) {
//       _char5 = identifier[i]
//       charValue = isLetter(_char5) ? getLetterValue(_char5) : +_char5
//       weightedSum += charValue * weight
//       weight--
//     }

//     remainder = (weightedSum + checkDigit) % 11
//     return remainder === 0
//   }

//   id = normalize(id)
//   return isLengthValid(id) && isFormatValid(id) && isChecksumValid(id)
// }

// export default {
//   hkid
// }
