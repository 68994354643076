import {
  CUSTOMER_INFORMATION_STEP,
  TERMS_PERSONAL_INFORMATION,
  TERMS_ADOBE,
  CUSTOMER_INFORMATION,
  ADDRESS,
  CRS,
  INVESTMENT_MANDATE_GROUP,
  INVESTMENT_MANDATE,
  INVESTMENT_MANDATE_VOLUNTEER,
  ACCOUNT_CONSOLIDATION,
  IDENTITY_UPLOAD,
  SUMMARY_GROUP,
  SUMMARY_CUSTOMER_INFO,
  SUMMARY_CRS,
  SUMMARY_INVESTMENT,
  SUMMARY_ACCOUNT_CONSOLIDATION,
  SUMMARY,
  DOCUMENT_UPLOAD,
  SCREEN_GUIDE,
} from 'client/components/steppers'

export const BROKER_DEFAULT = [
  {
    id: CUSTOMER_INFORMATION_STEP,
    label: 'Customer Section',
    to: 'personal-information-collection',
    steps: [
      {
        id: TERMS_PERSONAL_INFORMATION,
        label: 'Personal Information Collection',
        to: 'personal-information-collection',
        isActive: true,
      },
      {
        id: TERMS_ADOBE,
        label: 'Adobe terms and conditions',
        to: 'adobe-terms-and-conditions',
        isActive: true,
      },
      {
        id: SCREEN_GUIDE,
        label: 'Screen Guides',
        to: 'screen-guides',
        isActive: true,
      },
      {
        id: CUSTOMER_INFORMATION,
        label: 'Customer Information',
        to: 'customer-information',
        isActive: true,
      },
    ],
    isActive: true,
  },
  { id: ADDRESS, label: 'Address', to: 'address', isActive: true },
  {
    id: CRS,
    label: 'CRS',
    to: 'crs',
    isActive: true,
  },
  {
    id: INVESTMENT_MANDATE_GROUP,
    label: 'Investment Mandate',
    to: 'investment-mandate',
    steps: [
      {
        id: INVESTMENT_MANDATE,
        label: 'Investment Mandate',
        to: 'investment-mandate',
        isActive: true,
      },
      {
        id: INVESTMENT_MANDATE_VOLUNTEER,
        label: 'Voluntary Contribution',
        to: 'investment-mandate-volunteer',
        isActive: true,
      },
    ],
    isActive: true,
  },
  {
    id: ACCOUNT_CONSOLIDATION,
    label: 'Account(s) to be Consolidated',
    to: 'account-consolidation',
    isActive: true,
  },
  {
    id: IDENTITY_UPLOAD,
    label: 'Upload HKID/Passport',
    to: 'identity-upload',
    isActive: true,
  },
  {
    id: DOCUMENT_UPLOAD,
    label: 'Other Document(s)',
    to: 'document-upload',
    isActive: true,
  },
  {
    id: SUMMARY_GROUP,
    label: 'Summary',
    to: 'summary-customer-info',
    steps: [
      {
        id: SUMMARY_CUSTOMER_INFO,
        label: 'Summary - Customer Information',
        to: 'summary-customer-info',
        isActive: true,
      },
      {
        id: SUMMARY_CRS,
        label: 'Summary - CRS',
        to: 'summary-crs',
        isActive: true,
      },
      {
        id: SUMMARY_INVESTMENT,
        label: 'Summary - Investment',
        to: 'summary-investment-mandate',
        isActive: true,
      },
      {
        id: SUMMARY_ACCOUNT_CONSOLIDATION,
        label: 'Summary - Account Consolidation',
        to: 'summary-account-consolidation',
        isActive: true,
      },
      {
        id: SUMMARY,
        label: 'Summary',
        to: 'summary',
        isActive: true,
      },
    ],
    isActive: true,
  },
]
