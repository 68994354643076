import { FormControlLabel, Checkbox } from '@material-ui/core'
import React from 'react'
import { StyledTableCell } from '../style'
import moment from 'moment'
import { PpoAdminTableRowProps } from '../ppoAdminTableRow'

export function GeneralQueryPageBody(props: PpoAdminTableRowProps) {
  const { value, selected, handleSelect, referenceId } = props

  return (
    <>
      <StyledTableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={selected || false}
              onChange={(e: any) => {
                handleSelect(value.id, e.target.checked)
              }}
              value='itemChecked'
              color='primary'
            />
          }
          label={<span style={{ fontSize: 'small' }}>{referenceId}</span>}
        />
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value.submittedTime
          ? moment(value.submittedTime)
              .tz('Asia/Hong_Kong')
              .format('MM/DD/YYYY')
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          maxWidth: '250px',
        }}
      >{`${value.clientName}`}</StyledTableCell>
      {value.submissionSummary && value.submissionSummary.forms && (
        <StyledTableCell align='left'>
          {value.submissionSummary.forms.map((elem: any, index: any) => {
            return <div key={index}>{elem.label}</div>
          })}
        </StyledTableCell>
      )}
      {value.agentInformation && (
        <>
          <StyledTableCell align='left'>{`${value.agentInformation.unitCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.sourceCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.agentCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.agentName}`}</StyledTableCell>
        </>
      )}
      <StyledTableCell align='center'>
        {value &&
        value.submissionSummary &&
        value.submissionSummary.ppoReceivedDate
          ? `${moment(value.submissionSummary.ppoReceivedDate)
              .tz('Asia/Hong_Kong')
              .format('MM/DD/YYYY')}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value &&
        value.submissionSummary &&
        value.submissionSummary.isVulnerableClient
          ? `${value.submissionSummary.isVulnerableClient}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value.isRemoteSigning === true ? 'Y' : 'N'}
      </StyledTableCell>
    </>
  )
}
