import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchSubmissions } from 'client/actions'
import { ApplicationResponseDto } from 'client/api/application/api'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { ThunkDispatch } from 'redux-thunk'

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
  },
}))

export type ReduxDispatch = ThunkDispatch<any, any, any>
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>()
}

const ListSubmission: FunctionComponent<any> = () => {
  //const api = new DraftsApi(undefined, BASE_PATH)\
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const classes = useStyles()
  const [items, setItems] = useState<ApplicationResponseDto[] | undefined>()
  const [t] = useTranslation()

  useEffect(() => {
    ;(dispatch(fetchSubmissions()) as any).then((result: any) => {
      setItems(result)
    })
  }, [])

  const handleClone = (id: string) => {
    alert(id)
  }

  return (
    <Container maxWidth='md'>
      <Grid spacing={2} container alignContent='center' justifyContent='center'>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        {items && items.length === 0 && (
          <>
            <Grid item xs={12}>
              <Typography
                align='center'
                color='primary'
                variant='h2'
                component='h2'
              >
                {t('No submission was found')}
              </Typography>
            </Grid>
          </>
        )}
        {items && items.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography
                color='primary'
                variant='h2'
                component='h2'
                align='center'
              >
                {t('List of Submitted Applications')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={(props) => <Paper square {...props} />}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Ref # (PYYMMDD001)</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Client&nbsp;Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Submission Date</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Remark</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items
                      .sort(
                        (
                          a: ApplicationResponseDto,
                          b: ApplicationResponseDto,
                        ) => {
                          if (!a.submittedTime && b.submittedTime) return 1
                          if (!b.submittedTime && a.submittedTime) return -1
                          if (!b.submittedTime && !a.submittedTime) return -1
                          return (
                            b.submittedTime.getTime() -
                            a.submittedTime.getTime()
                          )
                        },
                      )
                      .map((value: ApplicationResponseDto, index: any) => {
                        const referenceId = value.referenceId
                        const ppoStatus = value.ppoStatus ? value.ppoStatus : ''
                        const ppoRemark = value.ppoRemark ? value.ppoRemark : ''
                        const remark = value.ppoRemark
                          ? `${ppoStatus} - ${ppoRemark}`
                          : ppoStatus
                        return (
                          <TableRow key={value.id}>
                            <TableCell>{referenceId}</TableCell>
                            <TableCell align='left'>{`${value.clientInformation.firstName}, ${value.clientInformation.lastName}`}</TableCell>
                            <TableCell align='left'>
                              {value.submittedTime
                                ? value.submittedTime.toLocaleString('en-HK', {
                                    timeZone: 'Asia/Hong_Kong',
                                  })
                                : 'N/A'}
                            </TableCell>
                            <TableCell>{value.status}</TableCell>
                            <TableCell>
                              {remark}
                              {/* {ppoStatus === 'REJECTED' && (
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    handleClone(value.referenceId)
                                  }}
                                >
                                  Clone application
                                </Button>
                              )} */}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default ListSubmission
