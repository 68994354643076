import { Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getBaseName } from 'utils/common'
import TermsAndConditions from 'client/components/termsAndConditions'

const LoginTerms = () => {
  
  const { t } = useTranslation()
  const baseName = getBaseName()
  
  // TODO: it has no effect currently
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h3' style={{ margin: '20px 0 10px' }}>{t('Important information to customer')}</Typography>
      </Grid>
      <TermsAndConditions to='/home'>
        <p>{t('logintc_1')}</p>
        <ol>
          {baseName === 'pa-axa' && (
            <li>{t('logintc_2')}</li>
          )}
          <li>{t('logintc_3')}</li>
          <li>{t('logintc_4')}</li>
          <li>{t('logintc_5')}</li>
          <li>{t('logintc_6')}</li>
          <li>{t('logintc_7')}</li>
          <li>{t('logintc_8')}</li>
          <li>{t('logintc_9')}</li>
        </ol>
      </TermsAndConditions>
    </Grid>
  )
}

export default LoginTerms
