import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import {
  ConsolidatedAccountDto,
  ConsolidatedAccountDtoHasVoluntaryEnum,
  ConsolidatedAccountDtoIsSelfEmployedAccountEnum,
  ConsolidatedAccountDtoSelfEmployedDetailsEnum,
  ConsolidatedAccountDtoTransferOptionEnum,
  ConsolidatedAccountDtoTransferPartialOptionsEnum,
  ConsolidatedAccountDtoTransferTypeEnum,
  ConsolidatedAccountDtoVoluntaryArrangeEnum,
  ConsolidatedAccountDtoWithdrawMethodEnum,
  ClientInformationDtoIsS800Enum,
  ClientInformationDtoAccountTypeEnum,
} from 'client/api/application/api'
import FormError from 'client/components/formErrorMsg'
import InputDate from 'client/components/inputDate'
import InputDynamicCheckbox from 'client/components/inputDynamicCheckbox'
import InputDynamicRadioGroup from 'client/components/inputDynamicRadioGroup'
import InputDynamicSelect from 'client/components/inputDynamicSelect'
import {
  acccountTransferOpt,
  mapToTrusteeGroup,
  schemesAIA,
  schemesBCT,
  schemesBEA,
  schemesBOC,
  schemesBOCI,
  schemesCL,
  schemesHSBC,
  schemesHSBCProv,
  schemesManulife,
  schemesPrincipal,
  schemesSHKP,
  schemesSunLife,
  schemesSunLifeTrustee,
  schemesYFLife,
  trusteeName,
  TRUSTEE_GROUP_HSBC_PROVIDENT_FUND,
} from 'config'
import _includes from 'lodash/includes'
import _isNumber from 'lodash/isNumber'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { Transition } from 'client/components/common/transition'
import { useStyles } from './style'

function createData(companyName: any, plan: any, type: any, tel: any) {
  return { companyName, plan, type, tel }
}

// function getModalStyle() {
//   const top = 50;
//   const left = 50;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }
interface Props {
  open: boolean
  onClose: any
  type?: ActionType
  data?: any
  isS800: ClientInformationDtoIsS800Enum
  accountType: ClientInformationDtoAccountTypeEnum
  editIdx?: number | null
}

interface ActionType {
  add: string
  edit: string
  cancel: string
}

const ModalAccount = (props: Props) => {
  const hookFormFunc = useForm()
  const { handleSubmit, register, errors, setValue } = hookFormFunc

  const classes = useStyles()

  // const [modalStyle] = useState(getModalStyle);
  // console.log('in modal child... ', props.open)
  // const [localOpen, localClose] = useState(props.open)
  const [t] = useTranslation()

  let ACCOUNT_TYPES: any[] = [
    {
      label: 'PA',
      value: ConsolidatedAccountDtoTransferTypeEnum.PA,
    },
    {
      label: 'CA_1',
      value: ConsolidatedAccountDtoTransferTypeEnum.CA1,
    },
    {
      label: 'CA_2',
      value: ConsolidatedAccountDtoTransferTypeEnum.CA2,
    },
  ]

  // const [selectedType, setSelectedType] = useState(
  //   ConsolidatedAccountDtoTransferTypeEnum.PA,
  // )
  const [selectedType, setSelectedType] = useState('')
  const [selTrusteeGroup, setSelTrusteeGroup] = useState('')
  //const [selTrustee, setSelTrustee] = useState('')
  const [selScheme, setSelScheme] = useState('')
  const [hasVoluntary, setHasVoluntary] = useState('')
  const [voluntaryArrange, setVoluntaryArrange] = useState('')
  const [withdrawMethod, setWithdrawMethod] = useState('')
  const phScheme = { label: 'Please select trustee', value: '' }
  const [curSchemes, setCurSchemes] = useState([phScheme])
  const [accountTypes, setAccountTypes] = useState<any>(ACCOUNT_TYPES)
  //CA_1
  const [isSelfEmployedAccount, setIsSelfEmployedAccount] = useState('')
  const [selfEmployedDetails, setSelfEmployedDetails] = useState('')
  //CA_2
  const [transferOption, setTransferOption] = useState('')
  const [transferPartialOptions, setTransferPartialOptions] = useState(
    [] as Array<ConsolidatedAccountDtoTransferPartialOptionsEnum>,
  )
  useEffect(() => {
    updateScheme(selTrusteeGroup)
    if (selTrusteeGroup === '19691') {
      setAccountTypes(
        ACCOUNT_TYPES.filter((e: any) => {
          return e.value !== ConsolidatedAccountDtoTransferTypeEnum.PA
        }),
      )
    } else {
      setAccountTypes(ACCOUNT_TYPES)
    }
  }, [selTrusteeGroup])

  useEffect(() => {
    if (props.data) {
      const ca: ConsolidatedAccountDto = props.data as ConsolidatedAccountDto
      setSelTrusteeGroup(mapToTrusteeGroup(ca.trustee))
      setSelScheme(`${ca.trustee},${ca.scheme}`)
      //setAccountNo(ca.accountNo)
      setSelectedType(ca.transferType)
      setHasVoluntary(ca.hasVoluntary)
      setVoluntaryArrange(ca.voluntaryArrange)
      setWithdrawMethod(ca.withdrawMethod)
      setSelfEmployedDetails(ca.selfEmployedDetails)
      //CA_1
      setIsSelfEmployedAccount(ca.isSelfEmployedAccount)
      //CA_2
      setTransferOption(ca.transferOption)
      if (ca.transferPartialOptions) {
        setTransferPartialOptions(ca.transferPartialOptions)
      }
    } else {
      setSelTrusteeGroup('')
      setSelScheme('')
      setSelectedType('')
      //setAccountNo(undefined)
      setHasVoluntary('')
      setVoluntaryArrange('')
      setWithdrawMethod('')
      setSelfEmployedDetails('')
      //CA_1
      setIsSelfEmployedAccount('')
      //CA_2
      setTransferOption('')
      setTransferPartialOptions([])
    }
  }, [props.data, props.open])

  // for transfer type input on change event
  function handleTypeChange(e: any) {
    setSelectedType(e.target.value)
    // reset other fields
    setHasVoluntary('')
    setWithdrawMethod('')
  }

  function handleSchemeChange(e: any) {
    setSelScheme(e.target.value)
  }

  function handleIfSelfEmployedChange(val: string) {
    setIsSelfEmployedAccount(val)
  }

  function handleSelfEmployedDetail(val: string) {
    setSelfEmployedDetails(val)
  }

  function handleChangeHasVoluntary(val: string) {
    setHasVoluntary(val)
    setWithdrawMethod('')
  }

  function handleVoluntaryArrange(val: string) {
    setVoluntaryArrange(val)
  }

  function handleChangeWithdrawMethod(val: string) {
    setWithdrawMethod(val)
  }

  function handleTransferOptionChange(val: string) {
    setTransferOption(val)
    setTransferPartialOptions([])
  }

  const onSubmit = (formData: any) => {
    // assoicated hidden fields from the custom Select component
    // formData.trusteeAndScheme = selScheme
    // formData.transferType = selectedType
    if (formData && formData.trusteeAndScheme) {
      formData.trustee = formData.trusteeAndScheme.split(',')[0]
      formData.scheme = formData.trusteeAndScheme.split(',')[1]
    }
    console.log('formdata in child... ', formData)
    if (selScheme === '') {
      return alert(t('Please select a new scheme.'))
    }
    if (selectedType === '') {
      return alert(t('Please select an account type'))
    }
    // edit
    if (formData && _isNumber(props.editIdx) && props.editIdx >= 0) {
      props.onClose('edit', formData, props.editIdx)
      // add
    } else if (formData) {
      props.onClose('add', formData)
      // cancel
    } else {
      props.onClose()
    }
  }

  // use label as value
  // const normalizeScheme = (val: any) => {
  //   const mapped = _map(val, val => {
  //     return {
  //       label: val.label,
  //       value: val.label,
  //     }
  //   })
  //   return mapped
  // }

  const updateScheme = (trustee: string) => {
    switch (trustee) {
      case '19684': {
        setCurSchemes(schemesAIA)
        break
      }
      case '19687': {
        setCurSchemes(schemesBOC)
        break
      }
      case '19686': {
        setCurSchemes(schemesBCT)
        break
      }
      case '19688': {
        setCurSchemes(schemesBEA)
        break
      }
      case '19690': {
        setCurSchemes(schemesBOCI)
        break
      }
      case '19693': {
        setCurSchemes(schemesCL)
        break
      }
      // case '19689': {
      //   setCurSchemes(schemesHSBC)
      //   break
      // }
      case TRUSTEE_GROUP_HSBC_PROVIDENT_FUND: {
        setCurSchemes(schemesHSBCProv)
        break
      }
      case '19698': {
        setCurSchemes(schemesManulife)
        break
      }
      case '44184': {
        setCurSchemes(schemesYFLife)
        break
      }
      case '19683': {
        setCurSchemes(schemesSunLife)
        break
      }
      case '19700': {
        setCurSchemes(schemesPrincipal)
        break
      }
      case '19695': {
        setCurSchemes(schemesSunLifeTrustee)
        break
      }
      case '19691': {
        setCurSchemes(schemesSHKP)
        break
      }
      default:
        setCurSchemes([phScheme])
    }
  }

  const handleTrusteeGroupChange = (e: any) => {
    const trustee = e.target.value
    setSelTrusteeGroup(trustee)
    setSelScheme('') // no effect against the custom select component
    setSelectedType('') // no effect against the custom select component
    setValue('trusteeAndScheme', '', {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue('transferType', '', { shouldValidate: true, shouldDirty: true })
    updateScheme(trustee)
    if (trustee === '19691') {
      setAccountTypes(
        ACCOUNT_TYPES.filter((e: any) => {
          return e.value !== ConsolidatedAccountDtoTransferTypeEnum.PA
        }),
      )
    } else {
      setAccountTypes(ACCOUNT_TYPES)
    }
  }

  const reasonOpts = Object.values(
    ConsolidatedAccountDtoTransferPartialOptionsEnum,
  )

  const data: ConsolidatedAccountDto = props.data || {}
  const onDialogClose = (event: object, reason: string) => {
    if (reason === 'backdropClick') {
      return
    }
    props.onClose()
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={onDialogClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='md'
        // TransitionComponent={Transition}
      >
        <DialogTitle id='form-dialog-title' className={classes.title}>
          {t('Transfer Account')}
        </DialogTitle>
        {/* <Divider /> */}
        <FormProvider {...hookFormFunc}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={6} md={6}>
                  <InputDynamicSelect
                    label={t('Name of original trustee')}
                    inputName='trusteeGroup'
                    handleOnChange={handleTrusteeGroupChange}
                    defaultValue={selTrusteeGroup}
                    items={trusteeName}
                    required
                  />

                  {/* <FormControl fullWidth variant='outlined'>
              <InputLabel
                htmlFor='trustee-input'
              >
                {t('Trustee Name')}
              </InputLabel>
              <Select
                // disabled={disabled}
                // value={selectedValue || ''}
                value={selTrustee}
                onChange={handleTrusteeChange}
                inputProps={{
                  name: 'trustee',
                  id: 'trustee-input',
                }}
                required
              >
                <MenuItem key='' value='Please select'>Please select</MenuItem>
                {_map(trusteeName, (val: any, idx: number) => {
                  return (
                    <MenuItem key={idx} value={val.label}>
                      {val.value}
                    </MenuItem>
                  )
                })}
              </Select>
              
            </FormControl>
            <FormError errors={errors.trustee}  /> */}
                </Grid>
                <Grid item xs={6} md={6}>
                  {/* <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              label={t('Original Plan')}
              name='originalPlan'
              inputRef={register}
              // className={classes.textField}
            /> */}
                  <InputDynamicSelect
                    label={t('Name of original scheme')}
                    inputName='trusteeAndScheme'
                    handleOnChange={handleSchemeChange}
                    defaultValue={selScheme}
                    items={curSchemes}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  label={t('Type of MPF account')}
                  name='mpfType'
                  inputRef={register}
                // className={classes.textField}
                /> */}
                  <InputDynamicSelect
                    label={t('Account Type')}
                    inputName='transferType'
                    handleOnChange={handleTypeChange}
                    defaultValue={selectedType}
                    items={accountTypes}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    defaultValue={data.accountNo}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label={t("Scheme Member's Account Number")}
                    name='accountNo'
                    inputRef={register({
                      // validate: ValidateResAddr,
                      maxLength: {
                        value: 40,
                        message: `${t('The max length is')} 40`,
                      },
                    })}
                  />
                  <FormError errors={errors['accountNo']} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    defaultValue={data.employerID}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label={t("Employer's identification number")}
                    name='employerID'
                    inputRef={register}
                  />
                </Grid>
                {selectedType === ConsolidatedAccountDtoTransferTypeEnum.PA ? (
                  <Grid item xs={6}></Grid>
                ) : (
                  <Grid item xs={6}>
                    <TextField
                      defaultValue={data.employerName}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label={t('Name of former employer')}
                      name='employerName'
                      inputRef={register}
                    />
                  </Grid>
                )}

                {selectedType !==
                  ConsolidatedAccountDtoTransferTypeEnum.CA2 && (
                  <>
                    <Grid item xs={12}>
                      <InputDynamicRadioGroup
                        inputName='hasVoluntary'
                        handleOnChange={handleChangeHasVoluntary}
                        legend={t(
                          'Any Voluntary Contributions in the Account?',
                        )}
                        value={hasVoluntary}
                        items={[
                          {
                            label: t('Yes'),
                            value: ConsolidatedAccountDtoHasVoluntaryEnum.Y,
                          },
                          {
                            label: t('No'),
                            value: ConsolidatedAccountDtoHasVoluntaryEnum.N,
                          },
                        ]}
                      />
                    </Grid>
                    {hasVoluntary ===
                      ConsolidatedAccountDtoHasVoluntaryEnum.Y && (
                      <>
                        <Grid item xs={12}>
                          <InputDynamicRadioGroup
                            inputName='voluntaryArrange'
                            value={voluntaryArrange}
                            legend={t(
                              'Arrangement of my voluntary contributions',
                            )}
                            handleOnChange={handleVoluntaryArrange}
                            items={[
                              {
                                label: t('Transfer'),
                                value:
                                  ConsolidatedAccountDtoVoluntaryArrangeEnum.T,
                              },
                              {
                                label: t('Withdraw'),
                                value:
                                  ConsolidatedAccountDtoVoluntaryArrangeEnum.W,
                              },
                            ]}
                          />
                        </Grid>
                      </>
                    )}
                    {hasVoluntary ===
                      ConsolidatedAccountDtoHasVoluntaryEnum.Y &&
                      voluntaryArrange ===
                        ConsolidatedAccountDtoVoluntaryArrangeEnum.W && (
                        <>
                          <Grid item xs={12}>
                            <InputDynamicRadioGroup
                              inputName='withdrawMethod'
                              legend={t('Withdraw Voluntary Contributions by')}
                              value={withdrawMethod}
                              handleOnChange={handleChangeWithdrawMethod}
                              items={[
                                {
                                  label: t('By cheque'),
                                  value:
                                    ConsolidatedAccountDtoWithdrawMethodEnum.C,
                                },
                                {
                                  label: t(
                                    'By depositing directly in a bank account',
                                  ),
                                  value:
                                    ConsolidatedAccountDtoWithdrawMethodEnum.B,
                                },
                              ]}
                            />
                          </Grid>
                        </>
                      )}
                    {voluntaryArrange ===
                      ConsolidatedAccountDtoVoluntaryArrangeEnum.W &&
                      withdrawMethod ===
                        ConsolidatedAccountDtoWithdrawMethodEnum.B && (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              defaultValue={data.bankName}
                              variant='outlined'
                              margin='normal'
                              fullWidth
                              label={t('Name of Bank')}
                              name='bankName'
                              inputRef={register}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              defaultValue={data.bankAccountHolder}
                              variant='outlined'
                              margin='normal'
                              fullWidth
                              label={t('Name of Bank Account Holder')}
                              name='bankAccountHolder'
                              inputRef={register}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              defaultValue={data.bankCode}
                              variant='outlined'
                              margin='normal'
                              fullWidth
                              label={t('Bank Code')}
                              name='bankCode'
                              inputRef={register}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              defaultValue={data.branchCode}
                              variant='outlined'
                              margin='normal'
                              fullWidth
                              label={t('Branch Code')}
                              name='branchCode'
                              inputRef={register}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              defaultValue={data.bankAccountNumber}
                              variant='outlined'
                              margin='normal'
                              fullWidth
                              label={t('Account Number')}
                              name='bankAccountNumber'
                              inputRef={register}
                            />
                          </Grid>
                        </>
                      )}
                  </>
                )}

                {selectedType ===
                  ConsolidatedAccountDtoTransferTypeEnum.CA1 && (
                  <>
                    <Grid item xs={6}>
                      <InputDynamicRadioGroup
                        legend={t('Transfer from self employed account?')}
                        inputName='isSelfEmployedAccount'
                        value={isSelfEmployedAccount}
                        items={[
                          {
                            label: t('Yes'),
                            value:
                              ConsolidatedAccountDtoIsSelfEmployedAccountEnum.Y,
                          },
                          {
                            label: t('No'),
                            value:
                              ConsolidatedAccountDtoIsSelfEmployedAccountEnum.N,
                          },
                        ]}
                        handleOnChange={handleIfSelfEmployedChange}
                      />
                    </Grid>

                    {isSelfEmployedAccount ===
                      ConsolidatedAccountDtoIsSelfEmployedAccountEnum.Y && (
                      <>
                        <Grid item xs={6}>
                          <InputDynamicRadioGroup
                            legend={t('Self employed Details')}
                            inputName='selfEmployedDetails'
                            value={selfEmployedDetails}
                            items={[
                              {
                                label: t('Terminate'),
                                value:
                                  ConsolidatedAccountDtoSelfEmployedDetailsEnum.T,
                              },
                              {
                                label: t('Ongoing'),
                                value:
                                  ConsolidatedAccountDtoSelfEmployedDetailsEnum.O,
                              },
                            ]}
                            handleOnChange={handleSelfEmployedDetail}
                          />
                        </Grid>
                        {selfEmployedDetails ===
                          ConsolidatedAccountDtoSelfEmployedDetailsEnum.T && (
                          <Grid item xs={6}>
                            <InputDate
                              name='terminationDate'
                              label={t('Termination Date')}
                              validationRule={{
                                required: t('Please fill in this field'),
                              }}
                              date={
                                data.terminationDate ||
                                moment(new Date()).format('YYYY-MM-DD')
                              }
                            />
                          </Grid>
                        )}
                        {selfEmployedDetails ===
                          ConsolidatedAccountDtoSelfEmployedDetailsEnum.O && (
                          <Grid item xs={6}>
                            <InputDate
                              name='payUntil'
                              label={t('Payment until')}
                              validationRule={{
                                required: t('Please fill in this field'),
                              }}
                              date={
                                data.payUntil ||
                                moment(new Date()).format('YYYY-MM-DD')
                              }
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}

                {selectedType ===
                  ConsolidatedAccountDtoTransferTypeEnum.CA2 && (
                  <>
                    <Grid item xs={6}>
                      <InputDynamicRadioGroup
                        legend={t('Transfer options')}
                        inputName='transferOption'
                        value={transferOption}
                        handleOnChange={handleTransferOptionChange}
                        items={acccountTransferOpt.filter((val: any) => {
                          if (
                            val &&
                            props.isS800 === ClientInformationDtoIsS800Enum.Y &&
                            props.accountType ===
                              ClientInformationDtoAccountTypeEnum.CA
                          ) {
                            return (
                              val.value ===
                              ConsolidatedAccountDtoTransferOptionEnum.P
                            )
                          }

                          return true
                        })}
                      />
                    </Grid>
                    {transferOption ===
                      ConsolidatedAccountDtoTransferOptionEnum.A && (
                      <Grid item xs={12}>
                        <ul className={classes.list}>
                          <li className={classes.listItem}>
                            <Trans i18nKey='modalac-q1a1'>
                              <u>ph1</u>ph2
                            </Trans>
                          </li>
                          <li className={classes.listItem}>
                            <Trans i18nKey='modalac-q1a2'>
                              <u>ph1</u>ph2
                            </Trans>
                          </li>
                          <li className={classes.listItem}>
                            <Trans i18nKey='modalac-q1a3'>
                              <u>ph1</u>ph2
                            </Trans>
                          </li>
                          <li className={classes.listItem}>
                            <Trans i18nKey='modalac-q1a4'>
                              <u>ph1</u>ph2
                            </Trans>
                          </li>
                        </ul>
                      </Grid>
                    )}
                    {transferOption ===
                      ConsolidatedAccountDtoTransferOptionEnum.P && (
                      <Grid item xs={6}>
                        {reasonOpts
                          .filter((val: any) => {
                            if (
                              props.isS800 ===
                                ClientInformationDtoIsS800Enum.Y &&
                              props.accountType ===
                                ClientInformationDtoAccountTypeEnum.CA
                            ) {
                              return (
                                val !==
                                  ConsolidatedAccountDtoTransferPartialOptionsEnum.M &&
                                val !==
                                  ConsolidatedAccountDtoTransferPartialOptionsEnum.V
                              )
                            }
                            return true
                          })
                          .map((val, idx) => {
                            return (
                              <InputDynamicCheckbox
                                key={idx}
                                inputName={`transferPartialOptions[${idx}]`}
                                label={t(`TRANSFER_PARTIAL_OPTIONS_${val}`)}
                                handleOnChange={(selected) => {
                                  let n = null
                                  if (selected) {
                                    n = [...transferPartialOptions, val]
                                  } else {
                                    n = transferPartialOptions.filter(
                                      (v: any) => v && v !== val,
                                    )
                                  }
                                  setTransferPartialOptions(n)
                                  setValue(
                                    'transferPartialOptionsValidation',
                                    n,
                                    { shouldValidate: true, shouldDirty: true },
                                  )
                                }}
                                defaultChecked={_includes(
                                  data.transferPartialOptions,
                                  val,
                                )}
                                value={val}
                              />
                            )
                          })}
                        <FormError
                          errors={errors.transferPartialOptionsValidation}
                        />
                        <input
                          name='transferPartialOptionsValidation'
                          defaultValue={transferPartialOptions}
                          type='hidden'
                          ref={register({
                            validate: (value: string) => {
                              return value
                                ? true
                                : 'Please select at least one option'
                            },
                          })}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                onClick={props.onClose}
                className={classes.button}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={classes.button}
              >
                {t('Submit')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  )
}

export default ModalAccount
