import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from 'client/reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const storeMiddleware =
  // swap below lines to enable redux logger
  // applyMiddleware(thunkMiddleware, logger)
  applyMiddleware(thunkMiddleware)
const store = createStore(rootReducer, composeEnhancers(storeMiddleware))

export default store
