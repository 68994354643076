import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import ChkBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import ChkChecked from '@material-ui/icons/CheckBoxOutlined'
import FormError from 'client/components/formErrorMsg'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

const useStyles = makeStyles(theme => ({
  button: {
    // margin: theme.spacing(1)
  },
  formControl: {
    //margin: theme.spacing(1)
  },
  group: {
    //margin: theme.spacing(1, 0)
  },
}))

interface Props {
  label: string
  inputName: string
  inputClass?: string
  defaultChecked?: boolean
  value?: string
  handleOnChange?: (isChecked: any) => void
  handleOnClick?: (e: any) => void
}

const InputDynamicCheckbox = ({
  label,
  inputName,
  inputClass,
  defaultChecked,
  value,
  handleOnChange,
  handleOnClick,
}: Props) => {
  const { register, errors } = useFormContext()

  //const [state, setState] = useState(isChecked)

  useEffect(() => {
    console.log('InputDynamicCheckbox, defaultChecked=', defaultChecked)
  }, [defaultChecked])

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={inputName}
            inputRef={register}
            className={inputClass}
            defaultChecked={defaultChecked}
            color='primary'
            icon={<ChkBlank color='primary' />}
            checkedIcon={<ChkChecked />}
            onClick={handleOnClick}
            onChange={(e: any) => {
              if (handleOnChange) {
                // @ts-ignore: Object is possibly 'null'.
                handleOnChange(e.target.checked)
              }
            }}
            value={value}
          />
        }
        label={label}
      />
      <FormError errors={errors[inputName]} />
    </>
  )
}

export default InputDynamicCheckbox
