import { withOidcSecure } from '@axa-fr/react-oidc-context'
import { Box, Grid } from '@material-ui/core'
// import AdobeSign from 'client/components/adobeSign'
import asyncComponent from 'client/components/asyncComponent'
import Error404 from 'client/Error404'
import React, { Suspense, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import TermsPersonalInfo from 'routes/termsPersonalInfo'
import AdminLogin from 'routes/adminLogin'
import Create from 'routes/create'
import Edit from 'routes/edit'
import Loading from 'routes/loading'
import LoginTerms from 'routes/loginTerms'
// import { OidcCallback, OidcSessionLost } from 'routes/oidcCB'
import ListSubmission from './listSubmission'
import PpoAdmin from './ppoAdmin'
import Submission from './submission'
import Home from 'routes/home'
import ListDraft from 'routes/listDraft'
import MrPersonalInfo from 'routes/mrPersonalInfo'
import MrDisclosure from 'routes/mrDisclosure'
import EditMR from './edit-mr'
import ListMr from './listMr'
import { useSelector } from 'react-redux'

// TODO: switch to loadable for SSR
// const Home = asyncComponent(() =>
//   import(/* webpackChunkName: 'home' */ 'routes/home'),
// )

// TODO: switch to loadable for SSR
// const ListDraft = asyncComponent(() =>
//   import(/* webpackChunkName: 'list-draft' */ 'routes/listDraft'),
// )

const WrappedPpoAdmin = withOidcSecure(PpoAdmin)
const WrappedHome = withOidcSecure(Home)
const PMrPersonalInfo = withOidcSecure(MrPersonalInfo as any)

interface Props {
  channel: string | null
}

const withAxiosAuthenticated = (Component: any) => {
  return (props: any) => {
    const isApiAuth = useSelector((state: any) => state.application.isApiAuth)
    if (isApiAuth) {
      return <Component {...props} />
    }
    return null
  }
}

const MyRouter = ({ channel = null }: Props) => {
  // const [activeStep, setActiveStep] = useState(1)
  // console.log('in router...')

  return (
    <Switch>
      <Route exact path='/' component={LoginTerms} />
      <Route path='/submission' component={Submission} />
      <Route
        path='/home'
        render={(props) => <WrappedHome channel={channel} routeProps={props} />}
      />
      {/* <Route path='/callback' component={OidcCallback}  /> */}
      <Route
        path='/list-draft'
        component={withOidcSecure(withAxiosAuthenticated(ListDraft))}
      />
      <Route
        path='/list-mr'
        component={withOidcSecure(withAxiosAuthenticated(ListMr))}
      />
      <Route
        path='/list-submission'
        component={withOidcSecure(withAxiosAuthenticated(ListSubmission))}
      />
      {/* <Route path='/style-guide' component={StyleGuide} /> */}
      {/* <Route path='/adobe-sign' component={AdobeSign} /> */}
      <Redirect
        exact
        from='/edit/:id'
        to='/edit/:id/personal-information-collection'
      />
      <Redirect
        exact
        from='/create'
        to='/create/personal-information-collection'
      />
      <Redirect exact from='/edit-mr/:id' to='/edit-mr/:id/create-mr' />
      <Route path='/edit/:id' render={(props) => <Edit {...props} />} />
      <Route path='/create' render={(props) => <Create {...props} />} />
      <Route path='/adm' component={AdminLogin} />
      <Route
        path='/ppo-admin'
        render={(props) => <WrappedPpoAdmin {...props} />}
      />
      {(channel === 'MR' || channel === 'BANK') && (
        <Route path='/edit-mr/:id' render={(props) => <EditMR {...props} />} />
      )}
      {(channel === 'MR' || channel === 'BANK') && (
        <Route path='/create-mr'>
          <PMrPersonalInfo activeStep={0}></PMrPersonalInfo>
        </Route>
      )}
      {/** TODO: return 404 http status */}
      <Route
        render={({ staticContext, location }) => {
          if (staticContext) {
            staticContext.statusCode = 404
          }
          return <Error404 location={location} />
        }}
      />
    </Switch>
  )
}

export default MyRouter
