import React from 'react'
import { Link } from '@material-ui/core'

export default function Dialog1zh() {
  return (
    <div>
      <p>
        身為美國人士（即美國公民或美國永久居民）之成員應從以下鏈結閱讀有關美國13959
        號行政命令的成員通知：
      </p>
      <ol>
        <li>
          關於信安強積金計劃800系列及信安強積金－明智之選的通知：美國人士對投資於盈富基金的投資
          [
          <Link
            href='https://www.principal.com.hk/sites/default/files/general-files/EO13959%20Notice_Chi.pdf'
            target='_blank'
          >
            連結
          </Link>
          ]
        </li>
        <li>
          致參與強積金及公積金計劃之人士關於美國13959號行政命令之通知 [
          <Link
            href='https://www.principal.com.hk/sites/default/files/general-files/EO13959_Notice_chi_10%20_Feb_2021.pdf'
            target='_blank'
          >
            連結
          </Link>
          ]
        </li>
      </ol>
    </div>
  )
}
