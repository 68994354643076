import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Button,
  Slide,
} from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { t } from 'i18next'
import { UploadDocumentDtoTypeEnum } from 'client/api/application/api'
import { Fragment, useState, useEffect } from 'react'
import React from 'react'
import { Transition } from 'client/components/common/transition'
import FormError from 'client/components/formErrorMsg'
import { useTranslation } from 'react-i18next'

interface ModalDocumentTypeProps {
  open: boolean
  onClose: () => void
  onAdd: (
    documentType: UploadDocumentDtoTypeEnum,
    otherDocumentType: string,
  ) => void
}

const ModalDocumentType = (props: ModalDocumentTypeProps) => {
  const { open, onClose, onAdd } = props
  const [t, i18n] = useTranslation()

  const hookFormFunc = useForm({
    mode: 'onBlur',
  })
  const { handleSubmit, register, errors, trigger } = hookFormFunc

  const [
    selectedDocumentType,
    setSelectedDocumentType,
  ] = useState<UploadDocumentDtoTypeEnum>(UploadDocumentDtoTypeEnum.OTHERS)
  const [otherDocumentType, setOtherDocumentType] = useState<string>('')

  const onSubmit = (formData: any) => {
    onAdd(selectedDocumentType, otherDocumentType)
  }

  const handleDocumentTypeChange = (event: any) => {
    setSelectedDocumentType(event.target.value)
    setOtherDocumentType('')
    trigger('otherDocumentType')
  }

  const handleOtherDocumentTypeChange = (event: any) => {
    setOtherDocumentType(event.target.value)
  }

  useEffect(() => {
    if (open) {
      setSelectedDocumentType(UploadDocumentDtoTypeEnum.OTHERS)
      setOtherDocumentType('')
    }
  }, [open])

  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <FormProvider {...hookFormFunc}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <DialogTitle id='alert-dialog-slide-title'>
            {t('Please select a document type')}
          </DialogTitle>
          <DialogContent>
            <FormControl variant='outlined' fullWidth style={{ minWidth: 400 }}>
              <Select
                onChange={handleDocumentTypeChange}
                defaultValue={selectedDocumentType}
              >
                <MenuItem
                  key={UploadDocumentDtoTypeEnum.OTHERS}
                  value={UploadDocumentDtoTypeEnum.OTHERS}
                >
                  {t(`uploadDocumentType:::${UploadDocumentDtoTypeEnum.OTHERS}`)}
                </MenuItem>
                {/* <MenuItem
                  key={UploadDocumentDtoTypeEnum.IDENTITYDOCUMENT}
                  value={UploadDocumentDtoTypeEnum.IDENTITYDOCUMENT}
                >
                  {t(
                    `uploadDocumentType:::${UploadDocumentDtoTypeEnum.IDENTITYDOCUMENT}`,
                  )}
                </MenuItem> */}
              </Select>
              {selectedDocumentType === UploadDocumentDtoTypeEnum.OTHERS && (
                <Fragment>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    inputProps={{ maxLength: 30 }}
                    defaultValue={otherDocumentType || ''}
                    onChange={handleOtherDocumentTypeChange}
                    fullWidth
                    name='otherDocumentType'
                    placeholder={t('Document type')}
                    inputRef={register({
                      required: 'Please fill in this field',
                    })}
                  />
                  <FormError errors={errors[`otherDocumentType`]} />
                </Fragment>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='primary'>
              {t('Cancel')}
            </Button>
            <Button type='submit' color='primary'>
              {t('OK')}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
export default ModalDocumentType
