import { Button, Grid, Link, Paper } from '@material-ui/core'
// import _debounce from 'lodash/debounce'
import React, { createRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { InView } from 'react-intersection-observer'
interface Props {
  to: string
  submitBtnTxt?: string
  children?: React.ReactNode
}

const AdapterLink: any = React.forwardRef((props: any, ref: any) => (
  <Link innerRef={ref} {...props} />
))

const TermsAndConditions: React.FC<Props> = (props: Props) => {
  // const {
  //   handleSubmit,
  //   register,
  //   errors,
  //   setValue,
  //   watch,
  //   getValues
  // } = useForm({ mode: 'onBlur' })

  const history = useHistory()
  const { t } = useTranslation()
  
  const submitBtnTxt = props.submitBtnTxt || t('I accept')

  const contentRef: any = createRef()
  // try to reset scrollbar on first render
  // const contentRef = (el: any) => {
  //   if (el) {
  //     console.log('in cb... ', el.getBoundingClientRect())
  //     window.scrollTo(0,0)
  //   }
  //   return null
  // }
  // const scrollWatcher = () contentRef.current.getboundingclientrect()
  // window.scrollTo(0,0)
  
  const [acceptBtn, DisableAcceptBtn] = useState(true)
  // const  { pathname, search } = useRouter();

  function handleAccept() {
    history.push(props.to)
  }

  // const scrollHandler: any = _debounce((e: any) => {
  //   if (contentRef.current) {
  //     const clientRect = contentRef.current.getBoundingClientRect()
  //     console.log(
  //       'in scrollhandler... ',
  //       window.scrollY,
  //       clientRect,
  //       contentRef.current.getClientRects(),
  //     )
  //     // TODO: rework logic
  //     DisableAcceptBtn(false)
  //     if (clientRect.bottom <= 1020) {
  //       DisableAcceptBtn(false)
  //     }
  //   }
  //   // const bottomY = 3500
  //   // if (window.scrollY >= bottomY) {
  //   //   DisableAcceptBtn(false)
  //   //   // console.log('current state... ', acceptBtn)
  //   // }
  // }, 300)

  // window.addEventListener('scroll', scrollHandler)
  
  // useEffect(() => {
  //   // console.log('hihi... ', contentRef.current)
  //   // console.log('hi,,. ', window.scrollbars.visible)
  //   scrollHandler()
  // }, [contentRef.current])

  // useEffect(() => {
  //   // if (contentRef.current.clientHeight) {
  //   //   console.log('in effect... ', window.scrollY, contentRef.current.clientHeight)
  //   //   window.scrollTo(0, 0)
  //   //   console.log('in effect... ', window.scrollY)
  //   // }

  //   // const el = contentCont.get .getBoundingClientRect().bottom
  //   // console.log('in effect...', contentRef.current.getBoundingClientRect())

  //   // contentRef.current.scrollTo(0, 0)
  //   // contentRef.current.scrollTop = 0
  //   // window.scrollY = 0
  // })

  // const contentRefCb = useCallback(node => {
  //   console.log('node... ', node)
  //   if (node !== null) {
  //     console.log('node 2... ', node)
  //   }
  // }, [])
  
  // const [ref, inView, entry] = useInView({
  //   /* Optional options */
  //   threshold: 0,
  // })

  return (
    <Grid item xs={12} innerRef={contentRef}>
      <Paper>
        {props.children}
      </Paper>
      
      <InView threshold={0.8} onChange={(inView, entry) => {
        // console.log('inview... ', inView, entry)
        DisableAcceptBtn(!inView)
       } }>
        <Button
          fullWidth
          disabled={acceptBtn}
          variant='contained'
          color='primary'
          component={AdapterLink}
          // TODO: tmp fix for broken forwardRef with <Link>
          // to='/home'
          onClick={handleAccept}
        >
          {submitBtnTxt}
        </Button>
      </InView>
    </Grid>
  )
}

export default TermsAndConditions
