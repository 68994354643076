import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    backgroundColor: '#464E7E',
    color: '#ffffff',
  },
  button: {
    backgroundColor: '#464E7E',
    color: '#ffffff',
    '&:hover': {
      color: '#464E7E'
    }
  },
  list: {
    margin: '0',
    listStyleType: 'disc',
  },
  listItem: {
    fontSize: '1rem',
    padding: '5px 0',
  },
}))
