import React from 'react'
import Button from '@material-ui/core/Button'
import _assign from 'lodash/assign'
import {
  enqueueNotification,
  dequeueNotification,
} from './applicationNotification'

export const TOGGLE_STEP = 'TOGGLE_STEP'
export const INIT_APPLICATION_STEP = 'INIT_APPLICATION_STEP'

/**
 * Enable or disable step in the stepper
 *
 * @param {string} stepId
 * @param {boolean} isActive
 * @returns
 */
export const initApplicationStepper = (distributionChannelCode: string) => {
  return (dispatch: any) => {
    dispatch({
      distributionChannelCode,
      type: INIT_APPLICATION_STEP,
    })
  }
}

/**
 * Enable or disable step in the stepper
 *
 * @param {string} stepId
 * @param {boolean} isActive
 * @returns
 */
export const toggleStep = (stepId: string, isActive: boolean) => {
  return (dispatch: any) => {
    dispatch({
      stepId,
      type: TOGGLE_STEP,
      isActive,
    })
  }
}

export const TOGGLE_STEP_ERROR = 'TOGGLE_STEP_ERROR'
export const toggleStepError = (stepId: string, error: boolean) => {
  return (dispatch: any) => {
    dispatch({
      stepId,
      type: TOGGLE_STEP_ERROR,
      error,
    })
    if (error) {
      dispatch(
        enqueueNotification(true, stepId + '.error', `Error in ${stepId}`, {
          variant: 'error',
          persist: true,
          action: (key: string) => (
            <Button
              style={{ color: '#FFF' }}
              onClick={() => dispatch(dequeueNotification(key, true))}
            >
              Close
            </Button>
          ),
        }),
      )
    }
  }
}
