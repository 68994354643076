import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context'
import {
  fetchApplicationData,
  resetApplicationData,
  upsertPirsData,
} from 'client/actions'
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { appInsights } from 'utils/appInsights'
import { useHistory } from 'react-router'
import { MR_APPLICATION_FORM_STEPS } from 'client/components/steppers/mr'

const EditMR: FunctionComponent<any> = ({ match }) => {
  const { path, params } = match
  const history = useHistory()
  const [isLoaded, setIsLoaded] = useState(false)
  const { oidcUser } = useReactOidc()
  const isApiAuth = useSelector((state: any) => state.application.isApiAuth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isApiAuth) {
      Promise.resolve(dispatch(fetchApplicationData(params.id)) as any)
        .then(() => {
          setIsLoaded(true)
        })
        .catch((error) => {
          // alert(formatHttpError(error))
          appInsights.trackEvent({
            name: 'RemoteRequestErr-Edit',
            properties: {
              data: error,
            },
          })
        })
    }
  }, [isApiAuth])

  const applicationData = useSelector((state: any) => {
    return state.application.data
  })

  if (!applicationData) return null

  /**
   * For render function using <Route> component
   *
   * @param {string} path file path of component
   * @param {string} stepperID ID for material UI stepper
   *q-
   */
  function renderFunc(path: any, props: any) {
    const Comp = React.lazy(path)
    const WrappedComp = withOidcSecure(Comp)
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <WrappedComp {...props} />
      </Suspense>
    )
  }

  function goNextStep(activeStep: number, path: string) {
    const nextTo =
      activeStep < MR_APPLICATION_FORM_STEPS.length - 1
        ? MR_APPLICATION_FORM_STEPS[activeStep + 1].to
        : undefined
    //const isExistingRecord = applicationData && applicationData._id

    //alert( (applicationData as any)['id'])
    Promise.resolve(dispatch(upsertPirsData(true)))
      .then((result: any) => {
        console.log('result', result)
        //navigate to next screen after record is saved for new record
        // if (result.data && !isExistingRecord) {
        //   setTimeout(() => {
        //     path =
        //       path || nextTo ? `/edit-mr/${result.data._id}/${nextTo}` : '/home'
        //     history.push(path)
        //   }, 0)
        // }
      })
      .catch((err) => console.error(err))
    //navigate to next screen asynchronously while the record is being saved
    path =
      path ||
      (nextTo ? `/edit-mr/${applicationData._id || 'temp'}/${nextTo}` : '/home')
    history.push(path)
  }

  return (
    <Switch>
      <Route
        path={`${path}/create-mr`}
        render={(props) => {
          return renderFunc(() => import('routes/mrPersonalInfo'), {
            ...props,
            applicationData,
            activeStep: 0,
          })
        }}
      ></Route>
      <Route
        path={`${path}/create-mr-address`}
        render={(props) => {
          return renderFunc(() => import('routes/mrAddress'), {
            ...props,
            applicationData,
            activeStep: 1,
            goNextStep,
          })
        }}
      ></Route>
      <Route
        path={`${path}/create-mr-client-confirmation`}
        render={(props) => {
          return renderFunc(() => import('routes/mrClientConfirm'), {
            ...props,
            applicationData,
            activeStep: 2,
            goNextStep,
          })
        }}
      ></Route>
      <Route
        path={`${path}/rpq`}
        render={(props) => {
          return renderFunc(() => import('routes/rpq'), {
            ...props,
            applicationData,
            activeStep: 3,
            goNextStep,
          })
        }}
      ></Route>
      <Route
        path={`${path}/mr-fna`}
        render={(props) => {
          return renderFunc(() => import('routes/mrFna'), {
            ...props,
            applicationData,
            activeStep: 4,
            goNextStep,
          })
        }}
      ></Route>
      <Route
        path={`${path}/mr-investment-advice-record`}
        render={(props) => {
          return renderFunc(() => import('routes/investmentAdvice'), {
            ...props,
            activeStep: 5,
            goNextStep,
          })
        }}
      ></Route>
      <Route
        path={`${path}/create-mr-disclosure`}
        render={(props) => {
          return renderFunc(() => import('routes/mrDisclosure'), {
            ...props,
            applicationData,
            activeStep: 6,
            goNextStep,
          })
        }}
      ></Route>
      <Route
        path={`${path}/mr-summary`}
        render={(props) => {
          return renderFunc(() => import('routes/mrSummary'), {
            ...props,
            applicationData,
            activeStep: 7,
            goNextStep,
          })
        }}
      ></Route>
    </Switch>
  )
}

export default EditMR
