import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import { Transition } from 'client/components/common/transition'
import { useTranslation } from 'react-i18next'
import React from 'react'

interface Props {
  open: boolean
  handleAgree: any
  handleDisagree: any
  title: string
  children: React.ReactNode
  fullScreen?: boolean
}

export default function AgreeDialog({
  open,
  handleAgree,
  handleDisagree,
  title,
  children,
  fullScreen,
}: Props) {
  const { t } = useTranslation()
  // set fullscreen to true if no defined
  fullScreen = fullScreen == null ? true : fullScreen

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // keepMounted
      onClose={handleDisagree}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      fullScreen={fullScreen}
    >
      <DialogTitle id='alert-dialog-slide-title'>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => handleDisagree()} color='primary'>
          {t('Disagree')}
        </Button>
        <Button onClick={() => handleAgree()} color='primary'>
          {t('Agree')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
