import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  outerCont: {
    'margin-top': 10
  },
  h3: {
    'margin-bottom': 24
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: theme.spacing(1, 0)
  }
}))

export const styledTextField = styled(TextField)`
  margin-top: 8px
` 