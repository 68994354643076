import {
  Theme,
  createStyles,
  TableCell,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React from 'react'
import { StyledTableCell } from '../style'
import moment from 'moment'
import { ClientInformationDtoIsS800Enum } from 'client/api/application/api'
import { PpoAdminTableRowProps } from '../ppoAdminTableRow'

export function FtLifeQueryPageBody(props: PpoAdminTableRowProps) {
  const { value, selected, handleSelect, referenceId } = props

  return (
    <>
      <StyledTableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={selected || false}
              onChange={(e: any) => {
                handleSelect(value.id, e.target.checked)
              }}
              value='itemChecked'
              color='primary'
            />
          }
          label={<span style={{ fontSize: 'small' }}>{referenceId}</span>}
        />
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value.submittedTime
          ? moment(value.submittedTime)
              .tz('Asia/Hong_Kong')
              .format('MM/DD/YYYY')
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          maxWidth: '250px',
        }}
      >{`${value.clientName}`}</StyledTableCell>
      {value.submissionSummary && value.submissionSummary.forms && (
        <StyledTableCell align='left'>
          {value.submissionSummary.forms.map((elem: any, index: any) => {
            return <div key={index}>{elem.label}</div>
          })}
        </StyledTableCell>
      )}
      {value.agentInformation && (
        <>
          <StyledTableCell align='left'>{`${value.agentInformation.unitCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.sourceCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.agentCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.agentName}`}</StyledTableCell>
        </>
      )}
      <StyledTableCell align='center'>
        {value &&
        value.submissionSummary &&
        value.submissionSummary.ppoReceivedDate
          ? `${moment(value.submissionSummary.ppoReceivedDate)
              .tz('Asia/Hong_Kong')
              .format('MM/DD/YYYY')}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value &&
        value.submissionSummary &&
        value.submissionSummary.isVulnerableClient
          ? `${value.submissionSummary.isVulnerableClient}`
          : 'N/A'}
      </StyledTableCell>
      {/* <StyledTableCell align='center'>{`${
        value.clientInformation.isS800 === ClientInformationDtoIsS800Enum.N
          ? 'Yes'
          : 'No'
      }`}</StyledTableCell>
      <StyledTableCell align='center'></StyledTableCell>
      <StyledTableCell align='center'>PRI</StyledTableCell>
      <StyledTableCell align='center'>PA</StyledTableCell>
      <StyledTableCell align='center'>{`${
        value.clientInformation.identificationNumber || ''
      }`}</StyledTableCell>
      <StyledTableCell align='center'>{value.numOfPcForm}</StyledTableCell>
      <StyledTableCell align='center'>{value.numOfPmForm}</StyledTableCell>
      <StyledTableCell align='center'>{value.numOfPpForm}</StyledTableCell>
      <StyledTableCell align='center'>{value.address1}</StyledTableCell>
      <StyledTableCell align='center'>{value.address2}</StyledTableCell> */}
      <StyledTableCell align='center'>
        {value &&
        value.submissionSummary &&
        value.submissionSummary.isClientSignedBySms
          ? `${value.submissionSummary.isClientSignedBySms}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value && value.isRemoteSigning ? 'Y' : 'N'}
      </StyledTableCell>
    </>
  )
}
