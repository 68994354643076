import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  outerCont: {
    'margin-top': 10,
  },
  form: {
    width: '100%',
  },
  h3: {
    'margin-bottom': 24,
  },
  caption: {
    'font-size': 20
  },
  risk: {
    display: 'inline-block',
    width: 16,
    height: 16,
    borderRadius: 50,
    verticalAlign: 'middle',
  },
  riskL: {
    backgroundColor: '#6dd400',
  },
  riskM: {
    backgroundColor: '#ffe66a',
  },
  riskMH: {
    backgroundColor: '#f78700',
  },
  riskH: {
    backgroundColor: '#e02020',
  },
  riskML: {
    backgroundColor: '#f6d55c',
  }
}))
