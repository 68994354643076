import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import { Transition } from 'client/components/common/transition'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

interface Props {
  open: boolean
  handleClose: any
  title: string
  children: React.ReactNode
}

export default function Dialog1(props: Props) {
  const { t } = useTranslation()
  const handleClose = () => {
    props.handleClose()
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      // keepMounted
      // onClose={handleDisagree}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
