import React, { useState, useEffect, useRef } from 'react'
import { Grid, Typography, Slider, Hidden } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LoadingOverlay from 'react-loading-overlay'
import { UploadApi } from 'client/api/application/api'
import { BASE_PATH } from 'config'

const PreviewLoader = (props: any) => {
  const uploadApi = new UploadApi(undefined, BASE_PATH)

  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  })

  const loadImage = async (imageId: string) => {
    let result = null
    if (imageId) {
      try {
        const response = await uploadApi.uploadsIdGet(imageId, 'large', {
          responseType: 'blob',
        })
        result = window.URL.createObjectURL(response.data)
      } catch (ex) {}
    }
    return result
  }

  const [imageBlob, setImageBlob] = useState<any>('')
  const [isLoading, setIsLoading] = useState(true)
  const [previewBrightness, setPreviewBrightness] = useState(100)
  const [previewContrast, setPreviewContrast] = useState(1000)
  // const [previewFilter, setPreviewFilter] = useState(
  //   'grayscale(100%) contrast(10000%) brightness(100%)',
  // )

  const getPreviewFilter = () => {
    return `grayscale(100%) contrast(${previewContrast}%) brightness(${previewBrightness}%)`
  }

  useEffect(() => {
    const init = async () => {
      const imageBlobUrl = props.imageId.startsWith('blob:')
        ? props.imageId
        : await loadImage(props.imageId)
      if (isMountedRef.current) {
        setImageBlob(imageBlobUrl)
        setIsLoading(false)
      }
    }
    if (props.imageId) {
      init()
    }
  }, [props.imageId])

  useEffect(() => {
    setImageBlob(props.previewImg)
  }, [props.previewImg])

  function handleBrightnessChange(value: number) {
    setPreviewBrightness(value)
    return `${value}%`
  }

  function handleContrastChange(value: number) {
    setPreviewContrast(value)
    return `${value}%`
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(0, 0, 0, 0)',
          color: '#0091da',
        }),
        spinner: (base: any) => ({
          ...base,
          '& svg circle': {
            stroke: 'rgba(0, 145, 218, 0.5)',
          },
        }),
      }}
      text='Loading Image...'
    >
      <div style={{ minHeight: '400px', minWidth: '400px' }}>
        <img
          alt=''
          style={{
            width: '100%',
            height: '100%',
            maxHeight: '500px',
            objectFit: 'contain',
            borderRadius: '20px',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            //filter: getPreviewFilter(),
          }}
          onLoad={() => {
            //setIsPreviewloading(false)
          }}
          src={imageBlob}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '0px',
          height: '100%',
          right: '-100px',
          visibility: 'hidden',
        }}
      >
        {previewBrightness}
        <Slider
          orientation='vertical'
          getAriaValueText={handleBrightnessChange}
          defaultValue={100}
          min={100}
          max={1000}
          aria-labelledby='vertical-slider'
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '0px',
          height: '100%',
          right: '-150px',
          visibility: 'hidden',
        }}
      >
        {previewContrast}
        <Slider
          orientation='vertical'
          getAriaValueText={handleContrastChange}
          defaultValue={1000}
          min={500}
          max={5000}
          aria-labelledby='vertical-slider'
        />
      </div>
    </LoadingOverlay>
  )
}

export default PreviewLoader
