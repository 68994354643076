import * as React from 'react'

const sessionLost = () => (
  <div>
    <h1>Session expired</h1>
    <p>Your session has expired. Please go back to login again.</p>
    {/* <button type='button' onClick={onAuthenticate}>Authenticate</button> */}
  </div>
)

export default sessionLost
