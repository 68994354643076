import {
  FormControlLabel,
  Checkbox,
  Button,
  Link,
  Divider,
  Grid,
  IconButton,
  TableCell,
  Box,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { StyledTableAppendixCell, StyledTableCell } from '../style'
import moment from 'moment'
import { PpoAdminTableRowProps } from '../ppoAdminTableRow'
import AppendixUploader from '../appendixUploader'
import { AppendixtDto, SubmissionsApi } from 'client/api/application/api'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { PpoAdminContext } from '..'
import { PPO_BASE_PATH } from 'config'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DialogAgree from 'client/components/common/agreeDialog'

const api = new SubmissionsApi(undefined, PPO_BASE_PATH)

export function MrQueryPageBody(props: PpoAdminTableRowProps) {
  const { value, selected, handleSelect, referenceId } = props
  const [uploadContext, setUploadContext] = useState<{ applicationId: string }>(
    {} as any,
  )
  const [isAgreeDialogOpen, setIsAgreeDialogOpen] = useState(false)
  const [appendixToDelete, setAppendixToDelete] = useState<AppendixtDto | null>(
    null,
  )
  const { fetchData } = React.useContext(PpoAdminContext)

  const downloadAppendix = async (
    applicationId: string,
    appendix: AppendixtDto,
  ) => {
    const response = await api.adminUploadAppendicesIdGet(
      applicationId,
      appendix.uploadId,
      { responseType: 'blob' },
    )
    const a = document.createElement('a')
    document.body.appendChild(a)
    const url = window.URL.createObjectURL(response.data)
    a.href = url
    //a.download = appendix.fileName
    a.target = '_blank'
    a.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }

  const deleteAppendix = async (
    applicationId: string,
    appendix: AppendixtDto,
  ) => {
    try {
      await api.adminUploadAppendicesIdDelete(applicationId, appendix.uploadId)
    } catch (ex) {
      alert(ex)
    }
  }

  return (
    <>
      <StyledTableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={selected || false}
              onChange={(e: any) => {
                handleSelect(value.id, e.target.checked)
              }}
              value='itemChecked'
              color='primary'
            />
          }
          label={<span style={{ fontSize: 'small' }}>{referenceId}</span>}
        />
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value.submittedTime
          ? moment(value.submittedTime)
              .tz('Asia/Hong_Kong')
              .format('MM/DD/YYYY')
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          maxWidth: '250px',
        }}
      >{`${value.clientName}`}</StyledTableCell>
      {value.submissionSummary && value.submissionSummary.forms && (
        <StyledTableCell align='left'>
          {value.submissionSummary.forms.map((elem: any, index: any) => {
            return <div key={index}>{elem.label}</div>
          })}
        </StyledTableCell>
      )}
       <StyledTableCell align='left'>{`${value.rpqScore}`}</StyledTableCell>
       <StyledTableCell align='left'>{`${value.rpqType}`}</StyledTableCell>
      {value.agentInformation && (
        <>
          <StyledTableCell align='left'>{`${value.agentInformation.unitCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.sourceCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.agentCode}`}</StyledTableCell>
          <StyledTableCell align='left'>{`${value.agentInformation.agentName}`}</StyledTableCell>
        </>
      )}
      <StyledTableCell align='center'>
        {value &&
        value.submissionSummary &&
        value.submissionSummary.ppoReceivedDate
          ? `${moment(value.submissionSummary.ppoReceivedDate)
              .tz('Asia/Hong_Kong')
              .format('MM/DD/YYYY')}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='center'>
        {value &&
        value.submissionSummary &&
        value.submissionSummary.isVulnerableClient
          ? `${value.submissionSummary.isVulnerableClient}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableAppendixCell align='center'>
        <Grid container direction='column' spacing={1}>
          <Grid item xs={12}>
            <IconButton
              color='primary'
              onClick={() => {
                //setIsUploadDialogOpen(true)
                setUploadContext({ applicationId: value.id })
              }}
            >
              <CloudUploadIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {value.appendices &&
              value.appendices.map((appendix: AppendixtDto) => {
                return (
                  <Grid key={appendix.uploadId} container direction='row' alignItems='center'>
                    <Grid item xs={10}>
                      <Link
                        style={{ wordBreak: 'break-word' }}
                        key={appendix.uploadId}
                        component='button'
                        variant='body2'
                        onClick={() => {
                          downloadAppendix(value.id, appendix)
                        }}
                      >
                        {appendix.fileName}
                      </Link>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => {
                          setAppendixToDelete(appendix)
                          setIsAgreeDialogOpen(true)
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      </StyledTableAppendixCell>
      <TableCell hidden={true}>
        <AppendixUploader
          uploadContext={uploadContext}
          handleClose={() => {
            if (fetchData) {
              fetchData()
            }
          }}
        />
        <DialogAgree
          fullScreen={false}
          open={isAgreeDialogOpen}
          handleAgree={async () => {
            if (appendixToDelete) {
              await deleteAppendix(value.id, appendixToDelete)
              if (fetchData) {
                fetchData()
              }
              setIsAgreeDialogOpen(false)
            }
          }}
          handleDisagree={() => {
            setIsAgreeDialogOpen(false)
          }}
          title={''}
        >
          <Typography>
            Are you sure to remove{' '}
            <strong>{appendixToDelete ? appendixToDelete.fileName : ''}</strong>
            ?
          </Typography>
        </DialogAgree>
      </TableCell>
    </>
  )
}
