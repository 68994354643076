import React, { useEffect, useState } from 'react'
import FormError from 'client/components/formErrorMsg'
import {
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(1)
  },
  formControl: {
    //margin: theme.spacing(1)
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },
  input: {
    padding: theme.spacing(1),
  },
  group: {
    //margin: theme.spacing(1, 0)
  },
  root: {
    '&$disabled': {
      color: '#000',
    },
  },
  disabled: {
    '&$disabled': {
      color: '#000',
    },
  },
}))

interface Props {
  inputName: string
  inputClass?: string
  items: Items[]
  legend?: string
  value?: any
  handleOnChange?: any
  notRequired?: boolean
  disabled?: boolean
}

interface Items {
  label: string
  value: string
}

const InputDynamicRadioGroup = (props: Props) => {
  const classes = useStyles()
  const data = useFormContext()

  const [selectedValue, setSelectedValue] = useState()
  const [t] = useTranslation()

  useEffect(() => {
    setSelectedValue(props.value)
  }, [props.value])

  const MSG_PLEASE_FILL_IN_THIS_FIELD = t('Please fill in this field')

  return (
    <FormControl component='fieldset' className={classes.formControl}>
      <FormGroup row>
        <FormLabel component='legend' className={classes.legend}>
          {props.legend}
        </FormLabel>
        <RadioGroup
          row
          name={props.inputName}
          className={classes.group}
          onChange={(e: any) => {
            setSelectedValue(e.target.value)
            if (props.handleOnChange) {
              props.handleOnChange(e.target.value)
            }
          }}
          value={selectedValue || ''}
        >
          {props.items.map((val: any, idx: number) => (
            <FormControlLabel
              disabled={props.disabled}
              key={`${props.inputName}_${idx}`}
              value={val.value}
              control={<Radio className={props.inputClass || classes.input} />}
              label={t(val.label)}
              inputRef={data.register({
                required: props.notRequired
                  ? false
                  : MSG_PLEASE_FILL_IN_THIS_FIELD,
              })}
              classes={{
                root: classes.root,
                disabled: classes.disabled,
              }}
            />
          ))}
        </RadioGroup>
        <FormError errors={data.errors[props.inputName]} />
      </FormGroup>
    </FormControl>
  )
}

export default InputDynamicRadioGroup
