import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Button,
  Slide,
  Switch,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { t } from 'i18next'
import { UploadDocumentDtoTypeEnum } from 'client/api/application/api'
import { Fragment, useState, useEffect, useRef, useCallback } from 'react'
import React from 'react'
import { Transition } from 'client/components/common/transition'
import FormError from 'client/components/formErrorMsg'
import { useTranslation } from 'react-i18next'
import Slider from '@material-ui/core/Slider'
import LoadingOverlay from 'react-loading-overlay'
import * as Comlink from 'comlink'
/* eslint-disable import/no-webpack-loader-syntax */
const Worker = require('worker-loader!./worker')
const canvasWorker = new Worker()

const canvasWorkerWrapper: any = Comlink.wrap(canvasWorker)

interface ModalDocumentTypeProps {
  dataUrl: string
  open: boolean
  onClose: () => void
  onAdd: (biLevelImage: Blob, orgImage: Blob) => void
}

interface FilterOption {
  algorithm: string
  thresholdValue: number
}

const thresholdMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 255,
    label: '255',
  },
]

const fillCanvas = (
  dataUrl: string,
  canvasElement: HTMLCanvasElement,
  isApplyFilter: boolean,
  filterOption: FilterOption | null,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    try {
      const ctx = canvasElement.getContext('2d')
      const img = new Image()
      img.onload = async () => {
        if (ctx) {
          const { width, height } = img
          if (width < height) {
            canvasElement.width = width
            canvasElement.height = height
          } else {
            canvasElement.width = width
            canvasElement.height = height
          }
          ctx.drawImage(img, 0, 0, width, height) // Or at whatever offset you like
          /***
           * TESTING
           */
          if (isApplyFilter && filterOption) {
            await applyFilter(
              canvasElement,
              filterOption.algorithm,
              filterOption.thresholdValue,
            )
            resolve()
          } else {
            resolve()
          }
        }
      }
      img.src = dataUrl
    } catch (ex) {
      reject(ex)
    }
  })
}

async function applyFilter(
  canvasElement: HTMLCanvasElement,
  algorithm: string,
  thresholdValue: number,
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // const worker = require('workerize-loader!./worker') // eslint-disable-line import/no-webpack-loader-syntax
      // const canvasWorker = worker()
      const ctx = canvasElement.getContext('2d')
      if (ctx) {
        const displayImageData = ctx.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height,
        )
        const img = await canvasWorkerWrapper.draw({
          image: {
            data: displayImageData,
            width: canvasElement.width,
            height: canvasElement.height,
          },
          processing: {
            greyscaleMethod: 'average',
            ditherMethod: algorithm,
            ditherThreshold: thresholdValue,
            replaceColours: false,
            replaceColourMap: {
              black: {
                r: 0,
                g: 0,
                b: 0,
                a: 0,
              },
              white: {
                r: 0,
                g: 0,
                b: 0,
                a: 0,
              },
            },
          },
        })
        ctx.putImageData(img.image.data, 0, 0)
        resolve()
      }
    } catch (ex) {
      reject(ex)
    }
  })
}

const ModalImageEdit = (props: ModalDocumentTypeProps) => {
  const { open, onClose, onAdd, dataUrl } = props
  const [t, i18n] = useTranslation()
  const handleOk = () => {
    if (inputCanvasElement && outputCanvasElement) {
      inputCanvasElement.toBlob((inputBlob: any) => {
        if (isPreview) {
          outputCanvasElement.toBlob((outputBlob: any) => {
            onAdd(outputBlob, inputBlob)
          })
        } else {
          onAdd(inputBlob, inputBlob)
        }
      })
    }
  }

  useEffect(() => {
    if (open) {
    }
  }, [open])

  const [isPreview, setIsPreview] = useState(true)
  const [isOutputProcessing, setIsOutputProcessing] = useState(false)
  const [algorithm, setAlgorithm] = useState('threshold')
  const [thresholdValue, setThresholdValue] = useState(127)
  const [thresholdDisplayValue, setThresholdDisplayValue] = useState(
    thresholdValue,
  )
  const [
    inputCanvasElement,
    setInputCanvasElement,
  ] = useState<HTMLCanvasElement>()
  const [
    outputCanvasElement,
    setOutputCanvasElement,
  ] = useState<HTMLCanvasElement>()

  const handleThresholdChange = (e: any, value: any) => {
    setThresholdDisplayValue(value)
  }

  const handleThresholdChangeComitted = (e: any, value: any) => {
    setThresholdValue(value)
  }

  const handlePreviewChange = (e: any, checked: boolean) => {
    setIsPreview(checked)
  }

  const handleAlgorithmChange = (e: any, algorithm: string) => {
    setAlgorithm(algorithm)
  }

  useEffect(() => {
    if (dataUrl && dataUrl !== '' && inputCanvasElement) {
      fillCanvas(dataUrl, inputCanvasElement, false, null)
    }
  }, [inputCanvasElement, dataUrl])

  useEffect(() => {
    // const sleep = (ms: number) =>
    //   new Promise((resolve) => setTimeout(resolve, ms))
    const fill = async () => {
      if (dataUrl && dataUrl !== '' && outputCanvasElement) {
        setIsOutputProcessing(true)
        await fillCanvas(dataUrl, outputCanvasElement, true, {
          algorithm,
          thresholdValue,
        } as FilterOption)
        setIsOutputProcessing(false)
      }
    }

    fill()
  }, [outputCanvasElement, dataUrl, algorithm, thresholdValue])

  const getInputCanvasContext = useCallback((node) => {
    if (node !== null) {
      setInputCanvasElement(node)
    }
  }, [])
  const getOutputCanvasContext = useCallback((node) => {
    if (node !== null) {
      setOutputCanvasElement(node)
    }
  }, [])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        {t('Please adjust the threshold value for a clear image')}
        <br />
        {/* <br />
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Convert to black and white</FormLabel>
          <Switch
            disabled={isOutputProcessing}
            checked={isPreview}
            onChange={handlePreviewChange}
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </FormControl>
        <br /> */}
        {isPreview && (
          <FormControl component='fieldset'>
            {/* <FormLabel component='legend'>Algorithm</FormLabel> */}
            <RadioGroup
              row
              aria-label='Algorithm'
              value={algorithm}
              onChange={handleAlgorithmChange}
            >
              {/* <FormControlLabel
                value='atkinson'
                control={<Radio disabled={isOutputProcessing} />}
                label='Atkinson'
              /> */}
              <FormControlLabel
                value='threshold'
                control={<Radio disabled={isOutputProcessing} />}
                label={`${t('Threshold')}(${thresholdDisplayValue})`}
              />
            </RadioGroup>
          </FormControl>
        )}
        {isPreview && algorithm === 'threshold' && (
          <Grid container spacing={2}>
            <Grid item xs>
              <Slider
                disabled={isOutputProcessing}
                min={0}
                max={255}
                marks={thresholdMarks}
                value={thresholdDisplayValue}
                onChangeCommitted={handleThresholdChangeComitted}
                onChange={handleThresholdChange}
                valueLabelDisplay='on'
              />
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <div>
          <canvas
            ref={getInputCanvasContext}
            style={{
              position: 'relative',
              height: '100%',
              width: '100%',
              top: '0px',
              left: '0px',
              display: isPreview ? 'none' : 'block',
            }}
          >
            Your browser does not support the HTML5 canvas tag.
          </canvas>
          <LoadingOverlay
            active={isOutputProcessing}
            spinner
            styles={{
              overlay: (base: any) => ({
                ...base,
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#0091da',
              }),
              spinner: (base: any) => ({
                ...base,
                '& svg circle': {
                  stroke: 'rgba(0, 145, 218, 0.5)',
                },
              }),
            }}
            text='Processing...'
          >
            <canvas
              ref={getOutputCanvasContext}
              style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                top: '0px',
                left: '0px',
                display: isPreview ? 'block' : 'none',
              }}
            >
              Your browser does not support the HTML5 canvas tag.
            </canvas>
          </LoadingOverlay>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {t('Cancel')}
        </Button>
        <Button onClick={handleOk} color='primary'>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ModalImageEdit
