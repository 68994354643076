import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Button, Grid, Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
// import Select from 'react-select'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import axios from 'axios'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { initUserProfile } from 'client/actions/userProfile'
import { DRAW_WIDTH } from 'client/components/appDrawer'
import { WithLogoutBtn } from 'client/components/logoutBtn'
import OverlayLoading from 'client/components/overlayLoading'
import SnackbarNotification from 'client/components/snackbarNotification'
import clsx from 'clsx'
import {
  buildId,
  defaultLang,
  devSecret,
  isDev,
  SESSION_STORAGE_LOCALE,
} from 'config'
import moment from 'moment'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IdleTimer from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import Router from 'routes'
import useInterval from 'utils/useInterval'
import { reactPlugin } from 'utils/appInsights'
import './App.css'
import logo from './images/logo-company-name-white-padded.png'
import { authenticate } from 'client/actions'
import _get from 'lodash/get'
import _has from 'lodash/has'

// const browserHistory = createBrowserHistory({ basename: '' })
// var reactPlugin = new ReactPlugin()
// var appInsights = new ApplicationInsights({
//   config: {
//     instrumentationKey: '4ad151dc-d7f6-4a79-a890-13309d22e066',
//     extensions: [reactPlugin],
//     extensionConfig: {
//       [reactPlugin.identifier]: { history: browserHistory },
//     },
//   },
// })
// appInsights.loadAppInsights()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        'min-width': 834,
        margin: 'auto',
      },
    },
    root: {
      display: 'flex',
    },
    paper: {},
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${DRAW_WIDTH}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: DRAW_WIDTH,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -DRAW_WIDTH,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    langBtn: {
      color: '#ffffff',
    },
  }),
)

// const CollisionLink = React.forwardRef((props: any, ref: any) => (
//   <Link innerRef={ref} to='/getting-started/installation/' {...props} />
// ))

// const langSelectStyle = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: 'flex',
//       flexWrap: 'wrap',
//       background: '#0091da',
//     },
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: theme.spacing(1),
//       background: '#0091da',
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//     whiteColor: {
//       color: 'white',
//     },
//     input: {},
//     inputFocused: {
//       width: '100%',
//       backgroundColor: 'rgba(0,145,218, 1)',
//       opacity: 1,
//     },
//     paper: {
//       padding: '5px',
//     },
//   }),
// )

const appBarStyles = makeStyles({
  logo: {
    height: '4rem',
  },
})

export const LangContext = React.createContext(defaultLang)

function App() {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const { oidcUser, logout } = useReactOidc()
  const oidcAccToken = _get(oidcUser, 'access_token')
  // Channel from id token of oidc
  const usrChannel = _get(oidcUser, 'profile.x_channel_code')
  const location = useLocation()

  const idleTimer = useRef(null)

  // in ms
  const timeout = 1200000
  // const [timeout, setTimeout] = useState(10000)
  const [isIdle, setIsIdle] = useState(false)
  const [remaining, setRemaining] = useState(timeout)
  // const [lastActive, setLastActive] = useState(0)
  // const [elapsed, setElapsed] = useState(0)

  const userProfile = useSelector((state: any) => {
    return state.userProfile
  })
  console.log('userProfile: ', userProfile)

  function appIsActive() {
    setIsIdle(false)
  }

  function appIsIdle() {
    setIsIdle(true)
    logout()
  }

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  const [language, setLanguage] = useState(
    sessionStorage.getItem(SESSION_STORAGE_LOCALE) || defaultLang,
  )

  const toggleLanguage = () => {
    const lang = language === 'en' ? defaultLang : 'en'
    sessionStorage.setItem(SESSION_STORAGE_LOCALE, language)
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }

  const dispatch = useDispatch()
  const [t, i18n] = useTranslation()

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_LOCALE, language)
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    // window.scrollTo(0,0)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location.pathname])

  useEffect(() => {
    console.log('oidcUser... ', oidcUser)

    if (
      oidcUser != null &&
      oidcAccToken &&
      oidcUser.token_type != null &&
      oidcUser.id_token != null
    ) {
      const { access_token, token_type, id_token } = oidcUser

      axios.interceptors.request.use(
        (config) => {
          if (config.url && !config.url.includes('api.na1.echosign.com')) {
            config = {
              ...config,
              headers: {
                ...config.headers,
                Authorization: `${token_type} ${access_token}`,
                id_token: `${id_token}`,
              },
              timeout: 240000,
            }
            if (isDev) {
              // skip not allowed header to adobe api
              // Object.assign(config.headers, { devSecret: devSecret })
              config.headers = {
                ...config.headers,
                devSecret,
              }
            }
          }
          console.log('axios.interceptors.request', config)

          return config
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error)
        },
      )
    }
    if (oidcUser && oidcUser.profile) {
      dispatch(initUserProfile(oidcUser.profile))
      dispatch(authenticate(oidcUser))
    }
  }, [oidcAccToken])

  const inactiveRemaining = (second: number) => {
    return moment.utc(second).format('mm:ss')
  }

  // useEffect(() => {
  //   if (!isDev && oidcUser) {
  //     window.onbeforeunload = (e: any) => {
  //       var dialogText = 'Do you really want to leave this site?'
  //       e.returnValue = dialogText
  //       return dialogText
  //     }
  //   }
  // })

  // useInterval(() => {
  // console.log(idleTimer)
  // setRemaining((idleTimer as any).current.getRemainingTime())
  // setLastActive((idleTimer as any).current.getLastActiveTime())
  // setElapsed((idleTimer as any).current.getElapsedTime())
  // }, 1000)

  // TODO: disable console log for production build, enable for sit and uat
  if (!isDev) console.log = function no_console() {}

  // console.log('current chan', oidcUser.profile)

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <LangContext.Provider value={language}>
        <div>
          <div
            style={{
              position: 'fixed',
              right: '5px',
              top: '5px',
              fontSize: '8pt',
              color: 'white',
              zIndex: 1,
            }}
          >
            {buildId}
          </div>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Grid container alignContent='flex-start'>
              <Grid item xs={12}>
                <AppBar position='static'>
                  <Toolbar>
                    <div style={{ flex: 1 }}>
                      <Link to='/home'>
                        <img
                          src={logo}
                          alt='logo'
                          className={appBarStyles().logo}
                        />
                      </Link>
                    </div>

                    {/* <span>Timeout in {inactiveRemaining(remaining)}</span> */}
                    {/* <StyledMuLink to='/' style={{ flex: 1 }}>
                  Home
                </StyledMuLink> */}
                    {oidcUser && oidcUser.profile && (
                      <>
                        <Typography variant='body1'>
                          {t('Hello')},{' '}
                          {`${oidcUser.profile.name} - ${
                            userProfile && userProfile.x_channel_code ? userProfile.x_channel_code === 'FTLIFE' ? 'CTFLIFE' : userProfile.x_channel_code
                            : ''
                          }`}
                          &nbsp;&nbsp;&nbsp;&nbsp;|{' '}
                        </Typography>
                        <WithLogoutBtn />|
                      </>
                    )}
                    <Button
                      className={classes.langBtn}
                      onClick={() => {
                        toggleLanguage()
                      }}
                      size='medium'
                    >
                      {language === 'en' ? '繁' : 'En'}
                    </Button>
                    <IconButton
                      color='inherit'
                      aria-label='open drawer'
                      edge='end'
                      onClick={handleDrawerOpen}
                      className={clsx(open && classes.hide)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid container style={{ padding: '5px' }}>
                <Container maxWidth='md'>
                  {/* <Router> */}
                  {/* {isDev && (
                    <AppDraw open={open} handleDrawerClose={handleDrawerClose} />
                  )} */}
                  {/* <Link to="/f3">F3</Link> */}
                  {/* <StyledLink to={{ pathname: "/f3", search: "?str1=fds" }}>F3</StyledLink> */}

                  <Router channel={usrChannel} />
                  {/* </Router> */}
                </Container>
              </Grid>
            </Grid>
            <SnackbarNotification />
            <OverlayLoading />
          </SnackbarProvider>
        </div>
      </LangContext.Provider>
    </AppInsightsContext.Provider>
  )
}

//export default withAITracking(reactPlugin, App)
export default App
