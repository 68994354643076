import DateFnsUtils from '@date-io/date-fns'
import {
  FormControl,
  FormLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { triggerAsyncId } from 'async_hooks'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface InputDateProps {
  name: string
  label: string
  validationRule: any
  date: any
}

const MUI_DATEPICKER_DATEFORMAT = 'dd/MM/yyyy'
const MOMENT_DATEPICKER_DATEFORMAT = 'DD/MM/YYYY'

const InputDate = (props: InputDateProps) => {
  const { name, label, validationRule, date, ...other } = props

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(2, 0),
    },
    group: {
      margin: theme.spacing(1, 0),
    },
  }))

  const formContext = useFormContext()
  const {
    register,
    errors,
    setValue,
    control,
    trigger,
    // formState: { dirty }
  } = formContext

  const classes = useStyles()
  const [t] = useTranslation()
  //const [value, setValue] = useState(moment(date, 'YYYY-MM-DD').toDate())

  // useEffect(() => {
  //   //const dtStr = moment(date, 'YYYY-MM-DD').toDate()
  //   setValue(date)
  //   setFormValue(name, date, false)
  // }, [date])

  // useEffect(() => {
  //   const dtStr = moment(value).format('YYYY-MM-DD')
  //   setFormValue(name, dtStr, true)
  // }, [value])
  register(name, {
    setValueAs: (value: any) => {
      console.log('setValueAs', value)
      const mt = moment(value, MOMENT_DATEPICKER_DATEFORMAT, true)
      if (mt.isValid()) {
        const dt = mt.toDate()
        const dtStr = moment(dt as Date).format('YYYY-MM-DD')
        console.log('setValueAs, dtStr', dtStr)
        return dtStr
      }
      return null
    },

    ...validationRule,

    validate: {
      isValidFormat: (value: any) => {
        if (!value) {
          return t('Invalid Date Format')
        }
        return true
      },
      ...validationRule.validate,
    },
  })

  useEffect(() => {
    // setValue to the init value which is in format YYYY-MM-DD 
    if (setValue && name && date) {
      setValue(name, date, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [setValue, name, date])

  return (
    <div className={classes.root}>
      <FormControl
        variant='outlined'
        component='fieldset'
        className={classes.formControl}
      >
        <FormLabel htmlFor={`${name}`}>{label}</FormLabel>
        {/* ref to example https://codesandbox.io/s/react-hook-form-v6-controller-forked-fm9bi?file=/src/Mui.js:1135-1276 */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty },
            ) => (
              <KeyboardDatePicker
                inputRef={register}
                invalidDateMessage=''
                margin='normal'
                id='date-picker-dialog'
                format={MUI_DATEPICKER_DATEFORMAT}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={(dt: MaterialUiPickersDate, value) => {
                  console.log('TEST-onChange', dt, value)
                  onChange(dt, value)
                  //trigger(name)
                  setValue(name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
            defaultValue={date}
            name={`${name}`}
            control={control}
          />
        </MuiPickersUtilsProvider>
        {/* <input type='hidden' ref={register} name={name} defaultValue={date}/> */}
        {errors[name] && (
          <Typography color='error'>
            {errors[name] && errors[name].message}
          </Typography>
        )}
      </FormControl>
    </div>
  )
}

export default InputDate
