import { Box, Button } from '@material-ui/core'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import {
  toggleStep,
  initApplicationStepper,
} from 'client/actions/applicationStep'
import {
  ApplicationDto,
  ClientInformationDto,
  ClientInformationDtoIsS800Enum,
} from 'client/api/application/api'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import QontoStepIcon from './badge'
import { QontoConnector, useStyles } from './style'
import {
  INVESTMENT_MANDATE_GROUP,
  CRS,
  SUMMARY_CRS,
  SUMMARY_INVESTMENT,
} from '../steppers'

const STEPPER_SIZE: number = 8

const WithApplicationStepper = (BaseComponent: any, stepId: string) => {
  const Comp = (props: any) => {
    const dispatch = useDispatch()
    // console.log('WithApplicationStepper!!!!', props)
    const userProfile = useSelector((state: any) => {
      return state.userProfile
    })

    const state: ApplicationDto = props.applicationData

    const steps = useSelector((state: any) => {
      const result = state.steps.filter((v: any) => {
        return v.isActive === true
      })
      result
        .filter((v: any) => v.steps == null)
        .forEach((e: any, idx: any) => {
          e.order = idx
        })
      return result
    })

    useEffect(() => {
      if (userProfile && userProfile.x_channel_code) {
        dispatch(initApplicationStepper(userProfile.x_channel_code))
      }
    }, [userProfile])

    useEffect(() => {
      if (
        state &&
        state.clientInformation &&
        state.clientInformation.isS800 === ClientInformationDtoIsS800Enum.Y
      ) {
        dispatch(toggleStep(INVESTMENT_MANDATE_GROUP, false))
        dispatch(toggleStep(CRS, false))
        dispatch(toggleStep(SUMMARY_CRS, false))
        dispatch(toggleStep(SUMMARY_INVESTMENT, false))
      } else {
        dispatch(toggleStep(INVESTMENT_MANDATE_GROUP, true))
        dispatch(toggleStep(CRS, true))
        dispatch(toggleStep(SUMMARY_CRS, true))
        dispatch(toggleStep(SUMMARY_INVESTMENT, true))
      }
    }, [state])

    const [t] = useTranslation()

    const activeStep: number = (() => {
      return steps.findIndex((item: any) => {
        if (item.steps) {
          for (let idx in item.steps) {
            const subItem = item.steps[idx]
            //console.log('subItem.id', subItem.id, 'stepId', stepId)
            if (subItem.id === stepId) return true
          }
          return false
        }
        return item.id === stepId
      })
    })()

    const getBoundedSteps = (index: number) => {
      const viewSteps = steps //steps.filter((v: any) => !v.groupId)

      const d: number = Math.ceil((STEPPER_SIZE - 1) / 2)
      let l: number = Math.max(0, index - d)
      let r: number = l + STEPPER_SIZE
      if (r > viewSteps.length) {
        r = viewSteps.length
        l = r - STEPPER_SIZE
      }
      l = Math.max(0, l)
      return viewSteps.slice(l, r)
    }

    const history = useHistory()
    //console.log('render WithApplicationStepper.Comp', props)
    //const id = props.match.params.id
    const classes = useStyles()

    const findNextStep = (id: string) => {
      let isFound = false
      console.log('findsteps... ', id, steps)
      for (let i in steps) {
        if (steps[i].steps) {
          for (let j in steps[i].steps) {
            // handle skipping page e.g. summary CRS page
            if (isFound && steps[i].steps[j].isActive === true)
              return steps[i].steps[j]
            if (steps[i].steps[j].id === id) isFound = true
          }
        } else {
          if (isFound) return steps[i]
          if (steps[i].id === id) isFound = true
        }
      }

      return null
    }

    const handleNext = (id: string) => {
      const nextStep = findNextStep(stepId)
      if (state && state.hasOwnProperty('id')) {
        id = id || (state as any)['id']
      }

      if (nextStep) {
        if (id) {
          //goToStepWithId(id, activeStep + 1)handleNexthandleNext
          //setTimeout(() => {
          history.push(`/edit/${id}/${nextStep.to}`)
          //}, 0)
        } else {
          //goToStep(activeStep + 1)
          //history.push(`./${nextStep.to}`)
          history.push(`/edit/temp/${nextStep.to}`)
        }
      }
    }

    // const handleBack = () => {
    //   goToStep(activeStep - 1)
    // }

    // const handleReset = () => {
    //   goToStep(0)
    // }

    const getIcon = (id: string) => {
      return (
        '' +
        (1 +
          steps.findIndex((val: any, idx: number) => {
            return id === val.id
          }))
      )
    }

    //console.log('WithApplicationStepper - RENDER!!!', stepId)

    const stepClickHandler = (
      curStep: number,
      renderStepObj: any,
      renderStepIdx: any,
    ) => {
      if (renderStepIdx < curStep) {
        history.push(`./${renderStepObj.to}`)
      }
    }

    return activeStep >= 0 ? (
      <>
        <Stepper
          className={classes.stepper}
          connector={<QontoConnector />}
          alternativeLabel
          activeStep={getBoundedSteps(activeStep).findIndex(
            (val: any, idx: number) => {
              //has sub steps
              return val.id === steps[activeStep].id
            },
          )}
        >
          {getBoundedSteps(activeStep).map((value: any, Index: number) => {
            // console.log('boundedsteps... ', value, Index)
            return (
              <Step key={Index}>
                <StepLabel
                  onClick={() => stepClickHandler(activeStep, value, Index)}
                  StepIconProps={{
                    icon: getIcon(value.id),
                    error: value.error,
                  }}
                  StepIconComponent={QontoStepIcon}
                >
                  {t(value.label)}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {/* TODO - move the title to here of each screen */}
        {/* <Typography align='center' variant='h3' component='h1'>
          {t(getStepLabel(stepId))}
        </Typography> */}
        <Box className={classes.container}>
          <BaseComponent
            stepId={stepId}
            // activeStep={activeStep}
            goNextStep={handleNext}
            {...props}
            Footer={
              <Button variant='contained' color='primary' type='submit'>
                {userProfile && userProfile.x_ictype === 'Y'
                  ? t('Save & Next')
                  : t('Next')}
              </Button>
            }
          />
        </Box>
      </>
    ) : (
      <p>{`${stepId} is not accessible`}</p>
    )
  }

  return Comp
}
export default WithApplicationStepper
