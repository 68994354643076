export default {
  // 'Hong Kong SAR': '香港特別行政區',
  Chinese: '中國',
  American: '美國',
  Australian: '澳洲',
  British: '英國',
  Canadian: '加拿大',
  Filipino: '菲律賓',
  French: '法國',
  Indian: '印度',
  Indonesian: '印度尼西亞',
  Italian: '意大利',
  Japanese: '日本',
  Korean: '韓國',
  Malaysian: '馬來西亞',
  Nepalese: '尼泊爾',
  'New Zealand': '新西蘭',
  Pakistani: '巴基斯坦',
  Philippine: '菲律賓',
  Singaporean: '新加坡',
  Taiwanese: '台灣',
  Thai: '泰國',
}
