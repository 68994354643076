import { createTheme } from '@material-ui/core/styles'
// import './principal.scss'

// const values = {
//   // xs: 0,
//   // sm: 600,
//   md: 960,
//   lg: 1280,
//   xl: 1920,
// };

export default createTheme({
  root: {
    color: {
      primary: '#000000',
      main: '#000000',
      // piCyan: '#00C4D9',
      // piDarkBlue: '#0076CF'
    },
  },
  palette: {
    primary: {
      main: '#0091da',
      light: '#00C4D9',
    },
    secondary: {
      main: '#0091da',
      light: '#464E7E',
    },
    // piCyan: {
    //   main: '#00C4D9'
    // },
    // piMidnight: {
    //   main: '#464E7E'
    // },
    // piDarkBlue: {
    //   main: '#0076CF'
    // }
  },
  typography: {
    fontFamily:
      '"FS Elliot Web Regular", "Microsoft JhengHei", "Arial", arial, sans-serif',
    h1: {
      fontSize: 36,
      fontWeight: 700,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
    },
    h3: {
      fontSize: 28,
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
    },
    h5: {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  // breakpoints: {
  //   keys: ['md', 'lg', 'xl'],
  //   up: key => `@media (min-width:${values[key]}px)`,
  // },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: '700',
        margin: '10px',
        '&:hover': {},
        borderRadius: '25px',
        textTransform: 'capitalize',
        padding: '8px 28px',
        fontSize: '14px',
      },
    },
    MuiBox: {
      root: {
        margin: '10px 0',
      },
    },
    MuiPaper: {
      elevation0: {
        backgroundColor: '#fafafa',
      },
      elevation1: {
        padding: '16px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'rgb(0,0,0,0)',
        },
      },
    },
    MuiRadio: {
      // colorSecondary: {
      //   color: '# custom color',
      //   '&$checked': {
      //     color: '#custom color',
      //   },
      // },
    },
    MuiTextField: {
      root: {
        margin: '8px 0',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: 8,
          '& $input': {
            padding: '6px 0',
          },
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '14px 10px',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#999999',
      },
      outlined: {
        transform: 'translate(14px, 15px) scale(1)',
      },
    },
    MuiTypography: {
      colorError: {
        padding: '0 10px',
      },
    },
    MuiSvgIcon: {
      colorError: {
        verticalAlign: 'middle',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000000',
      },
      // focused: {
      //   color: '#000000'
      // }
    },
    MuiStepLabel: {
      iconContainer: {
        zIndex: '999',
      },
      label: {
        fontSize: '12px',
        color: '#C7C7C7',
        fontFamily:
          '"FS Elliot Web Bold", "FS Elliot Web Regular", Arial, arial, sans-serif',
        '&$active': {
          color: '#0091da',
        },
        '&$completed': {
          color: '#0091da',
        },
      },
    },
  },
})
