import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context'
import axios from 'axios'
import { fetchApplicationData } from 'client/actions'
import WithApplicationStepper from 'client/components/withApplicationStepper'
//import F1 from 'client/components/form1'
import { devSecret, isDev } from 'config'
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import formatHttpError from 'utils/httpErrorFormatter'
import {
  INVESTMENT_MANDATE,
  INVESTMENT_MANDATE_VOLUNTEER,
  ACCOUNT_CONSOLIDATION,
  IDENTITY_UPLOAD,
  ADDRESS,
  TERMS_PERSONAL_INFORMATION_COLLECTION,
  TERMS_PERSONAL_INFORMATION,
  TERMS_DISCLOSURE_INFORMATION,
  TERMS_ADOBE,
  CUSTOMER_INFORMATION,
  SUMMARY_CUSTOMER_INFO,
  SUMMARY_INVESTMENT,
  SUMMARY_ACCOUNT_CONSOLIDATION,
  SUMMARY_CRS,
  CRS,
  AXA_REGULATED_ACTIVITIES_STEP,
  DOCUMENT_UPLOAD,
  SCREEN_GUIDE,
} from 'client/components/steppers'
import { FT_LIFE_REGULATED_ACTIVITIES_STEP } from 'client/components/steppers/ftLife'
import { TAIPING_CLINET_INFORMATION, TAIPING_DISCLOSURE, TAIPING_IMPORTANT_NOTE } from 'client/components/steppers/taiping'
import { appInsights } from 'utils/appInsights'
import { ApplicationDto } from 'client/api/application/api'

const Edit: FunctionComponent<any> = ({ match }) => {
  const { path, params } = match
  const [isLoaded, setIsLoaded] = useState(false)
  const applicationData: ApplicationDto = useSelector((state: any) => {
    return state.application.data
  })
  const { oidcUser } = useReactOidc()
  const isApiAuth = useSelector((state: any) => state.application.isApiAuth)
  const dispatch = useDispatch()
  useEffect(() => {
    if (isApiAuth) {
      Promise.resolve(dispatch(fetchApplicationData(params.id)) as any)
        .then(() => {
          setIsLoaded(true)
        })
        .catch((error) => {
          // alert(formatHttpError(error))
          appInsights.trackEvent({
            name: 'RemoteRequestErr-Edit',
            properties: {
              data: error,
            },
          })
        })
    }
  }, [isApiAuth])

  if (!isLoaded) return null

  /**
   * For render function using <Route> component
   *
   * @param {string} path file path of component
   * @param {string} stepperID ID for material UI stepper
   *q-
   */
  function renderFunc(path: any, stepperID: string, props: any) {
    const Comp = React.lazy(path)
    const WrappedComp = withOidcSecure(WithApplicationStepper(Comp, stepperID))
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <WrappedComp {...props} applicationData={applicationData} />
      </Suspense>
    )
  }
  return (
    <Switch>
      <Route
        path={`${path}/personal-information-collection-statement`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/termsPersonalInfo'),
            TERMS_PERSONAL_INFORMATION_COLLECTION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/personal-information-collection`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/participationAgreement'),
            TERMS_PERSONAL_INFORMATION,
            { ...props, mode: 'edit' },
          )
        }
      />

      <Route
        path={`${path}/disclosure-of-necessary-information`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/ftExtraCustPics'),
            TERMS_DISCLOSURE_INFORMATION,
            { ...props, mode: 'edit' },
          )
        }
      />

      <Route
        path={`${path}/adobe-terms-and-conditions`}
        render={(props: any) =>
          renderFunc(() => import('routes/termsAdobe'), TERMS_ADOBE, {
            ...props,
            mode: 'edit',
          })
        }
      />
      <Route
        path={`${path}/customer-information`}
        render={(props: any) =>
          renderFunc(
            () => import('client/components/form1'),
            CUSTOMER_INFORMATION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-customer-info`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/summaryCustomer'),
            SUMMARY_CUSTOMER_INFO,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-investment-mandate`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/summaryInvestment'),
            SUMMARY_INVESTMENT,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-account-consolidation`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/summaryAccountConsolidation'),
            SUMMARY_ACCOUNT_CONSOLIDATION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-crs`}
        render={(props: any) =>
          renderFunc(
            // () => import('routes/summaryCrs'),
            () => import('routes/formCrs'),
            SUMMARY_CRS,
            { ...props, mode: 'edit', readOnly: true },
          )
        }
      />
      {/* <Route
        path={`${path}/summary`}
        render={(props: any) =>
          renderFunc(() => import('routes/summary'), SUMMARY, props)
        }
      /> */}
      <Route
        path={`${path}/address`}
        render={(props: any) =>
          renderFunc(() => import('routes/addressForm'), ADDRESS, {
            ...props,
            mode: 'edit',
          })
        }
      />
      <Route
        path={`${path}/crs`}
        render={(props: any) =>
          renderFunc(() => import('routes/formCrs'), CRS, {
            ...props,
            mode: 'edit',
          })
        }
      />
      <Route
        path={`${path}/axa-regulated-activities`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formAxaRegulatedActivities'),
            AXA_REGULATED_ACTIVITIES_STEP,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/ft-life-regulated-activities`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formFTLifeRegulatedActivities'),
            FT_LIFE_REGULATED_ACTIVITIES_STEP,
            { ...props, mode: 'edit' },
          )
        }
      />
      {/* <Route path={`${path}/f1`} render={props => <WrappedF1 {...props} />} /> */}
      <Route
        path={`${path}/investment-mandate`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formInvestmentMandate'),
            INVESTMENT_MANDATE,
            { ...props, mode: 'edit', type: 'mandate' },
          )
        }
      />
      <Route
        path={`${path}/investment-mandate-volunteer`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formInvestmentMandate'),
            INVESTMENT_MANDATE_VOLUNTEER,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/account-consolidation`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formAccountConsolidation'),
            ACCOUNT_CONSOLIDATION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/identity-upload`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/identityUploadForm'),
            IDENTITY_UPLOAD,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/document-upload`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/documentUploadForm'),
            DOCUMENT_UPLOAD,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/screen-guides`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/screenGuide'),
            SCREEN_GUIDE,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/tp-disclosure-of-necessary-information`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formTPDisclosure'),
            TAIPING_DISCLOSURE,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/tp-client-information`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formTPClientInfo'),
            TAIPING_CLINET_INFORMATION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/tp-important-note`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formTPImportantNote'),
            TAIPING_IMPORTANT_NOTE,
            { ...props, mode: 'edit' },
          )
        }
      />
    </Switch>
  )
}

export default Edit
