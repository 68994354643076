import { INIT } from 'client/actions/userProfile'

const UserProfile = (state: any = null, action: any) => {
  switch (action.type) {
    case INIT:
      return {
        ...action.data,
      }
    default:
      return state
  }
}

export default UserProfile
