import { ADD_FORM1, PPO_BASE_PATH } from '../../config'
import _assign from 'lodash/assign'
import {
  ClientInformationDto,
  ApplicationDto,
  DraftsApi,
  ApplicationResponseDto,
  AxaRegulatedActivitiesDto,
  ConsolidatedAccountDto,
  CrsInformationDto,
  SubmissionsApi,
  PpoAdminSummaryResponseDto,
  PpoAdminCasesApi,
  ClientInformationDtoIsS800Enum,
  ClientInformationDtoAccountTypeEnum,
  ConsolidatedAccountDtoTransferTypeEnum,
  ConsolidatedAccountDtoTransferOptionEnum,
  ConsolidatedAccountDtoTransferPartialOptionsEnum,
  FTLifeRegulatedActivitiesDto,
  PirsesApi,
  PirsRpqDto,
  PirsFnaDto,
  PirsSummaryDto,
  PirsInvestmentAdviceDto,
  ApprovalDto,
} from '../api/application/api'
import { AxiosError, AxiosResponse } from 'axios'
import { BASE_PATH } from 'config'
import {
  enqueueNotification,
  dequeueNotification,
  enqueueNotificationError,
} from './applicationNotification'
import { jsonDateParser } from 'json-date-parser'

let nextTodoId = 0
let nextFormId = 0
export const addTodo = (text: any) => ({
  type: 'ADD_TODO',
  id: nextTodoId++,
  text,
})

export const setVisibilityFilter = (filter: any) => ({
  type: 'SET_VISIBILITY_FILTER',
  filter,
})

export const toggleTodo = (id: any) => ({
  type: 'TOGGLE_TODO',
  id,
})

export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE',
}

export const addForm1 = (data: any) => {
  // let formatData = {
  //   type: ADD_FORM1,
  //   id: nextFormId++,
  //   firstname: data.firstname,
  //   lastname: data.lastname,
  //   chiLastname: data.chiLastname,
  //   chiFirstname: data.chiFirstname,
  //   hkid: data.hkid,
  //   passport: data.passport,
  //   nationality: data.nationality,
  //   gender: data.gender1
  // }
  let formatData = data
  // data.type = ADD_FORM1
  // data.id = nextFormId++
  _assign(formatData, { type: ADD_FORM1 }, { id: nextFormId++ })

  if (data.hkid) formatData.hkid = data.hkid
  if (data.passport) formatData.passport = data.passport
  return formatData
}

///
///
///
export const LOAD_DUMMY_DATA = 'LOAD_DUMMY_DATA'
export const loadDummyData = () => ({
  type: LOAD_DUMMY_DATA,
})

export const ADD_APPLICATION_ERROR = 'ADD_APPLICATION_ERROR'
export const addApplicationError = (errorMessage: string) => ({
  type: ADD_APPLICATION_ERROR,
  errorMessage,
})

export const AUTHENTICATE = 'AUTHENTICATE'
export const authenticate = (oidcUser: any) => ({
  type: AUTHENTICATE,
  oidcUser,
})

export const RESET_APPLICATION_DATA = 'RESET_APPLICATION_DATA'
export const resetApplicationData = () => {
  return (dispatch: any, getState: () => any) => {
    const state = getState()
    if (state && state.userProfile) {
      sessionStorage.removeItem('applicationData')
      dispatch({
        type: RESET_APPLICATION_DATA,
        initData: {
          distributionChannelCode: state.userProfile.x_channel_code,
        },
      })
    }
  }
}

export const UPDATE_APPLICATION_DATA_TO_STORE =
  'UPDATE_APPLICATION_DATA_TO_STORE'
export const updateApplicationDataToStore = (data: ApplicationDto) => ({
  type: UPDATE_APPLICATION_DATA_TO_STORE,
  data,
})

export const UPDATE_CLIENT_INFORMATION = 'UPDATE_CLIENT_INFORMATION'
export const updateCustomerInformation = (data: ClientInformationDto) => ({
  type: UPDATE_CLIENT_INFORMATION,
  data,
})

export const UPDATE_PIRS_RPQ_INFORMATION = 'UPDATE_PIRS_RPQ_INFORMATION'
export const updatePirsRpqInformation = (data: PirsRpqDto) => ({
  type: UPDATE_PIRS_RPQ_INFORMATION,
  data,
})

export const UPDATE_PIRS_SUMMARY = 'UPDATE_PIRS_SUMMARY'
export const updatePirsSummary = (data: PirsSummaryDto) => ({
  type: UPDATE_PIRS_SUMMARY,
  data,
})

export const UPDATE_CRS_INFORMATION = 'UPDATE_CRS_INFORMATION'
export const updateCrsInformation = (data: CrsInformationDto) => ({
  type: UPDATE_CRS_INFORMATION,
  data,
})

export const UPDATE_ACCOUNT_CONSOLIDATION = 'UPDATE_ACCOUNT_CONSOLIDATION'
export const updateAccountConsolidation = (
  data: Array<ConsolidatedAccountDto>,
) => ({
  type: UPDATE_ACCOUNT_CONSOLIDATION,
  data,
})

export const UPDATE_APPLICATION_SIGNATURES = 'UPDATE_APPLICATION_SIGNATURES'
export const updateApplicationSignatures = (
  agentSignatureBase64: string,
  clientSignatureBase64: string,
  witnessSignatureBase64: string,
) => ({
  type: UPDATE_APPLICATION_SIGNATURES,
  data: { agentSignatureBase64, clientSignatureBase64, witnessSignatureBase64 },
})

export const UPDATE_AXA_REGULATED_ACTIVITIES = 'UPDATE_AXA_REGULATED_ACTIVITIES'
// export const updateAxaRegulatedActivities = (
//   data: AxaRegulatedActivitiesDto,
// ) => ({
//   type: UPDATE_AXA_REGULATED_ACTIVITIES,
//   data,
// })

export const UPDATE_REGULATED_ACTIVITIES = 'UPDATE_REGULATED_ACTIVITIES'
export const updateRegulatedActivities = (
  data: AxaRegulatedActivitiesDto | FTLifeRegulatedActivitiesDto,
) => ({
  type: UPDATE_REGULATED_ACTIVITIES,
  data,
})

export const UPDATE_FNA = 'UPDATE_FNA'
export const updateFna = (data: PirsFnaDto) => ({
  type: UPDATE_FNA,
  data,
})

export const REQUEST_DRAFTS = 'REQUEST_DRAFTS'
export const requestDrafts = () => ({
  type: REQUEST_DRAFTS,
})
export const RECEIVE_DRAFTS = 'RECEIVE_DRAFTS'
export const receiveDrafts = () => ({
  type: RECEIVE_DRAFTS,
})
export const REQUEST_SUBMISSIONS = 'REQUEST_SUBMISSIONS'
export const requestSubmissions = () => ({
  type: REQUEST_SUBMISSIONS,
})
export const RECEIVE_SUBMISSIONS = 'RECEIVE_SUBMISSIONS'
export const receiveSubmissions = () => ({
  type: RECEIVE_SUBMISSIONS,
})

export const REQUEST_APPLICATION_DATA = 'REQUEST_APPLICATION_DATA'
export const requestApplicationData = (id: string) => ({
  type: REQUEST_APPLICATION_DATA,
  id,
})

export const RECEIVE_APPLICATION_DATA = 'RECEIVE_APPLICATION_DATA'
export const receiveApplicationData = (data: ApplicationResponseDto) => ({
  type: RECEIVE_APPLICATION_DATA,
  data,
})

export const UPDATE_PIRS_INVESTMENT_ADVICE = 'UPDATE_PIRS_INVESTMENT_ADVICE'
export const updatePirsInvestmentAdvice = (data: PirsInvestmentAdviceDto) => ({
  type: UPDATE_PIRS_INVESTMENT_ADVICE,
  data,
})

export function fetchDrafts(isPirs: boolean) {
  return function (dispatch: any) {
    dispatch(requestDrafts())
    return new Promise(function (resolve, reject) {
      const api: any = isPirs
        ? new PirsesApi(undefined, BASE_PATH)
        : new DraftsApi(undefined, BASE_PATH)
      const fetch: any = isPirs ? api.pirsesGet : api.draftsGet

      fetch
        .call(api, 'summary', {
          transformResponse: [
            function (data: any) {
              return JSON.parse(data, jsonDateParser)
            },
          ],
        })
        .then((result: AxiosResponse<ApplicationResponseDto[]>) => {
          dispatch(receiveDrafts())
          resolve(result.data)
        })
        .catch((error: any) => {
          console.error(error)
          dispatch(receiveDrafts())
          reject(error)
        })
        .finally(function () {
          // always executed
        })
    })
  }
}

export function fetchSubmissions() {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return new Promise(function (resolve, reject) {
      const api = new SubmissionsApi(undefined, BASE_PATH)
      api
        .submissionsGet({
          transformResponse: [
            function (data: any) {
              return JSON.parse(data, jsonDateParser)
            },
          ],
        })
        .then((result: AxiosResponse<ApplicationResponseDto[]>) => {
          dispatch(receiveSubmissions())
          resolve(result.data)
        })
        .catch(function (error) {
          console.error(error)
          dispatch(receiveSubmissions())
          reject(error)
        })
        .finally(function () {
          // always executed
        })
    })
  }
}

function exportPpoCasesToSpreadsheet(
  dispatch: any,
  ids: string[],
  ppoViewStatus: 'ALL' | 'PENDING' | 'PROCESSED',
  spreadsheetExportType: 'CSV' | 'XLSX',
) {
  return new Promise(function (resolve, reject) {
    const api = new PpoAdminCasesApi(undefined, PPO_BASE_PATH)
    api
      .ppoAdminCasesSpreadsheetPost(ppoViewStatus, spreadsheetExportType, ids, {
        responseType: 'blob',
      })
      .then((result: any) => {
        dispatch(receiveSubmissions())
        resolve(result.data)
      })
      .catch(function (error) {
        console.error(error)
        dispatch(receiveSubmissions())
        reject(error)
      })
      .finally(function () {
        // always executed
      })
  })
}

export function exportPendingPpoCasesToCsv(ids: string[]) {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return exportPpoCasesToSpreadsheet(dispatch, ids, 'PENDING', 'CSV')
  }
}

export function exportProcessedPpoCasesToCsv(ids: string[]) {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return exportPpoCasesToSpreadsheet(dispatch, ids, 'PROCESSED', 'CSV')
  }
}

export function exportPendingPpoCasesToXlsx(ids: string[]) {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return exportPpoCasesToSpreadsheet(dispatch, ids, 'PENDING', 'XLSX')
  }
}

export function exportProcessedPpoCasesToXlsx(ids: string[]) {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return exportPpoCasesToSpreadsheet(dispatch, ids, 'PROCESSED', 'XLSX')
  }
}

export function rejectSubmissions(ids: string[], remark: string) {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return new Promise(function (resolve, reject) {
      const api = new PpoAdminCasesApi(undefined, PPO_BASE_PATH)
      api
        .ppoAdminCasesPatch('REJECT', { ids, remark } as ApprovalDto, {
          transformResponse: [
            function (data: any) {
              return JSON.parse(data, jsonDateParser)
            },
          ],
        })
        .then((result: AxiosResponse<Boolean>) => {
          dispatch(receiveSubmissions())
          resolve(result.data)
        })
        .catch(function (error) {
          console.error(error)
          dispatch(receiveSubmissions())
          reject(error)
        })
        .finally(function () {
          // always executed
        })
    })
  }
}

export function confirmSubmissions(ids: string[]) {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return new Promise(function (resolve, reject) {
      const api = new PpoAdminCasesApi(undefined, PPO_BASE_PATH)
      api
        .ppoAdminCasesPatch('CONFIRM', { ids, remark: '' } as ApprovalDto, {
          transformResponse: [
            function (data: any) {
              return JSON.parse(data, jsonDateParser)
            },
          ],
        })
        .then((result: AxiosResponse<Boolean>) => {
          dispatch(receiveSubmissions())
          resolve(result.data)
        })
        .catch(function (error) {
          console.error(error)
          dispatch(receiveSubmissions())
          reject(error)
        })
        .finally(function () {
          // always executed
        })
    })
  }
}

export function fetchPpoCases(start: string, end: string) {
  return function (dispatch: any) {
    dispatch(requestSubmissions())
    return new Promise(function (resolve, reject) {
      const api = new PpoAdminCasesApi(undefined, PPO_BASE_PATH)
      api
        .ppoAdminCasesGet(start, end, {
          transformResponse: [
            function (data: any) {
              return JSON.parse(data, jsonDateParser)
            },
          ],
        })
        .then((result: AxiosResponse<PpoAdminSummaryResponseDto[]>) => {
          dispatch(receiveSubmissions())
          resolve(result.data)
        })
        .catch(function (error) {
          console.error(error)
          dispatch(receiveSubmissions())
          reject(error)
        })
        .finally(function () {
          // always executed
        })
    })
  }
}

// Retrieve client and address info only to autofill repeating fields
export function cloneApplicationFromPirs(pirsReferenceId: string) {
  return function (dispatch: any) {
    dispatch(requestApplicationData(pirsReferenceId))
    return new Promise<void>(function (resolve, reject) {
      const api = new SubmissionsApi(undefined, BASE_PATH)
      api
        .submissionsIdGet(pirsReferenceId)
        .then((result: AxiosResponse<ApplicationResponseDto>) => {
          // console.log('result', result)
          let newData: ClientInformationDto = {
            ...result.data.clientInformation,
          }
          dispatch(updateCustomerInformation(newData))
          resolve()
        })
        .catch(function (error) {
          // handle error
          console.error(error)
          // alert(error)
          dispatch(receiveApplicationData({} as ApplicationResponseDto))
          reject(error)
        })
        .finally(function () {
          // always executed
        })
    })
  }
}

export function fetchApplicationData(id: string) {
  return function (dispatch: any) {
    if (id === 'temp') {
      const applicationJsonData = sessionStorage.getItem('applicationData')
      if (applicationJsonData) {
        dispatch(receiveApplicationData(JSON.parse(applicationJsonData)))
      } else {
        alert('JSON data was not found. Please try again.')
      }
    } else {
      dispatch(requestApplicationData(id))
      return new Promise<void>(function (resolve, reject) {
        const api = new DraftsApi(undefined, BASE_PATH)
        api
          .draftsIdGet(id)
          .then((result: AxiosResponse<ApplicationResponseDto>) => {
            console.log(result)
            dispatch(receiveApplicationData(result.data))
            resolve()
          })
          .catch(function (error) {
            // handle error
            console.error(error)
            // alert(error)
            dispatch(receiveApplicationData({} as ApplicationResponseDto))
            reject(error)
          })
          .finally(function () {
            // always executed
          })
      })
    }
  }
}

function updateApplication(id: any, data: any, isBackground: boolean) {
  const api = new DraftsApi(undefined, BASE_PATH)
  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      api
        .draftsIdPut(id, data)
        .then((result) => {
          resolve(result)
        })
        .catch(function (error) {
          console.error(error)
          reject(error)
        })
        .finally(function () {})
    })
  }
}

// function sleep(second: number) {
//   return (dispatch: any) => {
//     return new Promise(function (resolve, reject) {
//       setTimeout(async () => {
//         resolve(await dispatch(sleep2(second)))
//         alert('sleep done')
//       }, second * 1000)
//     })
//   }
// }

function sleep2(second: number) {
  return (dispatch: any) => {
    return new Promise<void>(function (resolve, reject) {
      setTimeout(() => {
        resolve()
        alert('sleep2 done')
      }, second * 1000)
    })
  }
}

function createPirs(data: any) {
  const api = new PirsesApi(undefined, BASE_PATH)
  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      api
        .pirsesPost(data)
        .then((result: AxiosResponse<ApplicationResponseDto>) => {
          dispatch(receiveApplicationData(result.data))
          resolve(result)
        })
        .catch(function (error) {
          dispatch(
            enqueueNotificationError(
              true,
              new Date().getTime() + '.createApplication',
              error.message,
            ),
          )
          console.error(error)
          reject(error)
        })
        .finally(function () {})
    })
  }
}

function createApplication(data: any) {
  const api = new DraftsApi(undefined, BASE_PATH)
  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      api
        .draftsPost(data)
        .then((result: AxiosResponse<ApplicationResponseDto>) => {
          dispatch(receiveApplicationData(result.data))
          resolve(result)
        })
        .catch(function (error) {
          dispatch(
            enqueueNotificationError(
              true,
              new Date().getTime() + '.createApplication',
              getAxiosErrorMessage(error),
            ),
          )
          console.error(error)
          reject(error)
        })
        .finally(function () {})
    })
  }
}

export function upsertApplicationData(isBackground = false, forceSave = false) {
  return _upsertApplicationData(false, isBackground, forceSave)
}

export function upsertPirsData(isBackground = false, forceSave = false) {
  return _upsertApplicationData(true, isBackground, forceSave)
}

export function _upsertApplicationData(
  isPirs = false,
  isBackground = false,
  forceSave = false,
) {
  return (dispatch: any, getState: () => any) => {
    const data: ApplicationDto = getState().application.data

    /**
     * For existing customer and Contribution Account
     * only display MV and AV for transfer partial options
     */
    if (
      data &&
      data.clientInformation &&
      data.clientInformation.isS800 === ClientInformationDtoIsS800Enum.Y &&
      data.clientInformation.accountType ===
        ClientInformationDtoAccountTypeEnum.CA
    ) {
      data.consolidatedAccounts.forEach((a: ConsolidatedAccountDto) => {
        if (
          a.transferOption === ConsolidatedAccountDtoTransferOptionEnum.P &&
          a.transferPartialOptions
        ) {
          a.transferPartialOptions = a.transferPartialOptions.filter(
            (option: ConsolidatedAccountDtoTransferPartialOptionsEnum) =>
              option !== ConsolidatedAccountDtoTransferPartialOptionsEnum.M &&
              option !== ConsolidatedAccountDtoTransferPartialOptionsEnum.V,
          )
        }
      })
    }
    /**
     * For existing customer and Contribution Account and Account type = CA_2
     * user is not allowed to choose transfer all
     */
    if (data && data.consolidatedAccounts)
      data.consolidatedAccounts.forEach((consolidatedAccount, index) => {
        if (
          data.clientInformation &&
          data.clientInformation.isS800 === ClientInformationDtoIsS800Enum.Y &&
          data.clientInformation.accountType ===
            ClientInformationDtoAccountTypeEnum.CA &&
          consolidatedAccount.transferType ===
            ConsolidatedAccountDtoTransferTypeEnum.CA2 &&
          consolidatedAccount.transferOption ===
            ConsolidatedAccountDtoTransferOptionEnum.A
        ) {
          consolidatedAccount.transferOption = null as any
        }
      })

    const userProfile: any = getState().userProfile

    if (!forceSave && userProfile) {
      //If NON-IC user, skip save draft
      if (userProfile.x_ictype === 'N') {
        return new Promise(function (resolve, reject) {
          const clone = { ...data, isPirs }
          console.log('clone', clone)
          sessionStorage.setItem('applicationData', JSON.stringify(clone))
          resolve({ data: clone })
        })
      }
    }

    const id = (data as any)['_id']
    const notificationId = id || `new_application.${Date.now}`
    dispatch(
      enqueueNotification(isBackground, notificationId, 'Saving...', {
        variant: 'info',
      }),
    )

    if (id) {
      return dispatch(updateApplication(id, data, isBackground))
        .catch((err: any) => {
          dispatch(
            enqueueNotificationError(
              true,
              notificationId,
              getAxiosErrorMessage(err),
            ),
          )
        })
        .finally(async () => {
          dispatch(dequeueNotification(notificationId, isBackground))
        })
    } else {
      if (isPirs) {
        return dispatch(createPirs(data))
          .catch((err: any) => {
            dispatch(
              enqueueNotificationError(
                true,
                notificationId,
                getAxiosErrorMessage(err),
              ),
            )
          })
          .finally(() => {
            dispatch(dequeueNotification(notificationId, isBackground))
          })
      } else {
        return dispatch(createApplication(data))
          .catch((err: any) => {
            dispatch(
              enqueueNotificationError(
                true,
                notificationId,
                getAxiosErrorMessage(err),
              ),
            )
          })
          .finally(() => {
            dispatch(dequeueNotification(notificationId, isBackground))
          })
      }
    }
  }
}

export function getAxiosErrorMessage(err: AxiosError) {
  let msg = 'UNKNOWN ERROR'

  if (err) {
    if (err.message) {
      msg = err.message
    }
    if (
      err.isAxiosError &&
      err.response &&
      err.response.data
    ) {
      msg = `${msg}, Response=${JSON.stringify(err.response.data)}`
    }
  }

  return msg
}
