import React, { useEffect, useState, useReducer } from 'react'
import {
  Grid,
  Typography,
  Button,
  Box,
  withStyles,
  Theme,
  Divider,
  makeStyles,
} from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { Trans } from 'react-i18next'
import UploadImage, {
  OPERATION_CANCELED_BY_THE_USER,
} from 'client/components/uploadImage'
import { BASE_PATH } from 'config'
import {
  ApplicationDto,
  UploadDocumentDto,
  UploadDocumentDtoTypeEnum,
} from 'client/api/application/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateApplicationDataToStore,
  upsertApplicationData,
} from 'client/actions'
import { toggleStepError } from 'client/actions/applicationStep'
import FormError from 'client/components/formErrorMsg'
import validationSchema from 'schema'
import { CancelTokenSource } from 'axios'
import { useTranslation } from 'react-i18next'
import { red } from '@material-ui/core/colors'
import PreviewLoader from './previewLoader'
import { v4 as uuid } from 'uuid'
import ModalDocumentType from 'client/components/modalDocumentType'
import { yupResolver } from '@hookform/resolvers/yup'

interface Document {
  id: string
  documentType: UploadDocumentDtoTypeEnum
  otheDocumentType: string
  imageId: string
  previewImg?: string
  isUploading: boolean
  cancelTokenSource?: CancelTokenSource
}

const CustomButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
}))(Button)

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

const DocumentUploadForm = ({ props, activeStep, stepId, goNextStep }: any) => {
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation()
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  const removeCache = (imageId: string) => {
    const newCache = { ...previewCache }
    if (newCache[imageId]) {
      delete newCache[imageId]
      setPreviewCache(newCache)
    }
  }

  const [previewCache, setPreviewCache] = useState<any>({})
  const [documents, dispatchDocuments] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case 'init':
        console.log('action.uploadDocuments', action.uploadDocuments)
        const list: Document[] = action.uploadDocuments.map(
          (doc: UploadDocumentDto, idx: number) => {
            const id = uuid()
            return {
              id,
              documentType: doc.type,
              otheDocumentType: doc.otherValue,
              imageId: doc.imageId,
              isPreviewloading: false,
              isUploading: false,
              previewImg: previewCache[doc.imageId],
            } as Document
          },
        )
        return list
      case 'add':
        return [...state, action.uploadDocument]
      case 'selected':
        return state.map((doc: Document) => {
          if (doc.id === action.id) {
            doc.previewImg = action.data
            doc.isUploading = true
          }
          return doc
        })
      case 'uploadStarted':
        return state.map((doc: Document) => {
          if (doc.id === action.id) {
            doc.isUploading = true
          }
          return doc
        })
      case 'createCancelTokenSource':
        return state.map((doc: Document) => {
          if (doc.id === action.id) {
            doc.cancelTokenSource = action.token
          }
          return doc
        })
      case 'clear':
        return [
          ...state.map((doc: Document) => {
            if (doc.id === action.id) {
              if (doc.cancelTokenSource) {
                doc.cancelTokenSource.cancel(OPERATION_CANCELED_BY_THE_USER)
              }
              removeCache(doc.imageId)
              doc.imageId = ''
              doc.previewImg = ''
              doc.isUploading = false
            }
            return doc
          }),
        ]
      case 'remove':
        const deletedDocument = state.find(
          (doc: Document) => doc.id === action.id,
        )
        if (deletedDocument) {
          removeCache(deletedDocument.imageId)
        }
        return [
          ...state.filter((doc: any) => {
            return doc.id !== action.id
          }),
        ]
      case 'uploaded':
        return state.map((doc: any) => {
          if (doc.id === action.id) {
            setPreviewCache({ ...previewCache, [action.data]: doc.previewImg })
            doc.imageId = action.data
            doc.isUploading = false
          }
          return doc
        })
      default:
        throw new Error()
    }
  }, [])

  const onFormSubmit = (formData: any) => {
    console.log('!!!formData', formData)
    // for saving using the id after upload complete
    dispatch(
      updateApplicationDataToStore({
        uploadDocuments: documents.map((document: Document) => {
          return {
            imageId: document.imageId,
            type: document.documentType,
            otherValue: document.otheDocumentType,
          } as UploadDocumentDto
        }),
      } as ApplicationDto),
    )

    dispatch(toggleStepError(stepId, false))

    //save via api
    ;(dispatch(upsertApplicationData(true)) as any)
      .then((result: any) => {})
      .catch((error: any) => {
        console.error(error)
      })

    if (goNextStep) {
      goNextStep()
    }
  }

  const hookFormFunc = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema.UPLOAD_DOCUMENT),
  })
  const { handleSubmit, register, setValue, errors, trigger } = hookFormFunc

  const state: any = useSelector((state) => (state as any).application.data)

  useEffect(() => {
    dispatchDocuments({ type: 'init', uploadDocuments: state.uploadDocuments })
  }, [state.uploadDocuments])

  const createCancelTokenSource = (
    componentId: string,
    source: CancelTokenSource,
  ) => {
    dispatchDocuments({
      type: 'createCancelTokenSource',
      id: componentId,
      token: source,
    })
  }

  const clearUpload = (componentId: string) => {
    dispatchDocuments({
      type: 'clear',
      id: componentId,
    })
    setValue(`uploadDocument_${componentId}`, null)
  }

  const removeUpload = (componentId: string) => {
    dispatchDocuments({
      type: 'remove',
      id: componentId,
    })
  }

  const openDialogModal = () => {
    setIsDialogOpen(true)
  }

  const onUploadFailed = (componentId: string, err: any) => {
    clearUpload(componentId)
    //setIsUploading(false)
  }

  const onUploadProgress = (progressEvent: any) => {}

  const onUploadStarted = (componentId: string) => {
    dispatchDocuments({
      type: 'uploadStarted',
      id: componentId,
    })
  }

  const onImageLoaded = (componentId: string, file: any) => {
    if (file instanceof File || file instanceof Blob) {
      console.log(
        'in upload complete... create local img path... ',
        window.URL.createObjectURL(file),
      )
      //setPreviewImg(window.URL.createObjectURL(file))
      dispatchDocuments({
        type: 'selected',
        id: componentId,
        data: window.URL.createObjectURL(file),
      })
    } else {
      alert('Only File and Blob is supported. Please try again')
    }
  }

  const onUploadCompleted = async (componentId: string, imageId: any) => {
    //setIsUploading(false)
    dispatchDocuments({
      type: 'uploaded',
      id: componentId,
      data: imageId,
    })
    setValue(`uploadDocument_${componentId}`, imageId)
    trigger(`uploadDocument_${componentId}`)

    // for saving using the id after upload complete
    dispatch(
      updateApplicationDataToStore({
        uploadDocuments: documents.map((doc: Document, idx: number) => {
          return {
            imageId: doc.imageId,
            type: doc.documentType,
            otherValue: doc.otheDocumentType,
          } as UploadDocumentDto
        }),
      } as ApplicationDto),
    )

    // //save via api
    ;(dispatch(upsertApplicationData(true)) as any)
      .then((result: any) => {})
      .catch((error: any) => {
        console.error(error)
      })
  }

  const [uploadPrefix] = useState(
    state.hasOwnProperty('_id') ? `${(state as any)['_id']}` : `${uuid()}.temp`,
  )

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleDocumentAdd = (
    documentType: UploadDocumentDtoTypeEnum,
    otheDocumentType: string,
  ) => {
    if (documentType) {
      dispatchDocuments({
        type: 'add',
        uploadDocument: {
          id: uuid(),
          documentType: documentType,
          otheDocumentType: otheDocumentType,
          imageId: '',
          isPreviewloading: false,
          isUploading: false,
        } as Document,
      })
    }
    setIsDialogOpen(false)
  }

  return (
    <>
      <div style={{ paddingTop: 30 }}>
        <FormProvider {...hookFormFunc}>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <Grid
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='center'
              spacing={10}
            >
              {documents.map((document: Document, index: number) => {
                return (
                  <Grid container item justifyContent='center' xs={12} key={index}>
                    <Grid item xs={12}>
                      <Typography align='center' variant='h3' component='h1'>
                        <Trans
                          ns='uploadDocumentType'
                          i18nKey={document.documentType}
                        ></Trans>{' '}
                        - {document.otheDocumentType}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ minHeight: '400px', minWidth: '400px' }}
                    >
                      {(document.imageId && document.imageId !== '') ||
                      (document.previewImg && document.previewImg !== '') ? (
                        <PreviewLoader
                          imageId={document.imageId}
                          previewImg={document.previewImg}
                        />
                      ) : (
                        <UploadImage
                          id={document.id}
                          createCancelTokenSource={createCancelTokenSource}
                          uploadEndpoint={`${BASE_PATH}/upload`}
                          onImageLoaded={onImageLoaded}
                          onUploadCompleted={onUploadCompleted}
                          onUploadFailed={onUploadFailed}
                          onUploadStarted={onUploadStarted}
                          onUploadProgress={onUploadProgress}
                          fileName={`${uploadPrefix}.${
                            document.documentType
                          }.${uuid()}`}
                        ></UploadImage>
                      )}

                      <input
                        type='hidden'
                        defaultValue={document.imageId}
                        name={`uploadDocument_${document.id}`}
                        ref={register}
                      />
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='center'
                    >
                      {(document.imageId || document.isUploading) && (
                        <CustomButton
                          variant='contained'
                          color='primary'
                          type='button'
                          onClick={() => {
                            clearUpload(document.id)
                          }}
                        >
                          {document.isUploading
                            ? t('Cancel')
                            : t('Upload Again')}
                        </CustomButton>
                      )}
                      <CustomButton
                        variant='contained'
                        color='primary'
                        type='button'
                        onClick={() => {
                          removeUpload(document.id)
                        }}
                      >
                        {t('Remove')}
                      </CustomButton>
                    </Grid>
                    <FormError
                      errors={errors[`uploadDocument_${document.id}`]}
                    />
                  </Grid>
                )
              })}

              <Grid>
                <Box m={2} pt={3}>
                  <Button
                    disabled={documents && documents.length >= 10}
                    onClick={openDialogModal}
                    variant='contained'
                    color='primary'
                  >
                    {t('Add a new document')}
                  </Button>
                </Box>
              </Grid>
              <Divider className={classes.divider} />
              <Grid
                container
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={
                    documents.filter((doc: any) => {
                      return doc.isUploading
                    }).length > 0
                  }
                >
                  {t('Save & Next')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <ModalDocumentType
        onAdd={handleDocumentAdd}
        onClose={handleDialogClose}
        open={isDialogOpen}
      />
    </>
  )
}

export default DocumentUploadForm
