import React from 'react'
// import Slide from '@material-ui/core/Slide'
// import { TransitionProps } from '@material-ui/core/transitions'
import LoadingOverlay from 'react-loading-overlay'
import styled from 'styled-components'
import { LOADING_MESSAGE } from 'client/reducers/applicationNotification'

const StyledLoadingOverlay = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;

  & > div._loading_overlay_overlay {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    background: #bbbbbb;
  }
`

export default function Loading() {
  return (
    <StyledLoadingOverlay
      active={true}
      spinner
      text={LOADING_MESSAGE}
    ></StyledLoadingOverlay>
  )
}
