import React, { createContext, useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router'
import { PirsRpqDtoPaServiceEnum } from 'client/api/application/api'

import { QontoConnector } from 'client/components/withApplicationStepper/style'
import QontoStepIcon from 'client/components/withApplicationStepper/badge'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const MR_APPLICATION_FORM_STEPS = [
  {
    label: 'Appointment & PICS',
    to: 'create-mr',
  },
  {
    label: 'Address',
    to: 'create-mr-address',
  },
  {
    label: 'Client Agreement & Risk Disclosure Statement',
    to: 'create-mr-client-confirmation',
  },
  {
    label: 'RPQ',
    to: 'rpq',
  },
  {
    label: 'FNA',
    to: 'mr-fna',
  },
  {
    label: 'Investment Advice Record',
    to: 'mr-investment-advice-record',
  },
  {
    label: 'Disclosure of Sales Related Information',
    to: 'create-mr-disclosure',
  },
  {
    label: 'Confirmation of Receiving Doc',
    to: 'mr-summary',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '25px 0',
    },
    stepper: {
      padding: '25px 0',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepClickIcon: {
      cursor: 'pointer',
    },
    stepDisabled: {
      cursor: 'default',
    },
  }),
)

/**
 * @param mode: number (optional)
 *  1 - disable FNA and investment advice page
 */
interface Props {
  activeStep: null | number
  channel?: null | string
  applicationId?: string
  mode?: number
}

export const StepperConf = {
  activeStep: 1,
}
export const StepperContext = createContext(StepperConf)

const MrStepper = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [activeStep, setActiveStep] = React.useState(props.activeStep || 0)
  const [t] = useTranslation()

  const rpqState = useSelector((state: any) => {
    return state.application.data.pirsRpq
  })

  function getSteps() {
    // return ['Personal Information', 'Address', 'Client Information']
    return MR_APPLICATION_FORM_STEPS
  }

  const steps = getSteps()

  useEffect(() => {
    if (props.activeStep) {
      setActiveStep(props.activeStep)
    }
  }, [props.activeStep])
  // if (nextStep) {
  //   setActiveStep(nextStep)
  // }

  return (
    <StepperContext.Provider value={{ activeStep }}>
      <Stepper
        className={classes.stepper}
        connector={<QontoConnector />}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((cur, idx) => {
          if (
            rpqState &&
            rpqState.paService === PirsRpqDtoPaServiceEnum.PAWITHOUTADVICE &&
            (idx === 4 || idx === 5)
          ) {
            return (
              <Step key={idx} completed={false}>
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                >
                  {t(cur.label)}
                </StepLabel>
              </Step>
            )
          } else {
            return (
              <Step key={idx}>
                <StepLabel
                  className={classes.stepClickIcon}
                  onClick={() => {
                    if (idx < activeStep) {
                      if (props.applicationId) {
                        history.push(
                          `/edit-mr/${props.applicationId}/${cur.to}`,
                        )
                      } else {
                        history.push(cur.to)
                      }
                    }
                  }}
                  StepIconComponent={QontoStepIcon}
                >
                  {t(cur.label)}
                </StepLabel>
              </Step>
            )
          }
        })}
      </Stepper>
    </StepperContext.Provider>
  )
}

export default MrStepper
