import { updateApplicationDataToStore } from '.'
import { ApplicationDto } from 'client/api/application/api'

export const INIT = 'INIT'
export const initUserProfile = (data: any) => {
  return (dispatch: any, getState: () => any) => {
    if (data) {
      const state = getState()
      if (state && state.application) {
        const applicationData: ApplicationDto = getState().application.data
        if (applicationData) {
          //update distribution channel code from user profile
          dispatch(
            updateApplicationDataToStore({
              ...applicationData,
              distributionChannelCode: data.x_channel_code,
            }),
          )
        }
      }
      dispatch({
        type: INIT,
        data,
      })
    }
  }
}

export const TEST_CHANGE_CHANNEL = 'TEST_CHANGE_CHANNEL'
export const testChangeChannel = () => {
  return (dispatch: any) => {
    dispatch({
      type: TEST_CHANGE_CHANNEL,
    })
  }
}
