import { withOidcSecure } from '@axa-fr/react-oidc-context'
import { resetApplicationData } from 'client/actions'
import {
  ACCOUNT_CONSOLIDATION,
  ADDRESS,
  AXA_REGULATED_ACTIVITIES_STEP,
  CRS,
  CUSTOMER_INFORMATION,
  DOCUMENT_UPLOAD,
  IDENTITY_UPLOAD,
  INVESTMENT_MANDATE,
  INVESTMENT_MANDATE_VOLUNTEER,
  SUMMARY_ACCOUNT_CONSOLIDATION,
  SUMMARY_CRS,
  SUMMARY_CUSTOMER_INFO,
  SUMMARY_INVESTMENT,
  TERMS_ADOBE,
  TERMS_PERSONAL_INFORMATION,
  TERMS_PERSONAL_INFORMATION_COLLECTION,
} from 'client/components/steppers'
import { FT_LIFE_REGULATED_ACTIVITIES_STEP } from 'client/components/steppers/ftLife'
import WithApplicationStepper from 'client/components/withApplicationStepper'
import React, { FunctionComponent, Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router'
import AddressForm from 'routes/addressForm'
import DocumentUploadForm from 'routes/documentUploadForm'
import FormAccountConsolidation from 'routes/formAccountConsolidation'
import FormInvestmentMandate from 'routes/formInvestmentMandate'
import IdentityUploadForm from 'routes/identityUploadForm'
import ParticipationAgreement from 'routes/participationAgreement'

// const WrappedF1 = withOidcSecure(
//   WithApplicationStepper(F1, CUSTOMER_INFORMATION),
// )
// const WrappedTermsPersonalInfo = withOidcSecure(
//   WithApplicationStepper(
//     TermsPersonalInfo,
//     TERMS_PERSONAL_INFORMATION_COLLECTION,
//   ),
// )
// const WrappedFormAxaRegulatedActivties = withOidcSecure(
//   WithApplicationStepper(
//     FormAxaRegulatedActivties,
//     AXA_REGULATED_ACTIVITIES_STEP,
//   ),
// )

const WrappedAddressForm = withOidcSecure(
  WithApplicationStepper(AddressForm, ADDRESS),
)

const WrappedFormInvestmentMandate = withOidcSecure(
  WithApplicationStepper(FormInvestmentMandate, INVESTMENT_MANDATE),
)

const WrappedFormInvestmentMandateVolunteer = withOidcSecure(
  WithApplicationStepper(FormInvestmentMandate, INVESTMENT_MANDATE_VOLUNTEER),
)

const WrappedFormAccountConsolidation = withOidcSecure(
  WithApplicationStepper(FormAccountConsolidation, ACCOUNT_CONSOLIDATION),
)

const WrappedIdentityUploadForm = withOidcSecure(
  WithApplicationStepper(IdentityUploadForm, IDENTITY_UPLOAD),
)

const WrappedDocumentUploadForm = withOidcSecure(
  WithApplicationStepper(DocumentUploadForm, DOCUMENT_UPLOAD),
)

const WrappedParticipationAgreement = withOidcSecure(
  WithApplicationStepper(ParticipationAgreement, TERMS_PERSONAL_INFORMATION),
)

// const WrappedCrs = withOidcSecure(WithApplicationStepper(FormCRS, CRS))

const Create: FunctionComponent<any> = ({ match }) => {
  const { path } = match
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetApplicationData())
  }, [])

  /**
   * For render function using <Route> component
   *
   * @param {string} path file path of component
   * @param {string} stepperID ID for material UI stepper
   * @param {*} props pass props from render function
   * @returns
   */
  function renderFunc(path: any, stepperID: string, props: any) {
    const Comp = React.lazy(path)
    const WrappedComp = withOidcSecure(WithApplicationStepper(Comp, stepperID))
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <WrappedComp {...props} />
      </Suspense>
    )
  }

  return (
    <Switch>
      <Route
        path={`${path}/personal-information-collection-statement`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/termsPersonalInfo'),
            TERMS_PERSONAL_INFORMATION_COLLECTION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/personal-information-collection`}
        render={(props) => <WrappedParticipationAgreement {...props} />}
      />
      {/* <Route
        path={`${path}/personal-information-collection`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/participationAgreement'),
            TERMS_PERSONAL_INFORMATION,
            { ...props, mode: 'edit' },
          )
        }
      /> */}
      <Route
        path={`${path}/adobe-terms-and-conditions`}
        render={(props: any) =>
          renderFunc(() => import('routes/termsAdobe'), TERMS_ADOBE, {
            ...props,
            mode: 'edit',
          })
        }
      />
      {/* <Redirect from={`${path}/*`} to={`${path}/personal-information-collection`} /> */}
      <Route
        path={`${path}/personal-information-collection-statement`}
        render={(props: any) =>
          renderFunc(() => import('routes/termsAdobe'), TERMS_ADOBE, {
            ...props,
            mode: 'edit',
          })
        }
      />
      <Route
        path={`${path}/customer-information`}
        render={(props: any) =>
          renderFunc(
            () => import('client/components/form1'),
            CUSTOMER_INFORMATION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-customer-info`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/summaryCustomer'),
            SUMMARY_CUSTOMER_INFO,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-investment-mandate`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/summaryInvestment'),
            SUMMARY_INVESTMENT,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-account-consolidation`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/summaryAccountConsolidation'),
            SUMMARY_ACCOUNT_CONSOLIDATION,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/summary-crs`}
        render={(props: any) =>
          renderFunc(
            // () => import('routes/summaryCrs'),
            () => import('routes/formCrs'),
            SUMMARY_CRS,
            { ...props, mode: 'edit', readOnly: true },
          )
        }
      />
      {/* <Route
        path={`${path}/summary`}
        render={(props: any) =>
          renderFunc(() => import('routes/summary'), SUMMARY, props)
        }
      /> */}
      <Route
        path={`${path}/address`}
        render={(props: any) => {
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <WrappedAddressForm {...props} />
            </Suspense>
          )
        }}
      />
      <Route
        path={`${path}/crs`}
        render={(props: any) =>
          renderFunc(() => import('routes/formCrs'), CRS, {
            ...props,
            mode: 'edit',
          })
        }
      />
      <Route
        path={`${path}/axa-regulated-activities`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formAxaRegulatedActivities'),
            AXA_REGULATED_ACTIVITIES_STEP,
            { ...props, mode: 'edit' },
          )
        }
      />
      <Route
        path={`${path}/ft-life-regulated-activities`}
        render={(props: any) =>
          renderFunc(
            () => import('routes/formFTLifeRegulatedActivities'),
            FT_LIFE_REGULATED_ACTIVITIES_STEP,
            { ...props, mode: 'edit' },
          )
        }
      />
      {/* <Route path={`${path}/f1`} render={props => <WrappedF1 {...props} />} /> */}
      <Route
        path={`${path}/investment-mandate`}
        render={(props) => (
          <WrappedFormInvestmentMandate {...props} type='mandate' mode='edit' />
        )}
      />
      <Route
        path={`${path}/investment-mandate-volunteer`}
        render={(props) => (
          <WrappedFormInvestmentMandateVolunteer {...props} mode='edit' />
        )}
      />
      <Route
        path={`${path}/account-consolidation`}
        render={(props) => (
          <WrappedFormAccountConsolidation {...props} mode='edit' />
        )}
      />
      <Route
        path={`${path}/identity-upload`}
        render={(props) => <WrappedIdentityUploadForm {...props} mode='edit' />}
      />
      <Route
        path={`${path}/document-upload`}
        render={(props) => <WrappedDocumentUploadForm {...props} mode='edit' />}
      />
      <Route
        path={`${path}/summary-investment-mandate`}
        render={(props: any) => {
          const SummaryInvestment = React.lazy(
            () => import('routes/summaryInvestment'),
          )
          const WrappedSummaryInvestment = withOidcSecure(
            WithApplicationStepper(SummaryInvestment, SUMMARY_INVESTMENT),
          )
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <WrappedSummaryInvestment {...props} />
            </Suspense>
          )
        }}
      />
      <Route
        path={`${path}/summary-account-consolidation`}
        render={(props: any) => {
          const SummaryAccountConsolidation = React.lazy(
            () => import('routes/summaryAccountConsolidation'),
          )
          const WrappedSummaryAccountConsolidation = withOidcSecure(
            WithApplicationStepper(
              SummaryAccountConsolidation,
              SUMMARY_ACCOUNT_CONSOLIDATION,
            ),
          )
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <WrappedSummaryAccountConsolidation {...props} />
            </Suspense>
          )
        }}
      />
    </Switch>
  )
}

export default Create
