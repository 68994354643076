import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormError from 'client/components/formErrorMsg'
import { natureOfBusiness } from 'config'
import _map from 'lodash/map'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0, 1),
  },
  select: {
    margin: theme.spacing(0, 0, 1),
  },
  formLabel: {
    backgroundColor: '#fff',
  },
}))

// natureOfBusiness.push({ value: '88', label: 'Others' })

export default function InputNob({ nob, otherNob }: any) {
  const classes = useStyles()

  const formContext = useFormContext()
  const {
    register,
    errors,
    // formState: { dirty }
  } = formContext
  const [t] = useTranslation()
  // const [values, setValues] = React.useState({
  //   nob: 'Plesae select',
  //   other: false
  // })
  // const handleNobChange = (
  //   evt: React.ChangeEvent<{ name?: string; value: unknown }>
  // ) => {
  //   setValues(prev => ({
  //     ...prev,
  //     [evt.target.name as string]: evt.target.value,
  //     other: (evt.target.value === '88') ? true : false,
  //   }))
  // }

  // const [selected, setSelected] = useState({
  //   selectedOption: '',
  //   other: false,
  // })

  // register({
  //   name: 'nob',
  //   required: 'Please fill in this field'
  // })
  // const handleNobChange = (
  //   // evt: React.ChangeEvent<{ name?: string; value: unknown }>
  //   evt: any, child?: any
  // ): any => {
  //   console.log('change... ', evt.target.value)
  //   setSelected(prev => ({
  //     ...prev,
  //     // [evt.target.name as string]: evt.target.value,
  //     selectedOption: evt.target.value,
  //     other: (evt.target.value === '88') ? true : false,
  //   }))
  // }

  // const handleNobChange = (selectedOption: any) => {
  //   let other = false
  //   console.log('channge... ', selectedOption)
  //   if (selectedOption.value === '88') other = true
  //   setSelected({ selectedOption, other })
  // }

  function handleNobChange(event: any) {
    const nob = parseInt(event.target.value)
    setValue(nob)
    formContext.setValue('nob', nob, {
      shouldValidate: true,
      shouldDirty: true,
    })
    if (nob === 88) {
      setOther('')
    }
  }

  function handleOtherNobChange(event: any) {
    const val = event.target.value
    setOther(val)
    //formContext.setValue('otherNob', val, true)
  }

  const [value, setValue] = useState(nob)
  const [other, setOther] = useState(otherNob)

  useEffect(() => {
    setValue(nob)
  }, [nob])
  useEffect(() => {
    setOther(otherNob)
  }, [otherNob])

  const MSG_PLEASE_FILL_IN_THIS_FIELD = t('Please fill in this field')

  return (
    <FormControl variant='outlined' className={classes.formControl} fullWidth>
      <InputLabel
        classes={{ outlined: classes.formLabel }}
        htmlFor='nob-select'
      >
        {t('Nature of Business')}
      </InputLabel>
      <Select
        value={value || ''}
        onChange={handleNobChange}
        className={classes.select}
      >
        {_map(natureOfBusiness, (val: string, idx: number) => {
          return (
            <MenuItem key={idx} value={idx}>
              {t(val)}
            </MenuItem>
          )
        })}
        <MenuItem key='88' value={88}>
          {t('Others')}
        </MenuItem>
      </Select>
      <input
        name='nob'
        type='hidden'
        value={value || ''}
        ref={register({
          required: MSG_PLEASE_FILL_IN_THIS_FIELD,
        })}
      />
      <FormError errors={errors.nob} />

      {value === 88 && (
        <>
          <TextField
            variant='outlined'
            margin='normal'
            defaultValue={other || ''}
            onChange={handleOtherNobChange}
            fullWidth
            name='otherNob'
            placeholder={t('Nature of Business')}
            inputRef={register({
              required: MSG_PLEASE_FILL_IN_THIS_FIELD,
            })}
          />
          {/* <input
            name='otherNob'
            type='hidden'
            value={other || ''}
            ref={register({
              required: MSG_PLEASE_FILL_IN_THIS_FIELD,
            })}
          /> */}
        </>
      )}
      <FormError errors={errors.otherNob} />
    </FormControl>
  )
}
