import { createStyles, TableCell } from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  tabs: {},
  tablecell: {},
  textarea: {
    width: '350px',
  },
}))

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: 'small',
    },
    body: {
      fontSize: 'small',
    },
  }),
)(TableCell)

export const StyledTableAppendixCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: 'small',
      minWidth: '200px',
    },
    body: {
      fontSize: 'small',
      minWidth: '200px',
    },
  }),
)(TableCell)
