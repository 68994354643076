import _ from 'lodash'
import {
  ENQUEUE_NOTIFICATION,
  DEQUEUE_NOTIFICATION,
  SHOW_LOADING,
  HIDE_LOADING,
} from 'client/actions/applicationNotification'

export const LOADING_MESSAGE = 'Loading...'

const ApplicationNotification = (
  state: any = {
    isLoading: false,
    notifications: [],
    notificationsAdded: [],
  },
  action: any,
) => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
        loadingMessage: action.data || LOADING_MESSAGE,
      }
    case HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    case ENQUEUE_NOTIFICATION:
      if (action.isBackground) {
        return {
          ...state,
          notificationsAdded: [
            {
              key: action.key,
              message: action.message,
              options: action.options,
            },
          ],
          notifications: [
            ...state.notifications,
            {
              key: action.key,
              message: action.message,
              options: action.options,
            },
          ],
        }
      } else {
        return {
          ...state,
          loadingMessage: LOADING_MESSAGE,
          isLoading: true,
        }
      }

    case DEQUEUE_NOTIFICATION:
      //remove an item which key is matched
      const prevNotification = [...state.notifications]
      const removeIdx = prevNotification.findIndex((n: any) => {
        return n.key === action.key
      })
      prevNotification.splice(removeIdx, 1)

      if (action.isBackground) {
        return {
          ...state,
          notifications: prevNotification,
          notificationsRemoved: [action.key],
        }
      } else {
        return {
          ...state,
          isLoading: false,
        }
      }

    default:
      return state
  }
}

export default ApplicationNotification
