import React, { useEffect, useState, useReducer, useMemo, useRef, useContext } from 'react'
import {
  Grid,
  Typography,
  Button,
  Box,
  withStyles,
  Theme,
  Divider,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core'
import { LangContext } from 'App'
import { useForm, FormProvider } from 'react-hook-form'
import { Trans } from 'react-i18next'
import UploadImage, {
  OPERATION_CANCELED_BY_THE_USER,
} from 'client/components/uploadImage'
import { BASE_PATH } from 'config'
import {
  ApplicationDto,
  UploadDocumentDto,
  UploadDocumentDtoTypeEnum,
} from 'client/api/application/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateApplicationDataToStore,
  upsertApplicationData,
} from 'client/actions'
import { toggleStepError } from 'client/actions/applicationStep'
import FormError from 'client/components/formErrorMsg'
import { useHistory } from 'react-router'
import validationSchema from 'schema'
import { CancelTokenSource } from 'axios'
import LoadingOverlay from 'react-loading-overlay'
import {
  enqueueNotification,
  dequeueNotification,
  enqueueNotificationError,
} from 'client/actions/applicationNotification'
import { useTranslation } from 'react-i18next'
import { red } from '@material-ui/core/colors'
import PreviewLoader from '../documentUploadForm/previewLoader'
import { v4 as uuid } from 'uuid'
import Slide from '@material-ui/core/Slide'
import { Transition } from 'client/components/common/transition'
import identityDocumentUploadSample from '../../images/identity-document-upload-sample.png'
import { yupResolver } from '@hookform/resolvers/yup'
import DialogNotice from 'client/components/common/noticeDialog'
import UploadImageNoticeDialogEn from './uploadImageNoticeDialogEn'
import UploadImageNoticeDialogZh from './uploadImageNoticeDialogZh'

interface Document {
  id: string
  documentType: string
  imageId: string
  previewImg?: string
  isUploading: boolean
  cancelTokenSource?: CancelTokenSource
}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

const CustomButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
}))(Button)

const IdentityUploadForm = ({
  applicationData,
  activeStep,
  stepId,
  goNextStep,
}: any) => {
  const state: ApplicationDto = applicationData
  const identityDocumentId: string = applicationData.identityDocumentId
  const lang = useContext(LangContext)
  const [isSampleDialogOpen, setIsSampleDialogOpen] = useState(false)
  const [isNoticeDialogOpen, setIsNoticeDialogOpen] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const [t, i18n] = useTranslation()
  const classes = useStyles()

  const removeCache = (imageId: string) => {
    const newCache = { ...previewCache }
    if (newCache[imageId]) {
      delete newCache[imageId]
      setPreviewCache(newCache)
    }
  }

  const [previewCache, setPreviewCache] = useState<any>({})
  const [documents, dispatchDocuments] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case 'init':
        console.log('init identityDocumentId=', action.identityDocumentId)
        return [
          {
            id: uuid(),
            documentType: UploadDocumentDtoTypeEnum.IDENTITYDOCUMENT,
            imageId: action.identityDocumentId,
            isPreviewloading: false,
            isUploading: false,
            previewImg: previewCache[action.identityDocumentId],
          } as Document,
        ]
      case 'selected':
        return state.map((doc: Document) => {
          if (doc.id === action.id) {
            doc.previewImg = action.data
            doc.isUploading = true
          }
          return doc
        })
      case 'uploadStarted':
        return state.map((doc: Document) => {
          if (doc.id === action.id) {
            doc.isUploading = true
          }
          return doc
        })
      case 'createCancelTokenSource':
        return state.map((doc: Document) => {
          if (doc.id === action.id) {
            doc.cancelTokenSource = action.token
          }
          return doc
        })
      case 'clear':
        return [
          ...state.map((doc: Document) => {
            if (doc.id === action.id) {
              if (doc.cancelTokenSource) {
                doc.cancelTokenSource.cancel(OPERATION_CANCELED_BY_THE_USER)
              }
              removeCache(doc.imageId)
              doc.imageId = ''
              doc.previewImg = ''
              doc.isUploading = false
            }
            return doc
          }),
        ]
      case 'uploaded':
        return state.map((doc: Document) => {
          if (doc.id === action.id) {
            setPreviewCache({ ...previewCache, [action.data]: doc.previewImg })
            doc.imageId = action.data
            doc.isUploading = false
          }
          return doc
        })
      default:
        throw new Error()
    }
  }, [] as Document[])

  const onSubmit = (formData: any) => {
    console.log('!!!formData', formData)
    // for saving using the id after upload complete
    dispatch(
      updateApplicationDataToStore({
        identityDocumentId:
          documents && documents.length > 0 ? documents[0].imageId : '',
      } as ApplicationDto),
    )

    dispatch(toggleStepError(stepId, false))

    //save via api
    ;(dispatch(upsertApplicationData(true)) as any)
      .then((result: any) => {})
      .catch((error: any) => {
        console.error(error)
      })

    if (goNextStep) {
      goNextStep()
    }
  }

  const hookFormFunc = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema.UPLOAD_DOCUMENT),
  })
  const { handleSubmit, register, setValue, errors, trigger } = hookFormFunc

  useEffect(() => {
    dispatchDocuments({
      type: 'init',
      identityDocumentId: identityDocumentId,
    })
  }, [identityDocumentId])

  const createCancelTokenSource = (
    componentId: string,
    source: CancelTokenSource,
  ) => {
    dispatchDocuments({
      type: 'createCancelTokenSource',
      id: componentId,
      token: source,
    })
  }

  const clearUpload = (componentId: string) => {
    dispatchDocuments({
      type: 'clear',
      id: componentId,
    })
    setValue(`uploadDocument_${componentId}`, null)
  }

  const onUploadFailed = (componentId: string, err: any) => {
    clearUpload(componentId)
    //setIsUploading(false)
  }

  const onUploadProgress = (progressEvent: any) => {}

  const onUploadStarted = (componentId: string) => {
    dispatchDocuments({
      type: 'uploadStarted',
      id: componentId,
    })
  }

  const onImageLoaded = (componentId: string, file: any) => {
    if (file instanceof File || file instanceof Blob) {
      console.log(
        'in upload complete... create local img path... ',
        window.URL.createObjectURL(file),
      )
      //setPreviewImg(window.URL.createObjectURL(file))
      dispatchDocuments({
        type: 'selected',
        id: componentId,
        data: window.URL.createObjectURL(file),
      })
    } else {
      alert('Only File and Blob is supported. Please try again')
    }
  }

  const onUploadCompleted = (componentId: string, imageId: any) => {
    dispatchDocuments({
      type: 'uploaded',
      id: componentId,
      data: imageId,
    })
  }

  // const uploadPrefix = state.hasOwnProperty('_id')
  //   ? `${(state as any)['_id']}`
  //   : `${uuid()}.temp`
  const uploadPrefix = state.hasOwnProperty('_id')
    ? `${(state as any)['_id']}`
    : `${uuid()}.temp`

  const handleClose = () => {
    setIsSampleDialogOpen(false)
  }

  return (
    <div style={{ paddingTop: 30 }}>
      <FormProvider {...hookFormFunc}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            alignItems='center'
            spacing={10}
          >
            {documents.map((document: Document, index: number) => {
              const showPreview =
                (document.imageId && document.imageId !== '') ||
                (document.previewImg && document.previewImg !== '')
              return (
                <Grid container item justifyContent='center' xs={12} key={index}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      spacing={2}
                    >
                      <Typography align='center' variant='h3' component='h1'>
                        <Trans
                          ns='uploadDocumentType'
                          i18nKey={document.documentType}
                        ></Trans>
                      </Typography>
                      <Button
                        onClick={() => {
                          setIsSampleDialogOpen(true)
                        }}
                        style={{ color: '#0091da' }}
                      >
                        {t('Click to see the sample')}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ minHeight: '400px', minWidth: '400px' }}
                  >
                    {showPreview ? (
                      <PreviewLoader
                        imageId={document.imageId}
                        previewImg={document.previewImg}
                      />
                    ) : null}
                    <div
                      style={{
                        display: showPreview ? 'none' : 'block',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <UploadImage
                        id={document.id}
                        createCancelTokenSource={createCancelTokenSource}
                        uploadEndpoint={`${BASE_PATH}/upload`}
                        onImageLoaded={onImageLoaded}
                        onUploadCompleted={onUploadCompleted}
                        onUploadFailed={onUploadFailed}
                        onUploadStarted={onUploadStarted}
                        onUploadProgress={onUploadProgress}
                        fileName={`${uploadPrefix}.${
                          document.documentType
                        }.${uuid()}`}
                      ></UploadImage>
                    </div>
                    <input
                      type='hidden'
                      defaultValue={document.imageId}
                      name={`uploadDocument_${document.id}`}
                      ref={register}
                    />
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                  >
                    {(document.imageId || document.isUploading) && (
                      <CustomButton
                        variant='contained'
                        color='primary'
                        type='button'
                        onClick={() => {
                          clearUpload(document.id)
                        }}
                      >
                        {document.isUploading ? t('Cancel') : t('Upload Again')}
                      </CustomButton>
                    )}
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <FormError
                      errors={errors[`uploadDocument_${document.id}`]}
                    />
                  </Grid>
                </Grid>
              )
            })}

            <Divider className={classes.divider} />
            <Grid
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={
                  documents.filter((doc: any) => {
                    return doc.isUploading
                  }).length > 0
                }
              >
                {t('Save & Next')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isSampleDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogContent>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <img src={identityDocumentUploadSample} alt='sample' />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color='primary'>
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogNotice
        open={isNoticeDialogOpen}
        handleClose={() => {
          setIsNoticeDialogOpen(false)
        }}
        title={t('IMPORTANT NOTICE')}
      >
        {lang === 'en' ? (
          <UploadImageNoticeDialogEn />
        ) : (
          <UploadImageNoticeDialogZh />
        )}
      </DialogNotice>
    </div>
  )
}

export default IdentityUploadForm
