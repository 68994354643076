import { Button, Grid, Paper } from '@material-ui/core'
import { resetApplicationData } from 'client/actions'
import { adminRole, isDev } from 'config'
import React, { FunctionComponent, useEffect } from 'react'
// import AdapterLink from 'client/components/common/adapterLink'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useStyles } from './style'

const AdapterLink: any = React.forwardRef((props: any, ref: any) => (
  <Link innerRef={ref} {...props} />
))

const Home: FunctionComponent<any> = ({
  channel = null,
  routeProps = null,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userProfile = useSelector((state: any) => {
    return state.userProfile
  })
  const [t] = useTranslation()

  // console.log('home... ', channel, routeProps)

  const adminCheck =
    userProfile && userProfile.role && adminRole.includes(userProfile.role)

  function showEdit() {
    if ((userProfile && userProfile.x_ictype === 'Y') || isDev === true) {
      return true
    }
    return false
  }

  useEffect(() => {
    dispatch(resetApplicationData())
  }, [])

  return (
    <Grid container spacing={2} justifyContent='center' className={classes.outerCont}>
      {adminCheck === true ? (
        <Grid item xs={6}>
          <Paper>
            <Button
              fullWidth
              color='secondary'
              component={AdapterLink}
              to='/ppo-admin'
              className={classes.button}
            >
              {t('Admin page')}
            </Button>
          </Paper>
        </Grid>
      ) : (
        <>
          {(channel === 'MR' || channel === 'BANK') && (
            <>
              <Grid item xs={6}>
                <Paper>
                  <Button
                    fullWidth
                    color='primary'
                    component={AdapterLink}
                    to='/create-mr'
                    className={classes.button}
                  >
                    {t('PIRS Process (New Case)')}
                  </Button>
                </Paper>
              </Grid>
              {showEdit() === true && (
                <Grid item xs={6}>
                  <Paper>
                    <Button
                      fullWidth
                      color='primary'
                      component={AdapterLink}
                      to='/list-mr'
                      className={classes.button}
                    >
                      {t('Edit PIRS Process')}
                    </Button>
                  </Paper>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={6}>
            <Paper>
              <Button
                fullWidth
                // size='large'
                // variant='contained'
                color='primary'
                component={AdapterLink}
                to='/create'
                className={classes.button}
              >
                {t('Create New Application')}
              </Button>
            </Paper>
          </Grid>
          {showEdit() === true && (
            <Grid item xs={6}>
              <Paper>
                <Button
                  fullWidth
                  color='secondary'
                  component={AdapterLink}
                  to='/list-draft'
                  className={classes.button}
                >
                  {t('Edit Draft')}
                </Button>
              </Paper>
            </Grid>
          )}
          <Grid item xs={6}>
            <Paper>
              <Button
                fullWidth
                color='primary'
                component={AdapterLink}
                to='/list-submission'
                className={classes.button}
              >
                {t('Submitted Applications')}
              </Button>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Home
