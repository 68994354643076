import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  outerCont: {
    'margin-top': 30
  },
  scrollCont: {
    overflowY: 'scroll',
    maxHeight: 300,
    border: '#cccccc 1px solid',
  },
  button: {
    width: '100%',
    'min-height': 50,
    'font-size': 24,
    textTransform: 'none',
    margin: '20px auto',
  }
}))
