import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 300,
  },
  labelRoot: {
    backgroundColor: '#ffffff',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
