import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { useTranslation } from 'react-i18next'

import { Transition } from 'client/components/common/transition'

interface Props {
  open: boolean
  handleClose: any
  /**
   * rendered as <DialogTitle>{props.title}</DialogTitle>
   *
   * @type {string}
   * @memberof Props
   */
  title: string
  /** rendered as <DialogContentText>{props.content}</DialogContentText> */
  content: any
}

const ConfirmDialog = (props: Props) => {
  const [t] = useTranslation()
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose('N')} color='primary'>
            {t('Cancel')}
          </Button>
          <Button onClick={() => props.handleClose('Y')} color='primary'>
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmDialog
