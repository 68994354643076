import React from 'react'
import { Link } from '@material-ui/core'

export default function UploadImageNoticeDialogZh() {
  return (
    <div>
      <p>上載圖片時，請確保</p>
      <ol>
        <li>文件圖片清晰</li>
        <li>所有文字清晰可讀</li>
      </ol>
    </div>
  )
}
