import React, { useState, memo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FormHelperText, Link } from '@material-ui/core'
import DialogNotice from 'client/components/common/noticeDialog'

const NationalityProofNotice: React.FunctionComponent = memo(() => {
  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState(false)
  const email = 'pihkprincipalmpf@principal.com'
  const subject = t('IPA – nationality proof')
  return (<>
    <FormHelperText id='nationality-proof-text'>
      <Link onClick={()=> setOpenDialog(true)} style={{ cursor: 'pointer' }}>{t('Please click')}</Link>: {t('Important note about nationality proof')}
    </FormHelperText>
    <DialogNotice
      open={openDialog}
      handleClose={() => {setOpenDialog(false)}}
      title={t('Important note about nationality proof')}
    >
      <p>
        <Trans i18nKey='nationality-proof-notice-body' values={{ email, subject }}>ph1<Link 
          href={`mailto:${email}?subject=${subject}`}
        >ph2</Link></Trans>
      </p>
    </DialogNotice>
  </>)
})

export default NationalityProofNotice
