import { Theme, createStyles, TableCell, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React from 'react'
import { PpoAdminTableHeadRowProps } from '../ppoAdminTableHeadRow'
import { StyledTableCell } from '../style'

export function MrLogPageHeader(props: PpoAdminTableHeadRowProps) {
  const { handleCheckAllClicked, isAllChecked } = props
  return (
    <>
      <StyledTableCell>
        <Checkbox
          checked={isAllChecked}
          onChange={() => handleCheckAllClicked()}
          value='allChecked'
          color='primary'
        />
        <strong>Ref # (PYYMMDD001)</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Record Date (MM/DD/YYYY)</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Client&nbsp;Name</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Forms&nbsp;Submitted</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>RPQ&nbsp;Score</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Risk&nbsp;Level</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Agent&nbsp;Code&nbsp;1</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Agent&nbsp;Code&nbsp;2</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Agent&nbsp;Code&nbsp;3</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Agent&nbsp;Name</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>
          Date&nbsp;of&nbsp;receiving
          <br />
          client&nbsp;instruction
          <br />
          (MM/DD/YYYY)
        </strong>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <strong>
          Vulunerable&nbsp;Client
          <br />
          (Y/N)
        </strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>
          Confirmed&nbsp;/
          <br />
          &nbsp;Rejected
        </strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>Remark</strong>
      </StyledTableCell>
      <StyledTableCell>
        <strong>
          Confirmed&nbsp;/ &nbsp;Rejected Date
          <br />
          (User)
          <br />
          (MM/DD/YYYY)
        </strong>
      </StyledTableCell>
    </>
  )
}
