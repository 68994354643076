import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { useFormContext } from 'react-hook-form'
import {
  FormGroup,
  TextField,
  FormHelperText,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FormError from 'client/components/formErrorMsg'
import _replace from 'lodash/replace'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  formControl: {
    flexDirection: 'row',
    margin: theme.spacing(0),
  },
  group: {
    flexDirection: 'row',
  },
}))

export default function InputIdentity({
  inputIdName,
  inputIdTypeName,
  idNumber,
  idType,
  validateHkid,
}: any) {
  const data = useFormContext()
  const classes = useStyles()
  //const [value, setValue] = useState('')
  const [type, setType] = useState('')
  const [t] = useTranslation()

  useEffect(() => {
    setType(idType)
  }, [idType])

  const getLabel = () => {
    if (type === 'hkid') {
      return t('HKID')
    }

    return t('Passport')
  }

  const MSG_PLEASE_FILL_IN_THIS_FIELD = t('Please fill in this field')

  return (
    <FormControl component='fieldset' className={classes.formControl}>
      <FormGroup row>
        <RadioGroup
          aria-label='identityNumber'
          name={inputIdTypeName}
          className={classes.group}
          value={type || ''}
          onChange={(e: any) => {
            setType(e.target.value)
            //setValue('')
            data.setValue(inputIdName, '', {
              shouldValidate: false,
              shouldDirty: false,
            })
          }}
        >
          <FormControlLabel
            value='hkid'
            control={<Radio />}
            label={t('HKID')}
            inputRef={data.register}
          />
          <FormControlLabel
            value='passport'
            control={<Radio />}
            label={t('Passport')}
            inputRef={data.register}
          />
        </RadioGroup>
        <FormControl>
          <TextField
            defaultValue={idNumber}
            variant='outlined'
            margin='normal'
            fullWidth
            label={getLabel()}
            name={inputIdName}
            onKeyUp={(e: any) => {
              let input = e.target.value
              // prevent symbol ()
              if (type === 'hkid' &&
                (e.keyCode === 57 || e.keyCode === 48)) {
                input = _replace(input, /\(|\)/g, '')
              }
              e.target.value = input.toUpperCase()
            }}
            inputRef={data.register({
              required: MSG_PLEASE_FILL_IN_THIS_FIELD,
              validate: (value: any) => {
                return validateHkid(value)
              },
            })}
          />
          <FormError errors={data.errors[inputIdName]} />
          {type === 'hkid' && (
            <FormHelperText id='component-helper-text'>
              {t('For A123456(7) Please input A1234567')}
            </FormHelperText>
          )}
        </FormControl>
      </FormGroup>
    </FormControl>
  )
}
