import { TableRow, FormControlLabel, Checkbox } from '@material-ui/core'
import {
  ApplicationResponseDtoDistributionChannelCodeEnum,
  ClientInformationDtoIsS800Enum,
  PpoAdminSummaryResponseDto,
  PpoAdminSummaryResponseDtoStatusEnum,
} from 'client/api/application/api'
import moment from 'moment'
import React from 'react'
import { StyledTableCell, useStyles } from './style'
import { FtLifeQueryPageBody } from './ftLife/queryPageBody'
import { GeneralQueryPageBody } from './general/queryPageBody'
import { FtLifeLogPageBody } from './ftLife/logPageBody'
import { GeneralLogPageBody } from './general/logPageBody'
import { MrQueryPageBody } from './mr/queryPageBody'
import { MrLogPageBody } from './mr/logPageBody'
export interface PpoAdminTableRowProps {
  tabIndex: any
  referenceId: any
  selected: any
  handleSelect: any
  value: PpoAdminSummaryResponseDto
  x_channel_code: string
}

export const PpoAdminTableRow = (props: PpoAdminTableRowProps) => {
  const classes = useStyles()
  //console.log('PpoAdminTableRow - render')
  const { tabIndex, value, x_channel_code } = props

  const QueryBody = () => {
    switch (x_channel_code) {
      case ApplicationResponseDtoDistributionChannelCodeEnum.FTLIFE:
        return <FtLifeQueryPageBody {...props} />
      case ApplicationResponseDtoDistributionChannelCodeEnum.MR:
      case ApplicationResponseDtoDistributionChannelCodeEnum.BANK:
        return <MrQueryPageBody {...props} />
      default:
        return <GeneralQueryPageBody {...props} />
    }
  }

  const LogBody = () => {
    switch (x_channel_code) {
      case ApplicationResponseDtoDistributionChannelCodeEnum.FTLIFE:
        return <FtLifeLogPageBody {...props} />
      case ApplicationResponseDtoDistributionChannelCodeEnum.MR:
        return <MrLogPageBody {...props} />
      default:
        return <GeneralLogPageBody {...props} />
    }
  }

  switch (tabIndex) {
    case 0:
      return (
        <TableRow key={value.id} className={classes.tablecell}>
          <QueryBody />
        </TableRow>
      )

    case 1:
    default:
      return (
        <TableRow key={value.id} className={classes.tablecell}>
          <LogBody />
        </TableRow>
      )
  }
}
