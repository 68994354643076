
export const CUSTOMER_INFORMATION_STEP: string = 'CUSTOMER_INFORMATION_STEP'
export const CUSTOMER_INFORMATION = 'CUSTOMER_INFORMATION'
export const ADDRESS = 'ADDRESS'
export const CRS: string = 'CRS'
export const AXA_REGULATED_ACTIVITIES_STEP: string =
  'AXA_REGULATED_ACTIVITIES_STEP'
export const INVESTMENT_MANDATE_GROUP: string = 'INVESTMENT_MANDATE_GROUP'
export const INVESTMENT_MANDATE: string = 'INVESTMENT_MANDATE'
export const INVESTMENT_MANDATE_VOLUNTEER: string =
  'INVESTMENT_MANDATE_VOLUNTEER'
export const ACCOUNT_CONSOLIDATION: string = 'ACCOUNT_CONSOLIDATION'
export const IDENTITY_UPLOAD: string = 'IDENTITY_UPLOAD'
export const DOCUMENT_UPLOAD: string = 'DOCUMENT_UPLOAD'
export const SUMMARY_GROUP: string = 'SUMMARY_GROUP'
export const SUMMARY: string = 'SUMMARY'
export const SUMMARY_ACCOUNT_CONSOLIDATION: string =
  'SUMMARY_ACCOUNT_CONSOLIDATION'
export const SUMMARY_INVESTMENT = 'SUMMARY_INVESTMENT'
export const SUMMARY_CUSTOMER_INFO = 'SUMMARY_CUSTOMER_INFO'
export const SUMMARY_CRS = 'SUMMARY_CRS'
export const AGREEMENT_PARTICIPATION = 'AGREEMENT_PARTICIPATION'
export const TERMS_PERSONAL_INFORMATION_COLLECTION = 'TERMS_PERSONAL_INFORMATION_COLLECTION'
export const TERMS_PERSONAL_INFORMATION = 'TERMS_PERSONAL_INFORMATION'
export const TERMS_DISCLOSURE_INFORMATION = 'TERMS_DISCLOSURE_INFORMATION'
export const TERMS_ADOBE = 'TERMS_ADOBE'
export const SCREEN_GUIDE = 'SCREEN_GUIDE'
