import React from 'react'
import { Link } from '@material-ui/core'

export default function UploadImageNoticeDialogEn() {
  return (
    <div>
      <p>Please make sure the uploaded image is clear and readable</p>
      <ol>
        <li>The document image is clear</li>
        <li>All text is legible</li>
      </ol>
    </div>
  )
}
