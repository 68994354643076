import styled from 'styled-components'

export default styled.section`
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: sans-serif;

  & .dropzone {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
`
