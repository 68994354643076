import React from 'react'
// import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { getBaseName } from 'utils/common'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { Provider } from 'react-redux'
// import logger from 'redux-logger'
import { createBrowserHistory } from 'history'
import { AuthenticationProvider, oidcLog } from '@axa-fr/react-oidc-context'

import './i18n'
import store from './store'
import {
  // baseName,
  isDev,
  oidcClientIdsArr,
  oidcConfiguration,
  oidcDevConfiguration,
  oidcCoreLogoutURI,
  oidcCoreRedirectURI,
  oidcCoreSilentURI,
  requireLogin,
} from 'config'
import { appInsights } from 'utils/appInsights'

// import {
//   OidcAuthenticating,
//   OidcCallback,
//   OidcNotAuthorized,
//   OidcNotAuthenticated,
// } from 'routes/oidcCB'

import CustomCallback from 'routes/oidcCB/callback'
import Authenticating from 'routes/oidcCB/authenticating'
import NotAuthorized from 'routes/oidcCB/notAuthorized'
import NotAuthenticated from 'routes/oidcCB/notAuthenticated'
import SessionLost from 'routes/oidcCB/sessionLost'

// if (!isDev) {
  appInsights.loadAppInsights()
  appInsights.trackPageView()
// }

let oidcConfig = oidcConfiguration
// const getPathArr = path => path.split('/').filter((val) => val.length > 0)
// const curPathArr = getPathArr(window.location.pathname)
// const baseName = curPathArr[0] || 'pa-core'
const baseName = getBaseName()
const history = createBrowserHistory({
  basename: baseName,
})
const BaseNameContext = React.createContext(baseName)

if (isDev) {
  oidcConfig = oidcDevConfiguration
} else {
  // Sentry.init({
  //   dsn: 'https://45ba10b090d74398a860f04e878cc59f@sentry.io/1807887',
  // })
}

// modify oidc config when user access the site from admin login page
const oidcConfigCheck = () => {
  const location = history.location
  // modify scope for core
  if (baseName === 'pa-core') {
    oidcConfig.client_id = oidcClientIdsArr[1]
    oidcConfig.scope = 'openid profile pa-core.agent offline_access'
    oidcConfig.post_logout_redirect_uri = oidcCoreLogoutURI
    oidcConfig.redirect_uri = oidcCoreRedirectURI
    oidcConfig.silent_redirect_uri = oidcCoreSilentURI
  }

  // modify scope for ppo admin
  if (location.pathname === '/adm') {
    switch (baseName) {
      case 'pa-core': {
        oidcConfig.scope = 'openid profile offline_access pa-core.ppo'
        break
      }
      default: {
        oidcConfig.scope = 'openid profile offline_access pa-axa.ppo'
      }
    }
  }
}

oidcConfigCheck()

ReactDOM.render(
  <AuthenticationProvider
    notAuthenticated={NotAuthenticated}
    notAuthorized={NotAuthorized}
    authenticating={Authenticating}
    callbackComponentOverride={CustomCallback}
    sessionLostComponent={SessionLost}
    configuration={oidcConfig}
    loggerLevel={isDev ? oidcLog.DEBUG : 0}
    isEnabled={requireLogin}
  >
    <Provider store={store}>
      {/* <BrowserRouter basename={`/${baseName}`}> */}
      <BrowserRouter basename={baseName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </AuthenticationProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
