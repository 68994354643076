export default {
  // 'Hong Kong SAR': 'Hong Kong SAR',
  Chinese: 'Chinese',
  American: 'American',
  Australian: 'Australian',
  British: 'British',
  Canadian: 'Canadian',
  Filipino: 'Filipino',
  French: 'French',
  Indian: 'Indian',
  Indonesian: 'Indonesian',
  Italian: 'Italian',
  Japanese: 'Japanese',
  Korean: 'Korean',
  Malaysian: 'Malaysian',
  Nepalese: 'Nepalese',
  'New Zealand': 'New Zealand',
  Pakistani: 'Pakistani',
  Philippine: 'Philippine',
  Singaporean: 'Singaporean',
  Taiwanese: 'Taiwanese',
  Thai: 'Thai',
}
