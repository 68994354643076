import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Grid,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { useTranslation } from 'react-i18next'
import {
  AdobeSignAgreementsApi,
  RemoteSigningStatusDto,
  RemoteSigningStatusDtoRejectReasonEnum,
  RemoteSigningStatusDtoStatusEnum,
} from 'client/api/application/api'
import { BASE_PATH } from 'config'
import { string } from 'yup'
import { makeStyles } from '@material-ui/styles'
import { use } from 'i18next'
import {
  showLoading,
  hideLoading,
} from 'client/actions/applicationNotification'
import { useDispatch } from 'react-redux'

import { Transition } from 'client/components/common/transition'

enum ADOBE_MEMEBER_SIGING_STATUS {
  WAITING_FOR_MY_SIGNATURE = 'WAITING_FOR_MY_SIGNATURE',
  NOT_YET_VISIBLE = 'NOT_YET_VISIBLE',
  WAITING_FOR_OTHERS = 'WAITING_FOR_OTHERS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

interface Props {
  remoteSigningStatus?: RemoteSigningStatusDto
  handleClose?: any
  handleEdit?: (applicationId: string) => void
  /**
   * The id of the application
   *
   * @type {string}
   * @memberof Props
   */
  applicationId?: string
  /**
   * The adobeSignAgreementId of the application
   *
   * @type {string}
   * @memberof Props
   */
  adobeSignAgreementId: any
}

const api = new AdobeSignAgreementsApi(undefined, BASE_PATH)

const useStyles = makeStyles((theme) => ({
  root: {
    //width: '100%',
  },
  button: {
    //   marginTop: theme.spacing(1),
    //   marginRight: theme.spacing(1),
  },
  actionsContainer: {
    //   marginBottom: theme.spacing(2),
  },
  resetContainer: {
    //   padding: theme.spacing(3),
  },
}))

const SigningStatusDialog = (props: Props) => {
  const { applicationId, adobeSignAgreementId, remoteSigningStatus } = props
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const [adobeAgreementMembers, setAdobeAgreementMembers] = useState<
    Array<any>
  >([])
  const [participantSets, setParticipantSets] = useState()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const result: any = await api.adobeSignAgreementControllerGetStatus(
        adobeSignAgreementId,
      )
      if (result.data) {
        console.log(result.data)
        const participantSets = result.data.participantSets.sort(
          (a: any, b: any) => {
            return a.order - b.order
          },
        )
        setParticipantSets(participantSets)
        let cnt = 0
        const memebers: any[] = participantSets.reduce(
          (memebers: any[], participantSet: any) => {
            const displayName = participantSet.name
            for (let memberInfo of participantSet.memberInfos) {
              if (
                participantSet.status ===
                ADOBE_MEMEBER_SIGING_STATUS.WAITING_FOR_MY_SIGNATURE
              ) {
                setActiveStep(cnt)
              } else if (
                participantSet.status === ADOBE_MEMEBER_SIGING_STATUS.COMPLETED
              ) {
                setActiveStep(cnt + participantSet.memberInfos.length)
              }
              memebers.push({ ...memberInfo, displayName })
            }
            cnt += participantSet.memberInfos.length
            return memebers
          },
          [],
        )
        if (
          participantSets.findIndex(
            (partiicpantSet: any) =>
              partiicpantSet.status === ADOBE_MEMEBER_SIGING_STATUS.CANCELLED,
          ) > -1
        ) {
          setActiveStep(-1)
        }
        setAdobeAgreementMembers(memebers)
      }
      dispatch(hideLoading())
      setIsOpen(true)
    }
    if (adobeSignAgreementId) {
      dispatch(showLoading(t('Loading data from Adobe Sign')))
      fetchData()
    }
  }, [adobeSignAgreementId])

  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(-1)
  const steps = getSteps()

  const handleClose = () => {
    setIsOpen(false)
    if (props.handleClose) {
      props.handleClose()
    }
  }

  function getSteps() {
    return ['Select campaign settings', 'Create an ad group', 'Create an ad']
  }

  function RejectReason({
    remoteSigningStatus,
    activeStep,
  }: {
    remoteSigningStatus: RemoteSigningStatusDto | undefined
    activeStep: number
  }) {
    if (
      remoteSigningStatus &&
      remoteSigningStatus.status === RemoteSigningStatusDtoStatusEnum.REJECTED
    ) {
      if (
        remoteSigningStatus.rejectReason ===
        RemoteSigningStatusDtoRejectReasonEnum.NOTSIGNEDINHK
      ) {
        return (
          <>
            <Typography>{t('The application is cancelled')}</Typography>
            <Typography>
              {t('The agreeemnt should be signed in Hong Kong')}
            </Typography>
          </>
        )
      }

      return <Typography>{remoteSigningStatus.rejectReason}</Typography>
    } else {
      if (activeStep >= adobeAgreementMembers.length) {
        return (
          <Typography>
            {t('The application is signed and being processed')}
          </Typography>
        )
      } else if (activeStep < 0) {
        return <Typography>{t('The application is cancelled')}</Typography>
      }
    }

    return null
  }

  return (
    <>
      <Dialog open={isOpen} TransitionComponent={Transition} keepMounted>
        <DialogTitle>{t('E-signatures Tracking')}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            <pre>{JSON.stringify(participantSets, null, 2)}</pre>
          </DialogContentText> */}
          <Grid
            spacing={2}
            container
            justifyContent='center'
            direction='column'
            alignItems='center'
          >
            <Stepper
              style={{ background: 'white' }}
              activeStep={activeStep}
              orientation='vertical'
            >
              {adobeAgreementMembers.map((memeber: any, index) => (
                <Step key={index}>
                  <StepLabel>
                    {memeber.displayName || 'N/A'}&nbsp;-&nbsp;{memeber.email}
                  </StepLabel>
                  <StepContent>
                    <Typography>{t('Waiting for signarure')}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            <RejectReason
              remoteSigningStatus={remoteSigningStatus}
              activeStep={activeStep}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid justifyContent='space-between' container>
            {/* {applicationId && (
              <Button
                onClick={() => {
                  if (applicationId && props.handleEdit) {
                    props.handleEdit(applicationId)
                  }
                }}
                color='primary'
              >
                {t('EDIT APPLICATION')}
              </Button>
            )} */}
            <Button onClick={() => handleClose()} color='primary'>
              {t('CLOSE')}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SigningStatusDialog
