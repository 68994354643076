export const nullifyEvt = (e) => {
  e.preventDefault()
  return false
}

export const getBaseName = () => {
  const getPathArr = path => path.split('/').filter((val) => val.length > 0)
  const curPathArr = getPathArr(window.location.pathname)
  const baseName = curPathArr[0] || 'pa-core'
  return baseName
}
