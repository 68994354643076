import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEn from 'locales/en/'
import translationZhHant from 'locales/zh-Hant'
import nationalityEn from 'locales/en/nationality'
import nationalityZhHant from 'locales/zh-Hant/nationality'
import uploadDocumentTypeEn from 'locales/en/uploadDocumentType'
import uploadDocumentTypeZhHant from 'locales/zh-Hant/uploadDocumentType'
import ftLifeEn from 'locales/en/ftLife'
import ftLifeZhHant from 'locales/zh-Hant/ftLife'
import pirsEn from 'locales/en/pirs'
import pirsZhHant from 'locales/zh-Hant/pirs'

export const defaultNS = 'translation'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'zh-Hant',
    resources: {
      en: {
        translation: translationEn,
        nationality: nationalityEn,
        uploadDocumentType: uploadDocumentTypeEn,
        pirs: pirsEn,
      },
      'zh-Hant': {
        translation: translationZhHant,
        nationality: nationalityZhHant,
        uploadDocumentType: uploadDocumentTypeZhHant,
        pirs: pirsZhHant,
      },
    },
    fallbackLng: 'en',
    // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u'],
    // transSupportBasicHtmlNodes: true,
    saveMissing: false,
    debug: false,

    // have a common namespace used around the full app
    ns: [defaultNS],
    defaultNS,

    keySeparator: false, // we use content as keys
    nsSeparator: ':::',

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
